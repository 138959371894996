import React from 'react'
import { render } from '@testing-library/react'
import useToken from '../../../session/userSession'
import { useComityMembersElements } from '../all/UseComityMembers'
import { SERVER_URL } from '../../../../config/environments'
import Toaster from '../../../../common/Toaster/Toaster'

const FETCH_ALL_COMITYMEMBERS_URL = `${SERVER_URL.environment}/estiam`

export function UseDeleteComityMemberElements(
    estiamId: string,
    comityMemberId: string,
) {
    const { mutateComityMembersElements } = useComityMembersElements()
    const { accessToken } = useToken()
    async function deleteComityMember() {
        const response = await fetch(
            `${FETCH_ALL_COMITYMEMBERS_URL}/${estiamId}/estiam-comity-member/${comityMemberId}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        )
        render(<Toaster success toastMsg="SUPPRIMER" />)
        await mutateComityMembersElements()
        return response.json()
    }
    return { deleteComityMember }
}
