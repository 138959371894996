import React, { useCallback, useState, useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { render } from '@testing-library/react'
import {
    unstable_createMuiStrictModeTheme as createMuiTheme,
    ThemeProvider,
    StyledEngineProvider,
} from '@mui/material'
import PageLab from 'components/lab/PageLab'
import PageJobOffers from 'components/job-offers/PageJobOffers'
import { AuthContext } from 'context/auth-context'
import useToken from 'hooks/session/userSession'
import Toaster from 'common/Toaster/Toaster'
import Layout from 'common/layout/Layout'
import NotFound from 'common/error404/Error404'
import Navbar from 'components/navbar/Navbar'
import Performance from 'components/home_page/performance/Performance'
import SuccessStories from 'components/home_page/successStories/SuccessStories'
import OffresEmploi from 'components/home_page/offresEmploi/OffresEmploi'
import ActivitySectorViewHome from 'components/home_page/activity-sector/activity-sector'
import NousFontConfiance from 'components/clients/Clients'
import Banderole from 'components/banderole/Banderole'
import SecteursActivites from 'components/activity-sector/SecteursActivites'
import { AddActivitySector } from 'components/activity-sector/secteursActivitesPopUp/SecteursActivitesPopUp'
import SectoralBusinessSolution from 'components/sectoral-business-solution/SectoralBusinessSolution'
import PageSuccessStories from 'components/successStories/PageSuccessStories'
import TechnicalSolutions from 'components/technical-solutions/TechnicalSolutions'
import { AddTechnicalSolution } from 'components/technical-solutions/add-technical-solution/AddTechnicalSolution'
import { AddSectoralBusinessSolution } from 'components/sectoral-business-solution/sectoral-business-solution/AddSectoralBusinessSolution'
import Experts from 'components/experts/Experts'
import Estiams from 'components/estiam/Estiams'
import Apply from 'components/apply/Apply'
import Contact from 'components/contact/Contact'
import LegalNotices from 'components/legal-notices/LegalNotices'
import CGU from 'components/cgu/CGU'
import JoinUs from 'components/join-us/JoinUs'
import ActivitySectorFavorite from 'components/home_page/favorite-activity-sector/ActivitySectorFavorite'
import TechnicalSolutionFavorite from 'components/home_page/technical-solution/TechnicalSolutionFavorite'
import MapMarkers from 'components/map-markers/MapMarkers'
import HomeView from 'components/home/HomeView'
import TechnicalSolutionViewHome from 'components/technical-solutions/TechnicalSolutionViewHome'
import MatchingCV from 'components/matching-cv/MatchingCV'
import PageTalkingAboutUs from 'components/talkingAboutUs/PageTalkingAboutUs'
import Welcome from 'common/welcome/Welcome'

function App() {
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#ff0033',
            },
            secondary: {
                main: '#ffffff',
            },
        },
    })

    const {
        accessToken,
        setToken,
        tokenClear,
        userEmail,
        lastName,
        firstName,
    } = useToken()

    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
    const [useremail, setUserEmail] = useState<string>('')
    const [userLastName, setUserLastName] = useState<string>('')
    const [userFirstName, setUserFirstName] = useState<string>('')

    const logout = useCallback((e: any) => {
        if (e) {
            setIsLoggedIn(false)
        }
        setIsLoggedIn(false)
        tokenClear()
        render(<Toaster info toastMsg="Déconnecté" id="logout" />)
    }, [])

    const loginData = useCallback(loginToken => {
        try {
            setToken(loginToken)
            render(<Toaster info toastMsg="Connecté" id="login" />)
        } catch (e) {
            logout(e)
        }
    }, [])

    useEffect(() => {
        if (accessToken) {
            setIsLoggedIn(true)
            setUserEmail(userEmail)
            setUserLastName(lastName)
            setUserFirstName(firstName)
        } else {
            setIsLoggedIn(false)
            tokenClear()
        }
    }, [accessToken])

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <AuthContext.Provider
                    value={{
                        accessToken,
                        isLoggedIn,
                        userLastName,
                        userFirstName,
                        useremail,
                        logout,
                        loginData,
                    }}
                >
                    <BrowserRouter>
                        <Layout>
                            <Switch>
                                <Route path="/" exact component={Welcome} />
                                <Route path="/home" component={HomeView} />
                                <Route path="/navbar" component={Navbar} />
                                <Route
                                    path="/banderole"
                                    exact
                                    component={Banderole}
                                />
                                <Route
                                    path="/second-banderole"
                                    component={Performance}
                                />
                                <Route
                                    path="/activity-sector-favorite"
                                    component={ActivitySectorFavorite}
                                />
                                <Route
                                    path="/technical-solution-favorite"
                                    component={TechnicalSolutionFavorite}
                                />
                                <Route
                                    path="/success-stories"
                                    component={SuccessStories}
                                />
                                <Route path="/join-us" component={JoinUs} />
                                <Route
                                    path="/offres-emploi"
                                    component={OffresEmploi}
                                />
                                <Route
                                    path="/nous-font-confiance"
                                    component={NousFontConfiance}
                                />
                                <Route
                                    path="/list-success-stories"
                                    component={PageSuccessStories}
                                />
                                <Route
                                    path="/list-talking-about-us"
                                    component={PageTalkingAboutUs}
                                />
                                <Route
                                    path="/list-offres-emploi"
                                    component={PageJobOffers}
                                />
                                <Route
                                    path="/activity-sector/:id"
                                    component={SecteursActivites}
                                />
                                <Route
                                    path="/add/activity-sector"
                                    component={AddActivitySector}
                                />
                                <Route
                                    path="/activity-sector"
                                    component={ActivitySectorViewHome}
                                />
                                <Route
                                    path="/technical-solution-meta"
                                    component={TechnicalSolutionViewHome}
                                />
                                <Route
                                    path="/technical-solution/:id"
                                    component={TechnicalSolutions}
                                />
                                <Route
                                    path="/add/technical-solution"
                                    component={AddTechnicalSolution}
                                />
                                <Route
                                    path="/nos-metiers/:id"
                                    component={SectoralBusinessSolution}
                                />
                                <Route
                                    path="/add/nos-metiers"
                                    component={AddSectoralBusinessSolution}
                                />
                                <Route
                                    path="/les-experts"
                                    component={Experts}
                                />
                                <Route
                                    path="/matching-cv"
                                    component={MatchingCV}
                                />
                                <Route
                                    path="/map-markers"
                                    component={MapMarkers}
                                />
                                <Route path="/lab" component={PageLab} />
                                <Route path="/postuler" component={Apply} />
                                <Route path="/estiam" component={Estiams} />
                                <Route path="/contact" component={Contact} />
                                <Route
                                    path="/mentions-legales"
                                    component={LegalNotices}
                                />
                                <Route path="/cgu" component={CGU} />
                                <Route exact component={NotFound} />
                            </Switch>
                        </Layout>
                    </BrowserRouter>
                </AuthContext.Provider>
            </ThemeProvider>
        </StyledEngineProvider>
    )
}

export default App
