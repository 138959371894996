import useSWR from 'swr'
import { ActivitySectorElement } from '../../../common/types/activity-sector/ActivitySectorElements'
import { SERVER_URL } from '../../../config/environments'

const FETCH_ALL_ACTIVITY_SECTOR_URL = `${SERVER_URL.environment}/activity-sector`

export function useActivitySectorElements() {
    const {
        data: activitySectorElements,
        mutate: mutateActivitySectorElements,
    } = useSWR<ActivitySectorElement[]>(
        'api/activity-sector/all',
        fetchAllActivitySector,
    )

    async function fetchAllActivitySector() {
        const response = await fetch(`${FETCH_ALL_ACTIVITY_SECTOR_URL}/all`)
        return response.json()
    }
    return { activitySectorElements, mutateActivitySectorElements }
}
