import React, { useState, useEffect } from 'react'
import {
    Box,
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Avatar,
    IconButton,
    TablePagination,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { Client } from 'common/types/clients/ClientsType'
import Aos from 'aos'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { useClientsElements } from 'hooks/clients/all/UseClients'
import ConfirmForm from 'common/confirm-form/ConfirmForm'
import { UseDeleteClientElement } from 'hooks/clients/delete/UseDeleteClient'
import { ClientPopUp } from './clientPopUp/clientPopUp'

export default function NousFontConfiance() {
    const [open, setOpen] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [client, setClient] = useState<any>()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [idClient, setIdClient] = React.useState('')

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage)
    }

    const { clientsElements } = useClientsElements()
    const { deleteClient } = UseDeleteClientElement(idClient)

    const onDelete = () => {
        deleteClient()
        setOpen(false)
    }

    const togglePopup = () => {
        setClient(null)
        setIsOpen(!isOpen)
    }

    const handleClickOpen = async (id: string) => {
        setIdClient(id)
        setOpen(true)
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const onEdit = (client: Client) => {
        setIdClient(client._id)
        setClient(client)
        setIsOpen(true)
    }

    useEffect(() => {
        Aos.init()
    }, [])

    return (
        <div>
            <Grid>
                <Box sx={{ p: 3 }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        startIcon={<AddIcon />}
                        onClick={togglePopup}
                    >
                        Ajouter un client
                    </Button>
                </Box>

                <Paper elevation={16} sx={{ p: 1, width: '100%' }}>
                    <TableContainer component={Paper}>
                        <Table
                            sx={{ minWidth: 650 }}
                            size="small"
                            aria-label="a dense table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Logo</TableCell>
                                    <TableCell>Titre</TableCell>
                                    <TableCell align="left">
                                        Entreprise
                                    </TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clientsElements
                                    ?.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage,
                                    )
                                    .map((row: Client) => (
                                        <TableRow
                                            key={clientsElements.indexOf(row)}
                                            sx={{
                                                '&:last-child td, &:last-child th':
                                                    { border: 0 },
                                            }}
                                        >
                                            <TableCell>
                                                <Avatar
                                                    alt={row.title}
                                                    src={row.logoUrl}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {row.title}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.partner}
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton
                                                    onClick={() => onEdit(row)}
                                                    size="large"
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() =>
                                                        handleClickOpen(row._id)
                                                    }
                                                    size="large"
                                                >
                                                    <DeleteOutlinedIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        labelRowsPerPage="Lignes par page"
                        component="div"
                        count={clientsElements?.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>

                <ConfirmForm
                    onConfirm={onDelete}
                    open={open}
                    setOpen={setOpen}
                    action="delete"
                />
            </Grid>

            {isOpen && (
                <ClientPopUp
                    isOpen={isOpen}
                    handleClose={togglePopup}
                    idClient={idClient}
                    client={client}
                />
            )}
        </div>
    )
}
