/* eslint-disable consistent-return */
import { useState } from 'react'
import jwt from 'jsonwebtoken'
import env from '../../config/environments'

const privateKey = env.REACT_APP_PrivateKey as string

function decode(token: any) {
    try {
        const decoded = jwt.verify(token, privateKey)

        return { valid: true, expired: false, decoded }
    } catch (error: any) {
        return {
            valid: false,
            expired: error.message === 'jwt expired',
            decoded: null,
        }
    }
}

export default function useToken() {
    const getaccessToken = () => {
        const accessToken = localStorage.getItem('accessToken') as string
        if (!accessToken) return undefined
        try {
            const { decoded, expired } = decode(accessToken)

            if (expired) {
                removeToken()
                return undefined
            }

            if (decoded) {
                return accessToken
            }
        } catch (e) {
            removeToken()
            return undefined
        }
        return accessToken
    }

    const isExpired = (): boolean => {
        const accessToken = localStorage.getItem('accessToken') as string
        try {
            const { expired } = decode(accessToken)
            if (expired) {
                return true
            }
            return false
        } catch (error) {
            return true
        }
    }

    const removeToken = () => {
        localStorage.removeItem('accessToken')
    }

    const getUserEmail = (): any => {
        const accessToken = getaccessToken()

        if (!accessToken) return ''

        const { valid, decoded } = decode(accessToken)
        if (valid) {
            const data = decoded as jwt.JwtPayload
            return data.email
        }
        return ''
    }

    const getUserFistName = (): any => {
        const accessToken = getaccessToken()

        if (!accessToken) return ''

        const { valid, decoded } = decode(accessToken)
        if (valid) {
            const data = decoded as jwt.JwtPayload
            return data.firstName
        }
        return ''
    }

    const getUserLastName = (): any => {
        const accessToken = getaccessToken()

        if (!accessToken) return ''

        const { valid, decoded } = decode(accessToken)
        if (valid) {
            const data = decoded as jwt.JwtPayload
            return data.lastName
        }
        return ''
    }

    const saveToken = (userTokens: any) => {
        if (userTokens) {
            setAccessToken(userTokens.accessToken)
            localStorage.setItem('accessToken', userTokens.accessToken)
        }
    }

    const [accessToken, setAccessToken] = useState(getaccessToken())

    return {
        accessToken,
        userEmail: getUserEmail(),
        firstName: getUserFistName(),
        lastName: getUserLastName(),
        setToken: saveToken,
        expired: isExpired(),
        tokenClear: removeToken,
    }
}
