import { render } from '@testing-library/react'
import useToken from '../../session/userSession'
import Toaster from '../../../common/Toaster/Toaster'
import axios from 'axios'
import { useSolutionTechniqueElements } from '../all/useSolutionTechnique'
import { SERVER_URL } from '../../../config/environments'

const FETCH_ALL_SOLUTION_TECHNIQUE_URL = `${SERVER_URL.environment}/technical-solution`

export function useUpdateSolutionTechniqueElement(
    SolutionTechniqueElementId: string,
) {
    const { mutateSolutionTechniqueElements } = useSolutionTechniqueElements()
    const { accessToken } = useToken()

    async function updateSolutionTechnique(body: FormData) {
        await axios({
            method: 'PUT',
            url: `${FETCH_ALL_SOLUTION_TECHNIQUE_URL}/${SolutionTechniqueElementId}`,
            data: body,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                mutateSolutionTechniqueElements()
                render(
                    <Toaster
                        success
                        toastMsg="Multi-solution modifiée avec succès !"
                    />,
                )
                return response
            })
            .catch(error => {
                render(<Toaster error toastMsg={error.response.data.message} />)
            })
    }
    return { updateSolutionTechnique }
}
