import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    Box,
    CardContent,
    CardHeader,
    Card,
    IconButton,
    Avatar,
    FormControl,
    FormHelperText,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Grid,
} from '@mui/material'
import { dropzoneStyle } from 'assets/GlobalStyles'
import { DropzoneDialog } from 'react-mui-dropzone'

import { object, string } from 'yup'
import { useForm } from 'react-hook-form'
import { useDebounce } from 'use-debounce/lib'

import { render } from '@testing-library/react'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { Theme } from '@mui/material/styles'

import './AddComityMember.scss'

import Toaster from 'common/Toaster/Toaster'

import { useAddComityMemberElement } from 'hooks/estiam/comity/addComityMembers/UseAddComityMembers'
import { useEditComityMemberElement } from 'hooks/estiam/comity/editComityMembers/UseEditComityMembers'
import EditorText from '../../../../common/custom-text-editor/CustomEditorText'
import SaveButton from 'common/custom-button/SaveButton'

interface Props {
    _id?: string
    firstName: string
    lastName: string
    role?: string
    imageUrl: string
    quote?: string
}

const schema = object({
    firstName: string(),
    lastName: string(),
    role: string(),
    quote: string(),
    imageUrl: string(),
})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            width: theme.spacing(15),
            height: theme.spacing(15),
            '@media (max-width: 868px)': {
                width: theme.spacing(11),
                height: theme.spacing(11),
            },
        },
    }),
)

export function AddComityMember({
    handleClose,
    estiam,
    isOpen,
    setLoading,
}: any) {
    const classes = useStyles()
    const dropzoneClass = dropzoneStyle()

    const [open, setOpen] = useState(false)
    const [key, setKey] = useState(0)
    const [file, setFile] = useState<any>([])
    const [debounceKey] = useDebounce(key, 1000)
    const [avatarView, setAvatarView] = useState<string>('')
    const [valueQuote, setValueQuote] = useState<string>('')

    const { addComityMember } = useAddComityMemberElement(estiam?.idEstiam)
    const { editComityMember } = useEditComityMemberElement(
        estiam?.idEstiam,
        estiam?.data?._id,
    )

    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<Props>({ resolver: yupResolver(schema) })

    async function onSubmit() {
        setLoading(true)
        try {
            addComityMembers({
                firstName: getValues('firstName'),
                lastName: getValues('lastName'),
                role: getValues('role'),
            })
        } catch (err: any) {
            render(<Toaster error toastMsg={err.response} />)
        }
    }

    const addComityMembers = async (comityMemberFormValues: {
        firstName?: string
        lastName?: string
        role?: string
    }) => {
        try {
            const bodyFormData = new FormData()

            Object.entries(comityMemberFormValues).forEach(([key, value]) => {
                bodyFormData.append(key, value)
            })

            bodyFormData.append('quote', valueQuote ? valueQuote : '')

            if (file) {
                bodyFormData.append('image', file)
                setKey(key + 1)
            }

            if (estiam.data._id != null) {
                bodyFormData.append('_id', estiam.data._id)
            }

            if (estiam.data._id) {
                editComityMember(bodyFormData)
                    .then(res => {
                        if (res === 'OK') {
                            handleClose()
                        }
                    })
                    .finally(() => setLoading(false))
            } else {
                addComityMember(bodyFormData)
                    .then(res => {
                        if (res === 'Created') {
                            handleClose()
                        }
                    })
                    .finally(() => setLoading(false))
            }
        } catch (error: any) {
            setLoading(false)
            render(<Toaster error toastMsg={error?.response?.data.message} />)
        }
    }

    useEffect(() => {
        setValueQuote(estiam?.data?.quote ? estiam?.data?.quote : '')
    }, [])

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
            >
                {estiam.data?._id ? (
                    <DialogTitle>{'Modifier un membre'}</DialogTitle>
                ) : (
                    <DialogTitle>{'Ajouter un membre'}</DialogTitle>
                )}
                <DialogContent>
                    <Card>
                        <CardHeader
                            avatar={
                                <Box sx={{ display: 'grid', flexWrap: 'wrap' }}>
                                    <IconButton
                                        component="span"
                                        onClick={() => setOpen(true)}
                                        size="large"
                                    >
                                        <Avatar
                                            className={classes.avatar}
                                            alt={estiam?.data?.firstName}
                                            src={
                                                file.length == 0
                                                    ? estiam?.data?.imageUrl
                                                    : avatarView
                                            }
                                        />
                                    </IconButton>
                                    <DropzoneDialog
                                        key={debounceKey}
                                        dropzoneClass={dropzoneClass.dropzone}
                                        acceptedFiles={['image/*']}
                                        dropzoneText="Glisser-déposer une image ou rechercher dans les fichiers (max : 10Mo)"
                                        maxFileSize={10000000}
                                        filesLimit={1}
                                        open={open}
                                        onClose={() => setOpen(false)}
                                        onDrop={async files => {
                                            const src =
                                                window.URL.createObjectURL(
                                                    files[0],
                                                )
                                            setAvatarView(src)
                                            setFile(files[0])
                                            setOpen(false)
                                        }}
                                        showAlerts={true}
                                        getDropRejectMessage={resultat =>
                                            `Ce fichier est trop grand , taille de fichier : ${resultat.size} bytes, taille max est 5000000 bytes (5Mo)`
                                        }
                                    />
                                    <Typography variant="caption">
                                        (Max : un image de 5Mo max.)
                                    </Typography>
                                </Box>
                            }
                            title={
                                <>
                                    <FormControl
                                        sx={{ m: 1, width: '40ch' }}
                                        variant="outlined"
                                    >
                                        <TextField
                                            required
                                            fullWidth
                                            label="Prénom"
                                            id="fistName-estiam"
                                            {...register('firstName')}
                                            defaultValue={
                                                estiam?.data?.firstName
                                            }
                                            onChange={e =>
                                                setValue(
                                                    'firstName',
                                                    e.target.value,
                                                )
                                            }
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                        <FormHelperText id="sector-estiam-weight-helper-text">
                                            {errors?.firstName?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </>
                            }
                            subheader={
                                <>
                                    <FormControl
                                        sx={{ m: 1, width: '40ch' }}
                                        variant="outlined"
                                    >
                                        <TextField
                                            required
                                            fullWidth
                                            id="lastName-estiam"
                                            label="Nom"
                                            {...register('lastName')}
                                            defaultValue={
                                                estiam?.data?.lastName
                                            }
                                            onChange={e =>
                                                setValue(
                                                    'lastName',
                                                    e.target.value,
                                                )
                                            }
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                        <FormHelperText id="sector-estiam-weight-helper-text">
                                            {errors?.lastName?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </>
                            }
                        />

                        <CardContent>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControl
                                    sx={{ m: 1, width: '65ch' }}
                                    variant="outlined"
                                >
                                    <TextField
                                        id="role-estiam"
                                        label="Poste"
                                        required
                                        {...register('role')}
                                        defaultValue={estiam?.data?.role}
                                        onChange={e =>
                                            setValue('role', e.target.value)
                                        }
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                    <FormHelperText id="sector-estiam-weight-helper-text">
                                        {errors?.role?.message}
                                    </FormHelperText>
                                </FormControl>
                            </Box>

                            <Grid>
                                <Box>
                                    <Typography variant="h6">
                                        Citation :
                                    </Typography>
                                </Box>
                                <Box sx={{ pb: 1 }}>
                                    <EditorText
                                        currentValue={valueQuote}
                                        updateCurrentValue={setValueQuote}
                                    />
                                    <Typography variant="caption">
                                        (Max : un paragraphe de 10 000
                                        caractères max.)
                                    </Typography>
                                </Box>
                                <span className="error">
                                    {errors?.quote?.message}
                                </span>
                            </Grid>
                        </CardContent>
                    </Card>
                </DialogContent>

                <SaveButton {...{ handleClose }} />
            </form>
        </Dialog>
    )
}
