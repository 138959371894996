import React, { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { cleanup } from '@testing-library/react'
import './Toaster.scss'

const Toaster = (props: any) => {
    const notifyErr = () =>
        toast.error(props.toastMsg, {
            toastId: props.id,
            className: props.classNameToast,
            autoClose: 5000,
        })
    const notifySuccess = () =>
        toast.success(props.toastMsg, {
            toastId: props.id,
            className: props.classNameToast,
            autoClose: 5000,
        })
    const notifyInfo = () =>
        toast.info(props.toastMsg, {
            toastId: props.id,
            className: props.classNameToast,
            autoClose: 5000,
        })

    useEffect(() => {
        if (props.error) notifyErr()
        if (props.success) notifySuccess()
        if (props.info) notifyInfo()

        const timer = setTimeout(() => {
            cleanup()
        }, 5000)
        return () => clearTimeout(timer)
    }, [])

    return (
        <>
            <div>
                <ToastContainer limit={1} />
            </div>
        </>
    )
}

export default Toaster
