import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    Button,
    Box,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Card,
    TextField,
    MenuItem,
    Avatar,
    Theme,
    Slide,
    OutlinedInput,
    InputLabel,
    FormHelperText,
    FormControl,
    Grid,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'

import IconButton from '@mui/material/IconButton'
import CheckIcon from '@mui/icons-material/Check'
import SearchIcon from '@mui/icons-material/Search'

import { DropzoneDialog } from 'react-mui-dropzone'

import { render } from '@testing-library/react'

import { useForm } from 'react-hook-form'

import { useDebounce } from 'use-debounce/lib'

import { object, string, number } from 'yup'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { useAddMapMarkersElement } from '../../../hooks/map-markers/addMapMarkers/UseAddMapMarkers'
import { UseMapMarkersElements } from 'hooks/map-markers/single/UseMapMarkers'

import Toaster from '../../../common/Toaster/Toaster'

import ProgressBar from 'common/progress-bar/ProgressBar'
import { addressToGeoCodingData } from 'common/utils/utils'
import { MapMarkerElement } from 'common/types/map-markers/MapMarkersElement'

const Transition = React.forwardRef(function Transition(
    procity: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...procity} />
})

const schema = object({
    latitude: number(),
    longitude: number(),
    firstAddress: string(),
    secondAddress: string(),
    city: string(),
    country: string(),
    email: string(),
    phone: string(),
    business: string(),
})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            borderRadius: '5px',
            width: theme.spacing(15),
            height: theme.spacing(15),
            '@media (max-width: 868px)': {
                width: theme.spacing(11),
                height: theme.spacing(11),
            },
        },
        dropzone: {
            '& .MuiDropzoneArea-textContainer': {
                padding: '20px',
                '& .MuiTypography-root': {
                    fontSize: '17px',
                },
            },
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    }),
)

type Props = {
    isOpen: boolean
    handleClose: () => void
    mapMarker?: MapMarkerElement
}

export function MapMarkersDialog({ isOpen, handleClose, mapMarker }: Props) {
    const classes = useStyles()

    const [open, setOpen] = useState(false)
    const [key, setKey] = useState(0)
    const [file, setFile] = useState<any>([])
    const [loading, setLoading] = useState(false)
    const [avatarView, setAvatarView] = useState<string>('')

    const [location, setLocations] = useState<{
        latitude?: number
        longitude?: number
    }>({
        latitude: mapMarker?.latitude,
        longitude: mapMarker?.longitude,
    })

    const [debounceKey] = useDebounce(key, 1000)

    const { update } = UseMapMarkersElements(mapMarker?._id)
    const { addMapMarkers } = useAddMapMarkersElement()

    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<Omit<MapMarkerElement, 'business'> & { business: string }>({
        resolver: yupResolver(schema),
    })

    async function onSubmit() {
        setLoading(true)
        try {
            addMapMarker({
                latitude: location.latitude,
                longitude: location.longitude,
                firstAddress: getValues('firstAddress'),
                secondAddress: getValues('secondAddress'),
                city: getValues('city'),
                country: getValues('country'),
                email: getValues('email'),
                phone: getValues('phone'),
                business: getValues('business'),
            })
        } catch (err: any) {
            render(<Toaster error toastMsg={err.response} />)
        }
    }

    async function searchGeoCodingData() {
        const { results } = await addressToGeoCodingData(
            getValues('firstAddress') ?? '',
        )

        if (results.length) {
            const [response] = results

            const {
                geometry: { location },
            } = response

            const { lat: latitude, lng: longitude } = location

            setLocations({ latitude, longitude })
        }
    }

    const addMapMarker = async (formValues: {
        latitude?: number
        longitude?: number
        firstAddress?: string
        secondAddress?: string
        city?: string
        country?: string
        email?: string
        phone?: string
        business?: string
    }) => {
        try {
            const bodyFormData = new FormData()

            Object.entries(formValues).forEach(([key, value]) => {
                bodyFormData.append(key, value.toString())
            })

            if (mapMarker) {
                bodyFormData.append('_id', mapMarker._id)
            }

            if (file) {
                bodyFormData.append('logo', file)
                setKey(key + 1)
            }

            if (mapMarker != null) {
                update(bodyFormData).finally(() => setLoading(false))
                handleClose()
            } else {
                await addMapMarkers(bodyFormData)
                    .then(res => {
                        if (res === 'Created') {
                            handleClose()
                        }
                    })
                    .finally(() => setLoading(false))
            }
        } catch (error: any) {
            setLoading(false)
            render(<Toaster error toastMsg={error?.response?.data.message} />)
        }
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
            TransitionComponent={Transition}
            keepMounted
            maxWidth="md"
            aria-describedby="alert-dialog-slide-description"
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
            >
                <DialogTitle>
                    {mapMarker != null
                        ? 'Modifier le repère'
                        : 'Ajouter un repère'}
                </DialogTitle>

                <DialogContent>
                    <Box>
                        <Card>
                            <CardHeader
                                avatar={
                                    <>
                                        <IconButton
                                            component="span"
                                            onClick={() => setOpen(true)}
                                            size="large"
                                        >
                                            <Avatar
                                                className={classes.avatar}
                                                alt={'map marker logo'}
                                                src={
                                                    file.length == 0
                                                        ? mapMarker?.logoUrl
                                                        : avatarView
                                                }
                                            />
                                        </IconButton>
                                        <DropzoneDialog
                                            dropzoneClass={classes.dropzone}
                                            key={debounceKey}
                                            acceptedFiles={['image/*']}
                                            dropzoneText="Glisser-déposer une image ou rechercher dans les fichiers (max : 10Mo)"
                                            maxFileSize={10000000}
                                            filesLimit={1}
                                            open={open}
                                            onClose={() => setOpen(false)}
                                            onDrop={async files => {
                                                const src =
                                                    window.URL.createObjectURL(
                                                        files[0],
                                                    )
                                                setAvatarView(src)
                                                setFile(files[0])
                                                setOpen(false)
                                            }}
                                            showAlerts={false}
                                        />
                                    </>
                                }
                                title={
                                    <>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                            }}
                                        >
                                            <FormControl
                                                sx={{ m: 1, width: '100%' }}
                                                variant="outlined"
                                            >
                                                <InputLabel
                                                    required
                                                    htmlFor="firstAddress-mapMarkers"
                                                >
                                                    1ère adresse
                                                </InputLabel>
                                                <OutlinedInput
                                                    id="firstAddress-mapMarkers"
                                                    label="1ère adresse"
                                                    required
                                                    {...register(
                                                        'firstAddress',
                                                    )}
                                                    defaultValue={
                                                        mapMarker?.firstAddress
                                                    }
                                                    onChange={e =>
                                                        setValue(
                                                            'firstAddress',
                                                            e.target.value,
                                                        )
                                                    }
                                                    inputProps={{
                                                        'aria-label': 'weight',
                                                    }}
                                                />
                                                <FormHelperText id="secondAddress-mapMarkers-weight-helper-text">
                                                    {
                                                        errors?.firstAddress
                                                            ?.message
                                                    }
                                                </FormHelperText>
                                            </FormControl>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                            }}
                                        >
                                            <FormControl
                                                sx={{ m: 1, width: '100%' }}
                                                variant="outlined"
                                            >
                                                <InputLabel htmlFor="secondAddress-mapMarkers">
                                                    Complément d'adresse
                                                </InputLabel>
                                                <OutlinedInput
                                                    id="secondAddress-mapMarkers"
                                                    label="Complément d'adresse"
                                                    {...register(
                                                        'secondAddress',
                                                    )}
                                                    defaultValue={
                                                        mapMarker?.secondAddress
                                                    }
                                                    onChange={e =>
                                                        setValue(
                                                            'secondAddress',
                                                            e.target.value,
                                                        )
                                                    }
                                                    inputProps={{
                                                        'aria-label': 'weight',
                                                    }}
                                                />
                                                <FormHelperText id="secondAddress-mapMarkers-weight-helper-text">
                                                    {
                                                        errors?.secondAddress
                                                            ?.message
                                                    }
                                                </FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </>
                                }
                            />

                            <CardContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <FormControl
                                        sx={{ m: 1, width: '50%' }}
                                        variant="outlined"
                                    >
                                        <InputLabel
                                            required
                                            htmlFor="city-mapMarkers"
                                        >
                                            Ville
                                        </InputLabel>
                                        <OutlinedInput
                                            id="city-mapMarkers"
                                            label="Post"
                                            required
                                            {...register('city')}
                                            defaultValue={mapMarker?.city}
                                            onChange={e =>
                                                setValue('city', e.target.value)
                                            }
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                        <FormHelperText id="secondAddress-mapMarkers-weight-helper-text">
                                            {errors?.city?.message}
                                        </FormHelperText>
                                    </FormControl>

                                    <FormControl
                                        sx={{ m: 1, width: '50%' }}
                                        variant="outlined"
                                    >
                                        <InputLabel
                                            required
                                            htmlFor="firstAddressedin-mapMarkers"
                                        >
                                            Pays
                                        </InputLabel>
                                        <OutlinedInput
                                            id="firstAddressedin-mapMarkers"
                                            label="Pays"
                                            required
                                            {...register('country')}
                                            defaultValue={mapMarker?.country}
                                            onChange={e =>
                                                setValue(
                                                    'country',
                                                    e.target.value,
                                                )
                                            }
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                        <FormHelperText id="secondAddress-mapMarkers-weight-helper-text">
                                            {errors?.country?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Box>

                                <Box sx={{ m: 2, textAlign: 'center' }}>
                                    <Button
                                        disabled={false}
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        onClick={searchGeoCodingData}
                                        startIcon={<SearchIcon />}
                                    >
                                        Rechercher l'adresse *
                                    </Button>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <FormControl
                                        sx={{ m: 1, width: '50%' }}
                                        variant="outlined"
                                    >
                                        <OutlinedInput
                                            fullWidth
                                            disabled
                                            placeholder="Latitude"
                                            {...register('latitude')}
                                            defaultValue={location?.latitude}
                                            value={location?.latitude}
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                        <FormHelperText id="mapMarkers-weight-helper-text">
                                            La latitude doit être un nombre (ex:
                                            12.3456)
                                        </FormHelperText>
                                        <FormHelperText id="mapMarkers-weight-helper-text">
                                            {errors?.latitude?.message}
                                        </FormHelperText>
                                    </FormControl>

                                    <FormControl
                                        sx={{ m: 1, width: '50%' }}
                                        variant="outlined"
                                    >
                                        <OutlinedInput
                                            fullWidth
                                            disabled
                                            placeholder="Longitude"
                                            {...register('longitude')}
                                            defaultValue={location?.longitude}
                                            value={location?.longitude}
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                        <FormHelperText id="mapMarkers-weight-helper-text">
                                            La longitude doit être un nombre
                                            (ex: 12.3456)
                                        </FormHelperText>
                                        <FormHelperText id="sec-mapMarkers-weight-helper-text">
                                            {errors?.longitude?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <FormControl
                                        sx={{ m: 1, width: '40%' }}
                                        variant="outlined"
                                    >
                                        <InputLabel htmlFor="email-mapMarkers">
                                            E-Mail
                                        </InputLabel>
                                        <OutlinedInput
                                            id="email-mapMarkers"
                                            label="E-Mail"
                                            {...register('email')}
                                            defaultValue={mapMarker?.email}
                                            onChange={e =>
                                                setValue(
                                                    'email',
                                                    e.target.value,
                                                )
                                            }
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                        <FormHelperText id="email-mapMarkers-weight-helper-text">
                                            {errors?.email?.message}
                                        </FormHelperText>
                                    </FormControl>

                                    <FormControl
                                        sx={{ m: 1, width: '40%' }}
                                        variant="outlined"
                                    >
                                        <InputLabel htmlFor="phone-mapMarkers">
                                            Téléphone
                                        </InputLabel>
                                        <OutlinedInput
                                            id="phone-mapMarkers"
                                            label="Téléphone"
                                            {...register('phone')}
                                            defaultValue={mapMarker?.phone}
                                            onChange={e =>
                                                setValue(
                                                    'phone',
                                                    e.target.value,
                                                )
                                            }
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                        <FormHelperText id="phone-mapMarkers-weight-helper-text">
                                            {errors?.phone?.message}
                                        </FormHelperText>
                                    </FormControl>

                                    <FormControl
                                        sx={{ m: 1, width: '20%' }}
                                        variant="outlined"
                                    >
                                        <TextField
                                            id="business-mapMarkers"
                                            select
                                            required
                                            label="Entreprise"
                                            {...register('business')}
                                            defaultValue={mapMarker?.business}
                                            onChange={e =>
                                                setValue(
                                                    'business',
                                                    e.target.value,
                                                )
                                            }
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        >
                                            {['ESTIAM', '42C'].map(option => (
                                                <MenuItem
                                                    key={option}
                                                    value={option}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <FormHelperText id="business-mapMarkers-weight-helper-text">
                                            {errors?.business?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Grid container justifyContent="right" spacing={2}>
                        <Grid item>
                            <Button onClick={handleClose}>Fermer</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                startIcon={<CheckIcon />}
                                type="submit"
                            >
                                ENREGISTRER
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </form>

            <ProgressBar loading={loading} />
        </Dialog>
    )
}
