import React, { useEffect, useState } from 'react'
import Aos from 'aos'
import { Box, Grid } from '@mui/material'
import Comity from './comity/Comity'
import Formations from './formations/Formations'
import { useEstiamsElements } from 'hooks/estiam/all/UseEstiams'
import ProgressBar from 'common/progress-bar/ProgressBar'
import EstiamView from 'common/view/view'
import Estiam from './Estiam/Estiam'

import { useAddEstiamElement } from 'hooks/estiam/addEstiam/UseAddEstiam'

export default function Estiams() {
    const { estiamsElements, mutateEstiamsElements } = useEstiamsElements()
    const { addEstiam } = useAddEstiamElement()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        async function createEstiam() {
            const emptyEstiamBodyForm = new FormData()
            await addEstiam(emptyEstiamBodyForm)
            await mutateEstiamsElements()
        }

        if (estiamsElements && estiamsElements?.length === 0) {
            createEstiam()
        }
    }, [estiamsElements])

    useEffect(() => {
        Aos.init()
    }, [])

    return (
        <>
            <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
                data-aos="fade-right"
            >
                <Grid item xs={12} sm={12} md={12}>
                    <EstiamView
                        useDescription
                        useTitle
                        useMeta
                        category="ESTIAM"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    {estiamsElements && (
                        <Estiam useDescription estiam={estiamsElements} />
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Box mb={2}>
                        {estiamsElements && estiamsElements[0]?.formations ? (
                            <Formations
                                formations={estiamsElements[0].formations}
                                idEstiam={estiamsElements[0]._id}
                                setLoading={setLoading}
                            />
                        ) : (
                            ''
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box mb={2}>
                        {estiamsElements && estiamsElements[0]?.comity ? (
                            <Comity
                                comity={estiamsElements[0].comity}
                                idEstiam={estiamsElements[0]._id}
                                setLoading={setLoading}
                            />
                        ) : (
                            ''
                        )}
                    </Box>
                </Grid>
            </Grid>

            <ProgressBar loading={loading} />
        </>
    )
}
