import {
    Dialog,
    DialogTitle,
    DialogContent,
    Card,
    FormControl,
    InputLabel,
    OutlinedInput,
    FormHelperText,
    CardContent,
    DialogActions,
    Button,
    Slide,
    Grid,
} from '@mui/material'
import { Box } from '@mui/system'
import { NavbarElement } from 'common/types/navbar-element/NavbarElement'
import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import CheckIcon from '@mui/icons-material/Check'
import { useNavbarElement } from 'hooks/navbar-elements/single/useNavbarElement'
import { render } from '@testing-library/react'
import Toaster from '../../../common/Toaster/Toaster'
import { useForm } from 'react-hook-form'
import { object, string } from 'yup'
import { TransitionProps } from '@mui/material/transitions'
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />
})

type Props = {
    isOpen: boolean
    handleClose: () => void
    navbarElement: NavbarElement | null
    setLoading: any
}

const schema = object({
    title: string(),
    link: string(),
})

export const PopupNavbarElementSave = ({
    isOpen,
    handleClose,
    navbarElement,
    setLoading,
}: Props) => {
    const { update, create } = useNavbarElement(navbarElement?._id)

    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm<NavbarElement>({ resolver: yupResolver(schema) })

    async function onSubmit(values: NavbarElement) {
        setLoading(true)
        try {
            if (navbarElement) {
                const { title } = await update(values)

                setLoading(false)
                render(
                    <Toaster
                        success
                        toastMsg={`L'élément - ${title} - a été mis à jour avec succès !`}
                    />,
                )
            } else {
                const { title } = await create(values)

                setLoading(false)
                render(
                    <Toaster
                        success
                        toastMsg={`L'élément - ${title} - a été créé avec succès !`}
                    />,
                )
            }

            handleClose()
        } catch (error: unknown) {
            if (error instanceof Error) {
                setLoading(false)
                render(<Toaster error toastMsg={error?.message} />)
            }
        }
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
            TransitionComponent={Transition}
            keepMounted
            maxWidth="md"
            aria-describedby="alert-dialog-slide-description"
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
            >
                <DialogTitle>
                    {navbarElement != null
                        ? "Modifier l'élément de la barre de navigation"
                        : 'Ajouter un élement à la barre de navigation'}
                </DialogTitle>

                <DialogContent>
                    <Box>
                        <Card>
                            <CardContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <FormControl
                                        sx={{ m: 1, width: '50%' }}
                                        variant="outlined"
                                    >
                                        <InputLabel
                                            required
                                            htmlFor="title-navbar-element"
                                        >
                                            Titre
                                        </InputLabel>
                                        <OutlinedInput
                                            required
                                            id="title-navbar-element"
                                            label="Titre"
                                            {...register('title')}
                                            defaultValue={navbarElement?.title}
                                            onChange={e =>
                                                setValue(
                                                    'title',
                                                    e.target.value,
                                                )
                                            }
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                        <FormHelperText id="title-navbar-element-weight-helper-text">
                                            {errors?.title?.message}
                                        </FormHelperText>
                                    </FormControl>

                                    <FormControl
                                        sx={{ m: 1, width: '50%' }}
                                        variant="outlined"
                                    >
                                        <InputLabel
                                            required
                                            htmlFor="link-navbar-element"
                                        >
                                            Chemin
                                        </InputLabel>
                                        <OutlinedInput
                                            required
                                            id="link-navbar-element"
                                            label="Link"
                                            {...register('link')}
                                            defaultValue={navbarElement?.link}
                                            onChange={e =>
                                                setValue('link', e.target.value)
                                            }
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                        <FormHelperText id="link-navbar-element-weight-helper-text">
                                            {errors?.link?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Grid container justifyContent="right" spacing={2} m={1}>
                        <Grid item>
                            <Button onClick={handleClose}>Fermer</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                startIcon={<CheckIcon />}
                                type="submit"
                            >
                                ENREGISTRER
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </form>
        </Dialog>
    )
}
