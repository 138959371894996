import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { render } from '@testing-library/react'
import { useForm } from 'react-hook-form'
import { object, string } from 'yup'
import { DropzoneArea } from 'react-mui-dropzone'
import { useDebounce } from 'use-debounce/lib'
import {
    Button,
    Box,
    CardContent,
    CardActions,
    CardHeader,
    Card,
    Grid,
    CircularProgress,
    Typography,
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import { useAddSectoralBusinessSolutionElement } from '../../../hooks/sectoral-business-solution/add/UseAddSectoralBusinessSolution'
import Toaster from '../../../common/Toaster/Toaster'
import ProgressBar from 'common/progress-bar/ProgressBar'
import EditorText from '../../../common/custom-text-editor/CustomEditorText'
import { dropzoneStyle } from 'assets/GlobalStyles'

interface Props {
    category?: string
    title?: string
    subTitle: string
    description?: string
    subDescription?: string
}

const schema = object({
    category: string(),
    title: string(),
    subTitle: string(),
    description: string(),
    subDescription: string(),
})

export function AddSectoralBusinessSolution() {
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<Props>({ resolver: yupResolver(schema) })

    const [key, setKey] = useState(0)
    const [file, setFile] = useState<any>([])
    const [GIFfile, setGIFFile] = useState<any>([])
    const [debounceKey] = useDebounce(key, 1000)
    const [loading, setLoading] = useState(false)
    const [valueDescription, setValueDescription] = useState<string>('')
    const [valueSubDescription, setValueSubDescription] = useState<string>('')
    const classes = dropzoneStyle()
    const { addSectoralBusinessSolutionElement } =
        useAddSectoralBusinessSolutionElement()

    async function onSubmit() {
        try {
            addESolutionMetiers(
                getValues('title'),
                getValues('category'),
                getValues('subTitle'),
            )
        } catch (err: any) {
            render(<Toaster error toastMsg={err.response} />)
        }
    }

    const addESolutionMetiers = async (
        title?: string,
        category?: string,
        subTitle?: string,
    ) => {
        try {
            const bodyFormData = new FormData()
            setLoading(true)

            if (title) {
                bodyFormData.append('title', title)
            }

            if (category) {
                bodyFormData.append('category', category)
            }

            bodyFormData.append('subTitle', subTitle ? subTitle : '')
            bodyFormData.append(
                'description',
                valueDescription ? valueDescription : '',
            )
            bodyFormData.append(
                'subDescription',
                valueSubDescription ? valueSubDescription : '',
            )

            if (file) {
                bodyFormData.append('image', file)
                setKey(key + 1)
            }

            if (GIFfile) {
                bodyFormData.append('gif', GIFfile)
                setKey(key + 1)
            }

            addSectoralBusinessSolutionElement(bodyFormData).finally(() =>
                setLoading(false),
            )
        } catch (error: any) {
            render(<Toaster error toastMsg={error?.response?.data.message} />)
        }
    }

    return (
        <Card elevation={16} style={{ margin: '15px' }}>
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
            >
                <CardHeader title={'Ajouter un métier sectoriel'} />

                <CardContent>
                    <Box style={{ padding: '10px' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-end',
                            }}
                        >
                            <FormControl
                                sx={{ m: 1, width: '50%' }}
                                variant="outlined"
                            >
                                <InputLabel
                                    required
                                    htmlFor="description-sector"
                                >
                                    Titre de la page
                                </InputLabel>
                                <OutlinedInput
                                    required
                                    fullWidth
                                    id="title-sector"
                                    label="title"
                                    {...register('title')}
                                    onChange={e =>
                                        setValue('title', e.target.value)
                                    }
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                                <FormHelperText id="sector-weight-helper-text">
                                    {errors?.title?.message}
                                </FormHelperText>
                            </FormControl>
                            <FormControl
                                sx={{ m: 1, width: '50%' }}
                                variant="outlined"
                            >
                                <InputLabel required htmlFor="categorie-sector">
                                    Catégorie
                                </InputLabel>
                                <OutlinedInput
                                    required
                                    id="description-sector"
                                    label="description"
                                    {...register('category')}
                                    onChange={e =>
                                        setValue('category', e.target.value)
                                    }
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                                <FormHelperText id="sector-weight-text">
                                    {errors?.category?.message}
                                </FormHelperText>
                            </FormControl>

                            <FormControl
                                sx={{ m: 1, width: '50%' }}
                                variant="outlined"
                            >
                                <InputLabel htmlFor="description-sector">
                                    Titre de la carte
                                </InputLabel>
                                <OutlinedInput
                                    required
                                    fullWidth
                                    id="title-sector"
                                    label="Titre de la carte"
                                    {...register('subTitle')}
                                    onChange={e =>
                                        setValue('subTitle', e.target.value)
                                    }
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                                <FormHelperText id="sector-weight-helper-text">
                                    {errors?.subTitle?.message}
                                </FormHelperText>
                            </FormControl>
                        </Box>
                        <Grid>
                            <Box>
                                <Typography variant="h6">
                                    Description de la page :
                                </Typography>
                            </Box>
                            <Box sx={{ pb: 1 }}>
                                <EditorText
                                    currentValue={valueDescription}
                                    updateCurrentValue={setValueDescription}
                                />
                                <Typography variant="caption">
                                    (Max : un paragraphe de 100 000 caractères
                                    max.)
                                </Typography>
                            </Box>
                            <span className="error">
                                {errors?.description?.message}
                            </span>
                        </Grid>

                        <Grid>
                            <Box>
                                <Typography variant="h6">
                                    Description de la carte :
                                </Typography>
                            </Box>
                            <Box sx={{ pb: 1 }}>
                                <EditorText
                                    currentValue={valueSubDescription}
                                    updateCurrentValue={setValueSubDescription}
                                />
                                <Typography variant="caption">
                                    (Max : un paragraphe de 10 000 caractères
                                    max.)
                                </Typography>
                            </Box>
                            <span className="error">
                                {errors?.subDescription?.message}
                            </span>
                        </Grid>

                        <Box mb={2}>
                            <Box sx={{ p: 1 }}>
                                <InputLabel htmlFor="description-sector">
                                    Image :
                                </InputLabel>
                            </Box>
                            <Box sx={{ p: 1 }}>
                                <DropzoneArea
                                    key={debounceKey}
                                    dropzoneClass={classes.dropzone}
                                    acceptedFiles={['image/*,video/*']}
                                    dropzoneText="Glisser-déposer un GIF ou rechercher dans les fichiers (max : 150Mo)"
                                    maxFileSize={150000000}
                                    filesLimit={1}
                                    onDrop={(files: any): void => {
                                        setFile(files[0])
                                    }}
                                    showAlerts={false}
                                />
                            </Box>

                            <Box sx={{ p: 1 }}>
                                <InputLabel htmlFor="description-sector">
                                    GIF :
                                </InputLabel>
                            </Box>
                            <Box sx={{ p: 1 }}>
                                <DropzoneArea
                                    key={debounceKey}
                                    acceptedFiles={['image/*']}
                                    dropzoneClass={classes.dropzone}
                                    dropzoneText="Glisser-déposer un GIF ou rechercher dans les fichiers (max : 150Mo)"
                                    maxFileSize={150000000}
                                    filesLimit={1}
                                    onDrop={(files): void => {
                                        setGIFFile(files[0])
                                    }}
                                    showAlerts={false}
                                />
                            </Box>
                        </Box>
                    </Box>
                </CardContent>

                <Box
                    style={{
                        top: '80%',
                        left: '50%',
                        right: '50%',
                        transform: 'translate(-50%, -50%)',
                        position: 'absolute',
                    }}
                >
                    {loading && <CircularProgress size={80} />}
                </Box>

                <CardActions>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: '10px' }}
                        type="submit"
                    >
                        ENREGISTRER
                    </Button>
                </CardActions>
                <ProgressBar loading={loading} />
            </form>
        </Card>
    )
}
