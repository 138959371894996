import axios from 'axios'
import useToken from '../../../session/userSession'
import { useLabElements } from '../../all/useLab'
import { render } from '@testing-library/react'
import Toaster from '../../../../common/Toaster/Toaster'
import { SERVER_URL } from '../../../../config/environments'

const FETCH_ALL_LAB_URL = `${SERVER_URL.environment}/lab/mission`

export function UseAddMission(LabElementId: string) {
    const { mutateLabElements } = useLabElements()
    const { accessToken } = useToken()
    let data = ''

    async function AddMission(bodyFormData: FormData) {
        await axios({
            method: 'POST',
            url: `${FETCH_ALL_LAB_URL}/${LabElementId}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                render(
                    <Toaster
                        success
                        toastMsg="Mission ajoutée avec succès !"
                    />,
                )
                mutateLabElements()
                data = response.statusText
            })
            .catch(error => {
                render(
                    <Toaster error toastMsg={error.response.data.message[0]} />,
                )
            })
        return data
    }
    return { AddMission }
}
