import ReactQuill from 'react-quill'
import EditorToolbar, { modules, formats } from './EditorToolbar'

import { Alert } from '@mui/material'

import 'react-quill/dist/quill.snow.css'
import './CustomTextEditor.scss'

export const Editor = ({ currentValue, updateCurrentValue }: any) => {
    const handleChange = (value: string) => {
        updateCurrentValue(value)
    }
    return (
        <div className="text-editor">
            <EditorToolbar />
            <ReactQuill
                theme="snow"
                value={currentValue || ''}
                onChange={handleChange}
                modules={modules}
                formats={formats}
            />
            {currentValue?.length > 1048612 && (
                <Alert sx={{ marginTop: 2 }} severity="error">
                    Le nombre de caractères maximum est 1048612 caractères !
                </Alert>
            )}
        </div>
    )
}

export default Editor
