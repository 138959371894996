import React from 'react'
import { Container } from '@mui/material'
import ActivitySectorView from '../../../common/view/view'

export default function ActivitySectorViewHome() {
    return (
        <Container component="section" maxWidth={false} disableGutters>
            <ActivitySectorView
                useDescription
                useTitle
                useMeta
                category="ACTIVITY_SECTOR"
            />
        </Container>
    )
}
