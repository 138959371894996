import axios from 'axios'
import { render } from '@testing-library/react'
import useToken from '../../session/userSession'
import Toaster from '../../../common/Toaster/Toaster'
import { useSuccessStoriesElements } from '../all/useSuccessStories'
import { SERVER_URL } from '../../../config/environments'

const FETCH_ALL_SUCCESSSTORIES_URL = `${SERVER_URL.environment}/success-story`

export function useAddSuccessStoriesElement() {
    const { mutateSuccessStoriesElements } = useSuccessStoriesElements()
    const { accessToken } = useToken()

    let data = ''

    async function addSuccessStories(bodyFormData: FormData) {
        await axios({
            method: 'POST',
            url: `${FETCH_ALL_SUCCESSSTORIES_URL}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                mutateSuccessStoriesElements()
                render(<Toaster success toastMsg="ENREGISTRÉ" />)
                data = response.statusText
            })
            .catch(error => {
                render(
                    <Toaster error toastMsg={error.response.data.message[0]} />,
                )
            })
        return data
    }
    return { addSuccessStories }
}
