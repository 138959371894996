import useSWR from 'swr'
import { Lab } from '../../../common/types/lab/Lab'
import { SERVER_URL } from '../../../config/environments'

const FETCH_ALL_LAB_URL = `${SERVER_URL.environment}/lab`

export function useLabElements() {
    const { data: labElements, mutate: mutateLabElements } = useSWR<Lab[]>(
        'api/lab/all',
        fetchLabElements,
    )

    async function fetchLabElements() {
        const response = await fetch(`${FETCH_ALL_LAB_URL}/all`)
        return response.json()
    }
    return { labElements, mutateLabElements }
}
