import React, { useContext, ReactNode } from 'react'
import { Box, Toolbar } from '@mui/material'
import { AuthContext } from '../../context/auth-context'
import SettingMenus from '../settings/Settings'
import Login from '../login/Login'

interface IProps {
    children: ReactNode
}

export default function Layout({ children }: IProps) {
    const auth: any = useContext(AuthContext)
    const drawerWidth = 240

    return (
        <Box>
            {auth.isLoggedIn && (
                <Box sx={{ display: 'flex' }}>
                    <SettingMenus />
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            p: 3,
                            width: { sm: `calc(100% - ${drawerWidth}px)` },
                        }}
                    >
                        <Toolbar />
                        {children}
                    </Box>
                </Box>
            )}
            {!auth.isLoggedIn && (
                <Box>
                    <Login />
                </Box>
            )}
        </Box>
    )
}
