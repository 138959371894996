import React from 'react'
import {
    CardContent,
    Typography,
    IconButton,
    Card,
    CardHeader,
    CardMedia,
    CardActions,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import '../SuccessStories.scss'
import ConfirmForm from 'common/confirm-form/ConfirmForm'
import { UseDeleteSuccessStories } from 'hooks/successStories/deleteSuccessStories/UseDeleteSuccessStories'

const SuccessStoriesCard = (props: any) => {
    const { successStories, setSuccessStorie, setIsOpen } = props
    const [open, setOpen] = React.useState(false)
    const [id, setId] = React.useState('')

    const { deleteSuccessStories } = UseDeleteSuccessStories(id)

    const handleClickDelete = (Id: string) => {
        setId(Id)
        setOpen(true)
    }

    const onDelete = () => {
        deleteSuccessStories()
        setOpen(false)
    }

    const onEdit = (successStorie: any) => {
        setSuccessStorie(successStorie)
        setIsOpen(true)
    }

    return (
        <div>
            <Card sx={{ maxWidth: 345 }}>
                <CardHeader
                    title={successStories?.title}
                    titleTypographyProps={{ variant: 'h6' }}
                    subheader={successStories?.realizedBy}
                />
                <CardMedia
                    sx={{
                        borderTop: '1px solid #f9a6a6',
                        borderBottom: '1px solid #f9a6a6',
                    }}
                    image={successStories?.imageUrl}
                    alt={successStories?.title}
                    component="img"
                    height="194"
                />
                <CardContent>
                    {successStories?.category && (
                        <div>
                            <Typography>
                                <b>Catégorie :</b>
                            </Typography>
                            <Typography>{successStories?.category}</Typography>
                        </div>
                    )}
                    {successStories?.summary && (
                        <div>
                            <Typography>
                                <b>Phrase d'accroche :</b>
                            </Typography>
                            <Typography
                                dangerouslySetInnerHTML={{
                                    __html: successStories?.summary
                                        .slice(0, 50)
                                        .concat('...'),
                                }}
                            />
                        </div>
                    )}
                    {successStories?.pages && (
                        <div>
                            <Typography>
                                <b>Pages :</b>
                            </Typography>
                            <Typography>
                                {successStories?.pages?.toString()}
                            </Typography>
                        </div>
                    )}
                </CardContent>
                <CardActions disableSpacing>
                    {successStories && (
                        <>
                            <IconButton
                                aria-label="Adit success storie"
                                onClick={() => onEdit(successStories)}
                                size="large"
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                aria-label="Adit success storie"
                                onClick={() =>
                                    handleClickDelete(successStories._id)
                                }
                                size="large"
                            >
                                <DeleteOutlinedIcon />
                            </IconButton>
                        </>
                    )}
                </CardActions>
            </Card>

            <ConfirmForm
                onConfirm={onDelete}
                open={open}
                setOpen={setOpen}
                action="delete"
            />
        </div>
    )
}
export default SuccessStoriesCard
