import React from 'react'
import { Grid, Container } from '@mui/material'
import '../successStoriesCard/SuccessStories.scss'
import './FavoriteUseCaseGrid.scss'
import FavoriteUseCaseCard from './favoriteUseCaseCard/FavoriteUseCaseCard'

const FavoriteUseCaseGrid = (props: any) => {
    const { favorites } = props

    return (
        <main>
            <Container sx={{ py: 4, width: '100%' }}>
                <Grid container spacing={4}>
                    {favorites.map((id: any) => (
                        <Grid item xs={12} md={4} lg={3} key={id}>
                            <FavoriteUseCaseCard useCaseId={id} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </main>
    )
}
export default FavoriteUseCaseGrid
