import axios, { AxiosResponse } from 'axios'
import useSWR from 'swr'
import { JobOfferElement } from 'common/types/job-offer/JobOfferElement'
import { SERVER_URL } from '../../config/environments'
import useToken from '../session/userSession'
import Toaster from 'common/Toaster/Toaster'
import { render } from '@testing-library/react'

const FETCH_ALL_JOB_OFFERS_URL = `${SERVER_URL.environment}/job-offers`

export function useJobOffers() {
    const { data: jobOffersElements, mutate: mutateJobOffersElements } = useSWR<
        JobOfferElement[]
    >('api/job-offers/all', fetchAllJobOffer)
    const { accessToken } = useToken()
    let data = ''

    async function fetchAllJobOffer() {
        const response = await fetch(`${FETCH_ALL_JOB_OFFERS_URL}/all`)
        return response.json()
    }

    async function deleteJobOffer(JobOfferElementId: string) {
        const response = await fetch(
            `${FETCH_ALL_JOB_OFFERS_URL}/${JobOfferElementId}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        )
        render(<Toaster success toastMsg="Offre supprimée" />)
        await mutateJobOffersElements()
        return response.json()
    }

    async function addJobOffer(bodyFormData: FormData) {
        await axios({
            method: 'POST',
            url: `${FETCH_ALL_JOB_OFFERS_URL}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response: AxiosResponse<JobOfferElement>) => {
                mutateJobOffersElements()
                render(<Toaster success toastMsg=" Offre ajoutée" />)
                data = response.statusText
            })
            .catch(error => {
                render(
                    <Toaster error toastMsg={error.response.data.message[0]} />,
                )
            })
        return data
    }

    async function updateJobOffer(
        bodyFormData: FormData,
        JobOfferElementId: string,
    ) {
        await axios({
            method: 'PUT',
            url: `${FETCH_ALL_JOB_OFFERS_URL}/${JobOfferElementId}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response: AxiosResponse<JobOfferElement>) => {
                mutateJobOffersElements()
                render(<Toaster success toastMsg="Offre modifiée" />)
                data = response.statusText
            })
            .catch(error => {
                render(
                    <Toaster error toastMsg={error.response.data.message[0]} />,
                )
            })
        return data
    }

    return {
        jobOffersElements,
        deleteJobOffer,
        addJobOffer,
        updateJobOffer,
        mutateJobOffersElements,
    }
}
