import React, { useEffect, useState } from 'react'
import Aos from 'aos'
import { Button, Box, Grid, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import ExpertView from '../../common/view/view'
import { AddExpert } from './add-expert/AddExpert'
import { ListExpert } from './listExpert/ListExpert'

import { useExpert } from 'hooks/expert/useExpert'

import { sortBy, uniq } from 'lodash'

export default function Experts() {
    const { experts } = useExpert()

    const sectors = uniq(experts?.map(({ sector }) => sector))

    const [isOpen, setIsOpen] = useState(false)
    const [expert, setExpert] = useState<any>()

    const togglePopup = () => {
        setExpert(null)
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        Aos.init()
    }, [])

    return (
        <div>
            <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
                data-aos="fade-right"
            >
                <Grid item xs={12}>
                    <ExpertView
                        useDescription
                        useTitle
                        useMeta
                        category="EXPERT"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{ margin: '1px' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="medium"
                            startIcon={<AddIcon />}
                            onClick={togglePopup}
                        >
                            Ajouter un expert
                        </Button>
                    </Box>
                </Grid>

                {sectors.map(sector => (
                    <Grid key={sector} item xs={12}>
                        <Typography
                            m={1}
                            pt={1}
                            pb={1}
                            variant="h5"
                            fontWeight="bold"
                        >
                            {sector + ' :'}
                        </Typography>
                        <ListExpert
                            sector={sector}
                            experts={
                                sortBy(
                                    experts?.filter(
                                        ({ sector: expertSector }) =>
                                            sector === expertSector,
                                    ),
                                    ['order'],
                                ) ?? []
                            }
                            setExpert={setExpert}
                            setIsOpen={setIsOpen}
                        />
                    </Grid>
                ))}

                {isOpen && (
                    <AddExpert
                        isOpen={isOpen}
                        handleClose={togglePopup}
                        expert={expert}
                    />
                )}
            </Grid>
        </div>
    )
}
