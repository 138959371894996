import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Card,
    CardContent,
    Box,
    FormControl,
    OutlinedInput,
    InputLabel,
    FormHelperText,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { object, string } from 'yup'
import './AddQuote.scss'
import { useQuote } from 'hooks/join-us/quote/UseQuote'
import SaveButton from 'common/custom-button/SaveButton'

interface Props {
    _id?: string
    firstName: string
    lastName: string
    role?: string
    quote: string
}

const schema = object({
    firstName: string(),
    lastName: string(),
    role: string(),
    quote: string(),
})

export function AddQuote({ handleClose, joinUs, isOpen, setLoading }: any) {
    const { createQuote, updateQuote } = useQuote()

    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<Props>({ resolver: yupResolver(schema) })

    async function onSubmit() {
        setLoading(true)
        addNewQuote({
            firstName: getValues('firstName'),
            lastName: getValues('lastName'),
            role: getValues('role'),
            quote: getValues('quote'),
        }).finally(() => setLoading(false))
    }

    const addNewQuote = async (formValues: {
        firstName?: string
        lastName?: string
        role?: string
        quote?: string
    }) => {
        const bodyFormData = new URLSearchParams()

        Object.entries(formValues).forEach(([key, value]) => {
            bodyFormData.append(key, value)
        })

        if (joinUs.data._id) {
            await updateQuote(joinUs?.idJoinUs, joinUs?.data?._id, bodyFormData)
        } else {
            await createQuote(joinUs?.idJoinUs, bodyFormData)
        }

        handleClose()
    }

    return (
        <Dialog open={isOpen} onClose={handleClose} fullWidth>
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
            >
                {joinUs.data._id ? (
                    <DialogTitle>{'Modifier un témoignage'}</DialogTitle>
                ) : (
                    <DialogTitle>{'Ajouter un nouveau témoignage'}</DialogTitle>
                )}
                <DialogContent>
                    <Card>
                        <CardContent>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControl
                                    sx={{ m: 1, width: '46%' }}
                                    variant="outlined"
                                >
                                    <InputLabel
                                        required
                                        htmlFor="firstename-joinUs"
                                    >
                                        Prénom
                                    </InputLabel>
                                    <OutlinedInput
                                        required
                                        fullWidth
                                        label="Prénom"
                                        id="fisrtname-joinUs"
                                        {...register('firstName')}
                                        defaultValue={joinUs?.data?.firstName}
                                        onChange={e =>
                                            setValue(
                                                'firstName',
                                                e.target.value,
                                            )
                                        }
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                    <FormHelperText id="firstename-weight-helper-text">
                                        {errors?.firstName?.message}
                                    </FormHelperText>
                                </FormControl>

                                <FormControl
                                    sx={{ m: 1, width: '46%' }}
                                    variant="outlined"
                                >
                                    <InputLabel
                                        required
                                        htmlFor="lastname-expertt"
                                    >
                                        Nom
                                    </InputLabel>
                                    <OutlinedInput
                                        required
                                        fullWidth
                                        id="lastname-joinUs"
                                        label="Nom"
                                        {...register('lastName')}
                                        defaultValue={joinUs?.data?.lastName}
                                        onChange={e =>
                                            setValue('lastName', e.target.value)
                                        }
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                    <FormHelperText id="lastname-weight-helper-text">
                                        {errors?.lastName?.message}
                                    </FormHelperText>
                                </FormControl>

                                <FormControl
                                    sx={{ m: 1, width: '65ch' }}
                                    variant="outlined"
                                >
                                    <InputLabel required htmlFor="role-joinUs">
                                        Poste
                                    </InputLabel>
                                    <OutlinedInput
                                        required
                                        id="role-joinUs"
                                        label="Role"
                                        {...register('role')}
                                        defaultValue={joinUs?.data?.role}
                                        onChange={e =>
                                            setValue('role', e.target.value)
                                        }
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                    <FormHelperText id="role-weight-helper-text">
                                        {errors?.role?.message}
                                    </FormHelperText>
                                </FormControl>

                                <FormControl
                                    sx={{ m: 1, width: '65ch' }}
                                    variant="outlined"
                                >
                                    <InputLabel required htmlFor="quote-joinUs">
                                        Témoignage
                                    </InputLabel>
                                    <OutlinedInput
                                        required
                                        id="role-joinUs"
                                        label="quote"
                                        {...register('quote')}
                                        defaultValue={joinUs?.data?.quote}
                                        onChange={e =>
                                            setValue('quote', e.target.value)
                                        }
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                    <FormHelperText id="quote-weight-helper-text">
                                        {errors?.quote?.message}
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                        </CardContent>
                    </Card>
                </DialogContent>

                <SaveButton {...{ handleClose }} />
            </form>
        </Dialog>
    )
}
