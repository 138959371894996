import React, { useState } from 'react'
import './Navbar.scss'
import { Button, Grid, Box } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useNavbarElements } from '../../hooks/navbar-elements/all/useNavbarElements'
import ListNavbarElement from './list-navbar-element/list-navbar-element'
import { PopupNavbarElementSave } from './navbarElement/popup-navbar-element'
import { NavbarElement } from 'common/types/navbar-element/NavbarElement'
import ProgressBar from 'common/progress-bar/ProgressBar'

export default function Navbar() {
    const { navbarElements } = useNavbarElements()
    const [isOpen, setIsOpen] = useState(false)
    const [navbarElement, setNavbarElement] = useState<NavbarElement | null>(
        null,
    )
    const [loading, setLoading] = useState(false)

    const togglePopup = () => {
        setNavbarElement(null)
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                className="Navbar"
                data-aos="fade-right"
            >
                <Grid item xs={12}>
                    <Box sx={{ marginBottom: '16px' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="medium"
                            startIcon={<AddIcon />}
                            onClick={togglePopup}
                        >
                            Ajouter un élément
                        </Button>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <ListNavbarElement
                        navbarElements={navbarElements || []}
                        onEdit={(element: NavbarElement) => {
                            setNavbarElement(element)
                            setIsOpen(true)
                        }}
                    />
                </Grid>

                {isOpen && (
                    <PopupNavbarElementSave
                        isOpen={isOpen}
                        handleClose={togglePopup}
                        navbarElement={navbarElement}
                        setLoading={setLoading}
                    />
                )}
            </Grid>
            <ProgressBar loading={loading} />
        </>
    )
}
