import useToken from '../../session/userSession'
import { SERVER_URL } from '../../../config/environments'
import { useSuccessStoriesElements } from '../all/useSuccessStories'
import axios from 'axios'
import Toaster from 'common/Toaster/Toaster'
import { render } from '@testing-library/react'

const FETCH_ALL_SUCCESSSTORIES_URL = `${SERVER_URL.environment}/success-story`

export function useSuccessStoriesElement(successStoriesId: string) {
    const { mutateSuccessStoriesElements } = useSuccessStoriesElements()
    const { accessToken } = useToken()

    let data = ''

    async function update(bodyFormData: FormData) {
        await axios({
            method: 'PUT',
            url: `${FETCH_ALL_SUCCESSSTORIES_URL}/${successStoriesId}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                mutateSuccessStoriesElements()
                data = response.statusText
            })
            .catch(error => {
                render(
                    <Toaster error toastMsg={error.response.data.message[0]} />,
                )
            })

        return data
    }
    return { update }
}
