import React, { useState, useEffect, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string, InferType } from 'yup'
import axios from 'axios'
import { render } from '@testing-library/react'
import {
    Button,
    Box,
    CardContent,
    Card,
    CardActions,
    TextField,
    Grid,
    Typography,
    Paper,
} from '@mui/material'
import Toaster from '../Toaster/Toaster'
import Editor from '../custom-text-editor/CustomTextEditor'
import { AuthContext } from '../../context/auth-context'
import { ServerData } from '../types/server-response/ServerResponse'
import { SERVER_URL } from '../../config/environments'
import SEO from 'common/seo/SEO'

const schemaView = object({
    title: string(),
    description: string(),
    metaTitle: string(),
    metaDescription: string(),
})

export type ViewProps = InferType<typeof schemaView>

export default function View({
    useDescription,
    category,
    useTitle,
    useMeta,
}: any) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ViewProps>({ resolver: yupResolver(schemaView) })

    const auth: any = useContext(AuthContext)
    const [valueTitre, setValueTitre] = useState<string>('')
    const [valueDescription, setValueDescription] = useState<string>('')
    const [valueMetaTitle, setValueMetaTitle] = useState<string>('')
    const [valueMetaDescription, setValueMetaDescription] = useState<string>('')
    const [idView, setIdView] = useState<string>('')

    const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValueTitre(event.target.value)
    }

    const handleChangeMetaTitle = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setValueMetaTitle(event.target.value)
    }

    const handleChangeMetaDescription = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setValueMetaDescription(event.target.value)
    }

    // view Endpoint
    const viewEndpoint = `${SERVER_URL.environment}/view`

    const getView = async () => {
        const response = await axios.get<ServerData>(`${viewEndpoint}`, {
            headers: {
                Authorization: `Bearer ${auth.accessToken}` as string,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            params: {
                category,
            },
        })
        if (response) {
            const { data } = response
            setValueTitre(data.title)
            setValueMetaTitle(data.metaTitle)
            setValueMetaDescription(data.metaDescription)
            setIdView(data._id)
            setValueDescription(data.description)
        }
    }

    async function onSubmitView() {
        if (idView) {
            const body = {
                title: valueTitre,
                description: valueDescription,
                metaTitle: valueMetaTitle,
                metaDescription: valueMetaDescription,
            }
            await axios
                .put(`${viewEndpoint}/${idView}`, body, {
                    headers: {
                        Authorization: `Bearer ${auth.accessToken}` as string,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(response => {
                    const { data } = response
                    const { title, description, metaTitle, metaDescription } =
                        data as ServerData
                    setValueTitre(title)
                    setValueDescription(description)
                    setValueMetaTitle(metaTitle)
                    setValueMetaDescription(metaDescription)
                    render(<Toaster success toastMsg="ENREGISTRÉ" />)
                })
                .catch(err => render(<Toaster error toastMsg={err.message} />))
        } else {
            const bodyData = {
                title: valueTitre,
                description: valueDescription,
                metaTitle: valueMetaTitle,
                metaDescription: valueMetaDescription,
                category: category,
            }
            await axios
                .post(`${viewEndpoint}`, bodyData, {
                    headers: {
                        Authorization: `Bearer ${auth.accessToken}` as string,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(response => {
                    const { data } = response
                    const { title, description, metaTitle, metaDescription } =
                        data as ServerData
                    setValueTitre(title)
                    setValueDescription(description)
                    setValueMetaTitle(metaTitle)
                    setValueMetaDescription(metaDescription)
                    render(<Toaster success toastMsg="ENREGISTRÉ" />)
                })
                .catch(err => render(<Toaster error toastMsg={err.message} />))
        }
    }

    useEffect(() => {
        getView()
    }, [category])

    return (
        <form
            onSubmit={handleSubmit(onSubmitView)}
            noValidate
            autoComplete="off"
        >
            <Card style={{ margin: '10px' }}>
                <CardContent>
                    {useMeta && (
                        <Paper
                            sx={{
                                mb: 2,
                            }}
                        >
                            <SEO
                                {...{
                                    valueMetaTitle,
                                    valueMetaDescription,
                                    handleChangeMetaTitle,
                                    handleChangeMetaDescription,
                                    errors,
                                    register,
                                }}
                                view
                            />
                        </Paper>
                    )}

                    {useTitle && (
                        <Grid mt={2}>
                            <Box p={1}>
                                <Typography variant="h6">Titre :</Typography>
                            </Box>
                            <Box sx={{ p: 2 }}>
                                <TextField
                                    id="titre"
                                    label="Titre"
                                    required
                                    value={valueTitre}
                                    style={{ width: '100%' }}
                                    {...register('title')}
                                    onChange={handleChangeTitle}
                                />
                            </Box>
                            <span className="error">
                                {errors?.title?.message}
                            </span>
                        </Grid>
                    )}

                    {useDescription && (
                        <Grid>
                            <Box p={1}>
                                <Typography variant="h6">
                                    Description :
                                </Typography>
                            </Box>
                            <Box sx={{ p: 2 }}>
                                <Editor
                                    currentValue={valueDescription}
                                    updateCurrentValue={setValueDescription}
                                />
                                <Typography variant="caption">
                                    (Max : un paragraphe de 100 000 caractères
                                    max.)
                                </Typography>
                            </Box>
                            <span className="error">
                                {errors?.description?.message}
                            </span>
                        </Grid>
                    )}
                </CardContent>

                <CardActions>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: '10px' }}
                        type="submit"
                    >
                        ENREGISTRER
                    </Button>
                </CardActions>
            </Card>
        </form>
    )
}
