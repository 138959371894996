import useSWR from 'swr'
import { TalkingAboutUs } from '../../../common/types/talking-about-us/TalkingAboutUs'
import { SERVER_URL } from '../../../config/environments'

const FETCH_TALKING_ABOUT_US_URL = `${SERVER_URL.environment}/talking-about-us`

export function useAllTalkingAboutUs() {
    const { data: talkingAboutUs, mutate: mutateTalkingAboutUsElements } =
        useSWR<TalkingAboutUs[]>(
            'api/talking-about-us/all',
            fetchAllTalkingAboutUs,
        )

    async function fetchAllTalkingAboutUs() {
        const response = await fetch(`${FETCH_TALKING_ABOUT_US_URL}/all`)
        return response.json()
    }
    return { talkingAboutUs, mutateTalkingAboutUsElements }
}
