import { useEffect } from 'react'
import Aos from 'aos'
import Grid from '@mui/material/Grid'

import LegalNoticesView from '../../common/view/view'

export default function LegalNotices() {
    useEffect(() => {
        Aos.init()
    }, [])

    return (
        <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
            data-aos="fade-right"
        >
            <Grid item xs={12}>
                <LegalNoticesView
                    useDescription
                    useTitle
                    useMeta
                    category="LEGAL_NOTICES"
                />
            </Grid>
        </Grid>
    )
}
