import useSWR from 'swr'
import { ApplyElement } from '../../common/types/apply/ApplyElement'
import { SERVER_URL } from '../../config/environments'
const FETCH_ALL_APPLY_URL = `${SERVER_URL.environment}/application`

export function useApply() {
    const { data: applyElements, mutate: mutateApplyElements } = useSWR<
        ApplyElement[]
    >('api/application/all', fetchAllApply)

    async function fetchAllApply() {
        const response = await fetch(`${FETCH_ALL_APPLY_URL}/all`)
        return response.json()
    }

    return { applyElements, mutateApplyElements }
}
