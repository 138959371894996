import React from 'react'
import axios from 'axios'
import { render } from '@testing-library/react'
import useToken from '../../session/userSession'
import Toaster from '../../../common/Toaster/Toaster'
import { useSectoralBusinessSolutionElements } from '../all/UseSectoralBusinessSolution'
import { SERVER_URL } from '../../../config/environments'
import { useHistory } from 'react-router-dom'

const FETCH_ALL_SECTORAL_BUSINESS_SOLUTION_URL = `${SERVER_URL.environment}/sectoral-business-solution`

export function useAddSectoralBusinessSolutionElement() {
    const { mutateSectoralBusinessSolutionElements } =
        useSectoralBusinessSolutionElements()
    const { accessToken } = useToken()
    const history = useHistory()
    let data = ''

    async function addSectoralBusinessSolutionElement(body: FormData) {
        await axios({
            method: 'POST',
            url: `${FETCH_ALL_SECTORAL_BUSINESS_SOLUTION_URL}`,
            data: body,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response: any) => {
                mutateSectoralBusinessSolutionElements()
                render(
                    <Toaster
                        success
                        toastMsg="Métier sectoriel ajouté avec succès !"
                    />,
                )
                data = response.statusText
                history.push('/nos-metiers/' + `${response.data._id}`)
            })
            .catch(error => {
                if (error.response.data.message === 'Internal server error') {
                    render(
                        <Toaster
                            error
                            toastMsg="La catégorie doit être unique"
                        />,
                    )
                }
                render(
                    <Toaster error toastMsg={error.response.data.message[0]} />,
                )
            })
        return data
    }
    return { addSectoralBusinessSolutionElement }
}
