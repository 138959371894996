import axios from 'axios'
import { render } from '@testing-library/react'
import useToken from '../../session/userSession'
import Toaster from '../../../common/Toaster/Toaster'
import { useAllTalkingAboutUs } from '../get-all/useAllTalkingAboutUs'
import { SERVER_URL } from '../../../config/environments'

const FETCH_TALKING_ABOUT_US_URL = `${SERVER_URL.environment}/talking-about-us`

export function useAddTalkingAboutUs() {
    const { mutateTalkingAboutUsElements } = useAllTalkingAboutUs()
    const { accessToken } = useToken()

    let data = ''

    async function addTalkingAboutUs(bodyFormData: FormData) {
        await axios({
            method: 'POST',
            url: `${FETCH_TALKING_ABOUT_US_URL}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                mutateTalkingAboutUsElements()
                render(<Toaster success toastMsg="ENREGISTRÉ" />)
                data = response.statusText
            })
            .catch(error => {
                render(
                    <Toaster error toastMsg={error.response.data.message[0]} />,
                )
            })
        return data
    }
    return { addTalkingAboutUs }
}
