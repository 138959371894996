import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    CardHeader,
    Dialog,
    DialogTitle,
    DialogContent,
    Card,
    IconButton,
    Avatar,
    FormControl,
    InputLabel,
    FormHelperText,
    OutlinedInput,
} from '@mui/material'
import { DropzoneDialog } from 'react-mui-dropzone'
import { useForm } from 'react-hook-form'
import { useDebounce } from 'use-debounce/lib'
import { object, string } from 'yup'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import './AddJobs.scss'
import { useOurJobs } from 'hooks/join-us/our-jobs/UseOurJobs'
import { dropzoneStyle } from 'assets/GlobalStyles'
import SaveButton from 'common/custom-button/SaveButton'

interface Props {
    _id?: string
    title: string
    imageUrl: string
}

const schema = object({
    title: string(),
    imageUrl: string(),
})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            borderRadius: '5px',
            width: theme.spacing(15),
            height: theme.spacing(15),
            '@media (max-width: 868px)': {
                width: theme.spacing(11),
                height: theme.spacing(11),
            },
        },
    }),
)

export function AddJobs({ handleClose, joinUs, isOpen, setLoading }: any) {
    const classes = useStyles()
    const dropzoneClass = dropzoneStyle()
    const [open, setOpen] = useState(false)
    const [key, setKey] = useState(0)
    const [file, setFile] = useState<any>([])
    const [debounceKey] = useDebounce(key, 1000)
    const [avatarView, setAvatarView] = useState<string>('')

    const { createJob, updateJob } = useOurJobs()

    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<Props>({ resolver: yupResolver(schema) })

    async function onSubmit() {
        setLoading(true)
        addJob(getValues('title')).finally(() => setLoading(false))
    }

    const addJob = async (title?: string) => {
        const bodyFormData = new FormData()

        bodyFormData.append('title', title ?? '')

        if (file) {
            bodyFormData.append('image', file)
            setKey(key + 1)
        }

        if (joinUs.data._id) {
            bodyFormData.append('_id', joinUs.data._id)
        }

        if (joinUs.data._id) {
            const response = await updateJob(
                joinUs?.idJoinUs,
                joinUs.data._id,
                bodyFormData,
            )

            if (response) {
                handleClose()
            }
        } else {
            const response = await createJob(joinUs?.idJoinUs, bodyFormData)

            if (response) {
                handleClose()
            }
        }
    }

    return (
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
            >
                {joinUs.data._id ? (
                    <DialogTitle>{'Ajouter un nouveau métier'}</DialogTitle>
                ) : (
                    <DialogTitle>{'Modifier un nouveau métier'}</DialogTitle>
                )}
                <DialogContent>
                    <Card>
                        <CardHeader
                            avatar={
                                <>
                                    <IconButton
                                        component="span"
                                        onClick={() => setOpen(true)}
                                        size="large"
                                    >
                                        <Avatar
                                            className={classes.avatar}
                                            alt={joinUs?.data?.title}
                                            src={
                                                file.length == 0
                                                    ? joinUs?.data?.imageUrl
                                                    : avatarView
                                            }
                                        />
                                    </IconButton>

                                    <DropzoneDialog
                                        key={debounceKey}
                                        acceptedFiles={['image/*']}
                                        dropzoneClass={dropzoneClass.dropzone}
                                        dropzoneText="Glisser-déposer une image ou rechercher dans les fichiers (max : 10Mo)"
                                        maxFileSize={10000000}
                                        filesLimit={1}
                                        open={open}
                                        onClose={() => setOpen(false)}
                                        onDrop={async files => {
                                            const src =
                                                window.URL.createObjectURL(
                                                    files[0],
                                                )
                                            setAvatarView(src)
                                            setFile(files[0])
                                            setOpen(false)
                                        }}
                                        showAlerts={false}
                                    />
                                </>
                            }
                            title={
                                <>
                                    <FormControl
                                        sx={{ m: 1, width: '65ch' }}
                                        variant="outlined"
                                    >
                                        <InputLabel
                                            required
                                            htmlFor="title-joinUs"
                                        >
                                            Titre
                                        </InputLabel>
                                        <OutlinedInput
                                            required
                                            fullWidth
                                            label="Titre"
                                            id="title-joinUs"
                                            {...register('title')}
                                            defaultValue={joinUs?.data?.title}
                                            onChange={e =>
                                                setValue(
                                                    'title',
                                                    e.target.value,
                                                )
                                            }
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                        <FormHelperText id="title-weight-helper-text">
                                            {errors?.title?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </>
                            }
                        />
                    </Card>
                </DialogContent>

                <SaveButton {...{ handleClose }} />
            </form>
        </Dialog>
    )
}
