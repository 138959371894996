import React from 'react'
import { render } from '@testing-library/react'
import useToken from '../../../session/userSession'
import { useFormationsElements } from '../all/UseFormations'
import { SERVER_URL } from '../../../../config/environments'
import Toaster from '../../../../common/Toaster/Toaster'

const FETCH_ALL_FORMATIONS_URL = `${SERVER_URL.environment}/estiam`

export function UseDeleteFormationsElements(
    estiamId: string,
    formationId: string,
) {
    const { mutateFormationsElements } = useFormationsElements()
    const { accessToken } = useToken()

    async function deleteFormations() {
        const response = await fetch(
            `${FETCH_ALL_FORMATIONS_URL}/${estiamId}/estiam-formation/${formationId}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        )
        render(<Toaster success toastMsg="SUPPRIMER" />)
        await mutateFormationsElements()
        return response.json()
    }
    return { deleteFormations }
}
