import * as React from 'react'
import ConfirmForm from 'common/confirm-form/ConfirmForm'
import {
    TablePagination,
    Table,
    TableHead,
    TableRow,
    TableContainer,
    TableCell,
    Paper,
} from '@mui/material'
import { useExpert } from 'hooks/expert/useExpert'
import { DropResult } from 'react-beautiful-dnd'
import { reorder } from '../../../common/utils/utils'
import DraggableList from '../../drag-and-drop/DraggableList'
import { ExpertElement } from 'common/types/expert/ExpertElement'
import Toaster from 'common/Toaster/Toaster'
import { render } from '@testing-library/react'

type Props = {
    sector: string
    experts: ExpertElement[]
    setExpert: (expert: ExpertElement) => void
    setIsOpen: (isOpen: boolean) => void
}

export function ListExpert({ experts, setExpert, setIsOpen }: Props) {
    const [open, setOpen] = React.useState(false)
    const [id, setId] = React.useState('')
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)
    const [elements, setElements] = React.useState<ExpertElement[]>([])

    const { updateOrder } = useExpert()

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const handleClickOpen = (id: string) => {
        setId(id)
        setOpen(true)
    }

    const { deleteExpert } = useExpert(id)

    const onDelete = () => {
        deleteExpert()
        setOpen(false)
    }

    const onDragEnd = ({ destination, source }: DropResult) => {
        // dropped outside the list
        if (!destination) return

        const newElements = reorder(elements, source.index, destination.index)
        const updatedElements = newElements.map((element, index) => {
            return { ...element, order: ++index }
        })
        setElements(updatedElements)
        try {
            updateOrder({
                elements: updatedElements.map(
                    (
                        {
                            _id: id,
                            firstName,
                            lastName,
                            quote,
                            sector,
                            imageUrl,
                            poste,
                            linkedInUrl,
                        },
                        index,
                    ) => {
                        return {
                            id,
                            firstName,
                            lastName,
                            quote,
                            sector,
                            imageUrl,
                            poste,
                            linkedInUrl,
                            order: ++index,
                        }
                    },
                ),
            })
            render(
                <Toaster
                    success
                    toastMsg={
                        "L'ordre des éléments a été mis à jour avec succès !"
                    }
                />,
            )
        } catch (error: unknown) {
            if (error instanceof Error) {
                render(<Toaster error toastMsg={error?.message} />)
            }
        }
    }

    const onEdit = (element: ExpertElement) => {
        setExpert(element)
        setIsOpen(true)
    }
    React.useEffect(() => setElements(experts), [experts])

    return (
        <div>
            <Paper sx={{ width: '100%' }}>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Photo</TableCell>
                                <TableCell>Prénom</TableCell>
                                <TableCell align="left">Nom</TableCell>
                                <TableCell align="left">Poste</TableCell>
                                <TableCell align="left">Secteur</TableCell>
                                <TableCell align="left">Ordre</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <DraggableList
                            elements={elements}
                            onEditExpert={onEdit}
                            onDeleteClicked={handleClickOpen}
                            onDragEnd={onDragEnd}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            experts={true}
                        />
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    labelRowsPerPage="Lignes par page"
                    component="div"
                    count={experts ? experts?.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            <ConfirmForm
                onConfirm={onDelete}
                open={open}
                setOpen={setOpen}
                action="delete"
            />
        </div>
    )
}
