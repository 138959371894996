import axios from 'axios'
import useToken from '../../session/userSession'
import { SERVER_URL } from '../../../config/environments'
import { useJoinUs } from 'hooks/join-us/UseJoinUs'
import Toaster from 'common/Toaster/Toaster'
import { render } from '@testing-library/react'

const TESTIMONY_URL = `${SERVER_URL.environment}/join-us`

export function useTestimony() {
    const { mutateJoinUsElements } = useJoinUs()
    const { accessToken } = useToken()

    const createTestimony = async (id: string, bodyFormData: FormData) => {
        await axios({
            method: 'POST',
            url: `${TESTIMONY_URL}${id}/testimony`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                mutateJoinUsElements()
                render(<Toaster success toastMsg="ENREGISTRÉ" />)
                return response
            })
            .catch(err => {
                render(<Toaster error toastMsg={err.response} />)
            })
    }

    const updateTestimony = async (id: string, bodyFormData: FormData) => {
        await axios({
            method: 'PUT',
            url: `${TESTIMONY_URL}/${id}/testimony`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                mutateJoinUsElements()
                render(<Toaster success toastMsg="MODIFIÉ" />)
                return response.data
            })
            .catch(err => {
                render(
                    <Toaster
                        error
                        toastMsg={err.response.data.message.toString()}
                    />,
                )
            })
    }

    return { createTestimony, updateTestimony }
}
