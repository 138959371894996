import axios from 'axios'
import useToken from '../../session/userSession'
import { useActivitySectorElements } from '../all/useActivitySector'
import { SERVER_URL } from '../../../config/environments'
import { render } from '@testing-library/react'
import Toaster from '../../../common/Toaster/Toaster'

const FETCH_ALL_ACTIVITY_SECTOR_URL = `${SERVER_URL.environment}/activity-sector`

export function useActivitySectorElement(ActivitySectorElementId: string) {
    const { mutateActivitySectorElements } = useActivitySectorElements()
    const { accessToken } = useToken()

    async function updateActivitySector(body: FormData) {
        await axios({
            method: 'PUT',
            url: `${FETCH_ALL_ACTIVITY_SECTOR_URL}/${ActivitySectorElementId}`,
            data: body,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(async (response: any) => {
                render(
                    <Toaster
                        success
                        toastMsg="Secteur d'activité modifié avec succès !"
                    />,
                )
                await mutateActivitySectorElements()
                return response.data
            })
            .catch((error: any) => {
                render(
                    <Toaster error toastMsg={error.response.data.message[0]} />,
                )
            })
    }
    return { updateActivitySector }
}
