import { useEffect, useState } from 'react'
import Aos from 'aos'

import { Button, Typography, Box, Grid } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { useMapMarkersElements } from '../../hooks/map-markers/all/UseMapMarkers'

import { ListMapMarkers } from './list-map-markers/ListMapMarkers'
import { MapMarkerElement } from 'common/types/map-markers/MapMarkersElement'
import { MapMarkersDialog } from './map-marker-dialog/MapMarkerDialog'

export default function MapMarkers() {
    const { mapMarkerElements } = useMapMarkersElements()
    const [isOpen, setIsOpen] = useState(false)
    const [mapMarker, setMapMarker] = useState<MapMarkerElement | undefined>()

    const togglePopup = () => {
        setMapMarker(undefined)
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        Aos.init()
    }, [])

    return (
        <>
            <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
                data-aos="fade-right"
            >
                <Grid item xs={12} mb={3}>
                    <Typography variant="h4" component="h4">
                        Carte 42c
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{ margin: '1px' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="medium"
                            startIcon={<AddIcon />}
                            onClick={togglePopup}
                        >
                            Ajouter un repère
                        </Button>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <ListMapMarkers
                        mapMarkers={mapMarkerElements ?? []}
                        setMapMarker={setMapMarker}
                        setIsOpen={setIsOpen}
                    />
                </Grid>

                {isOpen && (
                    <MapMarkersDialog
                        isOpen={isOpen}
                        handleClose={togglePopup}
                        mapMarker={mapMarker}
                    />
                )}
            </Grid>
        </>
    )
}
