import { render } from '@testing-library/react'
import useToken from '../../session/userSession'
import Toaster from '../../../common/Toaster/Toaster'
import axios from 'axios'
import { useSolutionTechniqueElements } from '../all/useSolutionTechnique'
import { SERVER_URL } from '../../../config/environments'
import { useHistory } from 'react-router-dom'

const FETCH_ALL_SOLUTION_TECHNIQUE_URL = `${SERVER_URL.environment}/technical-solution`

export function useDeleteSolutionTechniqueElement(
    SolutionTechniqueElementId: string,
) {
    const { mutateSolutionTechniqueElements } = useSolutionTechniqueElements()
    const { accessToken } = useToken()
    const history = useHistory()

    async function deleteSolutionTechniqueElement() {
        await axios({
            method: 'DELETE',
            url: `${FETCH_ALL_SOLUTION_TECHNIQUE_URL}/${SolutionTechniqueElementId}`,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(async (response: any) => {
                await mutateSolutionTechniqueElements().then((res: any) => {
                    render(
                        <Toaster
                            success
                            toastMsg="Multi-solution supprimée avec succès !"
                        />,
                    )
                    history.push('/technical-solution/' + `${res[0]._id}`)
                })
                return response.data
            })
            .catch(error => {
                render(<Toaster error toastMsg={error.response.data.message} />)
            })
    }
    return { deleteSolutionTechniqueElement }
}
