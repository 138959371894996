import axios from 'axios'
import useToken from '../../../session/userSession'
import { useLabElements } from '../../all/useLab'
import { render } from '@testing-library/react'
import Toaster from '../../../../common/Toaster/Toaster'
import { SERVER_URL } from '../../../../config/environments'

const FETCH_ALL_LAB_URL = `${SERVER_URL.environment}/lab/mission`

export function UseUpdateMission(LabElementId: string) {
    const { mutateLabElements } = useLabElements()
    const { accessToken } = useToken()
    async function UpdateMission(MissionId: string, bodyFormData: FormData) {
        await axios({
            method: 'PUT',
            url: `${FETCH_ALL_LAB_URL}/${LabElementId}/${MissionId}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        }).then(response => {
            render(
                <Toaster success toastMsg="Mission modifiée avec succès !" />,
            )
            mutateLabElements()
            return response
        })
    }
    return { UpdateMission }
}
