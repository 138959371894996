import axios from 'axios'
import { render } from '@testing-library/react'
import useToken from '../../session/userSession'
import Toaster from '../../../common/Toaster/Toaster'
import { useActivitySectorElements } from '../all/useActivitySector'
import { SERVER_URL } from '../../../config/environments'
import { useHistory } from 'react-router-dom'

const FETCH_ALL_ACTIVITY_SECTOR_URL = `${SERVER_URL.environment}/activity-sector`

export function useDeleteActivitySectorElement(
    ActivitySectorElementId: string,
) {
    const { mutateActivitySectorElements } = useActivitySectorElements()
    const { accessToken } = useToken()
    const history = useHistory()

    async function deleteActivitySectorElement() {
        await axios({
            method: 'DELETE',
            url: `${FETCH_ALL_ACTIVITY_SECTOR_URL}/${ActivitySectorElementId}`,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(async (response: any) => {
                await mutateActivitySectorElements().then((res: any) => {
                    render(
                        <Toaster
                            success
                            toastMsg="Secteur d'activité supprimé avec succès !"
                        />,
                    )
                    history.push('/activity-sector/' + `${res[0]._id}`)
                })
                return response.data
            })
            .catch((error: any) => {
                render(
                    <Toaster error toastMsg={error.response.data.message[0]} />,
                )
            })
    }
    return { deleteActivitySectorElement }
}
