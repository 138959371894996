import axios from 'axios'
import useToken from '../../session/userSession'
import { useClientsElements } from '../all/UseClients'
import { SERVER_URL } from '../../../config/environments'
import Toaster from 'common/Toaster/Toaster'
import { render } from '@testing-library/react'

const FETCH_ALL_CLIENTS_URL = `${SERVER_URL.environment}/client`

export function UseDeleteClientElement(ClientElementId: string) {
    const { mutateClientsElements } = useClientsElements()
    const { accessToken } = useToken()
    async function deleteClient() {
        await axios({
            method: 'DELETE',
            url: `${FETCH_ALL_CLIENTS_URL}/${ClientElementId}`,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                mutateClientsElements()
                render(
                    <Toaster
                        success
                        toastMsg="Client supprimé avec succès !"
                    />,
                )
                return response
            })
            .catch(error => {
                render(
                    <Toaster error toastMsg={error.response.data.message[0]} />,
                )
            })
    }
    return { deleteClient }
}
