import React, { useEffect, useState } from 'react'
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardHeader,
    IconButton,
    TextField,
    Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material/styles'
import { dropzoneStyle } from 'assets/GlobalStyles'
import Editor from 'common/custom-text-editor/CustomTextEditor'

import { DropzoneDialog } from 'react-mui-dropzone'
import { useDebounce } from 'use-debounce/lib'

import { useForm } from 'react-hook-form'
import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { useJoinUs } from 'hooks/join-us/UseJoinUs'
import { JoinUs } from 'common/types/join-us/JoinUs'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper1: {
            marginBottom: 10,
        },
        paper2: {
            marginBottom: 10,
        },
        textField: {
            width: '100%',
        },
        avatar: {
            borderRadius: '5px',
            width: theme.spacing(15),
            height: theme.spacing(15),
            '@media (max-width: 868px)': {
                width: theme.spacing(11),
                height: theme.spacing(11),
            },
        },
    }),
)

interface PropsForm {
    _id?: string
    title: string
    description?: string
    imageUrl?: string
}

const schema = object({
    title: string().min(4),
    description: string().min(4),
    imageUrl: string().min(4),
})

export type Props = {
    joinUs: JoinUs
}
export default function JoinUsView({ joinUs }: Props) {
    const [image, setImage] = useState<File | null>(null)
    const [avatarView, setAvatarView] = useState<string>('')
    const [key, setKey] = useState(0)
    const [debounceKey] = useDebounce(key, 1000)
    const [open, setOpen] = useState(false)
    const [valueTitle, setValueTitle] = useState('')
    const [valueDescription, setValueDescription] = useState<string>('')

    const dropzoneClass = dropzoneStyle()
    const classes = useStyles()

    const { createJoinUs, updateJoinUs } = useJoinUs()

    const { handleSubmit } = useForm<PropsForm>({
        resolver: yupResolver(schema),
    })

    function onSubmit() {
        addComityMembers()
    }

    const addComityMembers = async () => {
        const bodyFormData = new FormData()

        bodyFormData.append('title', valueTitle)
        bodyFormData.append('description', valueDescription)

        if (image) {
            bodyFormData.append('image', image)
            setKey(key + 1)
        } else if (!joinUs.imageUrl) {
            bodyFormData.append('imageUrl', '')
        }

        if (joinUs._id) {
            bodyFormData.append('_id', joinUs._id)
        }

        if (joinUs._id) {
            await updateJoinUs(joinUs._id, bodyFormData)
        } else {
            await createJoinUs(bodyFormData)
        }
    }

    useEffect(() => {
        setValueTitle(joinUs.title)
        setValueDescription(joinUs.description || '')
    }, [joinUs])

    return (
        <>
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
            >
                <Card>
                    <Box sx={{ margin: '20px' }}>
                        <Typography variant="h5">Notre aventure</Typography>
                    </Box>
                    <CardHeader
                        avatar={
                            <>
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <IconButton
                                        component="span"
                                        onClick={() => setOpen(true)}
                                        size="large"
                                    >
                                        <Avatar
                                            className={classes.avatar}
                                            alt={joinUs.imageUrl}
                                            src={
                                                image
                                                    ? avatarView
                                                    : joinUs.imageUrl
                                            }
                                        />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => {
                                            joinUs.imageUrl = ''
                                            setAvatarView('')
                                            setImage(null)
                                            setKey(key + 1)
                                        }}
                                        size="large"
                                    >
                                        <DeleteOutlinedIcon />
                                    </IconButton>
                                </Box>
                                <DropzoneDialog
                                    key={debounceKey}
                                    acceptedFiles={['image/*']}
                                    dropzoneClass={dropzoneClass.dropzone}
                                    dropzoneText="Glisser-déposer une image ou rechercher dans les fichiers (max : 10Mo)"
                                    maxFileSize={10000000}
                                    filesLimit={1}
                                    open={open}
                                    onClose={() => setOpen(false)}
                                    onDrop={async files => {
                                        const src = window.URL.createObjectURL(
                                            files[0],
                                        )
                                        setAvatarView(src)
                                        setImage(files[0])
                                        setOpen(false)
                                    }}
                                    showAlerts={false}
                                />
                            </>
                        }
                        title={
                            <>
                                <Box sx={{ margin: '0px 15px 1px 15px' }}>
                                    <TextField
                                        id="titre"
                                        required
                                        label="Titre"
                                        value={valueTitle}
                                        className={classes.textField}
                                        onChange={e =>
                                            setValueTitle(e.target.value)
                                        }
                                    />
                                </Box>
                            </>
                        }
                        subheader={
                            <>
                                <Box sx={{ margin: '0px 15px 1px 15px' }}>
                                    <Typography
                                        variant="h6"
                                        style={{ marginBottom: '10px' }}
                                    >
                                        Description :
                                    </Typography>
                                    <Editor
                                        currentValue={valueDescription}
                                        updateCurrentValue={setValueDescription}
                                    />

                                    <Typography variant="caption">
                                        (Max : un paragraphe de 100 000
                                        caractères max.)
                                    </Typography>
                                </Box>
                            </>
                        }
                    />
                    <CardActions>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            ENREGISTRER
                        </Button>
                    </CardActions>
                </Card>
            </form>
        </>
    )
}
