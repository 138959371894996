import React from 'react'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Button } from '@mui/material'
import useWindowSize from '../../hooks/WindowSize/useWindowSize'

type Props = {
    onSubmit: React.MouseEventHandler<HTMLButtonElement>
    text: string
}

export default function CustomButton({ text, onSubmit }: Props) {
    const { width } = useWindowSize()

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                width: width < 960 ? theme.spacing(20) : theme.spacing(40),
                background: 'linear-gradient(25deg, #FF0033, #FF5500);',
                fontFamily: 'IBMPlexSans',
                fontStyle: 'bold',
            },
        }),
    )

    const classes = useStyles()

    return (
        <Button
            variant="contained"
            color="primary"
            className={classes.root}
            onClick={onSubmit}
        >
            {text}
        </Button>
    )
}
