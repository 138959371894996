import axios from 'axios'
import useToken from '../../session/userSession'
import { useLabElements } from '../all/useLab'
import { render } from '@testing-library/react'
import Toaster from '../../../common/Toaster/Toaster'
import { SERVER_URL } from '../../../config/environments'

const FETCH_ALL_LAB_URL = `${SERVER_URL.environment}/lab`

export function useLab(LabElementId?: string) {
    const { mutateLabElements } = useLabElements()
    const { accessToken } = useToken()

    async function update(bodyFormData: FormData) {
        await axios({
            method: 'PUT',
            url: `${FETCH_ALL_LAB_URL}/${LabElementId}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        }).then(response => {
            render(<Toaster success toastMsg="ENREGISTER" />)
            mutateLabElements()
            return response
        })
    }

    async function create(bodyFormData: FormData) {
        await axios({
            method: 'POST',
            url: `${FETCH_ALL_LAB_URL}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        }).then(response => {
            render(<Toaster success toastMsg="ENREGISTER" />)
            mutateLabElements()
            return response
        })
    }
    return { update, create }
}
