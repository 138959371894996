import {
    InputLabel,
    IconButton,
    Avatar,
    Typography,
    Box,
    ClassNameMap,
} from '@mui/material'
import { JobOfferElement } from 'common/types/job-offer/JobOfferElement'
import React, { SetStateAction } from 'react'
import { DropzoneDialog } from 'react-mui-dropzone'

type JobOfferBanderoleFormProps = {
    jobOffer: JobOfferElement
    setOpenBanderoleImage: (value: SetStateAction<boolean>) => void
    openBanderoleImage: boolean
    setBanderoleImage: (value: any) => void
    setAvatarBanderole: (value: SetStateAction<string>) => void
    avatarBanderole: string
    debounceKey: number
    dropzoneClass: ClassNameMap<'dropzone'>
    classes: ClassNameMap<'avatar'>
    banderoleImage: any
}

export default function JobOfferBanderoleForm({
    jobOffer,
    setOpenBanderoleImage,
    openBanderoleImage,
    setBanderoleImage,
    setAvatarBanderole,
    avatarBanderole,
    debounceKey,
    dropzoneClass,
    classes,
    banderoleImage,
}: JobOfferBanderoleFormProps) {
    return (
        <Box
            sx={{
                display: 'grid',
                flexWrap: 'wrap',
            }}
        >
            <InputLabel htmlFor="banderole-jobOffer">
                Image de la banderole :
            </InputLabel>
            <IconButton
                component="span"
                onClick={() => setOpenBanderoleImage(true)}
                size="large"
            >
                <Avatar
                    className={classes.avatar}
                    alt={jobOffer?.title}
                    src={
                        banderoleImage.length === 0
                            ? jobOffer?.banderoleImageUrl
                            : avatarBanderole
                    }
                />
            </IconButton>
            <DropzoneDialog
                key={debounceKey}
                acceptedFiles={['image/*']}
                dropzoneClass={dropzoneClass.dropzone}
                dropzoneText="Glisser-déposer une image ou rechercher dans les fichiers (max : 10Mo)"
                maxFileSize={10000000}
                filesLimit={1}
                open={openBanderoleImage}
                onClose={() => setOpenBanderoleImage(false)}
                onDrop={async files => {
                    const src = window.URL.createObjectURL(files[0])
                    setAvatarBanderole(src)
                    setBanderoleImage(files[0])
                    setOpenBanderoleImage(false)
                }}
                showAlerts={true}
                getDropRejectMessage={resultat =>
                    `Ce fichier est trop grand , taille de fichier : ${resultat.size} bytes, taille max est 5000000 bytes (5Mo)`
                }
            />
            <Typography variant="caption">
                (Max : un file de 5Mo max.)
            </Typography>
        </Box>
    )
}
