import {
    Grid,
    InputLabel,
    IconButton,
    Avatar,
    Typography,
    FormControl,
    TextField,
    FormHelperText,
    ClassNameMap,
} from '@mui/material'
import { JobOfferElement } from 'common/types/job-offer/JobOfferElement'
import React, { Dispatch, SetStateAction } from 'react'
import { DropzoneDialog } from 'react-mui-dropzone'
import JobOfferDescriptions from './JobOfferDescriptions'
import { UseFormSetValue, UseFormRegister } from 'react-hook-form'

interface JobOfferEntityFormProps {
    jobOffer: JobOfferElement
    errorEntityMessage?: string
    errorDescriptionMessage?: string
    valueDescriptionEntity?: string
    setValueDescriptionEntity: Dispatch<SetStateAction<string | undefined>>
    register: UseFormRegister<JobOfferElement>
    setValue: UseFormSetValue<JobOfferElement>
    setOpenLogo: Dispatch<SetStateAction<boolean>>
    openLogo: boolean
    setLogo: Dispatch<any>
    setAvatarLogo: Dispatch<SetStateAction<string>>
    avatarLogo: string
    logo: any
    debounceKey: number
    dropzoneClass: ClassNameMap<'dropzone'>
    classes: ClassNameMap<'avatar'>
}

export default function JobOfferEntityForm({
    jobOffer,
    errorEntityMessage,
    errorDescriptionMessage,
    valueDescriptionEntity,
    setValueDescriptionEntity,
    register,
    setValue,
    setOpenLogo,
    openLogo,
    setLogo,
    setAvatarLogo,
    avatarLogo,
    logo,
    debounceKey,
    dropzoneClass,
    classes,
}: JobOfferEntityFormProps) {
    return (
        <Grid
            container
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Grid
                item
                md={4}
                p={2}
                sx={{
                    display: 'grid',
                    flexWrap: 'wrap',
                }}
            >
                <InputLabel htmlFor="logo-jobOffer">
                    Logo de l'entité :
                </InputLabel>
                <IconButton
                    component="span"
                    onClick={() => setOpenLogo(true)}
                    size="large"
                >
                    <Avatar
                        className={classes.avatar}
                        alt={jobOffer?.title}
                        src={logo.length === 0 ? jobOffer?.logoUrl : avatarLogo}
                    />
                </IconButton>
                <DropzoneDialog
                    key={debounceKey}
                    acceptedFiles={['image/*']}
                    dropzoneClass={dropzoneClass.dropzone}
                    dropzoneText="Glisser-déposer une image ou rechercher dans les fichiers (max : 10Mo)"
                    maxFileSize={10000000}
                    filesLimit={1}
                    open={openLogo}
                    onClose={() => setOpenLogo(false)}
                    onDrop={async files => {
                        const src = window.URL.createObjectURL(files[0])
                        setAvatarLogo(src)
                        setLogo(files[0])
                        setOpenLogo(false)
                    }}
                    showAlerts={true}
                    getDropRejectMessage={resultat =>
                        `Ce fichier est trop grand , taille de fichier : ${resultat.size} bytes, taille max est 5000000 bytes (5Mo)`
                    }
                />
                <Typography variant="caption">
                    (Max : un image de 5Mo max.)
                </Typography>
            </Grid>
            <Grid item md={7}>
                <FormControl
                    sx={{
                        m: 1,
                        width: '100%',
                    }}
                    variant="outlined"
                >
                    <TextField
                        id="title-entity-jobOffer"
                        label="Nom de l'entité"
                        required
                        {...register('entity.title')}
                        defaultValue={jobOffer?.entity?.title}
                        onChange={e => setValue('entity.title', e.target.value)}
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                    >
                        <FormHelperText id="title-entity-jobOffer-weight-helper-text">
                            {errorEntityMessage}
                        </FormHelperText>
                    </TextField>
                </FormControl>
            </Grid>
            <JobOfferDescriptions
                section="entity"
                descriptionValue={valueDescriptionEntity}
                setDescriptionValue={setValueDescriptionEntity}
                errorMessage={errorDescriptionMessage}
            />
        </Grid>
    )
}
