import React from 'react'
import {
    CardContent,
    Typography,
    IconButton,
    Card,
    CardHeader,
    CardMedia,
    CardActions,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import '../TalkingAboutUs.scss'
import ConfirmForm from 'common/confirm-form/ConfirmForm'
import { useDeleteTalkingAboutUs } from 'hooks/talking-about-us/delete/useDeleteTalkingAboutUs'
import moment from 'moment'

const TalkingAboutUsCard = (props: any) => {
    const { talkingAboutUs, setSingleTalkingAboutUs, setIsOpen } = props
    const [open, setOpen] = React.useState(false)
    const [id, setId] = React.useState('')

    const { deleteTalkingAboutUs } = useDeleteTalkingAboutUs(id)

    const handleClickDelete = (Id: string) => {
        setId(Id)
        setOpen(true)
    }

    const onDelete = () => {
        deleteTalkingAboutUs()
        setOpen(false)
    }

    const onEdit = (singleTalkingAboutUs: any) => {
        setSingleTalkingAboutUs(singleTalkingAboutUs)
        setIsOpen(true)
    }

    return (
        <div>
            <Card sx={{ maxWidth: 345 }}>
                <CardHeader
                    title={talkingAboutUs?.title}
                    titleTypographyProps={{ variant: 'h6' }}
                    subheader={moment(talkingAboutUs?.createdAt).format(
                        'DD/MM/YYYY à HH:MM',
                    )}
                />
                <CardMedia
                    sx={{
                        borderTop: '1px solid #f9a6a6',
                        borderBottom: '1px solid #f9a6a6',
                    }}
                    image={talkingAboutUs?.imageUrl}
                    alt={talkingAboutUs?.title}
                    component="img"
                    height="194"
                />
                <CardContent>
                    {talkingAboutUs?.category && (
                        <div>
                            <Typography>
                                <b>Catégorie :</b>
                            </Typography>
                            <Typography>{talkingAboutUs?.category}</Typography>
                        </div>
                    )}
                    {talkingAboutUs?.summary && (
                        <div>
                            <Typography>
                                <b>Phrase d'accroche :</b>
                            </Typography>
                            <Typography
                                dangerouslySetInnerHTML={{
                                    __html: talkingAboutUs?.summary
                                        .slice(0, 50)
                                        .concat('...'),
                                }}
                            />
                        </div>
                    )}
                </CardContent>
                <CardActions disableSpacing>
                    {talkingAboutUs && (
                        <>
                            <IconButton
                                aria-label="edit-talkingAboutUs"
                                onClick={() => onEdit(talkingAboutUs)}
                                size="large"
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                aria-label="delete-talkingAboutUs"
                                onClick={() =>
                                    handleClickDelete(talkingAboutUs._id)
                                }
                                size="large"
                            >
                                <DeleteOutlinedIcon />
                            </IconButton>
                        </>
                    )}
                </CardActions>
            </Card>

            <ConfirmForm
                onConfirm={onDelete}
                open={open}
                setOpen={setOpen}
                action="delete"
            />
        </div>
    )
}
export default TalkingAboutUsCard
