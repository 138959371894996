import axios, { AxiosResponse } from 'axios'
import { render } from '@testing-library/react'
import useToken from '../../session/userSession'
import Toaster from '../../../common/Toaster/Toaster'
import { useMapMarkersElements } from '../all/UseMapMarkers'
import { SERVER_URL } from '../../../config/environments'
import { MapMarkerElement } from '../../../common/types/map-markers/MapMarkersElement'

const FETCH_ALL_MAPMARKERS_URL = `${SERVER_URL.environment}/map-markers`

export function useAddMapMarkersElement() {
    const { mutateMapMarkersElements } = useMapMarkersElements()
    const { accessToken } = useToken()
    let data = ''

    async function addMapMarkers(bodyFormData: FormData) {
        await axios({
            method: 'POST',
            url: `${FETCH_ALL_MAPMARKERS_URL}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response: AxiosResponse<MapMarkerElement>) => {
                mutateMapMarkersElements()
                render(
                    <Toaster success toastMsg="Repère ajouté avec succès !" />,
                )
                data = response.statusText
            })
            .catch(error => {
                render(
                    <Toaster error toastMsg={error.response.data.message[0]} />,
                )
            })
        return data
    }
    return { addMapMarkers }
}
