import { render } from '@testing-library/react'
import axios from 'axios'
import useSWR from 'swr'
import Toaster from 'common/Toaster/Toaster'
import { SERVER_URL } from 'config/environments'
import useToken from 'hooks/session/userSession'
import { QuoteElement } from 'common/types/job-offer/quote'

const FETCH_ALL_QUOTES_URL = `${SERVER_URL.environment}/job-offers/`

export function useQuoteElement(jobOfferId: string) {
    const { data: quoteElements, mutate: mutateQuoteElements } = useSWR<
        QuoteElement[]
    >(`api/job-offers/${jobOfferId}/quote`, fetchAllQuoteElement)

    const { accessToken } = useToken()
    let data = ''

    async function fetchAllQuoteElement() {
        const response = await fetch(`${FETCH_ALL_QUOTES_URL}${jobOfferId}`)
        const data = await response.json()
        return data.quotes
    }

    async function addQuote(bodyFormData: URLSearchParams) {
        await axios({
            method: 'POST',
            url: `${FETCH_ALL_QUOTES_URL}${jobOfferId}/quote`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type':
                    'application/x-www-form-urlencoded;charset=UTF-8',
            },
        })
            .then(response => {
                mutateQuoteElements()
                render(<Toaster success toastMsg="ENREGISTRÉ" />)

                data = response.statusText
            })
            .catch((err: any) => {
                render(
                    <Toaster
                        error
                        toastMsg={err.response.data.message.toString()}
                    />,
                )
            })
        return data
    }

    async function deleteQuote(quoteId: string) {
        const response = await fetch(
            `${FETCH_ALL_QUOTES_URL}${jobOfferId}/quote/${quoteId}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        )
        render(<Toaster success toastMsg="SUPPRIMER" />)
        await mutateQuoteElements()
        return response.json()
    }

    async function editQuote(bodyFormData: URLSearchParams, quoteId: string) {
        await axios({
            method: 'PUT',
            url: `${FETCH_ALL_QUOTES_URL}${jobOfferId}/quote/${quoteId}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type':
                    'application/x-www-form-urlencoded;charset=UTF-8',
            },
        })
            .then(response => {
                mutateQuoteElements()
                render(<Toaster success toastMsg="MODIFIER" />)
                data = response.statusText
            })
            .catch((err: any) => {
                render(
                    <Toaster
                        error
                        toastMsg={err.response.data.message.toString()}
                    />,
                )
            })
        return data
    }

    return {
        addQuote,
        deleteQuote,
        editQuote,
        quoteElements,
    }
}
