import { GeocodingResponse } from 'common/types/shared'

export function orderToString(nbr?: number) {
    switch (nbr) {
        case 1:
            return '1er'
        case 2:
            return '2ème'
        case 3:
            return '3ème'
        case 4:
            return '4ème'
        case 5:
            return '5ème'
        case 6:
            return '6ème'
        case 7:
            return '7ème'
        case 8:
            return '8ème'
        case 9:
            return '9ème'
        case 10:
            return '10ème'
        case 11:
            return '11ème'
        case 12:
            return '12ème'
        case 13:
            return '13ème'
        case 14:
            return '14ème'
        case 15:
            return '15ème'
        case 16:
            return '16ème'
        case 17:
            return '17ème'
        case 18:
            return '18ème'
        case 19:
            return '19ème'
        case 20:
            return '20ème'
        case 0:
            return "L'ordre n'est pas sélectionné !"
        default:
            break
    }
}

export const reorder = <T,>(
    list: T[],
    startIndex: number,
    endIndex: number,
): T[] => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

export async function addressToGeoCodingData(
    address: string,
): Promise<GeocodingResponse> {
    // API key from dev@42mediatvcom.com google account
    const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyADae3j0WpJGpR72HLjZ_wPCQUfjmSHLzQ`,
    )

    return await response.json()
}
