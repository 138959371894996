import React, { useEffect, useState } from 'react'
import {
    CardContent,
    Typography,
    Card,
    CardHeader,
    CardMedia,
    CardActions,
    IconButton,
} from '@mui/material'
import axios from 'axios'
import FavoriteIcon from '@mui/icons-material/Favorite'

import { pink } from '@mui/material/colors'

import '../../successStoriesCard/SuccessStories.scss'

import { SERVER_URL } from '../../../../../config/environments'
import { UseCase } from '../../../../../common/types/use-case/UseCase'
import Toaster from '../../../../../common/Toaster/Toaster'

// SuccessStories Endpoint
const successStoriesEndpoint = `${SERVER_URL.environment}/success-story`

const FavoriteUseCaseCard = (props: any) => {
    const { useCaseId } = props
    const [useCase, setUseCase] = useState<UseCase>()

    const fetchSuccessStory = () => {
        axios
            .get<UseCase>(`${successStoriesEndpoint}/${useCaseId}`)
            .then(response => {
                setUseCase(response.data)
            })
            .catch((error: any) => <Toaster error toastMsg={error.message} />)
    }

    useEffect(() => {
        if (useCaseId) {
            fetchSuccessStory()
        }
    }, [useCaseId])

    return (
        <div>
            <Card sx={{ maxWidth: 345 }}>
                <CardHeader
                    title={useCase?.title}
                    titleTypographyProps={{ variant: 'h6' }}
                    subheader={useCase?.realizedBy}
                />
                <CardMedia
                    sx={{
                        borderTop: '1px solid #f9a6a6',
                        borderBottom: '1px solid #f9a6a6',
                    }}
                    image={useCase?.imageUrl}
                    alt={useCase?.title}
                    component="img"
                    height="194"
                />
                <CardContent>
                    {useCase?.category && (
                        <div>
                            <Typography>
                                <b>Catégorie :</b>
                            </Typography>
                            <Typography
                                dangerouslySetInnerHTML={{
                                    __html: useCase?.category,
                                }}
                            />
                        </div>
                    )}
                    {useCase?.description && (
                        <div>
                            <Typography>
                                <b>Description :</b>
                            </Typography>
                            <Typography
                                dangerouslySetInnerHTML={{
                                    __html: useCase?.description
                                        .slice(0, 50)
                                        .concat('...'),
                                }}
                            />
                        </div>
                    )}
                    {useCase?.pages && (
                        <div>
                            <Typography>
                                <b>Pages :</b>
                            </Typography>
                            <Typography>
                                {useCase?.pages?.toString()}
                            </Typography>
                        </div>
                    )}
                </CardContent>
                <CardActions disableSpacing>
                    <IconButton aria-label="Adit success storie" size="large">
                        <FavoriteIcon sx={{ color: pink[500] }} />
                    </IconButton>
                </CardActions>
            </Card>
        </div>
    )
}
export default FavoriteUseCaseCard
