import axios from 'axios'
import useToken from '../../session/userSession'
import { useEstiamsElements } from '../all/UseEstiams'
import { SERVER_URL } from '../../../config/environments'

const FETCH_ALL_ESTIAMS_URL = `${SERVER_URL.environment}/estiam`

export function UseEstiamElements(EstiamElementId: string) {
    const { mutateEstiamsElements } = useEstiamsElements()
    const { accessToken } = useToken()
    async function update(bodyFormData: FormData) {
        await axios({
            method: 'PUT',
            url: `${FETCH_ALL_ESTIAMS_URL}/${EstiamElementId}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        }).then(response => {
            mutateEstiamsElements()
            return response
        })
    }
    return { update }
}
