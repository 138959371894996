import * as React from 'react'
import { Card, CardHeader, CircularProgress, Fade, Box } from '@mui/material'

export type Props = {
    loading: boolean
}

export default function ProgressBar({ loading }: Props) {
    return (
        <Box
            sx={{
                width: '250px',
                position: 'fixed',
                top: '70px',
                right: '15px',
                zIndex: '99',
            }}
        >
            <Fade
                in={loading}
                style={{
                    transitionDelay: loading ? '800ms' : '0ms',
                }}
                unmountOnExit
            >
                <Card sx={{ color: '#000', fontFamily: 'sans-serif' }}>
                    <CardHeader
                        avatar={<CircularProgress size={25} />}
                        title="Chargement en cours "
                        subheader="Veuillez patienter"
                    />
                </Card>
            </Fade>
        </Box>
    )
}
