import React, { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import ConfirmForm from 'common/confirm-form/ConfirmForm'
import {
    TablePagination,
    Typography,
    TableRow,
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableBody,
    Paper,
    Avatar,
    IconButton,
} from '@mui/material'
import { UseDeleteMission } from '../../../hooks/lab/missions/delete/UseDeleteMission'

type Mission = {
    _id: string
    title: string
    description: string
    imageUrl: string
}

export function ListMission({
    missionElements,
    idLab,
    setMission,
    setIsOpen,
}: any) {
    const [open, setOpen] = useState(false)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [idMission, setIdMission] = React.useState('')

    const { deleteMission } = UseDeleteMission(idLab)

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const handleClickOpen = async (id: string) => {
        setIdMission(id)
        setOpen(true)
    }

    const onDelete = () => {
        deleteMission(idMission)
        setOpen(false)
    }

    const onEdit = (mission: Mission) => {
        setMission(mission)
        setIsOpen(true)
    }

    return (
        <div>
            <Paper sx={{ width: '100%' }}>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Icône</TableCell>
                                <TableCell>Titre</TableCell>
                                <TableCell align="left">Description</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {missionElements
                                ?.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )
                                .map((row: Mission) => (
                                    <TableRow
                                        key={missionElements.indexOf(row)}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                { border: 0 },
                                        }}
                                    >
                                        <TableCell>
                                            <Avatar
                                                alt={row.title}
                                                src={row.imageUrl}
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.title}
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography
                                                variant="body2"
                                                dangerouslySetInnerHTML={{
                                                    __html: row.description,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                onClick={() => onEdit(row)}
                                                size="large"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() =>
                                                    handleClickOpen(row._id)
                                                }
                                                size="large"
                                            >
                                                <DeleteOutlinedIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    labelRowsPerPage="Lignes par page"
                    component="div"
                    count={missionElements?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            <ConfirmForm
                onConfirm={onDelete}
                open={open}
                setOpen={setOpen}
                action="delete"
            />
        </div>
    )
}
