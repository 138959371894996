import React from 'react'
import axios from 'axios'
import { render } from '@testing-library/react'
import useToken from '../../session/userSession'
import Toaster from '../../../common/Toaster/Toaster'
import { useSectoralBusinessSolutionElements } from '../all/UseSectoralBusinessSolution'
import { SERVER_URL } from '../../../config/environments'
import { useHistory } from 'react-router-dom'

const FETCH_ALL_SECTORAL_BUSINESS_SOLUTION_URL = `${SERVER_URL.environment}/sectoral-business-solution`

export function useDeleteSectoralBusinessSolutionElement(
    SectoralBusinessSolutionElementId: string,
) {
    const { mutateSectoralBusinessSolutionElements } =
        useSectoralBusinessSolutionElements()
    const { accessToken } = useToken()
    const history = useHistory()

    async function deleteSectoralBusinessSolutionElement() {
        await axios({
            method: 'DELETE',
            url: `${FETCH_ALL_SECTORAL_BUSINESS_SOLUTION_URL}/${SectoralBusinessSolutionElementId}`,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(async (response: any) => {
                await mutateSectoralBusinessSolutionElements().then(
                    (res: any) => {
                        render(
                            <Toaster
                                success
                                toastMsg="Métier sectoriel supprimé avec succès !"
                            />,
                        )
                        history.push('/nos-metiers/' + `${res[0]._id}`)
                    },
                )
                return response.data
            })
            .catch((error: any) => {
                render(
                    <Toaster error toastMsg={error.response.data.message[0]} />,
                )
            })
    }
    return { deleteSectoralBusinessSolutionElement }
}
