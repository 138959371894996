import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    Box,
    CardContent,
    Dialog,
    DialogTitle,
    DialogContent,
    Card,
    FormControl,
    OutlinedInput,
    InputLabel,
    FormHelperText,
} from '@mui/material'

import { render } from '@testing-library/react'
import { useForm } from 'react-hook-form'
import { object, string } from 'yup'
import Toaster from 'common/Toaster/Toaster'

import { useQuoteElement } from 'hooks/job-offer/quote/UseQuote'
import ProgressBar from 'common/progress-bar/ProgressBar'
import SaveButton from 'common/custom-button/SaveButton'
import { QuoteElement } from 'common/types/job-offer/quote'
import { JobOfferElement } from 'common/types/job-offer/JobOfferElement'

interface Props {
    _id?: string
    firstName: string
    lastName: string
    quote?: string
    role?: string
}

const schema = object({
    firstName: string(),
    lastName: string(),
    role: string(),
    quote: string(),
})

interface AddQuoteProps {
    handleClose: () => void
    isOpen: boolean
    jobOffer: JobOfferElement
    quoteId?: string | null
    quoteElm?: QuoteElement | null
}

export function AddQuote({
    handleClose,
    isOpen,
    jobOffer,
    quoteId,
    quoteElm,
}: AddQuoteProps) {
    const [loading, setLoading] = useState(false)

    const { addQuote, editQuote } = useQuoteElement(jobOffer._id)

    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<Props>({ resolver: yupResolver(schema) })

    async function onSubmit() {
        try {
            addQuotes(
                getValues('firstName'),
                getValues('lastName'),
                getValues('role'),
                getValues('quote'),
            )
        } catch (err: any) {
            render(<Toaster error toastMsg={err.response} />)
        }
    }

    const addQuotes = async (
        firstName?: string,
        lastName?: string,
        role?: string,
        quote?: string,
    ) => {
        try {
            const bodyFormData = new URLSearchParams()
            setLoading(true)

            bodyFormData.append('firstName', firstName ? firstName : '')
            bodyFormData.append('lastName', lastName ? lastName : '')
            bodyFormData.append('role', role ? role : '')
            bodyFormData.append('quote', quote ? quote : '')

            if (quoteId != null) {
                editQuote(bodyFormData, quoteId)
                    .then(res => {
                        if (res === 'OK') {
                            handleClose()
                        }
                    })
                    .finally(() => setLoading(false))
            } else {
                addQuote(bodyFormData)
                    .then(res => {
                        if (res === 'Created') {
                            handleClose()
                        }
                    })
                    .finally(() => setLoading(false))
            }
        } catch (error: any) {
            setLoading(false)
            render(<Toaster error toastMsg={error?.response?.data.message} />)
        }
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
            >
                <DialogTitle>{'Ajouter une citation'}</DialogTitle>
                <DialogContent>
                    <Card>
                        <CardContent>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControl
                                    sx={{ m: 1, width: '65ch' }}
                                    variant="outlined"
                                >
                                    <InputLabel htmlFor="firstName-quote">
                                        Prénom
                                    </InputLabel>
                                    <OutlinedInput
                                        required
                                        fullWidth
                                        label="Prénom"
                                        id="firstName-quote"
                                        {...register('firstName')}
                                        defaultValue={quoteElm?.firstName}
                                        onChange={e =>
                                            setValue(
                                                'firstName',
                                                e.target.value,
                                            )
                                        }
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                    <FormHelperText id="sector-job-offer-weight-helper-text">
                                        {errors?.firstName?.message}
                                    </FormHelperText>
                                </FormControl>

                                <FormControl
                                    sx={{ m: 1, width: '65ch' }}
                                    variant="outlined"
                                >
                                    <InputLabel htmlFor="lastName-quote">
                                        Nom
                                    </InputLabel>
                                    <OutlinedInput
                                        required
                                        fullWidth
                                        id="lastName-quote"
                                        label="Nom"
                                        {...register('lastName')}
                                        defaultValue={quoteElm?.lastName}
                                        onChange={e =>
                                            setValue('lastName', e.target.value)
                                        }
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                    <FormHelperText id="sector-job-offer-weight-helper-text">
                                        {errors?.lastName?.message}
                                    </FormHelperText>
                                </FormControl>

                                <FormControl
                                    sx={{ m: 1, width: '65ch' }}
                                    variant="outlined"
                                >
                                    <InputLabel htmlFor="role-quote">
                                        Rôle
                                    </InputLabel>
                                    <OutlinedInput
                                        required
                                        id="role-quote"
                                        label="Rôle"
                                        {...register('role')}
                                        defaultValue={quoteElm?.role}
                                        onChange={e =>
                                            setValue('role', e.target.value)
                                        }
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                    <FormHelperText id="sector-job-offer-weight-helper-text">
                                        {errors?.role?.message}
                                    </FormHelperText>
                                </FormControl>

                                <FormControl
                                    sx={{ m: 1, width: '65ch' }}
                                    variant="outlined"
                                >
                                    <InputLabel htmlFor="quote-quote">
                                        Citation
                                    </InputLabel>
                                    <OutlinedInput
                                        id="quote-quote"
                                        label="Citation"
                                        {...register('quote')}
                                        defaultValue={quoteElm?.quote}
                                        onChange={e =>
                                            setValue('quote', e.target.value)
                                        }
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                    <FormHelperText id="sector-job-offer-weight-helper-text">
                                        {errors?.quote?.message}
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                        </CardContent>
                    </Card>
                </DialogContent>

                <SaveButton {...{ handleClose }} />
            </form>

            <ProgressBar loading={loading} />
        </Dialog>
    )
}
