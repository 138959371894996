import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />
})

type Props = {
    onConfirm: () => void
    open: boolean
    // eslint-disable-next-line no-unused-vars
    setOpen: (open: boolean) => void
    action?: 'add' | 'edit' | 'delete'
}

export default function ConfirmForm({
    onConfirm,
    open,
    setOpen,
    action,
}: Props) {
    return (
        <>
            <Dialog
                open={open}
                keepMounted
                TransitionComponent={Transition}
                onClose={() => setOpen(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    sx={{ borderBottom: '1px solid #CCC', marginBottom: 5 }}
                >
                    {(() => {
                        switch (action) {
                            case 'add':
                                return "Confirmation d'insertion"
                            case 'edit':
                                return 'Confirmation de Modification'
                            case 'delete':
                                return 'Confirmation de suppression'
                            default:
                                return 'Confirmation'
                        }
                    })()}
                </DialogTitle>
                <DialogContent sx={{ padding: 5 }}>
                    <DialogContentText id="alert-dialog-slide-description">
                        {(() => {
                            switch (action) {
                                case 'add':
                                    return 'Voulez - vous ajouter ?'
                                case 'edit':
                                    return 'Voulez - vous modifier ?'
                                case 'delete':
                                    return 'Voulez - vous vraiment supprimer ?'
                                default:
                                    return 'voulez vous vraiment continuer ? '
                            }
                        })()}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Annuler</Button>
                    <Button onClick={onConfirm}>Oui</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
