import React from 'react'
import { render } from '@testing-library/react'
import useToken from '../../session/userSession'
import { SERVER_URL } from '../../../config/environments'
import Toaster from '../../../common/Toaster/Toaster'
import { useSuccessStoriesElements } from '../all/useSuccessStories'

const FETCH_ALL_SUCCESSSTORIES_URL = `${SERVER_URL.environment}/success-story`

export function UseDeleteSuccessStories(successStoriesId: string) {
    const { mutateSuccessStoriesElements } = useSuccessStoriesElements()
    const { accessToken } = useToken()

    async function deleteSuccessStories() {
        const response = await fetch(
            `${FETCH_ALL_SUCCESSSTORIES_URL}/${successStoriesId}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        )
        render(<Toaster success toastMsg="SUPPRIMER" />)
        await mutateSuccessStoriesElements()
        return response.json()
    }
    return { deleteSuccessStories }
}
