import { render } from '@testing-library/react'
import axios from 'axios'
import useSWR from 'swr'
import Toaster from 'common/Toaster/Toaster'
import { QuestionResponseElement } from 'common/types/job-offer/question-response'
import { SERVER_URL } from 'config/environments'
import useToken from 'hooks/session/userSession'

const FETCH_ALL_QUESTIONS_RESPONSES_URL = `${SERVER_URL.environment}/job-offers/`

export function useQuestionResponseElement(jobOfferId: string) {
    const {
        data: questionResponseElements,
        mutate: mutateJquestionResponseElements,
    } = useSWR<QuestionResponseElement[]>(
        `api/job-offers/${jobOfferId}/question-response`,
        fetchAllQuestionRes,
    )
    const { accessToken } = useToken()

    let data = ''

    async function fetchAllQuestionRes() {
        const response = await fetch(
            `${FETCH_ALL_QUESTIONS_RESPONSES_URL}${jobOfferId}`,
        )
        const data = await response.json()
        return data.questionsResponses
    }

    async function addQuestionResponse(bodyFormData: URLSearchParams) {
        await axios({
            method: 'POST',
            url: `${FETCH_ALL_QUESTIONS_RESPONSES_URL}${jobOfferId}/question-response`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type':
                    'application/x-www-form-urlencoded;charset=UTF-8',
            },
        })
            .then(response => {
                mutateJquestionResponseElements()
                render(<Toaster success toastMsg="ENREGISTRÉ" />)
                data = response.statusText
            })
            .catch((err: any) => {
                render(
                    <Toaster
                        error
                        toastMsg={err.response.data.message.toString()}
                    />,
                )
            })
        return data
    }

    async function editQuestionResponse(
        bodyFormData: URLSearchParams,
        questionResponseId: string,
    ) {
        await axios({
            method: 'PUT',
            url: `${FETCH_ALL_QUESTIONS_RESPONSES_URL}${jobOfferId}/question-response/${questionResponseId}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type':
                    'application/x-www-form-urlencoded;charset=UTF-8',
            },
        })
            .then(response => {
                mutateJquestionResponseElements()
                render(<Toaster success toastMsg="MODIFIER" />)
                data = response.statusText
            })
            .catch((err: any) => {
                render(
                    <Toaster
                        error
                        toastMsg={err.response.data.message.toString()}
                    />,
                )
            })
        return data
    }

    async function deleteQuestionResponse(questionResponseId: string) {
        const response = await fetch(
            `${FETCH_ALL_QUESTIONS_RESPONSES_URL}${jobOfferId}/question-response/${questionResponseId}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        )
        render(<Toaster success toastMsg="SUPPRIMER" />)
        await mutateJquestionResponseElements()
        return response.json()
    }

    return {
        addQuestionResponse,
        editQuestionResponse,
        deleteQuestionResponse,
        questionResponseElements,
    }
}
