import { Client } from 'common/types/clients/ClientsType'
import useSWR from 'swr'
import { SERVER_URL } from '../../../config/environments'

const FETCH_ALL_CLIENTS_URL = `${SERVER_URL.environment}/client`

export function useClientsElements() {
    const { data: clientsElements, mutate: mutateClientsElements } = useSWR<
        Client[]
    >('api/client/all', fetchAllClientsElements)

    async function fetchAllClientsElements() {
        const response = await fetch(`${FETCH_ALL_CLIENTS_URL}/all`)
        return response.json()
    }
    return { clientsElements, mutateClientsElements }
}
