import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    Avatar,
    Button,
    Box,
    CardContent,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Card,
    Grid,
    Typography,
    IconButton,
    CircularProgress,
    FormControl,
    OutlinedInput,
    InputLabel,
    FormHelperText,
    Slide,
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { render } from '@testing-library/react'
import ProgressBar from 'common/progress-bar/ProgressBar'
import { useForm } from 'react-hook-form'
import { object, string } from 'yup'
import { UseUpdateMission } from '../../../hooks/lab/missions/update/UseUpdateMission'
import { UseAddMission } from '../../../hooks/lab/missions/add/UseAddMission'
import Toaster from '../../../common/Toaster/Toaster'
import { TransitionProps } from '@mui/material/transitions'
import { useDebounce } from 'use-debounce/lib'
import { DropzoneDialog } from 'react-mui-dropzone'
import EditorText from '../../../common/custom-text-editor/CustomEditorText'
import { dropzoneStyle } from 'assets/GlobalStyles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material/styles'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
    title: string
    description?: string
    imageUrl?: string
}

const schema = object({
    title: string(),
    description: string(),
    imageUrl: string(),
})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            width: theme.spacing(15),
            height: theme.spacing(15),
            '@media (max-width: 868px)': {
                width: theme.spacing(11),
                height: theme.spacing(11),
            },
        },
    }),
)
export function PopupAddMission({ isOpen, handleClose, idLab, mission }: any) {
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<Props>({ resolver: yupResolver(schema) })

    const [description, setDescription] = useState<string>('')
    const [key, setKey] = useState(0)
    const [debounceKey] = useDebounce(key, 1000)
    const [loading, setLoading] = useState(false)
    const [avatarView, setAvatarView] = useState<string>('')
    const [open, setOpen] = useState(false)
    const [file, setFile] = useState<any>([])

    const classes = dropzoneStyle()
    const style = useStyles()

    const { UpdateMission } = UseUpdateMission(idLab)
    const { AddMission } = UseAddMission(idLab)

    async function onSubmit() {
        try {
            addMission(getValues('title'), description)
        } catch (err: any) {
            render(<Toaster error toastMsg={err.response} />)
        }
    }

    const addMission = async (title?: string, description?: string) => {
        try {
            const bodyFormData = new FormData()
            setLoading(true)

            if (title) {
                bodyFormData.append('title', title)
            }
            if (description) {
                bodyFormData.append('description', description)
            }
            if (file) {
                bodyFormData.append('image', file)
                setKey(key + 1)
            }

            if (mission != null) {
                UpdateMission(mission._id, bodyFormData).finally(() => {
                    setLoading(false)
                    handleClose()
                })
            } else {
                AddMission(bodyFormData)
                    .then(res => {
                        if (res === 'Created') {
                            handleClose()
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
        } catch (error: any) {
            render(<Toaster error toastMsg={error?.response?.data.message} />)
        }
    }

    useEffect(() => {
        setDescription(mission?.description)
    }, [])

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
            maxWidth="md"
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
            >
                <DialogTitle>
                    {mission != null
                        ? 'Modifier Une Mission'
                        : 'Ajouter Une Mission'}
                </DialogTitle>

                <DialogContent>
                    <Box style={{ padding: '10px' }}>
                        <Card>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={4}
                                    mb={2}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid item xs={12} md={4}>
                                        <IconButton
                                            component="span"
                                            onClick={() => setOpen(true)}
                                            size="large"
                                        >
                                            <Avatar
                                                className={style.avatar}
                                                src={
                                                    file.length == 0
                                                        ? mission?.imageUrl
                                                        : avatarView
                                                }
                                            />
                                        </IconButton>
                                        <DropzoneDialog
                                            key={debounceKey}
                                            dropzoneClass={classes.dropzone}
                                            acceptedFiles={['image/*']}
                                            dropzoneText="Glisser-déposer une image ou rechercher dans les fichiers (max : 10Mo)"
                                            maxFileSize={10000000}
                                            filesLimit={1}
                                            open={open}
                                            onClose={() => setOpen(false)}
                                            onDrop={async files => {
                                                const src =
                                                    window.URL.createObjectURL(
                                                        files[0],
                                                    )
                                                setAvatarView(src)
                                                setFile(files[0])
                                                setOpen(false)
                                            }}
                                            showAlerts={true}
                                            getDropRejectMessage={resultat =>
                                                `Ce fichier est trop grand , taille de fichier : ${resultat.size} bytes, taille max est 5000000 bytes (5Mo)`
                                            }
                                        />
                                        <Grid xs={12}>
                                            <Typography variant="caption">
                                                (Max : un image de 5Mo max.)
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <FormControl
                                            sx={{ m: 1, width: '90%' }}
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                required
                                                htmlFor="tit-sector"
                                            >
                                                Titre
                                            </InputLabel>
                                            <OutlinedInput
                                                required
                                                fullWidth
                                                id="title-miss"
                                                label="title"
                                                defaultValue={
                                                    mission?.title != ''
                                                        ? mission?.title
                                                        : ''
                                                }
                                                {...register('title')}
                                                onChange={e =>
                                                    setValue(
                                                        'title',
                                                        e.target.value,
                                                    )
                                                }
                                                inputProps={{
                                                    'aria-label': 'weight',
                                                }}
                                            />
                                            <FormHelperText id="sector-weight-helper-text">
                                                {errors?.title?.message}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid>
                                    <Grid item xs={12} p={1}>
                                        <Typography
                                            variant="h6"
                                            component="div"
                                        >
                                            Description :
                                        </Typography>
                                    </Grid>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <EditorText
                                            currentValue={description}
                                            updateCurrentValue={setDescription}
                                            idEditor="editor-mission"
                                        />
                                    </Box>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>
                </DialogContent>

                <Box
                    style={{
                        top: '40%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: '1',
                        position: 'absolute',
                    }}
                >
                    {loading && <CircularProgress size={80} />}
                </Box>

                <DialogActions>
                    <Button onClick={handleClose} style={{ margin: '10px' }}>
                        Fermer
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        style={{ margin: '10px' }}
                        startIcon={<CheckIcon />}
                        type="submit"
                    >
                        ENREGISTRER
                    </Button>
                </DialogActions>
            </form>

            <ProgressBar loading={loading} />
        </Dialog>
    )
}
