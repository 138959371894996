import React from 'react'
import {
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material'

import { DropResult } from 'react-beautiful-dnd'
import { reorder } from 'common/utils/utils'
import DraggableList from '../../drag-and-drop/DraggableList'
import ConfirmForm from 'common/confirm-form/ConfirmForm'

import { NavbarElement } from 'common/types/navbar-element/NavbarElement'
import { useNavbarElement } from 'hooks/navbar-elements/single/useNavbarElement'
import Toaster from 'common/Toaster/Toaster'
import { render } from '@testing-library/react'
import { useNavbarElements } from 'hooks/navbar-elements/all/useNavbarElements'

type Props = {
    navbarElements: NavbarElement[]
    onEdit: (element: NavbarElement) => void
}

export default function ListNavbarElement({ navbarElements, onEdit }: Props) {
    const [id, setId] = React.useState('')
    const [open, setOpen] = React.useState(false)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [elements, setElements] = React.useState<NavbarElement[]>([])
    const { deleteById } = useNavbarElement(id)
    const { updateOrder } = useNavbarElements()

    const handleChangePage = (_event: any, newPage: number) => {
        setPage(newPage)
    }
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const onDeleteClicked = (id: string) => {
        setId(id)
        setOpen(true)
    }

    async function onDelete() {
        try {
            await deleteById()
            setOpen(false)

            render(
                <Toaster
                    success
                    toastMsg={`L'élément navbar a été supprimé avec succès`}
                />,
            )
        } catch (error) {
            if (error instanceof Error) {
                render(<Toaster success toastMsg={error.message} />)
            }
        }
    }

    const onDragEnd = ({ destination, source }: DropResult) => {
        // dropped outside the list
        if (!destination) return

        const newElements = reorder(elements, source.index, destination.index)
        const updatedElements = newElements.map((element, index) => {
            return { ...element, order: ++index }
        })
        setElements(updatedElements)
        try {
            updateOrder({
                elements: updatedElements.map(
                    ({ _id: id, title, link }, index) => {
                        return { id, title, link, order: ++index }
                    },
                ),
            })
            render(
                <Toaster
                    success
                    toastMsg={
                        "L'ordre des éléments a été mis à jour avec succès !"
                    }
                />,
            )
        } catch (error: unknown) {
            if (error instanceof Error) {
                render(<Toaster error toastMsg={error?.message} />)
            }
        }
    }

    React.useEffect(() => setElements(navbarElements), [navbarElements])

    return (
        <>
            <Paper sx={{ width: '100%' }}>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Titre</TableCell>
                                <TableCell>Chemin</TableCell>
                                <TableCell align="left">Ordre</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <DraggableList
                            elements={elements}
                            onEditNavbar={onEdit}
                            onDeleteClicked={onDeleteClicked}
                            onDragEnd={onDragEnd}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            navbar={true}
                        />
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    labelRowsPerPage="Lignes par page"
                    component="div"
                    count={elements.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            <ConfirmForm
                onConfirm={onDelete}
                open={open}
                setOpen={setOpen}
                action="delete"
            />
        </>
    )
}
