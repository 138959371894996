import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    Button,
    Box,
    CardContent,
    CardActions,
    CardHeader,
    Card,
    CircularProgress,
    FormControl,
    OutlinedInput,
    InputLabel,
    FormHelperText,
    Grid,
    Typography,
    Paper,
} from '@mui/material'
import { dropzoneStyle } from 'assets/GlobalStyles'
import { render } from '@testing-library/react'
import { useForm } from 'react-hook-form'
import { object, string } from 'yup'
import { useDebounce } from 'use-debounce/lib'
import Toaster from '../../../common/Toaster/Toaster'
import { useAddActivitySectorElement } from '../../../hooks/activity-sector/add/useAddActivitySector'
import ProgressBar from 'common/progress-bar/ProgressBar'
import { DropzoneArea } from 'react-mui-dropzone'
import EditorText from '../../../common/custom-text-editor/CustomEditorText'
import SEO from 'common/seo/SEO'
export interface AddActivitySectorProps {
    title?: string
    metaTitle?: string
    metaDescription?: string
    subTitle?: string
    description?: string
    subDescription?: string
    category?: string
}

const schema = object({
    title: string(),
    subTitle: string(),
    description: string(),
    subDescription: string(),
    category: string(),
    metaTitle: string(),
    metaDescription: string(),
})

export function AddActivitySector() {
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<AddActivitySectorProps>({ resolver: yupResolver(schema) })

    const [key, setKey] = useState(0)
    const [file, setFile] = useState<any>([])
    const [GIFfile, setGIFFile] = useState<any>([])
    const [debounceKey] = useDebounce(key, 1000)
    const [loading, setLoading] = useState(false)
    const [valueDescription, setValueDescription] = useState<string>('')
    const [valueSubDescription, setValueSubDescription] = useState<string>('')
    const [valueMetaTitle, setValueMetaTitle] = useState<string>('')
    const [valueMetaDescription, setValueMetaDescription] = useState<string>('')

    const classes = dropzoneStyle()

    const { addActivitySectorElement } = useAddActivitySectorElement()

    const handleChangeMetaTitle = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setValueMetaTitle(event.target.value)
    }
    const handleChangeMetaDescription = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setValueMetaDescription(event.target.value)
    }
    async function onSubmit() {
        try {
            addActivitiesSector(
                getValues('title'),
                getValues('subTitle'),
                getValues('category'),
            )
        } catch (err: any) {
            render(<Toaster error toastMsg={err.response} />)
        }
    }

    const addActivitiesSector = async (
        title?: string,
        subTitle?: string,
        category?: string,
    ) => {
        try {
            const bodyFormData = new FormData()
            setLoading(true)

            if (title) {
                bodyFormData.append('title', title)
            }

            if (category) {
                bodyFormData.append('category', category)
            }

            if (subTitle) {
                bodyFormData.append('subTitle', subTitle)
            }

            bodyFormData.append(
                'description',
                valueDescription ? valueDescription : '',
            )
            bodyFormData.append(
                'subDescription',
                valueSubDescription ? valueSubDescription : '',
            )
            bodyFormData.append(
                'metaDescription',
                valueMetaDescription ? valueMetaDescription : '',
            )
            bodyFormData.append(
                'metaTitle',
                valueMetaTitle ? valueMetaTitle : '',
            )

            if (file) {
                bodyFormData.append('image', file)
                setKey(key + 1)
            }

            if (GIFfile) {
                bodyFormData.append('gif', GIFfile)
                setKey(key + 1)
            }

            addActivitySectorElement(bodyFormData).finally(() =>
                setLoading(false),
            )
        } catch (error: any) {
            render(<Toaster error toastMsg={error?.response?.data.message} />)
        }
    }

    return (
        <Card elevation={16} style={{ margin: '15px' }}>
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
            >
                <CardHeader title={"Ajouter un secteur d'activité"} />

                <CardContent>
                    <Box style={{ padding: '10px' }}>
                        <Paper
                            sx={{
                                mb: 2,
                            }}
                        >
                            <SEO
                                {...{
                                    valueMetaTitle,
                                    valueMetaDescription,
                                    handleChangeMetaTitle,
                                    handleChangeMetaDescription,
                                    errors,
                                    register,
                                }}
                                activitySector
                            />
                        </Paper>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <FormControl
                                sx={{ m: 1, width: '50%' }}
                                variant="outlined"
                            >
                                <InputLabel
                                    required
                                    htmlFor="description-sector"
                                >
                                    Titre de la page
                                </InputLabel>
                                <OutlinedInput
                                    required
                                    fullWidth
                                    id="title-sector"
                                    label="title"
                                    {...register('title')}
                                    onChange={e =>
                                        setValue('title', e.target.value)
                                    }
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                                <FormHelperText id="sector-weight-helper-text">
                                    {errors?.title?.message}
                                </FormHelperText>
                            </FormControl>

                            <FormControl
                                sx={{ m: 1, width: '50%' }}
                                variant="outlined"
                            >
                                <InputLabel htmlFor="description-sector">
                                    Titre de la carte
                                </InputLabel>
                                <OutlinedInput
                                    required
                                    fullWidth
                                    id="title-sector"
                                    label="Titre de la carte"
                                    {...register('subTitle')}
                                    onChange={e =>
                                        setValue('subTitle', e.target.value)
                                    }
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                                <FormHelperText id="sector-weight-helper-text">
                                    {errors?.subTitle?.message}
                                </FormHelperText>
                            </FormControl>

                            <FormControl
                                sx={{ m: 1, width: '50%' }}
                                variant="outlined"
                            >
                                <InputLabel htmlFor="categorie-sector">
                                    Catégorie
                                </InputLabel>
                                <OutlinedInput
                                    required
                                    id="category-sector"
                                    label="category"
                                    {...register('category')}
                                    onChange={e =>
                                        setValue('category', e.target.value)
                                    }
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                                <FormHelperText id="sector-weight-text">
                                    {errors?.category?.message}
                                </FormHelperText>
                            </FormControl>
                        </Box>

                        <Grid>
                            <Box>
                                <Typography variant="h6">
                                    Description de la page :
                                </Typography>
                            </Box>
                            <Box sx={{ pb: 1 }}>
                                <EditorText
                                    currentValue={valueDescription}
                                    updateCurrentValue={setValueDescription}
                                />
                                <Typography variant="caption">
                                    (Max : un paragraphe de 100 000 caractères
                                    max.)
                                </Typography>
                            </Box>
                            <span className="error">
                                {errors?.description?.message}
                            </span>
                        </Grid>

                        <Grid>
                            <Box>
                                <Typography variant="h6">
                                    Description de la carte :
                                </Typography>
                            </Box>
                            <Box sx={{ pb: 1 }}>
                                <EditorText
                                    currentValue={valueSubDescription}
                                    updateCurrentValue={setValueSubDescription}
                                />
                                <Typography variant="caption">
                                    (Max : un paragraphe de 10 000 caractères
                                    max.)
                                </Typography>
                            </Box>
                            <span className="error">
                                {errors?.subDescription?.message}
                            </span>
                        </Grid>

                        <Grid container mb={2}>
                            <Grid item sm={6}>
                                <Box sx={{ p: 1 }}>
                                    <InputLabel
                                        required
                                        htmlFor="description-sector"
                                    >
                                        Image :
                                    </InputLabel>
                                </Box>
                                <Box sx={{ p: 1 }}>
                                    <DropzoneArea
                                        key={debounceKey}
                                        dropzoneClass={classes.dropzone}
                                        acceptedFiles={['image/*,video/*']}
                                        dropzoneText="Glisser-déposer une image ou rechercher dans les fichiers (max : 150Mo)"
                                        maxFileSize={150000000}
                                        filesLimit={1}
                                        onDrop={(files: any): void => {
                                            setFile(files[0])
                                        }}
                                        showAlerts={false}
                                    />
                                </Box>
                            </Grid>
                            <Grid item sm={6}>
                                <Box sx={{ p: 1 }}>
                                    <InputLabel
                                        required
                                        htmlFor="description-sector"
                                    >
                                        GIF :
                                    </InputLabel>
                                </Box>
                                <Box sx={{ p: 1 }}>
                                    <DropzoneArea
                                        dropzoneClass={classes.dropzone}
                                        key={debounceKey}
                                        acceptedFiles={['image/*']}
                                        dropzoneText="Glisser-déposer un GIF ou rechercher dans les fichiers (max : 150Mo)"
                                        maxFileSize={150000000}
                                        filesLimit={1}
                                        onDrop={(files): void => {
                                            setGIFFile(files[0])
                                        }}
                                        showAlerts={false}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>

                <Box
                    style={{
                        top: '80%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        position: 'absolute',
                    }}
                >
                    {loading && <CircularProgress size={80} />}
                </Box>

                <CardActions>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: '10px' }}
                        type="submit"
                    >
                        ENREGISTRER
                    </Button>
                </CardActions>
            </form>
            <ProgressBar loading={loading} />
        </Card>
    )
}
