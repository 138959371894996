import React from 'react'
import {
    List,
    Stack,
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { createStyles, makeStyles } from '@mui/styles'

interface JobOfferFilterListProps {
    items?: string[]
    setForm: (item: string) => void
}

export default function JobOfferFilterList({
    items,
    setForm,
}: JobOfferFilterListProps) {
    const useStyles = makeStyles(() =>
        createStyles({
            filterList: {
                display: 'flex',
                flexFlow: 'column wrap',
                gap: '0 30px',
            },
        }),
    )
    const classes = useStyles()
    return (
        <List component={Stack} direction="row" className={classes.filterList}>
            {items?.map((item: string) => (
                <ListItem key={item} disablePadding>
                    <ListItemButton onClick={() => setForm(item)}>
                        <ListItemText primary={item} />
                        <ListItemIcon>
                            <DeleteIcon />
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    )
}
