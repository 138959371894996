import React, { useEffect, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { TextField, Grid } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { render } from '@testing-library/react'
import { useHistory } from 'react-router-dom'
import { object, string, InferType } from 'yup'
import axios from 'axios'
import Aos from 'aos'
import Toaster from '../Toaster/Toaster'
import { AuthContext } from '../../context/auth-context'
import { SERVER_URL } from '../../config/environments'
import 'aos/dist/aos.css'
import Logo42 from '../../image/42c_degrade_fond_transparent.png'
import './Login.scss'

const schema = object({
    email: string()
        .email('Email must be a valid email address')
        .required('Email is required'),
    password: string()
        .required('Password is required')
        .min(8, 'Password is too short 8 chars minimum.')
        .matches(
            /^[a-zA-Z0-9_.-]*$/,
            'Password can only contain Latin letters.',
        ),
})

type Props = InferType<typeof schema>

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            width: theme.spacing(55),
            [theme.breakpoints.down('lg')]: {
                width: 300,
            },
        },
    }),
)

const Login = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Props>({ resolver: yupResolver(schema) })

    const classes = useStyles()
    const auth: any = useContext(AuthContext)
    const history: any = useHistory()

    async function onSubmit(values: Props) {
        await axios
            .post(`${SERVER_URL.environment}/auth/signin`, values)
            .then(response => {
                auth.loginData(response.data)
                return history.push('/')
            })
            .catch(err => render(<Toaster error toastMsg={err.message} />))
    }

    useEffect(() => {
        Aos.init()
    }, [])

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            style={{ minHeight: '90vh' }}
            className="login"
            data-aos="fade-right"
        >
            <Grid style={{ textAlign: 'center' }}>
                <img className="Logo42" src={Logo42} alt="logo" />
            </Grid>
            <Grid style={{ textAlign: 'center' }}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    autoComplete="off"
                >
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        direction="column"
                        spacing={2}
                    >
                        <Grid item>
                            <Grid
                                container
                                justifyContent="center"
                                direction="column"
                            >
                                <Grid item>
                                    <TextField
                                        id="email"
                                        label="Email"
                                        type="email"
                                        className={classes.textField}
                                        {...register('email')}
                                    />
                                </Grid>
                                <Grid item style={{ textAlign: 'left' }}>
                                    <span className="error">
                                        {errors?.email?.message}
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid
                                container
                                justifyContent="center"
                                direction="column"
                            >
                                <Grid item style={{ textAlign: 'left' }}>
                                    <TextField
                                        id="password"
                                        label="Password"
                                        type="password"
                                        className={classes.textField}
                                        {...register('password')}
                                    />
                                </Grid>
                                <Grid item style={{ textAlign: 'left' }}>
                                    <span className="error">
                                        {errors?.password?.message}
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <button type="submit">CONNEXION</button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    )
}

export default Login
