import useSWR from 'swr'
import { TechnicalSolution } from '../../../common/types/technical-solution/TechnicalSolution'
import { SERVER_URL } from '../../../config/environments'

const FETCH_ALL_SOLUTION_TECHNIQUE_URL = `${SERVER_URL.environment}/technical-solution`

export function useSolutionTechniqueElements() {
    const {
        data: solutionTechniqueElements,
        mutate: mutateSolutionTechniqueElements,
    } = useSWR<TechnicalSolution[]>(
        'api/technical-solution/all',
        fetchAllSolutionTechnique,
    )

    async function fetchAllSolutionTechnique() {
        const response = await fetch(`${FETCH_ALL_SOLUTION_TECHNIQUE_URL}/all`)
        return response.json()
    }
    return { solutionTechniqueElements, mutateSolutionTechniqueElements }
}
