import { makeStyles } from '@mui/styles'

export const dropzoneStyle = makeStyles({
    dropzone: {
        '& .MuiDropzoneArea-textContainer': {
            padding: '20px',
            '& .MuiTypography-root': {
                fontSize: '17px',
            },
        },
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
})
