import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    Button,
    Box,
    CardContent,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Card,
    Grid,
    Typography,
    CircularProgress,
    Select,
    MenuItem,
    FormControl,
    OutlinedInput,
    InputLabel,
    FormHelperText,
    Slide,
} from '@mui/material'
import { dropzoneStyle } from 'assets/GlobalStyles'
import { SelectChangeEvent } from '@mui/material/Select'
import CheckIcon from '@mui/icons-material/Check'
import { render } from '@testing-library/react'
import ProgressBar from 'common/progress-bar/ProgressBar'
import { useForm } from 'react-hook-form'
import { object, string } from 'yup'

import Toaster from 'common/Toaster/Toaster'
import { TransitionProps } from '@mui/material/transitions'
import { useDebounce } from 'use-debounce/lib'
import { DropzoneArea } from 'react-mui-dropzone'
import { UseUpdateClientElement } from 'hooks/clients/single/useClients'
import { UseAddClientElement } from 'hooks/clients/add/useAddClients'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
    title: string
    partner?: string
    imageUrl?: string
}

const schema = object({
    title: string(),
    partner: string(),
    imageUrl: string(),
})

export function ClientPopUp({ client, isOpen, handleClose, idClient }: any) {
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<Props>({ resolver: yupResolver(schema) })

    const [file, setImage] = useState<any>([])
    const [key, setKey] = useState(0)
    const [debounceKey] = useDebounce(key, 1000)
    const [loading, setLoading] = useState(false)
    const [partner, setPartner] = useState<string>(client?.partner)

    const { updateClient } = UseUpdateClientElement(idClient)
    const { AddClient } = UseAddClientElement()
    const classes = dropzoneStyle()
    const handleChange = (event: SelectChangeEvent) => {
        setPartner(event.target.value)
    }

    async function onSubmit() {
        try {
            crtUpClient(getValues('title'), partner)
        } catch (err: any) {
            render(<Toaster error toastMsg={err.response} />)
        }
    }

    const crtUpClient = async (title: string, partner: string) => {
        try {
            const bodyFormData = new FormData()
            setLoading(true)

            if (title) {
                bodyFormData.append('title', title)
            }
            if (partner) {
                bodyFormData.append('partner', partner)
            }
            if (file.length != 0) {
                bodyFormData.append('logo', file)
                setKey(key + 1)
            }

            if (client != null) {
                updateClient(bodyFormData)
                    .then(res => {
                        if (res === 'OK') {
                            handleClose()
                        }
                    })
                    .finally(() => setLoading(false))
            } else {
                AddClient(bodyFormData)
                    .then(res => {
                        if (res === 'Created') {
                            handleClose()
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
        } catch (error: any) {
            setLoading(false)
            render(<Toaster error toastMsg={error?.response?.data.message} />)
        }
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
            maxWidth="md"
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
            >
                <DialogTitle>
                    {client != null
                        ? 'Modifier le client'
                        : 'Ajouter un client'}
                </DialogTitle>

                <DialogContent>
                    <Box style={{ padding: '10px' }}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item md={8}>
                                        <FormControl
                                            sx={{
                                                m: 1,
                                                width: '100%',
                                            }}
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                required
                                                htmlFor="tit-sector"
                                            >
                                                Titre
                                            </InputLabel>
                                            <OutlinedInput
                                                sx={{ alignItems: 'center' }}
                                                required
                                                fullWidth
                                                id="title-miss"
                                                label="title"
                                                defaultValue={
                                                    client?.title != ''
                                                        ? client?.title
                                                        : ''
                                                }
                                                {...register('title')}
                                                onChange={e =>
                                                    setValue(
                                                        'title',
                                                        e.target.value,
                                                    )
                                                }
                                                inputProps={{
                                                    'aria-label': 'weight',
                                                }}
                                            />
                                            <FormHelperText id="sector-weight-helper-text">
                                                {errors?.title?.message}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={4}>
                                        <FormControl
                                            variant="outlined"
                                            sx={{ m: 1, width: '100%' }}
                                        >
                                            <InputLabel
                                                required
                                                htmlFor="tit-sector"
                                            >
                                                Partenaire
                                            </InputLabel>
                                            <Select
                                                labelId="partners-label"
                                                id="partners-favorites"
                                                value={partner ?? ''}
                                                onChange={handleChange}
                                                label="Partenaire"
                                                required
                                            >
                                                <MenuItem value={'ESTIAM'}>
                                                    ESTIAM
                                                </MenuItem>
                                                <MenuItem value={'LAB'}>
                                                    LAB
                                                </MenuItem>
                                                <MenuItem value={'42C'}>
                                                    42C
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid>
                                    <Box p={1}>
                                        <Typography variant="h6">
                                            Image * :
                                        </Typography>
                                    </Box>
                                    <Box>
                                        {client?.logoUrl && (
                                            <Box>
                                                <iframe
                                                    id="inlineFrameExample"
                                                    title="Inline Frame Example"
                                                    width="100%"
                                                    height="300"
                                                    frameBorder="0"
                                                    src={client?.logoUrl}
                                                />
                                            </Box>
                                        )}
                                        <DropzoneArea
                                            key={debounceKey}
                                            dropzoneClass={classes.dropzone}
                                            acceptedFiles={['image/*']}
                                            dropzoneText="Glisser-déposer une image ou rechercher dans les fichiers (max : 150Mo)"
                                            maxFileSize={150000000}
                                            filesLimit={1}
                                            onDrop={(files: any): void => {
                                                setImage(files[0])
                                            }}
                                            showAlerts={false}
                                        />
                                    </Box>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>
                </DialogContent>

                <Box
                    style={{
                        top: '40%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: '1',
                        position: 'absolute',
                    }}
                >
                    {loading && <CircularProgress size={80} />}
                </Box>

                <DialogActions>
                    <Grid container justifyContent="right" spacing={2}>
                        <Grid item>
                            <Button onClick={handleClose}>Fermer</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                startIcon={<CheckIcon />}
                                type="submit"
                            >
                                ENREGISTRER
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </form>

            <ProgressBar loading={loading} />
        </Dialog>
    )
}
