const url: string = window.location.toString()

export default function useCurrentEnv() {
    const isLocalEnv = () =>
        url.includes('127.0.0.1') || url.includes('localhost')

    const isDevelopEnv = () => url.includes('develop.42c.42cloud.io')
    const isStagingEnv = () => url.includes('staging.42c.42cloud.io')
    const isProdEnv = () => url.includes('42c.fr')

    return {
        isLocalEnv,
        isDevelopEnv,
        isStagingEnv,
        isProdEnv,
    }
}
