import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import ConfirmForm from 'common/confirm-form/ConfirmForm'
import EditIcon from '@mui/icons-material/Edit'
import { TablePagination } from '@mui/material'
import { useJobOffers } from 'hooks/job-offer/UseJobOffer'
import { JobOfferElement } from 'common/types/job-offer/JobOfferElement'

interface ListJobOfferProps {
    jobOffers?: JobOfferElement[]
    setJobOffer: (jobOffer: JobOfferElement) => void
    setIsOpen: (isOpen: boolean) => void
}

export function ListJobOffer({
    jobOffers,
    setJobOffer,
    setIsOpen,
}: ListJobOfferProps) {
    const [open, setOpen] = React.useState(false)
    const [id, setId] = React.useState('')
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const handleClickOpen = (id: string) => {
        setId(id)
        setOpen(true)
    }

    const { deleteJobOffer } = useJobOffers()

    const onDelete = () => {
        deleteJobOffer(id)
        setOpen(false)
    }

    const onEdit = (jobOffer: JobOfferElement) => {
        setJobOffer(jobOffer)
        setIsOpen(true)
    }

    return (
        <div>
            <Paper sx={{ width: '100%' }}>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Titre</TableCell>

                                <TableCell align="left">Localisation</TableCell>
                                <TableCell align="left">Niveau</TableCell>
                                <TableCell align="left">Contrat</TableCell>
                                <TableCell align="left">Catégorie</TableCell>
                                <TableCell align="left">Début</TableCell>
                                <TableCell align="left">Description</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {jobOffers
                                ?.map((row: JobOfferElement) => (
                                    <TableRow
                                        key={row._id}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.title}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.jobLocation}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.level}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.contract}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.category}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.startDate}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            dangerouslySetInnerHTML={{
                                                __html: row?.description
                                                    ?.slice(0, 300)
                                                    .concat('...')
                                                    ? row?.description
                                                          ?.slice(0, 300)
                                                          .concat('...')
                                                    : '',
                                            }}
                                        />

                                        <TableCell align="right">
                                            <IconButton
                                                onClick={() => onEdit(row)}
                                                size="large"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() =>
                                                    handleClickOpen(row._id)
                                                }
                                                size="large"
                                            >
                                                <DeleteOutlinedIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                                .reverse()
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 35, 50, 100]}
                    component="div"
                    count={jobOffers ? jobOffers?.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            <ConfirmForm
                onConfirm={onDelete}
                open={open}
                setOpen={setOpen}
                action="delete"
            />
        </div>
    )
}
