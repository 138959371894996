import React, { useState } from 'react'
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    IconButton,
    InputLabel,
    Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material/styles'
import './Estiam.scss'

import AttachFileIcon from '@mui/icons-material/AttachFile'

import { DropzoneArea, DropzoneDialog } from 'react-mui-dropzone'
import { useDebounce } from 'use-debounce/lib'

import Toaster from 'common/Toaster/Toaster'
import { render } from '@testing-library/react'

import { useAddEstiamElement } from 'hooks/estiam/addEstiam/UseAddEstiam'
import { UseEstiamElements } from 'hooks/estiam/single/UseEstiam'

import { Estiam } from 'common/types/estiam/Estiam'

import ConfirmForm from '../../../common/confirm-form/ConfirmForm'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper1: {
            marginBottom: 10,
        },
        paper2: {
            marginBottom: 10,
        },
        textField: {
            width: '100%',
        },
        dropzone: {
            padding: 15,
            minHeight: 80,
            display: 'flex',
            // width: 350,
        },
        dropzonetext: {
            fontSize: 14,
            fontweight: 300,
        },
        avatar: {
            borderRadius: 0,
            width: theme.spacing(15),
            height: theme.spacing(15),
            '@media (max-width: 868px)': {
                width: theme.spacing(11),
                height: theme.spacing(11),
            },
        },
    }),
)

export type Props = {
    estiam: Estiam[]
}
export default function Estiams({ estiam }: any) {
    const classes = useStyles()

    const [file, setFile] = useState<any>([])
    const [avatarImage, setAvatarImage] = useState<string>('')
    const [key, setKey] = useState(0)
    const [open, setOpen] = useState(false)
    const [brochure, setBrochure] = useState<any>([])
    const [openConfirmForm, setOpenConfirmForm] = useState(false)

    const [debounceKey] = useDebounce(key, 1000)

    const { addEstiam } = useAddEstiamElement()
    const { update } = UseEstiamElements(estiam ? estiam[0]?._id : '')

    const handleClickOpen = () => {
        setOpenConfirmForm(true)
    }

    async function onSubmit() {
        try {
            addComityMembers()
            render(<Toaster success toastMsg="ENREGISTRÉ" />)
        } catch (err: any) {
            render(<Toaster error toastMsg={err.response} />)
        }
    }

    const addComityMembers = async () => {
        try {
            const bodyFormData = new FormData()

            if (file) {
                bodyFormData.append('image', file)
                setKey(key + 1)
            } else {
                bodyFormData.append('imageUrl', '')
            }

            if (brochure) {
                bodyFormData.append('brochure', brochure)
                setKey(key + 1)
            }

            if (estiam.length > 0) {
                bodyFormData.append('_id', estiam[0]._id)
            }

            if (estiam.length > 0) {
                update(bodyFormData)
            } else {
                addEstiam(bodyFormData)
            }

            setOpenConfirmForm(false)
        } catch (error: any) {
            render(<Toaster error toastMsg={error} />)
        }
    }

    return (
        <>
            <form noValidate autoComplete="off">
                <Card>
                    <CardContent>
                        <Box
                            component="form"
                            sx={{ display: 'flex', flexWrap: 'wrap' }}
                        >
                            <Box
                                p={2}
                                sx={{ display: 'grid', flexWrap: 'wrap' }}
                            >
                                <InputLabel required htmlFor="image-estiam">
                                    Image :
                                </InputLabel>
                                <IconButton
                                    component="span"
                                    onClick={() => setOpen(true)}
                                    size="large"
                                >
                                    <Avatar
                                        className={classes.avatar}
                                        alt={estiam[0]?.title}
                                        src={
                                            file?.length === 0
                                                ? estiam[0]?.imageUrl
                                                : avatarImage
                                        }
                                    />
                                </IconButton>

                                <IconButton
                                    onClick={() => {
                                        estiam[0].imageUrl = null
                                        setAvatarImage('')
                                        setFile(null)
                                    }}
                                    size="large"
                                >
                                    <DeleteOutlinedIcon />
                                </IconButton>

                                <DropzoneDialog
                                    key={debounceKey}
                                    dropzoneClass={classes.dropzone}
                                    acceptedFiles={['image/*']}
                                    dropzoneText="Glisser-déposer une image ou rechercher dans les fichiers (max : 10Mo)"
                                    maxFileSize={10000000}
                                    filesLimit={1}
                                    open={open}
                                    onClose={() => setOpen(false)}
                                    onDrop={async files => {
                                        const src = window.URL.createObjectURL(
                                            files[0],
                                        )

                                        setAvatarImage(src)
                                        setFile(files[0])

                                        setOpen(false)
                                    }}
                                    showAlerts={true}
                                    getDropRejectMessage={resultat =>
                                        `Ce fichier est trop grand , taille de fichier : ${resultat.size} bytes, taille max est 5000000 bytes (5Mo)`
                                    }
                                />
                                <Typography variant="caption">
                                    (Max : un image de 5Mo max.)
                                </Typography>
                            </Box>
                            <Box p={2}>
                                <InputLabel required htmlFor="brochure-estiam">
                                    Brochure :
                                </InputLabel>
                                <DropzoneArea
                                    key={debounceKey}
                                    dropzoneClass={classes.dropzone}
                                    dropzoneParagraphClass={
                                        classes.dropzonetext
                                    }
                                    acceptedFiles={['application/pdf']}
                                    dropzoneText="Faites glisser et déposez un fichier ou cliquez ici"
                                    maxFileSize={50000000}
                                    filesLimit={1}
                                    onDrop={async files => {
                                        setBrochure(files[0])
                                    }}
                                    onDelete={() => {
                                        setBrochure([])
                                    }}
                                    showFileNames
                                    showAlerts={['error', 'success', 'info']}
                                    getDropRejectMessage={resultat =>
                                        `Ce fichier est trop grand , taille de fichier : ${resultat.size} bytes, taille max est 50000000 bytes (50Mo)`
                                    }
                                />
                                <Typography variant="caption">
                                    {brochure.length === 0 ? (
                                        estiam[0]?.brochureUrl ? (
                                            <div>
                                                <AttachFileIcon color="success" />
                                                Un fichier déja ajouté
                                            </div>
                                        ) : (
                                            <div>
                                                <AttachFileIcon color="primary" />
                                                Aucun fichier ajouté
                                            </div>
                                        )
                                    ) : (
                                        <div>
                                            <AttachFileIcon color="success" />
                                            Fichier a été bien sélectionné
                                        </div>
                                    )}
                                </Typography>
                                <Typography variant="caption">
                                    (Max : un fichier de 50Mo max.)
                                </Typography>
                            </Box>
                        </Box>
                    </CardContent>

                    <CardActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            ENREGISTRER
                        </Button>
                    </CardActions>
                </Card>
            </form>

            <ConfirmForm
                onConfirm={onSubmit}
                open={openConfirmForm}
                setOpen={setOpenConfirmForm}
                action="edit"
            />
        </>
    )
}
