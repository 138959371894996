import useSWR from 'swr'
import { MapMarkerElement } from '../../../common/types/map-markers/MapMarkersElement'
import { SERVER_URL } from '../../../config/environments'

const FETCH_ALL_MAP_MARKERS_URL = `${SERVER_URL.environment}/map-markers`

export function useMapMarkersElements() {
    const { data: mapMarkerElements, mutate: mutateMapMarkersElements } =
        useSWR<MapMarkerElement[]>('api/map-markers/all', fetchAllMapMarkers)

    async function fetchAllMapMarkers() {
        const response = await fetch(`${FETCH_ALL_MAP_MARKERS_URL}/all`)
        return response.json()
    }
    return { mapMarkerElements, mutateMapMarkersElements }
}
