import { yupResolver } from '@hookform/resolvers/yup'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    MenuItem,
    Box,
    FormControl,
    OutlinedInput,
    InputLabel,
    FormHelperText,
    Grid,
    Typography,
} from '@mui/material'
import { render } from '@testing-library/react'
import { useForm } from 'react-hook-form'
import { object, string } from 'yup'

import './PopupAddFormation.scss'
import Toaster from 'common/Toaster/Toaster'
import { useAddFormationElement } from 'hooks/estiam/formations/addFormations/UseAddFormations'
import { useEditFormationElement } from 'hooks/estiam/formations/editFormations/UseAddFormations'
import EditorText from '../../../../common/custom-text-editor/CustomEditorText'
import React, { useEffect, useState } from 'react'
import SaveButton from 'common/custom-button/SaveButton'

interface Props {
    _id?: string
    title: string
    description?: string
    formationType?: string
}

const schema = object({
    title: string(),
    description: string(),
    formationType: string(),
})

const rowsFormationTypes = [
    {
        value: 'TECHNICAL_PROGRAM',
        label: 'TECHNICAL_PROGRAM',
    },
    {
        value: 'MBA_DATA_SOLUTIONS_ARCHITECT_NWOW',
        label: 'MBA_DATA_SOLUTIONS_ARCHITECT_NWOW',
    },
]

export function AddFormation({ handleClose, estiam, isOpen, setLoading }: any) {
    const [valueDescription, setValueDescription] = useState<string>('')

    const { add } = useAddFormationElement(estiam?.idEstiam)
    const { update } = useEditFormationElement(
        estiam?.idEstiam,
        estiam?.data?._id,
    )

    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<Props>({ resolver: yupResolver(schema) })

    async function onSubmit() {
        try {
            addFormation({
                title: getValues('title'),
                description: valueDescription,
                formationType: getValues('formationType'),
            })
        } catch (err: any) {
            render(<Toaster error toastMsg={err.response} />)
        }
    }

    const addFormation = async (formationFormValues: {
        title?: string
        formationType?: string
        description: string
    }) => {
        try {
            const bodyFormData = new URLSearchParams()

            Object.entries(formationFormValues).forEach(([key, value]) => {
                bodyFormData.append(key, value)
            })

            if (estiam?.data?._id) {
                update(bodyFormData)
                    .then(res => {
                        if (res === 'OK') {
                            handleClose()
                        }
                    })
                    .finally(() => setLoading(false))
            } else {
                add(bodyFormData)
                    .then(res => {
                        if (res === 'Created') {
                            handleClose()
                        }
                    })
                    .finally(() => setLoading(false))
            }
        } catch (error: any) {
            setLoading(false)
            render(<Toaster error toastMsg={error?.response?.data.message} />)
        }
    }

    useEffect(() => {
        setValueDescription(
            estiam?.data?.description ? estiam?.data?.description : '',
        )
    }, [])

    return (
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
            >
                {estiam.data._id ? (
                    <DialogTitle>{'Modifier une formation'}</DialogTitle>
                ) : (
                    <DialogTitle>{'Ajouter une formation'}</DialogTitle>
                )}
                <DialogContent>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div>
                            <FormControl
                                sx={{ m: 1, width: '42ch' }}
                                variant="outlined"
                            >
                                <TextField
                                    select
                                    required
                                    id="formationType-estiam"
                                    label="Type de formation"
                                    {...register('formationType')}
                                    defaultValue={estiam?.data?.formationType}
                                    onChange={e =>
                                        setValue(
                                            'formationType',
                                            e.target.value,
                                        )
                                    }
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                >
                                    {rowsFormationTypes.map((option: any) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>

                            <FormControl
                                sx={{ m: 1, width: '42ch' }}
                                variant="outlined"
                            >
                                <InputLabel required htmlFor="lastname-expertt">
                                    Titre
                                </InputLabel>
                                <OutlinedInput
                                    required
                                    fullWidth
                                    id="lastname-estiam"
                                    label="Titre"
                                    {...register('title')}
                                    defaultValue={estiam?.data?.title}
                                    onChange={e =>
                                        setValue('title', e.target.value)
                                    }
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                                <FormHelperText id="sector-estiam-weight-helper-text">
                                    {errors?.title?.message}
                                </FormHelperText>
                            </FormControl>

                            <Grid>
                                <Box p={1}>
                                    <Typography variant="h6">
                                        Description :
                                    </Typography>
                                </Box>
                                <Box sx={{ p: 1 }}>
                                    <EditorText
                                        currentValue={valueDescription}
                                        updateCurrentValue={setValueDescription}
                                    />
                                </Box>
                                <span className="error">
                                    {errors?.description?.message}
                                </span>
                            </Grid>
                        </div>
                    </Box>
                </DialogContent>

                <SaveButton {...{ handleClose }} />
            </form>
        </Dialog>
    )
}
