import React from 'react'
import {
    Box,
    Container,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Button,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { UseCase } from '../../../../common/types/use-case/UseCase'

const useStyle = makeStyles({
    form: {
        marginTop: 30,
    },
    saveButton: {
        background: 'linear-gradient(25deg, #FF0033, #FF5500)',
        color: '#fff',
        borderRadius: 'unset',
        width: '350px',
        height: '30px',
        float: 'right',
    },
})

type Props = {
    favorites: string[]
    // eslint-disable-next-line no-unused-vars
    onFavoritesChange: (event: any) => void
    listSuccessStories: UseCase[]
    saveFavorites: () => void
}

export default function FavoriteUseCaseSelect({
    favorites,
    onFavoritesChange,
    listSuccessStories,
    saveFavorites,
}: Props) {
    // const { favorites, onFavoritesChange, listSuccessStories, saveFavorites } = props;
    const classes = useStyle()
    return (
        <Container
            component="form"
            maxWidth={false}
            disableGutters
            data-aos="fade-right"
        >
            <FormControl fullWidth className={classes.form}>
                <InputLabel id="multiple-favorites-label">
                    Sélectionner les success stories à mettre en avant sur la
                    page d'accueil
                </InputLabel>
                <Select
                    labelId="multiple-favorites-label"
                    id="multiple-favorites"
                    value={favorites}
                    onChange={onFavoritesChange}
                    input={<Input />}
                    multiple
                >
                    {listSuccessStories?.map((story: UseCase) => (
                        <MenuItem value={story._id} key={story._id}>
                            {story.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Box mt="30px">
                <Button onClick={saveFavorites} className={classes.saveButton}>
                    Sauvegarder
                </Button>
            </Box>
        </Container>
    )
}
