import React from 'react'
import { Container } from '@mui/material'
import TechnicalSolutionMetaView from '../../common/view/view'

export default function TechnicalSolutionViewHome() {
    return (
        <Container component="section" maxWidth={false} disableGutters>
            <TechnicalSolutionMetaView useMeta category="TECHNICAL_SOLUTION" />
        </Container>
    )
}
