import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material'
import './DetailContact.scss'

export function DetailContact({ contact, isOpen, setIsOpen }: any) {
    const handleClose = () => {
        setIsOpen(false)
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{'DÉTAILS'}</DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom component="div">
                    <b>Nom :</b> {contact?.lastName}
                </Typography>
                <Typography variant="body1" gutterBottom component="div">
                    <b>Prénom :</b> {contact?.firstName}
                </Typography>
                <Typography variant="body1" gutterBottom component="div">
                    <b>E-mail :</b> {contact?.email}
                </Typography>
                <Typography variant="body1" gutterBottom component="div">
                    <b>Objet :</b> {contact?.object}
                </Typography>
                <Typography variant="body1" gutterBottom component="div">
                    <b>Message :</b> {contact?.message}
                </Typography>
                <Typography variant="body1" gutterBottom component="div">
                    <b>Date d'envoi :</b> {contact?.createdAt}
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button variant="contained" onClick={handleClose}>
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    )
}
