import React, { useEffect, useState } from 'react'
import {
    Button,
    Card,
    CardHeader,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    OutlinedInput,
    Paper,
    TablePagination,
    Typography,
    IconButton,
    TableRow,
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableBody,
    CardActions,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Box } from '@mui/system'
import AddIcon from '@mui/icons-material/Add'
import { AddQuote } from './add-quote/AddQuote'
import { Quote } from 'common/types/join-us/Quote'
import { useQuote } from 'hooks/join-us/quote/UseQuote'
import ConfirmForm from 'common/confirm-form/ConfirmForm'
import { useForm } from 'react-hook-form'
import { useTestimony } from 'hooks/join-us/testimony/UseTestimony'
import { useJoinUs } from 'hooks/join-us/UseJoinUs'
import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

interface Props {
    _id?: string
    title: string
    videoUrl: string
}

const schema = object({
    title: string(),
    videoUrl: string(),
})

const Quotes = ({ testimony, idJoinUs, setLoading }: any) => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [isOpen, setIsOpen] = useState(false)
    const [joinUs, setJoinUs] = useState<any>()
    const [idQuote, setIdQuote] = useState('')
    const [openConfirmForm, setOpenConfirmForm] = useState(false)

    const { deleteQuote } = useQuote()
    const { updateTestimony } = useTestimony()
    const { createJoinUs } = useJoinUs()

    const handleClickOpen = (Id: string) => {
        setIdQuote(Id)
        setOpenConfirmForm(true)
    }

    const onDelete = () => {
        deleteQuote(idJoinUs, idQuote)
        setOpenConfirmForm(false)
    }

    const togglePopup = () => {
        setJoinUs({ data: testimony, idJoinUs: idJoinUs })
        setIsOpen(!isOpen)
    }

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const onEdit = (qte: Quote) => {
        setJoinUs({ data: qte, idJoinUs: idJoinUs })
        setIsOpen(true)
    }

    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<Props>({ resolver: yupResolver(schema) })

    async function onSubmit() {
        setLoading(true)
        addTestimony(getValues('title'), getValues('videoUrl')).finally(() =>
            setLoading(false),
        )
    }

    const addTestimony = async (title?: string, videoUrl?: string) => {
        if (!idJoinUs) {
            const data = new FormData()
            data.append('title', 'Nous rejoindre')
            idJoinUs = await createJoinUs(data).then(
                (response: any) => response._id,
            )
        }

        const bodyFormData = new FormData()

        if (title !== undefined) {
            bodyFormData.append('title', title)
        }

        bodyFormData.append('videoUrl', videoUrl ? videoUrl : '')

        await updateTestimony(idJoinUs, bodyFormData)
    }

    useEffect(() => {
        setIsOpen(false)
    }, [])

    return (
        <Paper elevation={3} sx={{ padding: '10px' }}>
            <Grid item xs={12}>
                <Box sx={{ margin: '10px' }}>
                    <Typography variant="h5">Les témoignages</Typography>
                </Box>
            </Grid>

            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
            >
                <Card>
                    <CardHeader
                        title={
                            <FormControl
                                sx={{ m: 1, width: '70%' }}
                                variant="outlined"
                            >
                                <InputLabel required htmlFor="title-joinUs">
                                    Titre
                                </InputLabel>
                                <OutlinedInput
                                    required
                                    fullWidth
                                    label="Titre"
                                    id="title-joinUs"
                                    {...register('title')}
                                    defaultValue={testimony.title}
                                    onChange={e => {
                                        setValue('title', e.target.value)
                                    }}
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                                <FormHelperText id="title-weight-helper-text">
                                    {errors?.title?.message}
                                </FormHelperText>
                            </FormControl>
                        }
                        subheader={
                            <FormControl
                                sx={{ m: 1, width: '70%' }}
                                variant="outlined"
                            >
                                <InputLabel required htmlFor="videoUrl-joinUs">
                                    ID de la vidéo YouTube
                                </InputLabel>
                                <OutlinedInput
                                    required
                                    fullWidth
                                    label="ID de la vidéo YouTube"
                                    id="videoUrl-joinUs"
                                    {...register('videoUrl')}
                                    defaultValue={testimony.videoUrl}
                                    onChange={e => {
                                        setValue('videoUrl', e.target.value)
                                    }}
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                                <FormHelperText id="videoUrl-weight-helper-text">
                                    L'ID de la vidéo se trouve généralement à la
                                    fin de l'URL, ex:
                                    https://www.youtube.com/watch?v=
                                    <strong>BD23XLKkfI8</strong>
                                    {errors?.videoUrl?.message}
                                </FormHelperText>
                            </FormControl>
                        }
                    />
                    <CardActions>
                        <FormControl
                            sx={{ m: 1, width: '20ch', float: 'right' }}
                            variant="outlined"
                        >
                            <Button
                                variant="outlined"
                                color="primary"
                                size="medium"
                                type="submit"
                            >
                                ENREGISTRER
                            </Button>
                        </FormControl>
                    </CardActions>
                </Card>
            </form>

            <Grid item xs={12} mt={2}>
                <Box sx={{ margin: '10px' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        startIcon={<AddIcon />}
                        onClick={togglePopup}
                    >
                        Ajouter
                    </Button>
                </Box>
            </Grid>

            <TableContainer component={Paper}>
                <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>Nom</TableCell>
                            <TableCell>Prénom</TableCell>
                            <TableCell>Témoignages</TableCell>
                            <TableCell>Poste</TableCell>

                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {testimony &&
                            testimony?.quotes.map((row: any, index: number) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell>{row.lastName}</TableCell>
                                    <TableCell>{row.firstName}</TableCell>
                                    <TableCell>{row.quote}</TableCell>
                                    <TableCell>{row.role}</TableCell>
                                    <TableCell align="right">
                                        <IconButton
                                            onClick={() => onEdit(row)}
                                            size="large"
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() =>
                                                handleClickOpen(row._id)
                                            }
                                            size="large"
                                        >
                                            <DeleteOutlinedIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage="Lignes par page"
                component="div"
                count={testimony?.quotes?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {isOpen && (
                <AddQuote
                    handleClose={togglePopup}
                    joinUs={joinUs}
                    isOpen={isOpen}
                    setLoading={setLoading}
                />
            )}

            <ConfirmForm
                onConfirm={onDelete}
                open={openConfirmForm}
                setOpen={setOpenConfirmForm}
                action="delete"
            />
        </Paper>
    )
}

export default Quotes
