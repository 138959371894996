import useSWR from 'swr'
import { UseCase } from '../../../common/types/use-case/UseCase'
import { SERVER_URL } from '../../../config/environments'

const FETCH_ALL_SUCCESS_STORIES_URL = `${SERVER_URL.environment}/success-story`

export function useSuccessStoriesElements() {
    const { data: successStories, mutate: mutateSuccessStoriesElements } =
        useSWR<UseCase[]>('api/success-story/all', fetchAllSuccessStories)

    async function fetchAllSuccessStories() {
        const response = await fetch(`${FETCH_ALL_SUCCESS_STORIES_URL}/all`)
        return response.json()
    }
    return { successStories, mutateSuccessStoriesElements }
}
