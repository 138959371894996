import { useEffect, useState } from 'react'
import 'dayjs/locale/fr'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogContent,
    DialogTitle,
    Avatar,
    OutlinedInput,
    InputLabel,
    FormHelperText,
    FormControl,
    Theme,
    IconButton,
    CircularProgress,
    Typography,
    Grid,
    TextField,
} from '@mui/material'
import { AttachFile, Delete } from '@mui/icons-material'
import { dropzoneStyle } from 'assets/GlobalStyles'
import { DropzoneDialog, DropzoneArea } from 'react-mui-dropzone'

import { render } from '@testing-library/react'

import { useForm } from 'react-hook-form'

import { useDebounce } from 'use-debounce/lib'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import './PopupAddTalkingAboutUs.scss'
import Toaster from '../../../common/Toaster/Toaster'

import { useAddTalkingAboutUs } from 'hooks/talking-about-us/add/useAddTalkingAboutUs'
import { useUpdateTalkingAboutUs } from 'hooks/talking-about-us/update/useUpdateTalkingAboutUs'
import ProgressBar from 'common/progress-bar/ProgressBar'
import { TalkingAboutUs } from 'common/types/talking-about-us/TalkingAboutUs'
import SaveButton from 'common/custom-button/SaveButton'

const schema = object({
    _id: string(),
    title: string(),
    category: string(),
    realizedBy: string(),
    description: string(),
    summary: string(),
    imageUrl: string(),
    link: string(),
    fileUrl: string(),
    createdAt: string(),
})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            width: theme.spacing(15),
            height: theme.spacing(15),
            '@media (max-width: 868px)': {
                width: theme.spacing(11),
                height: theme.spacing(11),
            },
        },

        dropzone: {
            padding: 15,
            minHeight: 80,
            display: 'flex',
            justifyContent: 'center',
        },
        dropzonetext: {
            fontSize: 14,
            fontweight: 300,
        },
    }),
)

export function PopupAddTalkingAboutUs({
    handleClose,
    isOpen,
    singleTalkingAboutUs,
}: {
    handleClose: () => void
    isOpen: boolean
    singleTalkingAboutUs: TalkingAboutUs
}) {
    const classes = useStyles()
    const dropzoneClass = dropzoneStyle()

    const [open, setOpen] = useState(false)
    const [key, setKey] = useState(0)
    const [loading, setLoading] = useState(false)

    const [file, setFile] = useState<any>([])
    const [imageFile, setImageFile] = useState<any>([])
    const [avatarView, setAvatarView] = useState<string>('')
    const [valueDescription, setValueDescription] = useState<string>('')
    const [talkingAboutUsForm, setTalkingAboutUsForm] =
        useState<TalkingAboutUs>({
            ...singleTalkingAboutUs,
        })

    const [debounceKey] = useDebounce(key, 1000)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<TalkingAboutUs>({ resolver: yupResolver(schema) })

    const { update, deleteFile } = useUpdateTalkingAboutUs(
        singleTalkingAboutUs?._id,
    )
    const { addTalkingAboutUs } = useAddTalkingAboutUs()

    const onDropImage = async (files: any[]) => {
        const src = window.URL.createObjectURL(files[0])
        setAvatarView(src)
        setImageFile(files[0])
        setOpen(false)
    }

    const onDropFile = async (files: any[]) => {
        setFile(files[0])
    }

    const onDeleteFile = async () => {
        try {
            await deleteFile().then(() => {
                setFile([])
            })
        } catch (err: any) {
            render(<Toaster error toastMsg={err.response} />)
        }
    }

    async function onSubmit() {
        try {
            saveTalkingAboutUs({
                ...talkingAboutUsForm,
                description: valueDescription,
            })
            handleClose()
        } catch (err: any) {
            render(<Toaster error toastMsg={err.response} />)
        }
    }

    const saveTalkingAboutUs = async (singleTalkingAboutUs: TalkingAboutUs) => {
        const { ...rest } = singleTalkingAboutUs

        try {
            const bodyFormData = new FormData()
            setLoading(true)

            Object.entries(rest).forEach(([key, value]) => {
                bodyFormData.append(key, value)
            })

            if (file) {
                bodyFormData.append('file', file)
                setKey(key + 1)
            }

            if (imageFile) {
                bodyFormData.append('image', imageFile)
                setKey(key + 1)
            }

            if (singleTalkingAboutUs._id) {
                update(bodyFormData)
                    .then(res => {
                        if (res === 'OK') {
                            handleClose()
                        }
                    })
                    .finally(() => setLoading(false))
            } else {
                addTalkingAboutUs(bodyFormData)
                    .then(res => {
                        if (res === 'Created') {
                            handleClose()
                        }
                    })
                    .finally(() => setLoading(false))
            }
        } catch (error: any) {
            render(<Toaster error toastMsg={error?.response?.data.message} />)
        }
    }

    useEffect(() => {
        setValueDescription(talkingAboutUsForm?.description)
    }, [])

    return (
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
            >
                <DialogTitle>{'Ajouter un article'}</DialogTitle>

                <DialogContent>
                    <Card elevation={1}>
                        <CardHeader
                            avatar={
                                <>
                                    <IconButton
                                        component="span"
                                        onClick={() => setOpen(true)}
                                        size="large"
                                    >
                                        <Avatar
                                            className={classes.avatar}
                                            alt={talkingAboutUsForm?.title}
                                            src={
                                                imageFile.length == 0
                                                    ? talkingAboutUsForm?.imageUrl
                                                    : avatarView
                                            }
                                        />
                                    </IconButton>
                                    <DropzoneDialog
                                        key={debounceKey}
                                        acceptedFiles={['image/*']}
                                        dropzoneClass={dropzoneClass.dropzone}
                                        dropzoneText="Glisser-déposer une image ou rechercher dans les fichiers (max : 10Mo)"
                                        maxFileSize={10000000}
                                        filesLimit={1}
                                        open={open}
                                        onClose={() => setOpen(false)}
                                        onDrop={onDropImage}
                                        showAlerts={false}
                                    />
                                </>
                            }
                            title={
                                <>
                                    <FormControl
                                        sx={{ m: 1, width: '74ch' }}
                                        variant="outlined"
                                    >
                                        <InputLabel
                                            required
                                            htmlFor="title-talking-about-us"
                                        >
                                            Titre
                                        </InputLabel>
                                        <OutlinedInput
                                            required
                                            fullWidth
                                            label="Titre"
                                            id="title-talking-about-us"
                                            {...register('title')}
                                            defaultValue={
                                                talkingAboutUsForm?.title
                                            }
                                            onChange={e => {
                                                setTalkingAboutUsForm({
                                                    ...talkingAboutUsForm,
                                                    title: e.target.value,
                                                })
                                            }}
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                        <FormHelperText id="title-talking-about-us-weight-helper-text">
                                            {errors?.title?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </>
                            }
                            subheader={
                                <>
                                    <FormControl
                                        sx={{ m: 1, width: '74ch' }}
                                        variant="outlined"
                                    >
                                        <InputLabel
                                            required
                                            htmlFor="summary-talking-about-us"
                                        >
                                            Phrase d'accroche
                                        </InputLabel>
                                        <OutlinedInput
                                            required
                                            id="summary-talking-about-us"
                                            label="Phrase d'accroche"
                                            {...register('summary')}
                                            defaultValue={
                                                talkingAboutUsForm?.summary
                                            }
                                            onChange={e => {
                                                setTalkingAboutUsForm({
                                                    ...talkingAboutUsForm,
                                                    summary: e.target.value,
                                                })
                                            }}
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                            multiline
                                        />
                                        <FormHelperText id="summary-talking-about-us-weight-helper-text">
                                            {errors?.summary?.message}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl
                                        sx={{ m: 1, width: '74ch' }}
                                        variant="outlined"
                                    >
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale={'fr'}
                                        >
                                            <DateTimePicker
                                                renderInput={props => (
                                                    <TextField {...props} />
                                                )}
                                                label="Date de création"
                                                value={
                                                    talkingAboutUsForm?.createdAt
                                                }
                                                onChange={value =>
                                                    setTalkingAboutUsForm({
                                                        ...talkingAboutUsForm,
                                                        createdAt: value
                                                            ? value
                                                            : undefined,
                                                    })
                                                }
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </>
                            }
                        />

                        <CardContent>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                }}
                            >
                                <FormControl
                                    sx={{ m: 1, width: '42ch' }}
                                    variant="outlined"
                                >
                                    <InputLabel htmlFor="realizedBy-talking-about-us">
                                        Réalisé par
                                    </InputLabel>
                                    <OutlinedInput
                                        required
                                        fullWidth
                                        label="Réalisé par"
                                        id="realizedBy-talking-about-us"
                                        {...register('realizedBy')}
                                        defaultValue={
                                            talkingAboutUsForm?.realizedBy
                                        }
                                        onChange={e => {
                                            setTalkingAboutUsForm({
                                                ...talkingAboutUsForm,
                                                realizedBy: e.target.value,
                                            })
                                        }}
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                    <FormHelperText id="description-talking-about-us-weight-helper-text">
                                        {errors?.realizedBy?.message}
                                    </FormHelperText>
                                </FormControl>

                                <FormControl
                                    sx={{ m: 1, width: '42ch' }}
                                    variant="outlined"
                                >
                                    <InputLabel
                                        required
                                        htmlFor="summary-talking-about-us"
                                    >
                                        Catégorie
                                    </InputLabel>
                                    <OutlinedInput
                                        required
                                        id="summary-talking-about-us"
                                        label="Catégorie"
                                        {...register('category')}
                                        defaultValue={
                                            talkingAboutUsForm?.category
                                        }
                                        onChange={e => {
                                            setTalkingAboutUsForm({
                                                ...talkingAboutUsForm,
                                                category: e.target.value,
                                            })
                                        }}
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                    <FormHelperText id="talking-about-us-weight-helper-text">
                                        {errors?.category?.message}
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                            <Box>
                                <Typography fontWeight="bold" m={1} pb={1}>
                                    Veuillez entrer le lien de l'article{' '}
                                    <u>ou</u> le fichier PDF de référence :
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                }}
                            >
                                <FormControl
                                    sx={{ m: 1, width: '86ch' }}
                                    variant="outlined"
                                >
                                    <InputLabel htmlFor="link-talking-about-us">
                                        Lien
                                    </InputLabel>
                                    <OutlinedInput
                                        id="link-talking-about-us"
                                        label="Lien"
                                        {...register('link')}
                                        defaultValue={talkingAboutUsForm?.link}
                                        onChange={e => {
                                            setTalkingAboutUsForm({
                                                ...talkingAboutUsForm,
                                                link: e.target.value,
                                            })
                                        }}
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                    <FormHelperText id="talking-about-us-weight-helper-text">
                                        {errors?.link?.message}
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                            <Box p={2}>
                                <InputLabel htmlFor="talking-about-us-file">
                                    Fichier :
                                </InputLabel>
                                <DropzoneArea
                                    key={debounceKey}
                                    dropzoneClass={classes.dropzone}
                                    dropzoneParagraphClass={
                                        classes.dropzonetext
                                    }
                                    acceptedFiles={[
                                        'image/*,video/*,application/pdf',
                                    ]}
                                    dropzoneText="Faites glisser et déposez un fichier ou cliquez ici"
                                    maxFileSize={50000000}
                                    filesLimit={1}
                                    onDrop={onDropFile}
                                    onDelete={onDeleteFile}
                                    initialFiles={
                                        talkingAboutUsForm?.fileUrl
                                            ? [talkingAboutUsForm?.fileUrl]
                                            : []
                                    }
                                    showFileNames
                                    showAlerts={['error', 'success', 'info']}
                                    getDropRejectMessage={resultat =>
                                        `Ce fichier est trop grand , taille de fichier : ${resultat.size} bytes, taille max est 50000000 bytes (50Mo)`
                                    }
                                />
                                <Typography variant="caption">
                                    {file.length !== 0 ? (
                                        <div>
                                            <AttachFile color="success" />
                                            Un fichier a été bien sélectionné
                                        </div>
                                    ) : talkingAboutUsForm?.fileUrl ? (
                                        <div>
                                            <AttachFile color="success" />
                                            Un fichier a déjà été ajouté
                                        </div>
                                    ) : (
                                        <div>
                                            <AttachFile color="primary" />
                                            Aucun fichier ajouté
                                        </div>
                                    )}
                                </Typography>
                                <Typography variant="caption">
                                    (Max : Fichier de 50Mo max.)
                                </Typography>
                                {singleTalkingAboutUs &&
                                    singleTalkingAboutUs.fileUrl && (
                                        <Grid container mt={2}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                startIcon={<Delete />}
                                                onClick={onDeleteFile}
                                            >
                                                Supprimer le fichier
                                            </Button>
                                        </Grid>
                                    )}
                            </Box>
                        </CardContent>
                    </Card>
                </DialogContent>

                <Box
                    style={{
                        top: '40%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: '1',
                        position: 'absolute',
                    }}
                >
                    {loading && <CircularProgress size={80} />}
                </Box>

                <SaveButton {...{ handleClose }} />
            </form>
            <ProgressBar loading={loading} />
        </Dialog>
    )
}
