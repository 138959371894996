import React, { useContext, useEffect, useState } from 'react'
import Aos from 'aos'
import { Container, Grid } from '@mui/material'
import SectoralBusinessSolutionView from '../../../common/view/view'
import './ActivitySectorFavorite.scss'
import FavoriteActivitySectorSelect from './favorite-activity-sector-select/FavoriteActivitySectorSelect'
import { AuthContext } from 'context/auth-context'
import axios from 'axios'
import { SERVER_URL } from 'config/environments'
import { render } from '@testing-library/react'
import Toaster from 'common/Toaster/Toaster'
import FavoriteActivitySectorCard from './favorite-activity-sector/FavoriteActivitySectorCard'
import { ActivitySectorElement } from 'common/types/activity-sector/ActivitySectorElements'

// Secteur d'activités endpoints Endpoint
const ActivitySectorEndpoint = `${SERVER_URL.environment}/activity-sector`

export default function ActivitySectorFavorite() {
    const auth: any = useContext(AuthContext)
    const [activitySectorList, setActivitySectorList] = useState<
        ActivitySectorElement[]
    >([])
    const [favorites, setFavorites] = useState<string[]>([])
    const [oldFavorites, setOldFavorites] = useState<string[]>([])

    async function getAllActivitySector() {
        try {
            await axios
                .get<ActivitySectorElement[]>(`${ActivitySectorEndpoint}/all`, {
                    headers: {
                        Authorization: `Bearer ${auth.accessToken}` as string,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(response => setActivitySectorList(response?.data))
        } catch (error: any) {
            render(<Toaster error toastMsg={error.message} />)
        }
    }

    async function getFavoriteActivitySector() {
        try {
            await axios
                .get<ActivitySectorElement[]>(
                    `${ActivitySectorEndpoint}/all/favorite`,
                    {
                        headers: {
                            Authorization:
                                `Bearer ${auth.accessToken}` as string,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    },
                )
                .then(response => {
                    const favArr =
                        response?.data?.map(
                            (uc: ActivitySectorElement) => uc._id,
                        ) ?? []
                    setFavorites(favArr as string[])
                    setOldFavorites(favArr as string[])
                })
        } catch (error: any) {
            render(<Toaster error toastMsg={error.message} />)
        }
    }

    async function saveFavorites() {
        try {
            const formData = new FormData()
            formData.append('isFavorite', 'true')

            const callback = () => {
                removeFromFavorites().then(() => getFavoriteActivitySector())
                render(<Toaster success toastMsg="Sauvegardé avec succès !" />)
            }

            favorites.map(async (activitySectorId: string) => {
                await axiosUpdateRequest(
                    activitySectorId,
                    formData,
                    callback,
                    (err: any) =>
                        render(<Toaster error toastMsg={err.message} />),
                )
            })
            removeFromFavorites()
        } catch (error: any) {
            render(<Toaster error toastMsg={error.message} />)
        }
    }

    const removeFromFavorites = async () => {
        try {
            const formData = new FormData()
            formData.append('isFavorite', 'false')

            const toRm: string[] = []
            oldFavorites.forEach(oldFavorite => {
                if (!favorites.find(favorite => favorite === oldFavorite)) {
                    toRm.push(oldFavorite)
                }
            })

            toRm.map(async (activitySectorId: string) => {
                await axiosUpdateRequest(
                    activitySectorId,
                    formData,
                    () => null,
                    (err: any) =>
                        render(<Toaster error toastMsg={err.message} />),
                )
            })
        } catch (error: any) {
            render(<Toaster error toastMsg={error.message} />)
        }
    }

    const axiosUpdateRequest = async (
        id: string,
        formData: FormData,
        _cb: () => void,
        // eslint-disable-next-line no-unused-vars
        catchError: (error: any) => void,
    ) => {
        await axios({
            method: 'put',
            url: `${ActivitySectorEndpoint}/${id}`,
            data: formData,
            headers: {
                Authorization: `Bearer ${auth.accessToken}` as string,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(() => {
                getAllActivitySector()
                render(<Toaster success toastMsg="ENREGISTRÉ" />)
            })
            .catch(err => catchError(err))
    }

    const handleFavoritesChange = (event: any) => {
        setFavorites(event.target.value)
    }

    useEffect(() => {
        getAllActivitySector().then(() => getFavoriteActivitySector())
        Aos.init()
    }, [])

    return (
        <Container component="section" maxWidth={false} disableGutters>
            <SectoralBusinessSolutionView
                useDescription
                useTitle
                category="SECTORAL_BUSINESS_SOLUTION"
            />
            <FavoriteActivitySectorSelect
                favorites={favorites}
                onFavoritesChange={handleFavoritesChange}
                activitySectorList={activitySectorList}
                saveFavorites={saveFavorites}
            />

            <Grid container spacing={4}>
                {favorites
                    .map(
                        _id =>
                            activitySectorList.find(
                                activitySector => activitySector._id === _id,
                            ) as ActivitySectorElement,
                    )
                    .map(activitySector => (
                        <Grid
                            item
                            xs={12}
                            md={4}
                            lg={3}
                            key={activitySector._id}
                        >
                            <FavoriteActivitySectorCard
                                activitySector={activitySector}
                            />
                        </Grid>
                    ))}
            </Grid>
            {/* <FavoriteUseCaseGrid favorites={favorites} /> */}
        </Container>
    )
}
