import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material'
import './DetailApply.scss'

export function DetailApply({ apply, isOpen, setIsOpen }: any) {
    const handleClose = () => {
        setIsOpen(false)
    }
    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{'DÉTAILS CANDIDATURES'}</DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom component="div">
                    <b>Nom :</b> {apply.lastName}
                </Typography>
                <Typography variant="body1" gutterBottom component="div">
                    <b>Prénom :</b> {apply.firstName}
                </Typography>
                <Typography variant="body1" gutterBottom component="div">
                    <b>E-mail :</b> {apply.email}
                </Typography>
                <Typography variant="body1" gutterBottom component="div">
                    <b>Offre :</b> {apply?.jobOffer?.title}
                </Typography>
                <Typography variant="body1" gutterBottom component="div">
                    <b>CV : </b>
                    <a href={apply.resumeUrl} target="_blank" rel="noreferrer">
                        Télécharger
                    </a>
                </Typography>
                <Typography variant="body1" gutterBottom component="div">
                    <b>Date d'envoi :</b> {apply?.createdAt}
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button variant="contained" onClick={handleClose}>
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    )
}
