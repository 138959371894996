import React, { useEffect, useState } from 'react'
import Aos from 'aos'
import { Grid, Box } from '@mui/material'
import { useJoinUs } from 'hooks/join-us/UseJoinUs'
import ProgressBar from 'common/progress-bar/ProgressBar'
import JoinUsView from './join-us/JoinUsView'
import OurJob from './our-job/OurJob'
import Advantages from './advantages/Advantages'
import JoinUsMetaView from '../../common/view/view'
import Quotes from './quote/Quotes'
import { JoinUs as JoinUsElement } from 'common/types/join-us/JoinUs'

export default function JoinUs() {
    const { joinUsElements, createJoinUs } = useJoinUs()

    const [loading, setLoading] = useState(false)
    const [joinUs, setJoinUs] = useState<JoinUsElement>({
        _id: '',
        title: '',
        description: '',
        imageUrl: '',
        ourJobs: {
            title: '',
            description: '',
            jobs: [],
        },
        advantages: [],
        testimony: {
            title: '',
            videoUrl: '',
            quotes: [],
        },
    })

    useEffect(() => {
        async function createEmptyJoinUs() {
            const emptyJoinUsForm = new FormData()
            emptyJoinUsForm.append('title', 'Nous rejoindre')

            await createJoinUs(emptyJoinUsForm)
        }

        if (joinUsElements?.length === 0) {
            createEmptyJoinUs()
        } else if (joinUsElements?.[0]) {
            setJoinUs(joinUsElements[0])
        }

        Aos.init()
        setLoading(false)
    }, [joinUsElements])

    return (
        <>
            <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
                data-aos="fade-right"
            >
                <Grid item xs={12}>
                    <Box mb={2}>
                        <JoinUsMetaView useMeta category="JOIN_US" />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <JoinUsView joinUs={joinUs} />
                </Grid>
                <Grid item xs={12}>
                    <Box mb={2}>
                        <OurJob
                            ourJob={joinUs.ourJobs}
                            idJoinUs={joinUs._id}
                            setLoading={setLoading}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box mb={2}>
                        <Advantages />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box mb={2}>
                        <Quotes
                            testimony={joinUs.testimony}
                            idJoinUs={joinUs._id}
                            setLoading={setLoading}
                        />
                    </Box>
                </Grid>
            </Grid>

            <ProgressBar loading={loading} />
        </>
    )
}
