import React from 'react'
import {
    Box,
    Container,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Button,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { JobOfferElement } from 'common/types/job-offer/JobOfferElement'

const useStyle = makeStyles({
    form: {
        marginTop: 30,
    },
    saveButton: {
        background: 'linear-gradient(25deg, #FF0033, #FF5500)',
        color: '#fff',
        borderRadius: 'unset',
        width: '350px',
        height: '30px',
        float: 'right',
    },
})

type Props = {
    favorites: string[]
    // eslint-disable-next-line no-unused-vars
    onFavoritesChange: (event: any) => void
    jobOffersList: JobOfferElement[]
    saveFavorites: () => void
}

export default function FavoriteJobOffersSelect({
    favorites,
    onFavoritesChange,
    jobOffersList,
    saveFavorites,
}: Props) {
    const classes = useStyle()
    return (
        <Container
            component="form"
            maxWidth={false}
            disableGutters
            data-aos="fade-right"
        >
            <FormControl fullWidth className={classes.form}>
                <InputLabel id="multiple-favorites-label">
                    Sélectionner les offres d'emplois à mettre en avant sur la
                    page d'accueil
                </InputLabel>
                <Select
                    labelId="multiple-favorites-label"
                    id="multiple-favorites"
                    value={favorites}
                    onChange={onFavoritesChange}
                    input={<Input />}
                    multiple
                >
                    {jobOffersList
                        ?.map((jobOffer: JobOfferElement) => (
                            <MenuItem value={jobOffer._id} key={jobOffer._id}>
                                {jobOffer.title}
                            </MenuItem>
                        ))
                        .reverse()}
                </Select>
            </FormControl>
            <Box mt="30px">
                <Button onClick={saveFavorites} className={classes.saveButton}>
                    Enregistrer les favoris
                </Button>
            </Box>
        </Container>
    )
}
