export const pagesList = [
    {
        pageTitle: 'Les experts',
        slug: 'page-experts',
    },
    {
        pageTitle: 'Le Lab',
        slug: 'page-lab',
    },
    {
        pageTitle: 'Success stories',
        slug: 'page-success-stories',
    },
    {
        pageTitle: 'Contact',
        slug: 'page-contact',
    },
    {
        pageTitle: 'Mentions légales',
        slug: 'page-mentions-legales',
    },
    {
        pageTitle: 'CGU',
        slug: 'page-cgu',
    },
    {
        pageTitle: 'Qui sommes-nous',
        slug: 'page-qui-sommes-nous',
    },
    {
        pageTitle: 'Postuler',
        slug: 'page-postuler',
    },
    {
        pageTitle: 'Page Multi-solutions',
        slug: 'page-multi-solutions',
    },
    {
        pageTitle: "Page Secteurs d'activités",
        slug: 'page-secteurs-d-activites',
    },
    {
        pageTitle: 'Page On parle de nous',
        slug: 'page-on-parle-de-nous',
    },
]
