import axios from 'axios'
import useSWR from 'swr'
import { JoinUs } from 'common/types/join-us/JoinUs'
import useToken from '../session/userSession'
import { SERVER_URL } from '../../config/environments'
import Toaster from 'common/Toaster/Toaster'
import { render } from '@testing-library/react'

const JOIN_US_URL = `${SERVER_URL.environment}/join-us`

export function useJoinUs() {
    const { data: joinUsElements, mutate: mutateJoinUsElements } = useSWR<
        JoinUs[]
    >('api/join-us/all', fetchAllJoinUs)
    const { accessToken } = useToken()

    async function fetchAllJoinUs() {
        const response = await fetch(`${JOIN_US_URL}/all`)
        return response.json()
    }

    async function createJoinUs(bodyFormData: FormData) {
        return await axios({
            method: 'POST',
            url: `${JOIN_US_URL}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                mutateJoinUsElements()
                render(<Toaster success toastMsg="ENREGISTRÉ" />)
                return response.data
            })
            .catch(err => {
                render(<Toaster error toastMsg={err.response} />)
            })
    }

    async function updateJoinUs(id: string, bodyFormData: FormData) {
        await axios({
            method: 'PUT',
            url: `${JOIN_US_URL}/${id}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                mutateJoinUsElements()
                render(<Toaster success toastMsg="MODIFIÉ" />)
                return response
            })
            .catch(err => {
                render(<Toaster error toastMsg={err.response} />)
            })
    }

    async function deleteJoinUs(id: string) {
        await fetch(`${JOIN_US_URL}/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then(response => {
                mutateJoinUsElements()
                render(<Toaster success toastMsg="SUPPRIMÉ" />)
                return response.json()
            })
            .catch(err => {
                render(<Toaster error toastMsg={err.response} />)
            })
    }

    return {
        joinUsElements,
        fetchAllJoinUs,
        createJoinUs,
        updateJoinUs,
        deleteJoinUs,
        mutateJoinUsElements,
    }
}
