import {
    Typography,
    FormControl,
    TextField,
    FormHelperText,
    Box,
} from '@mui/material'
import { JobOfferElement } from 'common/types/job-offer/JobOfferElement'
import React from 'react'
import { UseFormSetValue, UseFormRegister } from 'react-hook-form'

interface DiscoverTeamFormProps {
    register: UseFormRegister<JobOfferElement>
    errorVideoUrlMessage?: string
    setValue: UseFormSetValue<JobOfferElement>
    jobOffer: JobOfferElement
}

export default function DiscoverTeam({
    register,
    errorVideoUrlMessage,
    setValue,
    jobOffer,
}: DiscoverTeamFormProps) {
    return (
        <Box>
            <Typography variant="h6" mt={2} mb={2}>
                Découvrez l'équipe :
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                }}
            >
                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                    <TextField
                        id="videoUrl-jobOffer"
                        label="ID de la vidéo YouTube"
                        {...register('videoUrl')}
                        defaultValue={jobOffer?.videoUrl}
                        onChange={e => setValue('videoUrl', e.target.value)}
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                    />
                    <FormHelperText id="videoUrl-jobOffer-weight-helper-text">
                        L'ID de la vidéo se trouve généralement à la fin de
                        l'URL, ex: https://www.youtube.com/watch?v=
                        <strong>BD23XLKkfI8</strong>
                        {errorVideoUrlMessage}
                    </FormHelperText>
                </FormControl>
            </Box>
        </Box>
    )
}
