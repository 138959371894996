import React, { useEffect, useState } from 'react'

import {
    Avatar,
    Button,
    Card,
    CardHeader,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    OutlinedInput,
    Paper,
    TablePagination,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Box } from '@mui/system'
import AddIcon from '@mui/icons-material/Add'
import { AddComityMember } from './add-comity-member/AddComityMember'
import { ComityMembersElement } from 'common/types/estiam/Comity'
import { UseDeleteComityMemberElements } from 'hooks/estiam/comity/deleteComityMembers/UseDeleteComityMembers'
import ConfirmForm from 'common/confirm-form/ConfirmForm'
import Toaster from 'common/Toaster/Toaster'
import { render } from '@testing-library/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import { useAddEstiamElement } from 'hooks/estiam/addEstiam/UseAddEstiam'
import { UseEstiamElements } from 'hooks/estiam/single/UseEstiam'
import EditorText from '../../../common/custom-text-editor/CustomEditorText'

type ComityProps = {
    title: string
    description: string
}

const schema = object({
    title: string(),
    description: string(),
})

interface IComity {
    comity: any
    idEstiam: string
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Comity({ comity, idEstiam, setLoading }: IComity) {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [isOpen, setIsOpen] = useState(false)
    const [estiam, setEstiam] = useState<any>()
    const [idComity, setIdComity] = useState('')
    const [openConfirmForm, setOpenConfirmForm] = useState(false)
    const [valueDescription, setValueDescription] = useState<string>('')

    const { addEstiam } = useAddEstiamElement()
    const { update } = UseEstiamElements(idEstiam)

    const { deleteComityMember } = UseDeleteComityMemberElements(
        idEstiam,
        idComity,
    )

    const handleClickOpen = (Id: string) => {
        setIdComity(Id)
        setOpenConfirmForm(true)
    }

    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<ComityProps>({
        resolver: yupResolver(schema),
    })

    async function onSubmit() {
        try {
            addComity(getValues('title'))
            render(<Toaster success toastMsg="ENREGISTRER" />)
        } catch (err: any) {
            render(<Toaster error toastMsg={err.response} />)
        }
    }

    const addComity = async (comityTitle?: string) => {
        try {
            const bodyFormData = new FormData()

            if (comityTitle) {
                bodyFormData.append('comity.title', comityTitle)
            }

            if (valueDescription) {
                bodyFormData.append('comity.description', valueDescription)
            }

            if (idEstiam) {
                bodyFormData.append('_id', idEstiam)
            }

            if (idEstiam) {
                update(bodyFormData)
            } else {
                addEstiam(bodyFormData)
            }
        } catch (error: any) {
            render(<Toaster error toastMsg={error} />)
        }
    }

    const onDelete = () => {
        deleteComityMember()
        setOpenConfirmForm(false)
    }

    const togglePopup = () => {
        setEstiam({ data: comity, idEstiam: idEstiam })
        setIsOpen(!isOpen)
    }

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const onEdit = (comityMember: ComityMembersElement) => {
        setEstiam({ data: comityMember, idEstiam: idEstiam })
        setIsOpen(true)
    }

    useEffect(() => {
        setValueDescription(comity?.description ? comity?.description : '')
    }, [])

    return (
        <Paper elevation={3} sx={{ padding: '10px' }}>
            <Grid item xs={12}>
                <Box sx={{ margin: '10px' }}>
                    <Typography variant="h5">Les comités</Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    autoComplete="off"
                >
                    <Card>
                        <CardHeader
                            title={
                                <div>
                                    <FormControl
                                        sx={{ m: 1, width: '42ch' }}
                                        variant="outlined"
                                    >
                                        <InputLabel
                                            required
                                            htmlFor="title-comity"
                                        >
                                            Titre
                                        </InputLabel>
                                        <OutlinedInput
                                            required
                                            fullWidth
                                            label="Titre"
                                            id="title-comity"
                                            {...register('title')}
                                            defaultValue={comity?.title}
                                            onChange={e =>
                                                setValue(
                                                    'title',
                                                    e.target.value,
                                                )
                                            }
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                        <FormHelperText id="title-weight-helper-text">
                                            {errors?.title?.message}
                                        </FormHelperText>
                                    </FormControl>

                                    <Grid>
                                        <Box p={1}>
                                            <Typography variant="h6">
                                                Description :
                                            </Typography>
                                        </Box>
                                        <Box sx={{ p: 1 }}>
                                            <EditorText
                                                currentValue={valueDescription}
                                                updateCurrentValue={
                                                    setValueDescription
                                                }
                                            />
                                        </Box>
                                        <span className="error">
                                            {errors?.description?.message}
                                        </span>
                                    </Grid>
                                </div>
                            }
                            subheader={
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    type="submit"
                                >
                                    ENREGISTRER
                                </Button>
                            }
                        />
                    </Card>
                </form>
            </Grid>

            <Grid item xs={12}>
                <Box sx={{ margin: '10px' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        startIcon={<AddIcon />}
                        onClick={togglePopup}
                    >
                        Ajouter un membre
                    </Button>
                </Box>
            </Grid>

            <TableContainer component={Paper}>
                <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Nom</TableCell>
                            <TableCell>Prénom</TableCell>
                            <TableCell>Poste</TableCell>
                            <TableCell>Citation</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {comity?.comityMembers.map(
                            (row: any, index: number) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell>
                                        <Avatar
                                            alt={row.firstName}
                                            src={row.imageUrl}
                                        />
                                    </TableCell>
                                    <TableCell>{row.lastName}</TableCell>
                                    <TableCell>{row.firstName}</TableCell>
                                    <TableCell>{row.role}</TableCell>
                                    <TableCell>{row.quote}</TableCell>
                                    <TableCell align="right">
                                        <IconButton
                                            onClick={() => onEdit(row)}
                                            size="large"
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() =>
                                                handleClickOpen(row._id)
                                            }
                                            size="large"
                                        >
                                            <DeleteOutlinedIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ),
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage="Lignes par page"
                component="div"
                count={comity?.comityMembers?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {isOpen && (
                <AddComityMember
                    handleClose={togglePopup}
                    estiam={estiam}
                    isOpen={isOpen}
                    setLoading={setLoading}
                />
            )}

            <ConfirmForm
                onConfirm={onDelete}
                open={openConfirmForm}
                setOpen={setOpenConfirmForm}
                action="delete"
            />
        </Paper>
    )
}
