import axios from 'axios'
import useSWR from 'swr'
import useToken from '../../session/userSession'
import { Advantage } from '../../../common/types/join-us/Advantage'
import { SERVER_URL } from '../../../config/environments'
import Toaster from 'common/Toaster/Toaster'
import { render } from '@testing-library/react'

const FETCH_ALL_ADVANTAGE_URL = `${SERVER_URL.environment}/advantage`

export function useAdvantage() {
    const { data: advantages, mutate: mutateAdvantageElements } = useSWR<
        Advantage[]
    >('api/advantage/all', fetchAllAdvantage)
    const { accessToken } = useToken()

    async function fetchAllAdvantage() {
        const response = await fetch(`${FETCH_ALL_ADVANTAGE_URL}/all`)
        return response.json()
    }

    async function createAdvantage(bodyFormData: Advantage) {
        return await axios({
            method: 'POST',
            url: `${FETCH_ALL_ADVANTAGE_URL}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                mutateAdvantageElements()
                render(<Toaster success toastMsg="SAUVEGARDÉ" />)
                return response.statusText
            })
            .catch(err => {
                render(
                    <Toaster
                        error
                        toastMsg={err.response.data.message.toString()}
                    />,
                )
            })
    }

    async function updateAdvantage(id: string, bodyFormData: Advantage) {
        return await axios({
            method: 'PUT',
            url: `${FETCH_ALL_ADVANTAGE_URL}/${id}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                mutateAdvantageElements()
                render(<Toaster success toastMsg="MODIFIÉ" />)
                return response
            })
            .catch(err => {
                render(
                    <Toaster
                        error
                        toastMsg={err.response.data.message.toString()}
                    />,
                )
            })
    }

    async function deleteAdvantage(id: string) {
        await fetch(`${FETCH_ALL_ADVANTAGE_URL}/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then(response => {
                mutateAdvantageElements()
                render(<Toaster success toastMsg="SUPPRIMÉ" />)
                return response.json()
            })
            .catch(err => {
                render(<Toaster error toastMsg={err.message} />)
            })
    }

    return {
        advantages,
        createAdvantage,
        updateAdvantage,
        deleteAdvantage,
        mutateAdvantageElements,
    }
}
