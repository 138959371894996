import useSWR from 'swr'
import {
    NavbarElement,
    UpdateNavbarElement,
} from '../../../common/types/navbar-element/NavbarElement'
import useToken from '../../session/userSession'
import { SERVER_URL } from '../../../config/environments'

const FETCH_ALL_NAVBAR_ELEMENTS_URL = `${SERVER_URL.environment}/navbar-element`
const NAVBAR_ELEMENT_URL = `${SERVER_URL.environment}/navbar-element`

export function useNavbarElements() {
    const { accessToken } = useToken()
    const { data: navbarElements, mutate: mutateNavbarElements } = useSWR<
        NavbarElement[]
    >('api/navbar-element/all', fetchAllNavbarElements)

    async function fetchAllNavbarElements() {
        const response = await fetch(`${FETCH_ALL_NAVBAR_ELEMENTS_URL}/all`)
        return response.json()
    }

    async function updateOrder(
        body: Partial<{ elements: UpdateNavbarElement[] }>,
    ): Promise<any> {
        const response = await fetch(`${NAVBAR_ELEMENT_URL}/all`, {
            body: JSON.stringify(body),
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        })

        if (!response.ok) {
            const error = await response.json()
            throw new Error(error.message)
        }

        return await response.json()
    }
    return { navbarElements, mutateNavbarElements, updateOrder }
}
