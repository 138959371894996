import React, { useState, useEffect, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, mixed, InferType, string, array } from 'yup'
import { render } from '@testing-library/react'
import { DropzoneArea } from 'react-mui-dropzone'
import { Grid, Paper, Typography, TextField, Box, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import CustomButton from 'common/custom-button/CustomButton'
import Aos from 'aos'
import axios from 'axios'
import { useDebounce } from 'use-debounce/lib'
import { AuthContext } from '../../../context/auth-context'
import { SERVER_URL } from '../../../config/environments'
import Toaster from '../../../common/Toaster/Toaster'

import './Performance.scss'

const SECOND_BANDEROLE_ENDPOINT = `${SERVER_URL.environment}/second-banderole`

const schema = object({
    file: mixed(),
    _id: string(),
    images: array().of(string()),
    statistics: array().of(
        object({
            number: string(),
            unit: string(),
            description: string(),
        }),
    ),
})

type Props = InferType<typeof schema>

interface SecondBanderole {
    _id: string
    secondBanderoleStatistics: {
        number: string
        unit: string
        description: string
    }[]
    firstImageUrl: string
    secondImageUrl: string
    thirdImageUrl: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        paper1: {
            height: theme.spacing(24),
        },
        paper2: {
            height: theme.spacing(26),
        },
        textField: {
            margin: 2,
            width: '100%',
        },
        dropzone: {
            '& .MuiDropzoneArea-textContainer': {
                padding: '20px',
                '& .MuiTypography-root': {
                    fontSize: '17px',
                },
            },
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    }),
)

export default function Performance() {
    const {
        formState: { errors },
    } = useForm<Props>({ resolver: yupResolver(schema), defaultValues: {} })

    const classes = useStyles()
    const auth: any = useContext(AuthContext)
    const [firstImageFile, setFirstImageFile] = useState<File>()
    const [secondImageFile, setSecondImageFile] = useState<File>()
    const [thirdImageFile, setThirdImageFile] = useState<File>()
    const [performance, setPerformance] = useState<SecondBanderole>()
    const [number1, setNumber1] = useState<string>('')
    const [number2, setNumber2] = useState<string>('')
    const [number3, setNumber3] = useState<string>('')
    const [unit1, setUnit1] = useState<string>('')
    const [unit2, setUnit2] = useState<string>('')
    const [unit3, setUnit3] = useState<string>('')
    const [description1, setDescription1] = useState<string>('')
    const [description2, setDescription2] = useState<string>('')
    const [description3, setDescription3] = useState<string>('')

    const [key, setKey] = useState(0)
    const [debounceKey] = useDebounce(key, 1000)

    const fetchFirstPerformance = () => {
        axios
            .get<SecondBanderole[]>(`${SECOND_BANDEROLE_ENDPOINT}/all`, {
                headers: {
                    Authorization: `Bearer ${auth.accessToken}` as string,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (response?.data?.[0]) {
                    setPerformance(response.data[0])
                }
            })
            .catch(err => render(<Toaster error toastMsg={err.message} />))
    }

    const onSubmit = () => {
        const bodyFormData = new FormData()

        if (firstImageFile) {
            bodyFormData.append('firstImage', firstImageFile)
        }
        if (secondImageFile) {
            bodyFormData.append('secondImage', secondImageFile)
        }
        if (thirdImageFile) {
            bodyFormData.append('thirdImage', thirdImageFile)
        }
        if (performance) {
            performance?.secondBanderoleStatistics?.forEach(
                (statistic, index) => {
                    bodyFormData.append(
                        `secondBanderoleStatistics[${index}][number]`,
                        statistic.number,
                    )
                    bodyFormData.append(
                        `secondBanderoleStatistics[${index}][unit]`,
                        statistic.unit,
                    )
                    bodyFormData.append(
                        `secondBanderoleStatistics[${index}][description]`,
                        statistic.description,
                    )
                },
            )

            axios
                .put(
                    `${SECOND_BANDEROLE_ENDPOINT}/${performance?._id}`,
                    bodyFormData,
                    {
                        headers: {
                            Authorization:
                                `Bearer ${auth.accessToken}` as string,
                            Accept: 'application/json',
                            'Content-Type': 'multipart/form-data',
                        },
                    },
                )
                .then(() => {
                    fetchFirstPerformance()
                    setKey(key + 1)
                    render(<Toaster success toastMsg="ENREGISTRÉ" />)
                })
                .catch(err => render(<Toaster error toastMsg={err.message} />))
        } else {
            if (number1) {
                bodyFormData.append(
                    `secondBanderoleStatistics[0][number]`,
                    number1,
                )
            }
            if (number2) {
                bodyFormData.append(
                    `secondBanderoleStatistics[1][number]`,
                    number2,
                )
            }
            if (number3) {
                bodyFormData.append(
                    `secondBanderoleStatistics[2][number]`,
                    number3,
                )
            }

            if (unit1) {
                bodyFormData.append(`secondBanderoleStatistics[0][unit]`, unit1)
            }
            if (unit2) {
                bodyFormData.append(`secondBanderoleStatistics[1][unit]`, unit2)
            }
            if (unit3) {
                bodyFormData.append(`secondBanderoleStatistics[2][unit]`, unit3)
            }

            if (description1) {
                bodyFormData.append(
                    `secondBanderoleStatistics[0][description]`,
                    description1,
                )
            }
            if (description2) {
                bodyFormData.append(
                    `secondBanderoleStatistics[1][description]`,
                    description2,
                )
            }
            if (description3) {
                bodyFormData.append(
                    `secondBanderoleStatistics[2][description]`,
                    description3,
                )
            }

            axios
                .post(`${SECOND_BANDEROLE_ENDPOINT}`, bodyFormData, {
                    headers: {
                        Authorization: `Bearer ${auth.accessToken}` as string,
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(() => {
                    fetchFirstPerformance()
                    setKey(key + 1)
                    render(<Toaster success toastMsg="ENREGISTRÉ" />)
                })
                .catch(err => render(<Toaster error toastMsg={err.message} />))
        }
    }

    useEffect(() => {
        fetchFirstPerformance()
        Aos.init()
    }, [])

    return (
        <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
            className="performance"
            data-aos="fade-right"
        >
            <Grid item xs={12}>
                <form noValidate autoComplete="off">
                    {performance?.secondBanderoleStatistics?.map(
                        (statistic, index) => (
                            <Box mb={2} key={index}>
                                <Paper elevation={3}>
                                    <Box sx={{ p: 2 }}>
                                        <Typography variant="h6" gutterBottom>
                                            Statistique :
                                        </Typography>
                                    </Box>

                                    <Grid container spacing={2} sx={{ p: 2 }}>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                label="Nombre"
                                                required
                                                onChange={e =>
                                                    setPerformance({
                                                        ...performance,
                                                        secondBanderoleStatistics:
                                                            [
                                                                ...performance.secondBanderoleStatistics.slice(
                                                                    0,
                                                                    index,
                                                                ),
                                                                {
                                                                    ...performance
                                                                        .secondBanderoleStatistics[
                                                                        index
                                                                    ],
                                                                    number: e
                                                                        .target
                                                                        .value,
                                                                },
                                                                ...performance.secondBanderoleStatistics.slice(
                                                                    index + 1,
                                                                ),
                                                            ],
                                                    })
                                                }
                                                defaultValue={statistic.number}
                                                error={
                                                    !!errors?.statistics?.[
                                                        index
                                                    ]?.number
                                                }
                                                helperText={
                                                    errors?.statistics?.[index]
                                                        ?.number
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                required
                                                onChange={e =>
                                                    setPerformance({
                                                        ...performance,
                                                        secondBanderoleStatistics:
                                                            [
                                                                ...performance.secondBanderoleStatistics.slice(
                                                                    0,
                                                                    index,
                                                                ),
                                                                {
                                                                    ...performance
                                                                        .secondBanderoleStatistics[
                                                                        index
                                                                    ],
                                                                    unit: e
                                                                        .target
                                                                        .value,
                                                                },
                                                                ...performance.secondBanderoleStatistics.slice(
                                                                    index + 1,
                                                                ),
                                                            ],
                                                    })
                                                }
                                                defaultValue={statistic.unit}
                                                label="Unités"
                                                error={
                                                    !!errors?.statistics?.[
                                                        index
                                                    ]?.unit
                                                }
                                                helperText={
                                                    errors?.statistics?.[index]
                                                        ?.unit
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                required
                                                onChange={e =>
                                                    setPerformance({
                                                        ...performance,
                                                        secondBanderoleStatistics:
                                                            [
                                                                ...performance.secondBanderoleStatistics.slice(
                                                                    0,
                                                                    index,
                                                                ),
                                                                {
                                                                    ...performance
                                                                        .secondBanderoleStatistics[
                                                                        index
                                                                    ],
                                                                    description:
                                                                        e.target
                                                                            .value,
                                                                },
                                                                ...performance.secondBanderoleStatistics.slice(
                                                                    index + 1,
                                                                ),
                                                            ],
                                                    })
                                                }
                                                defaultValue={
                                                    statistic.description
                                                }
                                                label="Description"
                                                error={
                                                    !!errors?.statistics?.[
                                                        index
                                                    ]?.description
                                                }
                                                helperText={
                                                    errors?.statistics?.[index]
                                                        ?.description
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Box>
                        ),
                    )}

                    {!performance?.secondBanderoleStatistics && (
                        <div>
                            <Box mb={2}>
                                <Paper elevation={3}>
                                    <Box sx={{ p: 2 }}>
                                        <Typography variant="h6" gutterBottom>
                                            Statistique :
                                        </Typography>
                                    </Box>

                                    <Grid container spacing={2} sx={{ p: 2 }}>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                label="Nombre"
                                                required
                                                onChange={e => {
                                                    setNumber1(e.target.value)
                                                }}
                                                error={
                                                    !!errors?.statistics?.[0]
                                                        ?.number
                                                }
                                                helperText={
                                                    errors?.statistics?.[0]
                                                        ?.number
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                required
                                                onChange={e =>
                                                    setUnit1(e.target.value)
                                                }
                                                label="Unités"
                                                error={
                                                    !!errors?.statistics?.[0]
                                                        ?.unit
                                                }
                                                helperText={
                                                    errors?.statistics?.[0]
                                                        ?.unit
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                required
                                                onChange={e =>
                                                    setDescription1(
                                                        e.target.value,
                                                    )
                                                }
                                                label="Description"
                                                error={
                                                    !!errors?.statistics?.[0]
                                                        ?.description
                                                }
                                                helperText={
                                                    errors?.statistics?.[0]
                                                        ?.description
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Box>

                            <Box mb={2}>
                                <Paper elevation={3}>
                                    <Box sx={{ p: 2 }}>
                                        <Typography variant="h6" gutterBottom>
                                            Statistique :
                                        </Typography>
                                    </Box>

                                    <Grid container spacing={2} sx={{ p: 2 }}>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                label="Nombre"
                                                required
                                                onChange={e => {
                                                    setNumber2(e.target.value)
                                                }}
                                                error={
                                                    !!errors?.statistics?.[1]
                                                        ?.number
                                                }
                                                helperText={
                                                    errors?.statistics?.[1]
                                                        ?.number
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                required
                                                onChange={e =>
                                                    setUnit2(e.target.value)
                                                }
                                                label="Unités"
                                                error={
                                                    !!errors?.statistics?.[1]
                                                        ?.unit
                                                }
                                                helperText={
                                                    errors?.statistics?.[1]
                                                        ?.unit
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                required
                                                onChange={e =>
                                                    setDescription2(
                                                        e.target.value,
                                                    )
                                                }
                                                label="Description"
                                                error={
                                                    !!errors?.statistics?.[1]
                                                        ?.description
                                                }
                                                helperText={
                                                    errors?.statistics?.[1]
                                                        ?.description
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Box>

                            <Box mb={2}>
                                <Paper elevation={3}>
                                    <Box sx={{ p: 2 }}>
                                        <Typography variant="h6" gutterBottom>
                                            Statistique :
                                        </Typography>
                                    </Box>

                                    <Grid container spacing={2} sx={{ p: 2 }}>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                label="Nombre"
                                                required
                                                onChange={e => {
                                                    setNumber3(e.target.value)
                                                }}
                                                error={
                                                    !!errors?.statistics?.[2]
                                                        ?.number
                                                }
                                                helperText={
                                                    errors?.statistics?.[2]
                                                        ?.number
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                required
                                                onChange={e =>
                                                    setUnit3(e.target.value)
                                                }
                                                label="Unités"
                                                error={
                                                    !!errors?.statistics?.[2]
                                                        ?.unit
                                                }
                                                helperText={
                                                    errors?.statistics?.[2]
                                                        ?.unit
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                className={classes.textField}
                                                variant="outlined"
                                                required
                                                onChange={e =>
                                                    setDescription3(
                                                        e.target.value,
                                                    )
                                                }
                                                label="Description"
                                                error={
                                                    !!errors?.statistics?.[2]
                                                        ?.description
                                                }
                                                helperText={
                                                    errors?.statistics?.[2]
                                                        ?.description
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Box>
                        </div>
                    )}
                    {/* <Box p={1}>
                        <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={() => {
                                if (performance) {
                                    const newPerformance = { ...performance }
                                    newPerformance.secondBanderoleStatistics =
                                        newPerformance.secondBanderoleStatistics.concat(
                                            {
                                                number: '',
                                                unit: '',
                                                description: '',
                                            },
                                        )
                                    setPerformance(newPerformance)
                                }
                            }}
                        >
                            Ajouter une statistique
                        </Button>
                    </Box> */}

                    <Grid container key={performance?._id}>
                        <Grid sx={{ p: 1 }} item md={4}>
                            <Paper elevation={3}>
                                <Box sx={{ p: 1 }}>
                                    <Typography variant="h6" gutterBottom>
                                        Première image :
                                    </Typography>
                                </Box>

                                <Box p={1}>
                                    {performance?.firstImageUrl && (
                                        <img
                                            width="100%"
                                            height="auto"
                                            src={performance?.firstImageUrl}
                                            alt="first"
                                        />
                                    )}
                                </Box>

                                <Box sx={{ p: 1 }}>
                                    <DropzoneArea
                                        key={debounceKey}
                                        dropzoneClass={classes.dropzone}
                                        acceptedFiles={['image/*']}
                                        dropzoneText="Glisser-déposer une image ou rechercher dans les fichiers (max : 10Mo)"
                                        maxFileSize={10000000}
                                        filesLimit={1}
                                        onDrop={([file]) =>
                                            setFirstImageFile(file)
                                        }
                                        showAlerts={false}
                                    />
                                </Box>
                            </Paper>
                        </Grid>

                        <Grid sx={{ p: 1 }} item md={4}>
                            <Paper elevation={3}>
                                <Box sx={{ p: 1 }}>
                                    <Typography variant="h6" gutterBottom>
                                        Seconde image :
                                    </Typography>
                                </Box>

                                <Box p={1}>
                                    {performance?.secondImageUrl && (
                                        <img
                                            width="100%"
                                            height="auto"
                                            src={performance?.secondImageUrl}
                                            alt="second"
                                        />
                                    )}
                                </Box>
                                <Box sx={{ p: 1 }}>
                                    <DropzoneArea
                                        key={debounceKey}
                                        dropzoneClass={classes.dropzone}
                                        acceptedFiles={['image/*']}
                                        dropzoneText="Glisser-déposer une image ou rechercher dans les fichiers (max : 10Mo)"
                                        maxFileSize={10000000}
                                        filesLimit={1}
                                        onDrop={([file]) =>
                                            setSecondImageFile(file)
                                        }
                                        showAlerts={false}
                                    />
                                </Box>
                            </Paper>
                        </Grid>

                        <Grid sx={{ p: 1 }} item md={4}>
                            <Paper elevation={3}>
                                <Box sx={{ p: 1 }}>
                                    <Typography variant="h6" gutterBottom>
                                        Troisième image :
                                    </Typography>
                                </Box>

                                <Box p={1}>
                                    {performance?.thirdImageUrl && (
                                        <img
                                            width="100%"
                                            height="auto"
                                            src={performance?.thirdImageUrl}
                                            alt="third"
                                        />
                                    )}
                                </Box>

                                <Box sx={{ p: 1 }}>
                                    <DropzoneArea
                                        key={debounceKey}
                                        dropzoneClass={classes.dropzone}
                                        acceptedFiles={['image/*']}
                                        dropzoneText="Glisser-déposer une image ou rechercher dans les fichiers (max : 10Mo)"
                                        maxFileSize={10000000}
                                        filesLimit={1}
                                        onDrop={([file]) =>
                                            setThirdImageFile(file)
                                        }
                                        showAlerts={false}
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid item mt={2} xs={12} style={{ textAlign: 'right' }}>
                        <CustomButton
                            text="ENREGISTRER"
                            onSubmit={() => onSubmit()}
                        />
                    </Grid>
                </form>
            </Grid>
        </Grid>
    )
}
