import { Banderole } from '../../common/types/banderole/banderole'
import { SERVER_URL } from '../../config/environments'
import useToken from '../session/userSession'

const NAVBAR_ELEMENT_URL = `${SERVER_URL.environment}/banderole`

export function useBanderole() {
    const { accessToken } = useToken()

    async function removeBanderoleImage(
        banderoleId: string,
    ): Promise<Banderole> {
        const response = await fetch(
            `${NAVBAR_ELEMENT_URL}/${banderoleId}/remove-image`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        )

        return response.json()
    }

    return { removeBanderoleImage }
}
