import { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'

import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    MenuItem,
    DialogContent,
    DialogTitle,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Avatar,
    OutlinedInput,
    Select,
    InputLabel,
    FormHelperText,
    FormControl,
    Theme,
    IconButton,
    CircularProgress,
} from '@mui/material'
import {
    Check as CheckIcon,
    Add as AddIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material'
import { dropzoneStyle } from 'assets/GlobalStyles'
import { DropzoneDialog } from 'react-mui-dropzone'

import { render } from '@testing-library/react'

import { useForm } from 'react-hook-form'

import { useDebounce } from 'use-debounce/lib'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import './PopupAddSuccessStories.scss'
import Toaster from '../../../common/Toaster/Toaster'

import { useAddSuccessStoriesElement } from 'hooks/successStories/addSuccessStories/UseAddSuccessStories'
import { useSuccessStoriesElement } from 'hooks/successStories/single/useSuccessStories'
import { UseCase } from 'common/types/use-case/UseCase'
import ProgressBar from 'common/progress-bar/ProgressBar'
import { useSolutionTechniqueElements } from 'hooks/solution-technique/all/useSolutionTechnique'
import { useActivitySectorElements } from 'hooks/activity-sector/all/useActivitySector'
import SaveButton from 'common/custom-button/SaveButton'

const schema = object({
    _id: string(),
    title: string(),
    category: string(),
    pages: string(),
    realizedBy: string(),
    description: string(),
    keywords: string(),
    summary: string(),
    imageUrl: string(),
})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            width: theme.spacing(15),
            height: theme.spacing(15),
            '@media (max-width: 868px)': {
                width: theme.spacing(11),
                height: theme.spacing(11),
            },
        },
        keywordsList: {
            display: 'flex',
            flexFlow: 'column wrap',
            gap: '0 30px',
        },
    }),
)

export function PopupAddSuccessStories({
    handleClose,
    isOpen,
    successStory,
}: {
    handleClose: () => void
    isOpen: boolean
    successStory: UseCase
}) {
    const classes = useStyles()
    const dropzoneClass = dropzoneStyle()

    const [open, setOpen] = useState(false)
    const [key, setKey] = useState(0)
    const [loading, setLoading] = useState(false)

    const [file, setFile] = useState<any>([])
    const [avatarView, setAvatarView] = useState<string>('')
    const [valueDescription, setValueDescription] = useState<string>('')

    const [successStoryForm, setSuccessStoryForm] = useState<UseCase>({
        ...successStory,
        keywords: successStory?.keywords ?? [],
        pages: successStory?.pages ?? [],
    })

    const [keywordInput, setKeywordInput] = useState<string>('')

    const [pageInput, setPageInput] = useState<string[]>([])

    const { solutionTechniqueElements } = useSolutionTechniqueElements()
    const { activitySectorElements } = useActivitySectorElements()

    const [debounceKey] = useDebounce(key, 1000)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<UseCase>({ resolver: yupResolver(schema) })

    const { update } = useSuccessStoriesElement(successStory?._id)
    const { addSuccessStories } = useAddSuccessStoriesElement()

    async function onSubmit() {
        try {
            saveSuccessStory({
                ...successStoryForm,
                description: valueDescription,
            })
            handleClose()
            render(<Toaster success toastMsg={'Success story enregistrée !'} />)
        } catch (err: any) {
            render(<Toaster error toastMsg={err.response} />)
        }
    }

    const saveSuccessStory = async (successStory: UseCase) => {
        const { keywords, pages, ...rest } = successStory

        try {
            const bodyFormData = new FormData()
            setLoading(true)

            Object.entries(rest).forEach(([key, value]) => {
                bodyFormData.append(key, value)
            })

            if (keywords) {
                keywords.forEach((keyword, index) => {
                    bodyFormData.append(`keywords[${index}]`, keyword)
                })
            }

            if (pages.length) {
                pages.forEach((page, index) => {
                    bodyFormData.append(`pages[${index}]`, page)
                })
            } else {
                bodyFormData.append(`pages[0]`, 'no page')
            }

            if (file) {
                bodyFormData.append('image', file)
                setKey(key + 1)
            }

            if (successStory._id) {
                update(bodyFormData)
                    .then(res => {
                        if (res === 'OK') {
                            handleClose()
                        }
                    })
                    .finally(() => setLoading(false))
            } else {
                bodyFormData.append('isFavorite', 'false')
                addSuccessStories(bodyFormData)
                    .then(res => {
                        if (res === 'Created') {
                            handleClose()
                        }
                    })
                    .finally(() => setLoading(false))
            }
        } catch (error: any) {
            render(<Toaster error toastMsg={error?.response?.data.message} />)
        }
    }

    const savePages = () =>
        setSuccessStoryForm({
            ...successStoryForm,
            pages: successStoryForm?.pages
                ?.concat(pageInput)
                .filter((page, i, arr) => arr.indexOf(page) === i),
        })

    useEffect(() => {
        setValueDescription(successStoryForm?.description)
    }, [])

    return (
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
            >
                <DialogTitle>{'Ajouter une success story'}</DialogTitle>

                <DialogContent>
                    <Card className="Experts" elevation={1}>
                        <CardHeader
                            avatar={
                                <>
                                    <IconButton
                                        component="span"
                                        onClick={() => setOpen(true)}
                                        size="large"
                                    >
                                        <Avatar
                                            className={classes.avatar}
                                            alt={successStoryForm?.title}
                                            src={
                                                file.length == 0
                                                    ? successStoryForm?.imageUrl
                                                    : avatarView
                                            }
                                        />
                                    </IconButton>
                                    <DropzoneDialog
                                        key={debounceKey}
                                        acceptedFiles={['image/*']}
                                        dropzoneClass={dropzoneClass.dropzone}
                                        dropzoneText="Glisser-déposer une image ou rechercher dans les fichiers (max : 10Mo)"
                                        maxFileSize={10000000}
                                        filesLimit={1}
                                        open={open}
                                        onClose={() => setOpen(false)}
                                        onDrop={async files => {
                                            const src =
                                                window.URL.createObjectURL(
                                                    files[0],
                                                )
                                            setAvatarView(src)
                                            setFile(files[0])
                                            setOpen(false)
                                        }}
                                        showAlerts={false}
                                    />
                                </>
                            }
                            title={
                                <>
                                    <FormControl
                                        sx={{ m: 1, width: '50ch' }}
                                        variant="outlined"
                                    >
                                        <InputLabel
                                            required
                                            htmlFor="firstename-successStorie"
                                        >
                                            Titre
                                        </InputLabel>
                                        <OutlinedInput
                                            required
                                            fullWidth
                                            label="Titre"
                                            id="fisrtname-successStorie"
                                            {...register('title')}
                                            defaultValue={
                                                successStoryForm?.title
                                            }
                                            onChange={e => {
                                                setSuccessStoryForm({
                                                    ...successStoryForm,
                                                    title: e.target.value,
                                                })
                                            }}
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                        <FormHelperText id="sector-successStorie-weight-helper-text">
                                            {errors?.title?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </>
                            }
                            subheader={
                                <>
                                    {' '}
                                    <FormControl
                                        sx={{ m: 1, width: '50ch' }}
                                        variant="outlined"
                                    >
                                        <InputLabel
                                            required
                                            htmlFor="summary-successStorie"
                                        >
                                            Phrase d'accroche
                                        </InputLabel>
                                        <OutlinedInput
                                            required
                                            id="summary-successStorie"
                                            label="Phrase d'accroche"
                                            {...register('summary')}
                                            defaultValue={
                                                successStoryForm?.summary
                                            }
                                            onChange={e => {
                                                setSuccessStoryForm({
                                                    ...successStoryForm,
                                                    summary: e.target.value,
                                                })
                                            }}
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                            multiline
                                        />
                                        <FormHelperText id="sector-successStorie-weight-helper-text">
                                            {errors?.summary?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </>
                            }
                        />

                        <CardContent>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                }}
                            >
                                <FormControl
                                    sx={{ m: 1, width: '42ch' }}
                                    variant="outlined"
                                >
                                    <InputLabel htmlFor="realizedBy-successStorie">
                                        Réalisé par
                                    </InputLabel>
                                    <OutlinedInput
                                        required
                                        fullWidth
                                        label="Réalisé par"
                                        id="realizedBy-successStorie"
                                        {...register('realizedBy')}
                                        defaultValue={
                                            successStoryForm?.realizedBy
                                        }
                                        onChange={e => {
                                            setSuccessStoryForm({
                                                ...successStoryForm,
                                                realizedBy: e.target.value,
                                            })
                                        }}
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                    <FormHelperText id="sector-successStorie-weight-helper-text">
                                        {errors?.realizedBy?.message}
                                    </FormHelperText>
                                </FormControl>
                                <FormControl
                                    sx={{ m: 1, width: '42ch' }}
                                    variant="outlined"
                                >
                                    <InputLabel
                                        required
                                        htmlFor="summary-successStorie"
                                    >
                                        Catégorie
                                    </InputLabel>
                                    <OutlinedInput
                                        required
                                        id="summary-successStorie"
                                        label="Catégorie"
                                        {...register('category')}
                                        defaultValue={
                                            successStoryForm?.category
                                        }
                                        onChange={e => {
                                            setSuccessStoryForm({
                                                ...successStoryForm,
                                                category: e.target.value,
                                            })
                                        }}
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                    <FormHelperText id="sector-successStorie-weight-helper-text">
                                        {errors?.category?.message}
                                    </FormHelperText>
                                </FormControl>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                }}
                            >
                                <div>
                                    <FormControl
                                        sx={{ m: 1, width: '42ch' }}
                                        variant="outlined"
                                    >
                                        <InputLabel
                                            required
                                            htmlFor="keywords-successStorie"
                                        >
                                            Pages
                                        </InputLabel>

                                        <Select
                                            required
                                            multiple
                                            id="pages-successStorie"
                                            label="Page(s)"
                                            input={
                                                <OutlinedInput
                                                    id="select-multiple-pages"
                                                    label="Pages"
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                sx={{
                                                                    marginRight: 2,
                                                                }}
                                                                onClick={
                                                                    savePages
                                                                }
                                                            >
                                                                <CheckIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            }
                                            value={pageInput}
                                            onChange={(e: any) =>
                                                setPageInput(e.target.value)
                                            }
                                        >
                                            {solutionTechniqueElements?.map(
                                                ({ _id: id, title, slug }) => (
                                                    <MenuItem
                                                        key={id}
                                                        value={slug}
                                                    >
                                                        {title}
                                                    </MenuItem>
                                                ),
                                            )}
                                            {activitySectorElements?.map(
                                                ({ _id: id, title, slug }) => (
                                                    <MenuItem
                                                        key={id}
                                                        value={slug}
                                                    >
                                                        {title}
                                                    </MenuItem>
                                                ),
                                            )}
                                        </Select>
                                        <FormHelperText id="sector-successStorie-weight-helper-text">
                                            Sélectionner la ou les page(s)
                                            mettant en avant cette success story
                                            puis valider.
                                        </FormHelperText>

                                        <List
                                            component={Stack}
                                            direction="row"
                                            className={classes.keywordsList}
                                        >
                                            {successStoryForm?.pages?.map(
                                                page => (
                                                    <ListItem
                                                        key={page}
                                                        disablePadding
                                                    >
                                                        <ListItemButton
                                                            onClick={() =>
                                                                setSuccessStoryForm(
                                                                    {
                                                                        ...successStoryForm,
                                                                        pages: successStoryForm.pages?.filter(
                                                                            k =>
                                                                                k !==
                                                                                page,
                                                                        ),
                                                                    },
                                                                )
                                                            }
                                                        >
                                                            <ListItemText
                                                                primary={page}
                                                            />
                                                            <ListItemIcon>
                                                                <DeleteIcon />
                                                            </ListItemIcon>
                                                        </ListItemButton>
                                                    </ListItem>
                                                ),
                                            )}
                                        </List>
                                    </FormControl>
                                    <FormControl
                                        sx={{ m: 1, width: '42ch' }}
                                        variant="outlined"
                                    >
                                        <InputLabel
                                            required
                                            htmlFor="keywords-successStorie"
                                        >
                                            Mots-clés
                                        </InputLabel>
                                        <OutlinedInput
                                            required
                                            fullWidth={true}
                                            id="keywords-successStorie"
                                            label="Mots-clés"
                                            value={keywordInput}
                                            onChange={e =>
                                                setKeywordInput(e.target.value)
                                            }
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() =>
                                                            setSuccessStoryForm(
                                                                {
                                                                    ...successStoryForm,
                                                                    keywords:
                                                                        successStoryForm?.keywords
                                                                            ?.concat(
                                                                                keywordInput,
                                                                            )
                                                                            .filter(
                                                                                (
                                                                                    keyword,
                                                                                    i,
                                                                                    arr,
                                                                                ) =>
                                                                                    arr.indexOf(
                                                                                        keyword,
                                                                                    ) ===
                                                                                    i,
                                                                            ),
                                                                },
                                                            )
                                                        }
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <List
                                            component={Stack}
                                            direction="row"
                                            className={classes.keywordsList}
                                        >
                                            {successStoryForm?.keywords?.map(
                                                keyword => (
                                                    <ListItem
                                                        key={keyword}
                                                        disablePadding
                                                    >
                                                        <ListItemButton
                                                            onClick={() =>
                                                                setSuccessStoryForm(
                                                                    {
                                                                        ...successStoryForm,
                                                                        keywords:
                                                                            successStoryForm.keywords?.filter(
                                                                                k =>
                                                                                    k !==
                                                                                    keyword,
                                                                            ),
                                                                    },
                                                                )
                                                            }
                                                        >
                                                            <ListItemText
                                                                primary={
                                                                    keyword
                                                                }
                                                            />
                                                            <ListItemIcon>
                                                                <DeleteIcon />
                                                            </ListItemIcon>
                                                        </ListItemButton>
                                                    </ListItem>
                                                ),
                                            )}
                                        </List>
                                        <FormHelperText id="sector-successStorie-weight-helper-text">
                                            {errors?.description?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </div>
                            </Box>
                        </CardContent>
                    </Card>
                </DialogContent>

                <Box
                    style={{
                        top: '40%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: '1',
                        position: 'absolute',
                    }}
                >
                    {loading && <CircularProgress size={80} />}
                </Box>

                <SaveButton {...{ handleClose }} />
            </form>
            <ProgressBar loading={loading} />
        </Dialog>
    )
}
