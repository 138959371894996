import React, { useState, useEffect, useContext } from 'react'
import { UseFormRegister, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string, InferType } from 'yup'
import axios from 'axios'
import { useDebounce } from 'use-debounce/lib'
import {
    Grid,
    Typography,
    TextField,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    InputLabel,
    CardHeader,
    IconButton,
    Paper,
} from '@mui/material'
import { DeleteOutlined } from '@mui/icons-material'
import Editor from '../custom-text-editor/CustomTextEditor'
import { AuthContext } from '../../context/auth-context'
import { SERVER_URL } from '../../config/environments'
import { DropzoneArea } from 'react-mui-dropzone'
import ConfirmForm from 'common/confirm-form/ConfirmForm'
import { useDeleteActivitySectorElement } from '../../hooks/activity-sector/delete/useDeleteActivitySector'
import { useDeleteSectoralBusinessSolutionElement } from '../../hooks/sectoral-business-solution/delete/UseDeleteSectoralBusinessSolution'
import { useDeleteSolutionTechniqueElement } from '../../hooks/solution-technique/delete/useDeleteSolutionTechnique'
import ProgressBar from 'common/progress-bar/ProgressBar'
import { useSectoralBusinessSolutionElement } from '../../hooks/sectoral-business-solution/single/UseSectoralBusinessSolution'
import { useActivitySectorElement } from '../../hooks/activity-sector/update/useActivitySector'
import { useUpdateSolutionTechniqueElement } from '../../hooks/solution-technique/single/useSolutionTechnique'
import EditorText from '../custom-text-editor/CustomEditorText'
import { dropzoneStyle } from 'assets/GlobalStyles'
import SEO from 'common/seo/SEO'

type Solution = {
    _id: string
    category: string
    title: string
    description: string
    metaTitle: string
    metaDescription: string
    imageUrl: string
    subTitle: string
    subDescription: string
    gifUrl: string
    cardImageUrl: string
    cardGifUrl: string
}

const schemaView = object({
    title: string(),
    description: string(),
    metaTitle: string(),
    metaDescription: string(),
    subTitle: string(),
    subDescription: string(),
    category: string(),
})

export type CustomEditItemProps = InferType<typeof schemaView>

export default function CustomEditItem({ useDescription, category, id }: any) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<CustomEditItemProps>({ resolver: yupResolver(schemaView) })

    // item Endpoint
    const itemEndpoint = `${SERVER_URL.environment}/${category}/${id}`

    const auth: any = useContext(AuthContext)

    const classes = dropzoneStyle()

    const [key, setKey] = useState(0)
    const [debounceKey] = useDebounce(key, 1000)

    const [valueTitre, setValueTitre] = useState<string>('')
    const [valueCategory, setValueCategory] = useState<string>('')
    const [valueDescription, setValueDescription] = useState<string>('')
    const [valueMetaTitle, setValueMetaTitle] = useState<string>('')
    const [valueMetaDescription, setValueMetaDescription] = useState<string>('')
    const [valueSubTitle, setValueSubTitle] = useState<string>('')
    const [valueSubDescription, setValueSubDescription] = useState<string>('')
    const [imageView, setImage] = useState<any>([])
    const [imageGIFView, setGIFImageView] = useState<any>([])
    const [cardImageView, setCardImageView] = useState<any>([])
    const [cardGIFView, setCardGIFView] = useState<any>([])
    const [file, setFile] = useState<any>([])
    const [GIFfile, setGIFFile] = useState<any>([])
    const [cardGIFFile, setCardGIFFile] = useState<any>([])
    const [cardImageFile, setCardImageFile] = useState<any>([])
    const [isOpenDelete, setIsOpenDelete] = useState(false)
    const [loading, setLoading] = useState(false)

    const { deleteActivitySectorElement } = useDeleteActivitySectorElement(id)
    const { deleteSectoralBusinessSolutionElement } =
        useDeleteSectoralBusinessSolutionElement(id)
    const { deleteSolutionTechniqueElement } =
        useDeleteSolutionTechniqueElement(id)

    const handleChangeCategory = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setValueCategory(event.target.value)
    }
    const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValueTitre(event.target.value)
    }
    const handleChangeSubTitle = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setValueSubTitle(event.target.value)
    }
    const handleChangeMetaTitle = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setValueMetaTitle(event.target.value)
    }
    const handleChangeMetaDescription = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setValueMetaDescription(event.target.value)
    }
    const toggleDelete = () => {
        setIsOpenDelete(true)
    }

    const onDeleteActivitySector = () => {
        deleteActivitySectorElement()
        setIsOpenDelete(false)
    }

    const onDeleteSectoralBusinessSolution = () => {
        deleteSectoralBusinessSolutionElement()
        setIsOpenDelete(false)
    }

    const onDeleteTechnicalSolution = () => {
        deleteSolutionTechniqueElement()
        setIsOpenDelete(false)
    }

    const { updateSectoralBusinessSolutionElement } =
        useSectoralBusinessSolutionElement(id)
    const { updateSolutionTechnique } = useUpdateSolutionTechniqueElement(id)
    const { updateActivitySector } = useActivitySectorElement(id)

    const getItem = async () => {
        const response = await axios.get<Solution>(`${itemEndpoint}`, {
            headers: {
                Authorization: `Bearer ${auth.accessToken}` as string,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
        if (response) {
            const { data } = response

            if (data.category) {
                setValueCategory(data.category)
            }
            if (data.title) {
                setValueTitre(data.title)
            }
            if (data.subTitle) {
                setValueSubTitle(data.subTitle)
            } else {
                setValueSubTitle('')
            }
            if (data.subDescription) {
                setValueSubDescription(data.subDescription)
            } else {
                setValueSubDescription('')
            }
            if (data.description) {
                setValueDescription(data.description)
            } else {
                setValueDescription('')
            }
            if (data.metaTitle) {
                setValueMetaTitle(data.metaTitle)
            } else {
                setValueMetaTitle('')
            }
            if (data.metaDescription) {
                setValueMetaDescription(data.metaDescription)
            } else {
                setValueMetaDescription('')
            }
            if (data.imageUrl != null) {
                setImage(data.imageUrl)
            } else {
                setImage('')
            }
            if (data.gifUrl != null) {
                setGIFImageView(data.gifUrl)
            } else {
                setGIFImageView('')
            }
            if (data.cardImageUrl != null) {
                setCardImageView(data.cardImageUrl)
            } else {
                setCardImageView('')
            }
            if (data.cardGifUrl != null) {
                setCardGIFView(data.cardGifUrl)
            } else {
                setCardGIFView('')
            }
        }
    }

    async function onSubmitView() {
        const bodyFormData = new FormData()
        setLoading(true)

        if (category === 'activity-sector') {
            bodyFormData.append('category', valueCategory ? valueCategory : '')
        } else {
            if (valueCategory) {
                bodyFormData.append('category', valueCategory)
            }
        }
        if (valueTitre) {
            bodyFormData.append('title', valueTitre)
        }

        bodyFormData.append(
            'description',
            valueDescription ? valueDescription : '',
        )
        bodyFormData.append(
            'metaDescription',
            valueMetaDescription ? valueMetaDescription : '',
        )
        bodyFormData.append('metaTitle', valueMetaTitle ? valueMetaTitle : '')

        bodyFormData.append(
            'subDescription',
            valueSubDescription ? valueSubDescription : '',
        )

        bodyFormData.append('subTitle', valueSubTitle ? valueSubTitle : '')

        if (GIFfile.length != 0) {
            bodyFormData.append('gif', GIFfile)
            setKey(key + 1)
        }
        if (cardImageFile.length != 0) {
            bodyFormData.append('cardImage', cardImageFile)
            setKey(key + 1)
        }
        if (cardGIFFile.length != 0) {
            bodyFormData.append('cardGif', cardGIFFile)
            setKey(key + 1)
        }
        if (file.length != 0) {
            bodyFormData.append('image', file)
            setKey(key + 1)
        }

        if (category === 'sectoral-business-solution') {
            await updateSectoralBusinessSolutionElement(bodyFormData).finally(
                () => setLoading(false),
            )
            await getItem()
        }

        if (category === 'activity-sector') {
            await updateActivitySector(bodyFormData).finally(() =>
                setLoading(false),
            )
            await getItem()
        }

        if (category === 'technical-solution') {
            await updateSolutionTechnique(bodyFormData).finally(() =>
                setLoading(false),
            )
            await getItem()
        }
    }

    useEffect(() => {
        getItem()
    }, [id])

    return (
        <>
            <form
                onSubmit={handleSubmit(onSubmitView)}
                noValidate
                autoComplete="off"
            >
                <Card elevation={16} style={{ margin: '15px' }}>
                    <CardHeader
                        action={
                            <IconButton
                                onClick={() => {
                                    toggleDelete()
                                }}
                                size="large"
                            >
                                <DeleteOutlined />
                            </IconButton>
                        }
                    />
                    <CardContent>
                        <Paper
                            sx={{
                                mb: 2,
                            }}
                        >
                            <SEO
                                {...{
                                    valueMetaTitle,
                                    valueMetaDescription,
                                    handleChangeMetaTitle,
                                    handleChangeMetaDescription,
                                    errors,
                                }}
                                register={
                                    register as UseFormRegister<CustomEditItemProps>
                                }
                                customEditItem
                            />
                        </Paper>

                        <Grid
                            container
                            alignItems="center"
                            style={{
                                marginBottom: '30px',
                            }}
                        >
                            <Grid item xs={12} md={12}>
                                <Box
                                    style={{
                                        marginRight: '20px',
                                    }}
                                >
                                    <Typography variant="h6">
                                        Catégorie :
                                    </Typography>
                                </Box>
                                <Box>
                                    <TextField
                                        id="titre"
                                        value={valueCategory}
                                        style={{ width: '100%' }}
                                        {...register('category')}
                                        onChange={handleChangeCategory}
                                    />
                                </Box>
                                <span>{errors?.category?.message}</span>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography variant="h6">
                                        Titre de la page :
                                    </Typography>
                                </Box>
                                <Box sx={{ p: 1 }}>
                                    <TextField
                                        id="titre"
                                        value={valueTitre}
                                        style={{ width: '100%' }}
                                        {...register('title')}
                                        onChange={handleChangeTitle}
                                    />
                                </Box>
                                <span>{errors?.title?.message}</span>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography variant="h6">
                                        Titre de la carte :
                                    </Typography>
                                </Box>
                                <Box sx={{ p: 1 }}>
                                    <TextField
                                        id="subtitre"
                                        value={valueSubTitle}
                                        style={{ width: '100%' }}
                                        {...register('subTitle')}
                                        onChange={handleChangeSubTitle}
                                    />
                                </Box>
                                <span>{errors?.subTitle?.message}</span>
                            </Grid>
                        </Grid>
                        {useDescription && (
                            <div>
                                <Grid>
                                    <Box pt={1}>
                                        <Typography variant="h6">
                                            Description de la page :
                                        </Typography>
                                    </Box>
                                    <Box sx={{ p: 1 }}>
                                        <Editor
                                            currentValue={valueDescription}
                                            updateCurrentValue={
                                                setValueDescription
                                            }
                                        />
                                        <Typography variant="caption">
                                            (Max : un paragraphe de 100 000
                                            caractères max.)
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid>
                                    <Box>
                                        <Typography variant="h6">
                                            Description de la carte :
                                        </Typography>
                                    </Box>
                                    <Box sx={{ pb: 1 }}>
                                        <EditorText
                                            currentValue={valueSubDescription}
                                            updateCurrentValue={
                                                setValueSubDescription
                                            }
                                        />
                                        <Typography variant="caption">
                                            (Max : un paragraphe de 10 000
                                            caractères max.)
                                        </Typography>
                                    </Box>
                                    <span className="error">
                                        {errors?.subDescription?.message}
                                    </span>
                                </Grid>
                            </div>
                        )}
                        <Box mb={2}>
                            <Grid container alignItems="center">
                                <Grid item sm={6} xs={12}>
                                    <Box p={1}>
                                        <InputLabel
                                            required
                                            htmlFor="description-sector"
                                        >
                                            Image page d'accueil :
                                        </InputLabel>
                                    </Box>
                                    {imageView && (
                                        <Box p={1}>
                                            <img
                                                src={imageView}
                                                width="100%"
                                                height="250"
                                            />
                                        </Box>
                                    )}
                                    <Box p={1}>
                                        <DropzoneArea
                                            dropzoneClass={classes.dropzone}
                                            key={debounceKey}
                                            acceptedFiles={['image/*,video/*']}
                                            dropzoneText="Glisser-déposer une image ou rechercher dans les fichiers (max : 150Mo)"
                                            maxFileSize={150000000}
                                            filesLimit={1}
                                            onDrop={(files: any): void => {
                                                setFile(files[0])
                                            }}
                                            showAlerts={false}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Box p={1}>
                                        <InputLabel
                                            required
                                            htmlFor="description-sector"
                                        >
                                            GIF page d'accueil :
                                        </InputLabel>
                                    </Box>

                                    {imageGIFView && (
                                        <Box p={1}>
                                            <img
                                                src={imageGIFView}
                                                width="100%"
                                                height="250"
                                            />
                                        </Box>
                                    )}

                                    <Box p={1}>
                                        <DropzoneArea
                                            dropzoneClass={classes.dropzone}
                                            key={debounceKey}
                                            acceptedFiles={['image/*']}
                                            dropzoneText="Glisser-déposer un GIF ou rechercher dans les fichiers (max : 150Mo)"
                                            maxFileSize={150000000}
                                            filesLimit={1}
                                            onDrop={(files): void => {
                                                setGIFFile(files[0])
                                            }}
                                            showAlerts={false}
                                        />
                                    </Box>
                                </Grid>

                                {category === 'technical-solution' && (
                                    <Grid container item xs={12} md={12}>
                                        <Grid item sm={6} xs={12}>
                                            <Box p={1}>
                                                <InputLabel
                                                    required
                                                    htmlFor="description-sector"
                                                >
                                                    Image :
                                                </InputLabel>
                                            </Box>

                                            {cardImageView && (
                                                <Box p={1}>
                                                    <img
                                                        src={cardImageView}
                                                        width="100%"
                                                        height="250"
                                                    />
                                                </Box>
                                            )}

                                            <Box p={1}>
                                                <DropzoneArea
                                                    dropzoneClass={
                                                        classes.dropzone
                                                    }
                                                    key={debounceKey}
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText="Glisser-déposer une image ou rechercher dans les fichiers (max : 150Mo)"
                                                    maxFileSize={150000000}
                                                    filesLimit={1}
                                                    onDrop={(files): void => {
                                                        setCardImageFile(
                                                            files[0],
                                                        )
                                                    }}
                                                    showAlerts={false}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <Box p={1}>
                                                <InputLabel
                                                    required
                                                    htmlFor="description-sector"
                                                >
                                                    GIF :
                                                </InputLabel>
                                            </Box>

                                            {cardGIFView && (
                                                <Box p={1}>
                                                    <img
                                                        src={cardGIFView}
                                                        width="100%"
                                                        height="250"
                                                    />
                                                </Box>
                                            )}

                                            <Box p={1}>
                                                <DropzoneArea
                                                    dropzoneClass={
                                                        classes.dropzone
                                                    }
                                                    key={debounceKey}
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText="Glisser-déposer un GIF ou rechercher dans les fichiers (max : 150Mo)"
                                                    maxFileSize={150000000}
                                                    filesLimit={1}
                                                    onDrop={(files): void => {
                                                        setCardGIFFile(files[0])
                                                    }}
                                                    showAlerts={false}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </CardContent>

                    <CardActions>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ margin: '10px' }}
                            type="submit"
                        >
                            ENREGISTRER
                        </Button>
                    </CardActions>
                </Card>
            </form>

            <ConfirmForm
                onConfirm={
                    category === 'activity-sector'
                        ? onDeleteActivitySector
                        : category === 'sectoral-business-solution'
                        ? onDeleteSectoralBusinessSolution
                        : onDeleteTechnicalSolution
                }
                open={isOpenDelete}
                setOpen={setIsOpenDelete}
                action="delete"
            />

            <ProgressBar loading={loading} />
        </>
    )
}
