import React, { useEffect, useState } from 'react'
import Aos from 'aos'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { Button, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useJobOffers } from 'hooks/job-offer/UseJobOffer'
import { JobOfferForm } from './job-offer-form/JobOfferForm'
import { ListJobOffer } from './jobOfferList/ListJobOffer'
import JobOffersView from '../../common/view/view'

export default function OffresEmploi() {
    const { jobOffersElements } = useJobOffers()
    const [isOpen, setIsOpen] = useState(false)
    const [jobOffer, setJobOffer] = useState<any>()

    const togglePopup = () => {
        setJobOffer(null)
        setIsOpen(!isOpen)
    }

    const handleClose = () => {
        setIsOpen(false)
    }

    useEffect(() => {
        Aos.init()
    }, [])

    return (
        <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
            data-aos="fade-right"
        >
            <Grid item xs={12} mb={4}>
                <Typography variant="h4" component="h4">
                    Offres d'emploi
                </Typography>
            </Grid>
            <Grid item xs={12} mb={4}>
                <JobOffersView useMeta category="JOB_OFFER" />
            </Grid>

            <Grid item xs={12}>
                <Box sx={{ margin: '1px' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        startIcon={<AddIcon />}
                        onClick={togglePopup}
                    >
                        Ajouter une offre d'emploi
                    </Button>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <ListJobOffer
                    jobOffers={jobOffersElements}
                    setJobOffer={setJobOffer}
                    setIsOpen={setIsOpen}
                />
            </Grid>

            {isOpen && (
                <JobOfferForm
                    jobOffer={jobOffer}
                    handleClose={handleClose}
                    isOpen={isOpen}
                />
            )}
        </Grid>
    )
}
