import React, { useState, useEffect, useContext } from 'react'
import Aos from 'aos'
import axios from 'axios'
import { render } from '@testing-library/react'
import { Container } from '@mui/material'

import Toaster from '../../../common/Toaster/Toaster'
import { SERVER_URL } from '../../../config/environments'
import { AuthContext } from '../../../context/auth-context'
import {
    PropSuccessStories,
    UseCase,
} from '../../../common/types/use-case/UseCase'
import FavoriteUseCaseSelect from './favoriteUseCaseSelect/FavoriteUseCaseSelect'
import FavoriteUseCaseGrid from './favoriteUseCaseGrid/FavoriteUseCaseGrid'

import './successStoriesCard/SuccessStories.scss'

// SuccessStories Endpoint
const successStoriesEndpoint = `${SERVER_URL.environment}/success-story`

export default function SuccessStories() {
    const auth: any = useContext(AuthContext)
    const [listSuccessStories, setListSuccessStories] = useState<UseCase[]>([])
    const [favorites, setFavorites] = useState<string[]>([])
    const [oldFavorites, setOldFavorites] = useState<string[]>([])

    async function getAllSuccessStories() {
        try {
            await axios
                .get<UseCase[]>(`${successStoriesEndpoint}/all`, {
                    headers: {
                        Authorization: `Bearer ${auth.accessToken}` as string,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(response => setListSuccessStories(response?.data))
        } catch (error: any) {
            render(<Toaster error toastMsg={error.message} />)
        }
    }

    async function getFavoritesSuccessStories() {
        try {
            await axios
                .get(`${successStoriesEndpoint}/all/favorite`, {
                    headers: {
                        Authorization: `Bearer ${auth.accessToken}` as string,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response: PropSuccessStories) => {
                    const favArr = response?.data.map((uc: UseCase) => uc._id)
                    setFavorites(favArr)
                    setOldFavorites(favArr)
                })
        } catch (error: any) {
            render(<Toaster error toastMsg={error.message} />)
        }
    }

    async function saveFavorites() {
        try {
            const formData = new FormData()
            formData.append('isFavorite', 'true')

            const callback = () => {
                removeFromFavorites().then(() => getFavoritesSuccessStories())
                render(<Toaster success toastMsg="Sauvegardée avec succès !" />)
            }

            favorites.map(async (useCaseId: string) => {
                await axiosUpdateRequest(
                    useCaseId,
                    formData,
                    callback,
                    (err: any) =>
                        render(<Toaster error toastMsg={err.message} />),
                )
                removeFromFavorites()
            })
        } catch (error: any) {
            render(<Toaster error toastMsg={error.message} />)
        }
    }

    const removeFromFavorites = async () => {
        try {
            const formData = new FormData()
            formData.append('isFavorite', 'false')

            const toRm: string[] = []
            oldFavorites.forEach(oldFavorite => {
                if (!favorites.find(favorite => favorite === oldFavorite)) {
                    toRm.push(oldFavorite)
                }
            })

            toRm.map(async (useCaseId: string) => {
                await axiosUpdateRequest(
                    useCaseId,
                    formData,
                    () => null,
                    (err: any) =>
                        render(<Toaster error toastMsg={err.message} />),
                )
            })
        } catch (error: any) {
            render(<Toaster error toastMsg={error.message} />)
        }
    }

    const axiosUpdateRequest = async (
        id: string,
        formData: FormData,
        _cb: () => void,
        // eslint-disable-next-line no-unused-vars
        catchError: (error: any) => void,
    ) => {
        await axios({
            method: 'put',
            url: `${successStoriesEndpoint}/${id}`,
            data: formData,
            headers: {
                Authorization: `Bearer ${auth.accessToken}` as string,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(() => {
                getAllSuccessStories()
                render(<Toaster success toastMsg="ENREGISTRÉ" />)
            })
            .catch(err => catchError(err))
    }

    const handleFavoritesChange = (event: any) => {
        if (event.target.value.length <= 3) {
            setFavorites(event.target.value)
        }
    }

    useEffect(() => {
        getAllSuccessStories().then(() => getFavoritesSuccessStories())
        Aos.init()
    }, [])

    return (
        <Container component="section" maxWidth={false} disableGutters>
            <FavoriteUseCaseSelect
                favorites={favorites}
                onFavoritesChange={handleFavoritesChange}
                listSuccessStories={listSuccessStories}
                saveFavorites={saveFavorites}
            />
            <FavoriteUseCaseGrid favorites={favorites} />
        </Container>
    )
}
