import axios from 'axios'
import { SERVER_URL } from '../../../config/environments'
import useToken from '../../session/userSession'
import { Banderole } from '../../../common/types/banderole/banderole'

const BANDEROLE_ELEMENT_URL = `${SERVER_URL.environment}/banderole`

export function useAddBanderole() {
    const { accessToken } = useToken()

    async function addBanderole(bodyFormData: FormData): Promise<Banderole> {
        return await axios({
            method: 'POST',
            url: `${BANDEROLE_ELEMENT_URL}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        }).then(response => {
            return response.data
        })
    }

    return { addBanderole }
}
