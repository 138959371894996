import { Estiam } from 'common/types/estiam/Estiam'
import useSWR from 'swr'
import { SERVER_URL } from '../../../config/environments'

const FETCH_ALL_ESTIAMS_URL = `${SERVER_URL.environment}/estiam`

export function useEstiamsElements() {
    const { data: estiamsElements, mutate: mutateEstiamsElements } = useSWR<
        Estiam[]
    >('api/estiam/all', fetchAllEstiam)

    async function fetchAllEstiam() {
        const response = await fetch(`${FETCH_ALL_ESTIAMS_URL}/all`)
        return response.json()
    }
    return { estiamsElements, mutateEstiamsElements }
}
