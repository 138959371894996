import {
    FormControl,
    TextField,
    Typography,
    FormHelperText,
} from '@mui/material'
import { JobOfferElement } from 'common/types/job-offer/JobOfferElement'
import React from 'react'
import { UseFormSetValue, UseFormRegister } from 'react-hook-form'

interface JobOfferDetailsProps {
    detail: 'title' | 'category' | 'startDate' | 'city' | 'contract' | 'level'
    errorMessage?: string
    register: UseFormRegister<JobOfferElement>
    setValue: UseFormSetValue<JobOfferElement>
    jobOffer: JobOfferElement
}

export default function JobOfferDetails({
    detail,
    errorMessage,
    register,
    setValue,
    jobOffer,
}: JobOfferDetailsProps) {
    const getJobOfferDetailForm = (detail: string) => {
        switch (detail) {
            case 'title':
                return (
                    <FormControl
                        sx={{ m: 1, width: '55ch' }}
                        variant="outlined"
                    >
                        <TextField
                            required
                            fullWidth
                            label="Titre"
                            id="title-jobOffer"
                            {...register('title')}
                            defaultValue={jobOffer?.title}
                            onChange={e => setValue('title', e.target.value)}
                            inputProps={{
                                'aria-label': 'weight',
                            }}
                        />
                    </FormControl>
                )
            case 'category':
                return (
                    <FormControl
                        sx={{ m: 1, width: '55ch' }}
                        variant="outlined"
                    >
                        <TextField
                            fullWidth
                            required
                            label="Catégorie"
                            id="categorie-jobOffer"
                            {...register('category')}
                            defaultValue={jobOffer?.category}
                            onChange={e => setValue('category', e.target.value)}
                            inputProps={{
                                'aria-label': 'weight',
                            }}
                        />
                        <Typography variant="caption">
                            Catégorie doit être l'une des valeurs suivantes:
                            CDD, CDI
                        </Typography>
                    </FormControl>
                )
            case 'startDate':
                return (
                    <FormControl
                        sx={{ m: 1, width: '42ch' }}
                        variant="outlined"
                    >
                        <TextField
                            fullWidth
                            required
                            label="Date de début"
                            id="startDate-jobOffer"
                            {...register('startDate')}
                            defaultValue={jobOffer?.startDate}
                            onChange={e =>
                                setValue('startDate', e.target.value)
                            }
                            inputProps={{
                                'aria-label': 'weight',
                            }}
                        />
                    </FormControl>
                )
            case 'city':
                return (
                    <FormControl
                        sx={{ m: 1, width: '42ch' }}
                        variant="outlined"
                    >
                        <TextField
                            fullWidth
                            required
                            label="Ville"
                            id="ville-jobOffer"
                            {...register('jobLocation')}
                            defaultValue={jobOffer?.jobLocation}
                            onChange={e =>
                                setValue('jobLocation', e.target.value)
                            }
                            inputProps={{
                                'aria-label': 'weight',
                            }}
                        />
                        <FormHelperText id="jobLocalisation-jobOffer-weight-helper-text">
                            {errorMessage}
                        </FormHelperText>
                    </FormControl>
                )
            case 'contract':
                return (
                    <FormControl
                        sx={{ m: 1, width: '42ch' }}
                        variant="outlined"
                    >
                        <TextField
                            id="contract-jobOffer"
                            label="Contrat"
                            required
                            {...register('contract')}
                            defaultValue={jobOffer?.contract}
                            onChange={e => setValue('contract', e.target.value)}
                            inputProps={{
                                'aria-label': 'weight',
                            }}
                        />
                        <FormHelperText id="contract-jobOffer-weight-helper-text">
                            {errorMessage}
                        </FormHelperText>
                    </FormControl>
                )
            case 'level':
                return (
                    <FormControl
                        sx={{ m: 1, width: '42ch' }}
                        variant="outlined"
                    >
                        <TextField
                            fullWidth
                            required
                            label="Niveau"
                            id="level-jobOffer"
                            {...register('level')}
                            defaultValue={jobOffer?.level}
                            onChange={e => setValue('level', e.target.value)}
                            inputProps={{
                                'aria-label': 'weight',
                            }}
                        />
                        <FormHelperText id="level-jobOffer-weight-helper-text">
                            {errorMessage}
                        </FormHelperText>
                    </FormControl>
                )
            default:
                return null
        }
    }
    return getJobOfferDetailForm(detail)
}
