import useSWR from 'swr'
import { ComityMembersElement } from '../../../../common/types/estiam/Comity'
import { SERVER_URL } from '../../../../config/environments'

const FETCH_ALL_COMITYMEMBERS_URL = `${SERVER_URL.environment}/estiam`

export function useComityMembersElements() {
    const { data: comityMembersElements, mutate: mutateComityMembersElements } =
        useSWR<ComityMembersElement[]>('api/estiam/all', fetchAllComityMembers)

    async function fetchAllComityMembers() {
        const response = await fetch(`${FETCH_ALL_COMITYMEMBERS_URL}/all`)
        return response.json()
    }
    return { comityMembersElements, mutateComityMembersElements }
}
