import React, { SetStateAction } from 'react'
import {
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    InputAdornment,
    IconButton,
    MenuItem,
    FormHelperText,
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { pagesList } from 'common/constants/pagesList'
import AddIcon from '@mui/icons-material/Add'
import { TechnicalSolution } from 'common/types/technical-solution/TechnicalSolution'
import { ActivitySectorElement } from 'common/types/activity-sector/ActivitySectorElements'
import JobOfferFilterList from './JobOfferFilterList'

interface JobOfferFilterFormProps {
    filter: 'pages' | 'keywords'
    savePages: () => void
    pageInput: string[]
    setPageInput: (value: SetStateAction<string[]>) => void
    solutionTechniqueElements?: TechnicalSolution[]
    activitySectorElements?: ActivitySectorElement[]
    jobOfferForm: {
        pages: string[]
        keywords?: string[]
    }
    setJobOfferForm: (
        value: SetStateAction<{
            pages: string[]
            keywords?: string[]
        }>,
    ) => void
    keywordInput: string
    setKeywordInput: (value: SetStateAction<string>) => void
}

export default function JobOfferFilterForm({
    filter,
    savePages,
    pageInput,
    setPageInput,
    solutionTechniqueElements,
    activitySectorElements,
    jobOfferForm,
    setJobOfferForm,
    keywordInput,
    setKeywordInput,
}: JobOfferFilterFormProps) {
    function setForm(filter: 'pages' | 'keywords', type: string) {
        if (filter === 'pages') {
            setJobOfferForm({
                ...jobOfferForm,
                pages: jobOfferForm.pages?.filter(k => k !== type),
            })
        } else {
            setJobOfferForm({
                ...jobOfferForm,
                keywords: jobOfferForm.keywords?.filter(k => k !== type),
            })
        }
    }

    function addKeyword() {
        setJobOfferForm({
            ...jobOfferForm,
            keywords: jobOfferForm?.keywords
                ?.concat(keywordInput)
                .filter(
                    (keyword: string, i: number, arr: string[]) =>
                        arr.indexOf(keyword) === i,
                ),
        })
    }

    const getJobOfferFilterForm = (filter: string) => {
        switch (filter) {
            case 'pages':
                return (
                    <>
                        <Select
                            required
                            multiple
                            id="pages-joboffer"
                            label="Page(s)"
                            input={
                                <OutlinedInput
                                    id="select-multiple-pages"
                                    label="Pages"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                sx={{
                                                    marginRight: 2,
                                                }}
                                                onClick={savePages}
                                            >
                                                <CheckIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            }
                            value={pageInput}
                            onChange={(e: any) => setPageInput(e.target.value)}
                        >
                            {pagesList.map(({ pageTitle, slug }) => (
                                <MenuItem key={slug} value={slug}>
                                    {pageTitle}
                                </MenuItem>
                            ))}
                            {solutionTechniqueElements?.map(
                                ({ _id: id, title, slug }) => (
                                    <MenuItem key={id} value={slug}>
                                        {title}
                                    </MenuItem>
                                ),
                            )}
                            {activitySectorElements?.map(
                                ({ _id: id, title, slug }) => (
                                    <MenuItem key={id} value={slug}>
                                        {title}
                                    </MenuItem>
                                ),
                            )}
                        </Select>
                        <FormHelperText id="sector-joboffer-weight-helper-text">
                            Sélectionner la ou les page(s) mettant en avant
                            cette offre d'emploi puis valider.
                        </FormHelperText>
                    </>
                )
            case 'keywords':
                return (
                    <OutlinedInput
                        required
                        fullWidth={true}
                        id="keywords-job-offers"
                        label="Mots-clés"
                        value={keywordInput}
                        onChange={e => setKeywordInput(e.target.value)}
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={() => addKeyword()}>
                                    <AddIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                )
            default:
                return null
        }
    }
    return (
        <>
            <div>
                <FormControl sx={{ m: 1, width: '36ch' }} variant="outlined">
                    <InputLabel
                        required
                        htmlFor={
                            filter === 'pages'
                                ? 'pages-joboffer'
                                : 'keywords-joboffer'
                        }
                    >
                        {filter === 'pages' ? 'Page(s)' : 'Mots-clés'}
                    </InputLabel>
                    {getJobOfferFilterForm(filter)}
                    <JobOfferFilterList
                        items={
                            filter === 'pages'
                                ? jobOfferForm?.pages
                                : jobOfferForm?.keywords
                        }
                        setForm={
                            filter === 'pages'
                                ? page => setForm(filter, page)
                                : keyword => setForm(filter, keyword)
                        }
                    />
                </FormControl>
            </div>
        </>
    )
}
