import React from 'react'
import CustomEditItem from '../../common/custom-edit-item/CustomEditItem'

export default function SectoralBusinessSolution() {
    return (
        <CustomEditItem
            useDescription
            category="sectoral-business-solution"
            id={window.location.pathname.split('/')[2]}
        />
    )
}
