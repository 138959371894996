import * as React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { UseDeleteMapMarkerElement } from 'hooks/map-markers/deleteMapMarkers/UseDeleteMapMarkers'
import ConfirmForm from 'common/confirm-form/ConfirmForm'
import {
    TablePagination,
    TableRow,
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableBody,
    Paper,
    Avatar,
    IconButton,
} from '@mui/material'

import { MapMarkerElement } from 'common/types/map-markers/MapMarkersElement'

type Props = {
    mapMarkers: MapMarkerElement[]
    setMapMarker: (mapMarkerElement: MapMarkerElement) => void
    setIsOpen: (isOpen: boolean) => void
}

export function ListMapMarkers({
    mapMarkers,
    setMapMarker: setMapMarkers,
    setIsOpen,
}: Props) {
    const [open, setOpen] = React.useState(false)
    const [id, setId] = React.useState('')
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const handleClickOpen = (Id: string) => {
        setId(Id)
        setOpen(true)
    }

    const { deleteMapMarker } = UseDeleteMapMarkerElement(id)

    const onDelete = () => {
        deleteMapMarker()
        setOpen(false)
    }

    const onEdit = (mapMarker: MapMarkerElement) => {
        setMapMarkers(mapMarker)
        setIsOpen(true)
    }

    return (
        <>
            <Paper sx={{ width: '100%' }}>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Logo</TableCell>
                                <TableCell>Ville</TableCell>
                                <TableCell align="left">Pays</TableCell>
                                <TableCell align="left">Entreprise</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {mapMarkers
                                ?.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )
                                .map(row => (
                                    <TableRow
                                        key={row._id}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                { border: 0 },
                                        }}
                                    >
                                        <TableCell>
                                            <Avatar
                                                alt={row.city}
                                                src={row.logoUrl}
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.city}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.country}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.business}
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                onClick={() => onEdit(row)}
                                                size="large"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() =>
                                                    handleClickOpen(row._id)
                                                }
                                                size="large"
                                            >
                                                <DeleteOutlinedIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    labelRowsPerPage="Lignes par page"
                    component="div"
                    count={mapMarkers ? mapMarkers?.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            <ConfirmForm
                onConfirm={onDelete}
                open={open}
                setOpen={setOpen}
                action="delete"
            />
        </>
    )
}
