import React, { useContext, useEffect, useState } from 'react'
import Aos from 'aos'
import { Container, Grid } from '@mui/material'
import OffresEmploiView from '../../../common/view/view'
import './OffresEmploi.scss'
import FavoriteJobOffersSelect from './favorite-job-offers-select/FavoriteJobOffersSelect'
import { JobOfferElement } from 'common/types/job-offer/JobOfferElement'
import { AuthContext } from 'context/auth-context'
import axios from 'axios'
import { SERVER_URL } from 'config/environments'
import { render } from '@testing-library/react'
import Toaster from 'common/Toaster/Toaster'
import FavoriteJobOfferCard from './favorite-job-offers/FavoriteJobOfferCard'

// Job offers Endpoint
const jobOffersEndpoint = `${SERVER_URL.environment}/job-offers`

export default function OffresEmploi() {
    const auth: any = useContext(AuthContext)
    const [jobOffersList, setJobOffersList] = useState<JobOfferElement[]>([])
    const [favorites, setFavorites] = useState<string[]>([])
    const [oldFavorites, setOldFavorites] = useState<string[]>([])

    async function getAllJobOffers() {
        try {
            await axios
                .get<JobOfferElement[]>(`${jobOffersEndpoint}/all`, {
                    headers: {
                        Authorization: `Bearer ${auth.accessToken}` as string,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(response => setJobOffersList(response?.data))
        } catch (error: any) {
            render(<Toaster error toastMsg={error.message} />)
        }
    }

    async function getFavoriteJobOffers() {
        try {
            await axios
                .get<JobOfferElement[]>(`${jobOffersEndpoint}/all/favorite`, {
                    headers: {
                        Authorization: `Bearer ${auth.accessToken}` as string,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(response => {
                    const favArr =
                        response?.data?.map((uc: JobOfferElement) => uc._id) ??
                        []
                    setFavorites(favArr as string[])
                    setOldFavorites(favArr as string[])
                })
        } catch (error: any) {
            render(<Toaster error toastMsg={error.message} />)
        }
    }

    async function saveFavorites() {
        try {
            const formData = new FormData()
            formData.append('isFavorite', 'true')

            const callback = () => {
                removeFromFavorites().then(() => getFavoriteJobOffers())
                render(<Toaster success toastMsg="Sauvegardé avec succès !" />)
            }

            favorites.map(async (jobOfferId: string) => {
                await axiosUpdateRequest(
                    jobOfferId,
                    formData,
                    callback,
                    (err: any) =>
                        render(<Toaster error toastMsg={err.message} />),
                )
            })
            removeFromFavorites()
        } catch (error: any) {
            render(<Toaster error toastMsg={error.message} />)
        }
    }

    const removeFromFavorites = async () => {
        try {
            const formData = new FormData()
            formData.append('isFavorite', 'false')

            const toRm: string[] = []
            oldFavorites.forEach(oldFavorite => {
                if (!favorites.find(favorite => favorite === oldFavorite)) {
                    toRm.push(oldFavorite)
                }
            })

            toRm.map(async (jobOfferId: string) => {
                await axiosUpdateRequest(
                    jobOfferId,
                    formData,
                    () => null,
                    (err: any) =>
                        render(<Toaster error toastMsg={err.message} />),
                )
            })
        } catch (error: any) {
            render(<Toaster error toastMsg={error.message} />)
        }
    }

    const axiosUpdateRequest = async (
        id: string,
        formData: FormData,
        _cb: () => void,
        // eslint-disable-next-line no-unused-vars
        catchError: (error: any) => void,
    ) => {
        await axios({
            method: 'put',
            url: `${jobOffersEndpoint}/${id}`,
            data: formData,
            headers: {
                Authorization: `Bearer ${auth.accessToken}` as string,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(() => {
                getAllJobOffers()
                render(<Toaster success toastMsg="ENREGISTRÉ" />)
            })
            .catch(err => catchError(err))
    }

    const handleFavoritesChange = (event: any) => {
        setFavorites(event.target.value)
    }

    useEffect(() => {
        getAllJobOffers().then(() => getFavoriteJobOffers())
        Aos.init()
    }, [])

    return (
        <Container component="section" maxWidth={false} disableGutters>
            <OffresEmploiView
                useDescription={false}
                useTitle
                category="JOB_OFFER"
            />
            <FavoriteJobOffersSelect
                favorites={favorites}
                onFavoritesChange={handleFavoritesChange}
                jobOffersList={jobOffersList}
                saveFavorites={saveFavorites}
            />

            <Grid container spacing={4}>
                {favorites
                    .map(
                        _id =>
                            jobOffersList.find(
                                jobOffer => jobOffer._id === _id,
                            ) as JobOfferElement,
                    )
                    .map(jobOffer => (
                        <Grid item xs={12} md={4} lg={3} key={jobOffer._id}>
                            <FavoriteJobOfferCard jobOffer={jobOffer} />
                        </Grid>
                    ))}
            </Grid>
            {/* <FavoriteUseCaseGrid favorites={favorites} /> */}
        </Container>
    )
}
