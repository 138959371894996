import React, { useEffect, useState } from 'react'
import Aos from 'aos'
import Grid from '@mui/material/Grid'

import ApplyView from '../../common/view/view'
import { DetailApply } from './detail-apply/DetailApply'
import { ListApply } from './list-apply/ListApply'
import { ApplyElement } from '../../common/types/apply/ApplyElement'
import { useApply } from '../../hooks/apply/useApply'

export default function Apply() {
    const { applyElements } = useApply()
    const [isOpen, setIsOpen] = useState(false)
    const [apply, setApply] = useState<ApplyElement>()

    useEffect(() => {
        Aos.init()
    }, [])

    return (
        <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
            data-aos="fade-right"
        >
            <Grid item xs={12}>
                <ApplyView
                    useDescription
                    useTitle
                    useMeta
                    category="APPLICATION"
                />
            </Grid>

            <Grid item xs={12}>
                <ListApply
                    apply={applyElements}
                    setApply={setApply}
                    setIsOpen={setIsOpen}
                />
            </Grid>

            {isOpen && (
                <DetailApply
                    apply={apply}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                />
            )}
        </Grid>
    )
}
