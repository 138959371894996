import axios from 'axios'
import useToken from '../../session/userSession'
import { useJoinUs } from 'hooks/join-us/UseJoinUs'
import { SERVER_URL } from '../../../config/environments'
import Toaster from 'common/Toaster/Toaster'
import { render } from '@testing-library/react'

const QUOTE_URL = `${SERVER_URL.environment}/join-us`

export function useQuote() {
    const { mutateJoinUsElements } = useJoinUs()
    const { accessToken } = useToken()

    async function createQuote(id: string, bodyFormData: URLSearchParams) {
        await axios({
            method: 'POST',
            url: `${QUOTE_URL}/${id}/quote`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type':
                    'application/x-www-form-urlencoded;charset=UTF-8',
            },
        })
            .then(response => {
                mutateJoinUsElements()
                render(<Toaster success toastMsg="ENREGISTRÉ" />)
                return response
            })
            .catch(err => {
                render(<Toaster error toastMsg={err.response} />)
            })
    }

    async function updateQuote(
        joinUsId: string,
        quoteId: string,
        bodyFormData: URLSearchParams,
    ) {
        await axios({
            method: 'PUT',
            url: `${QUOTE_URL}/${joinUsId}/quote/${quoteId}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type':
                    'application/x-www-form-urlencoded;charset=UTF-8',
            },
        })
            .then(response => {
                mutateJoinUsElements()
                render(<Toaster success toastMsg="MODIFIÉ" />)
                return response
            })
            .catch(err => {
                render(<Toaster error toastMsg={err.response} />)
            })
    }

    async function deleteQuote(joinUsId: string, quoteId: string) {
        await fetch(`${QUOTE_URL}/${joinUsId}/quote/${quoteId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then(response => {
                mutateJoinUsElements()
                render(<Toaster success toastMsg="SUPPRIMÉ" />)
                return response.json()
            })
            .catch(err => {
                render(<Toaster error toastMsg={err.response} />)
            })
    }

    return { createQuote, updateQuote, deleteQuote }
}
