import { Grid, Typography, TextField, Box } from '@mui/material'
import { CustomEditItemProps } from 'common/custom-edit-item/CustomEditItem'
import { JobOfferElement } from 'common/types/job-offer/JobOfferElement'
import { RegisterFn } from 'common/types/seo/SEO'
import { ViewProps } from 'common/view/view'
import { AddActivitySectorProps } from 'components/activity-sector/secteursActivitesPopUp/SecteursActivitesPopUp'
import { AddTechnicalSolutionProps } from 'components/technical-solutions/add-technical-solution/AddTechnicalSolution'
import React, { ChangeEvent } from 'react'
import { UseFormRegister } from 'react-hook-form'

interface SEOProps {
    valueMetaTitle?: string
    valueMetaDescription?: string
    handleChangeMetaTitle: (event: ChangeEvent<HTMLInputElement>) => void
    handleChangeMetaDescription: (event: ChangeEvent<HTMLInputElement>) => void
    errorMetaTitleMessage?: string
    errorMetaDescriptionMessage?: string
    register: RegisterFn
    defaultMetaDescription?: string
    customEditItem?: boolean
    view?: boolean
    jobOffer?: boolean
    technicalSolution?: boolean
    activitySector?: boolean
}

export default function SEO({
    valueMetaTitle,
    valueMetaDescription,
    handleChangeMetaTitle,
    handleChangeMetaDescription,
    errorMetaTitleMessage,
    errorMetaDescriptionMessage,
    register,
    defaultMetaDescription,
    customEditItem,
    view,
    jobOffer,
    technicalSolution,
    activitySector,
}: SEOProps) {
    const registerMeta = (type: 'metaTitle' | 'metaDescription') => {
        switch (true) {
            case customEditItem:
                return (register as UseFormRegister<CustomEditItemProps>)(type)
            case view:
                return (register as UseFormRegister<ViewProps>)(type)
            case jobOffer:
                return (register as UseFormRegister<JobOfferElement>)(type)
            case technicalSolution:
                return (register as UseFormRegister<AddTechnicalSolutionProps>)(
                    type,
                )
            case activitySector:
                return (register as UseFormRegister<AddActivitySectorProps>)(
                    type,
                )
            default:
                return (register as UseFormRegister<JobOfferElement>)(type)
        }
    }

    return (
        <Grid
            sx={{
                display: 'flex',
                alignItems: 'flex-end',
            }}
        >
            <Grid container mb={4} item xs={12}>
                <Grid container p={1}>
                    <Typography variant="h6" fontWeight="bold">
                        SEO
                    </Typography>
                </Grid>
                <Grid item md={6}>
                    <Box sx={{ p: 2 }}>
                        <TextField
                            id="metaTitle"
                            label="Meta title"
                            value={valueMetaTitle}
                            style={{ width: '100%' }}
                            {...registerMeta('metaTitle')}
                            onChange={handleChangeMetaTitle}
                        />
                    </Box>
                    <span className="error">{errorMetaTitleMessage}</span>
                </Grid>
                <Grid item md={6}>
                    <Box sx={{ p: 2 }}>
                        <TextField
                            id="metaDescription"
                            label="Meta description"
                            defaultValue={defaultMetaDescription}
                            multiline
                            value={valueMetaDescription}
                            style={{ width: '100%' }}
                            {...registerMeta('metaDescription')}
                            onChange={handleChangeMetaDescription}
                        />
                    </Box>
                    <span className="error">{errorMetaDescriptionMessage}</span>
                </Grid>
            </Grid>
        </Grid>
    )
}
