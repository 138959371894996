import axios from 'axios'
import { render } from '@testing-library/react'
import useToken from '../../session/userSession'
import Toaster from '../../../common/Toaster/Toaster'
import { useActivitySectorElements } from '../all/useActivitySector'
import { SERVER_URL } from '../../../config/environments'
import { useHistory } from 'react-router-dom'

const FETCH_ALL_ACTIVITY_SECTOR_URL = `${SERVER_URL.environment}/activity-sector`

export function useAddActivitySectorElement() {
    const { mutateActivitySectorElements } = useActivitySectorElements()
    const { accessToken } = useToken()
    const history = useHistory()
    let data = ''

    async function addActivitySectorElement(body: FormData) {
        await axios({
            method: 'POST',
            url: `${FETCH_ALL_ACTIVITY_SECTOR_URL}`,
            data: body,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response: any) => {
                mutateActivitySectorElements()
                render(
                    <Toaster
                        success
                        toastMsg="Secteur d'activité ajouté avec succès !"
                    />,
                )
                data = response.statusText
                history.push('/activity-sector/' + `${response.data._id}`)
            })
            .catch(error => {
                render(
                    <Toaster error toastMsg={error.response.data.message[0]} />,
                )
            })
        return data
    }
    return { addActivitySectorElement }
}
