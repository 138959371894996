import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string, InferType, mixed } from 'yup'
import { DropzoneArea } from 'react-mui-dropzone'
import { render } from '@testing-library/react'
import { useDebounce } from 'use-debounce/lib'
import {
    Button,
    Box,
    CardContent,
    Card,
    CardActions,
    TextField,
    Grid,
    Typography,
    CircularProgress,
} from '@mui/material'
import Editor from '../../common/custom-text-editor/CustomTextEditor'
import ProgressBar from 'common/progress-bar/ProgressBar'
import AddIcon from '@mui/icons-material/Add'
import Toaster from '../../common/Toaster/Toaster'
import { useLab } from '../../hooks/lab/single/useLab'
import { ListMission } from './listMissions/listMissions'
import { PopupAddMission } from './missionPopUp/MissionPopUp'
import { dropzoneStyle } from 'assets/GlobalStyles'

const schema = object({
    _id: string(),
    description: string(),
    title: string(),
    image: mixed(),
})

type Props = InferType<typeof schema>

export function LabElements({ row }: any) {
    const [title, setValueTitre] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [file, setFile] = useState<any>([])
    const [key, setKey] = useState(0)
    const [mission, setMission] = useState<any>()
    const [loading, setLoading] = useState(false)

    const classes = dropzoneStyle()
    const { update } = useLab(row._id)

    const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValueTitre(event.target.value)
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Props>({
        resolver: yupResolver(schema),
    })

    const [debounceKey] = useDebounce(key, 1000)
    const [isOpen, setIsOpen] = useState(false)

    const togglePopup = () => {
        setMission(null)
        setIsOpen(!isOpen)
    }

    function onSubmit() {
        updateLab(title, description)
        setKey(key + 1)
    }

    const updateLab = async (title?: string, description?: string) => {
        try {
            const bodyFormData = new FormData()
            setLoading(true)

            if (title) {
                bodyFormData.append('title', title)
            }

            if (description) {
                bodyFormData.append('description', description)
            }

            if (file) {
                bodyFormData.append('image', file)
            }

            update(bodyFormData).finally(() => setLoading(false))
        } catch (error: any) {
            render(<Toaster error toastMsg={error?.response?.data.message} />)
        }
    }

    useEffect(() => {
        setDescription(row.description)
    }, [])

    return (
        <div>
            <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Card elevation={16} style={{ margin: '10px' }}>
                    <CardContent>
                        <Grid>
                            <Box p={1}>
                                <Typography variant="h6">Titre : </Typography>
                            </Box>
                            <Box sx={{ p: 2 }}>
                                <TextField
                                    id="titre"
                                    required
                                    label="Titre"
                                    defaultValue={row.title}
                                    style={{ width: '100%' }}
                                    {...register('title')}
                                    onChange={handleChangeTitle}
                                />
                            </Box>
                            <span className="error">
                                {errors?.title?.message}
                            </span>
                        </Grid>

                        <Grid>
                            <Box p={1}>
                                <Typography variant="h6">
                                    Description :
                                </Typography>
                            </Box>
                            <Box sx={{ p: 2 }}>
                                <Editor
                                    currentValue={description}
                                    updateCurrentValue={setDescription}
                                    idEditor="idPageLab"
                                />

                                <Typography variant="caption">
                                    (Max : un paragraphe de 150 000 caractères
                                    max.)
                                </Typography>
                            </Box>
                            <span className="error">
                                {errors?.description?.message}
                            </span>
                        </Grid>

                        <Grid>
                            <Box p={1}>
                                <Typography variant="h6">Image :</Typography>
                            </Box>
                            <Box sx={{ p: 2 }}>
                                {row.imageUrl && (
                                    <Box>
                                        <iframe
                                            id="inlineFrameExample"
                                            title="Inline Fram Example"
                                            width="100%"
                                            height="300"
                                            src={row.imageUrl}
                                        />
                                    </Box>
                                )}
                                <DropzoneArea
                                    key={debounceKey}
                                    dropzoneClass={classes.dropzone}
                                    acceptedFiles={['image/*,video/*']}
                                    dropzoneText="Glisser-déposer une image ou rechercher dans les fichiers (max : 150Mo)"
                                    maxFileSize={150000000}
                                    filesLimit={1}
                                    onDrop={(files: any): void => {
                                        setFile(files[0])
                                    }}
                                    showAlerts={false}
                                />
                            </Box>
                        </Grid>
                    </CardContent>

                    <Box
                        style={{
                            top: '75%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: '1',
                            position: 'absolute',
                        }}
                    >
                        {loading && <CircularProgress size={80} />}
                    </Box>

                    <CardActions>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ margin: '10px' }}
                            type="submit"
                        >
                            ENREGISTRER
                        </Button>
                    </CardActions>
                </Card>
            </form>

            <br />
            <Grid>
                <Box sx={{ p: 1 }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        startIcon={<AddIcon />}
                        onClick={togglePopup}
                    >
                        Ajouter une mission
                    </Button>
                </Box>

                <Box>
                    <ListMission
                        missionElements={row.missions}
                        idLab={row._id}
                        setIsOpen={setIsOpen}
                        setMission={setMission}
                    />
                </Box>
            </Grid>

            {isOpen && (
                <PopupAddMission
                    isOpen={isOpen}
                    handleClose={togglePopup}
                    idLab={row._id}
                    mission={mission}
                />
            )}

            <ProgressBar loading={loading} />
        </div>
    )
}
