import React, { useState, useEffect, useContext } from 'react'
import Aos from 'aos'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string, InferType, mixed } from 'yup'
import { DropzoneArea } from 'react-mui-dropzone'
import { render } from '@testing-library/react'
import { useDebounce } from 'use-debounce/lib'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import {
    Grid,
    Paper,
    Typography,
    Button,
    MenuItem,
    FormControl,
} from '@mui/material'
import { Box } from '@mui/system'
import { dropzoneStyle } from 'assets/GlobalStyles'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import { Banderole as BanderoleType } from '../../common/types/banderole/banderole'
import { useBanderole } from 'hooks/banderole/useBanderole'
import { AuthContext } from 'context/auth-context'
import Toaster from 'common/Toaster/Toaster'
import Editor from 'common/custom-text-editor/CustomTextEditor'
import { SERVER_URL } from 'config/environments'
import { PopupAddBanderole } from './PopupAddBanderole'

const BanderoleEndpoint = `${SERVER_URL.environment}/banderole`

const schema = object({
    _id: string(),
    title: string(),
    image: mixed(),
})

type Props = InferType<typeof schema>

export default function Banderole() {
    const [banderoles, setBanderoles] = useState<BanderoleType>()
    const [image, setImage] = useState<any>([])
    const [valueTitre, setTitre] = useState<string>(banderoles?.title || '')
    const [page, setPage] = useState<string>('HOME')
    const [key, setKey] = useState(0)

    const classes = dropzoneStyle()

    const {
        handleSubmit,
        formState: { errors },
    } = useForm<Props>({
        resolver: yupResolver(schema),
        defaultValues: { ...banderoles },
    })

    const { removeBanderoleImage } = useBanderole()
    const auth: any = useContext(AuthContext)
    const [debounceKey] = useDebounce(key, 1000)
    const [isOpen, setIsOpen] = useState(false)

    const handleChange = (event: SelectChangeEvent) => {
        setPage(event.target.value)
        getBanderoles(event.target.value)
    }

    const togglePopup = () => {
        setIsOpen(!isOpen)
    }

    async function getBanderoles(page: string) {
        try {
            await axios
                .get<BanderoleType>(`${BanderoleEndpoint}/page/${page}`, {
                    headers: {
                        Authorization: `Bearer ${auth.accessToken}` as string,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(response => {
                    setBanderoles(response.data)
                    setTitre(response.data.title)
                    setImage(response.data.imageUrl)
                })
        } catch (error: any) {
            setBanderoles(undefined)
            render(<Toaster error toastMsg={error?.response?.data} />)
        }
    }

    async function onSubmit() {
        if (banderoles) {
            updateBanderole(banderoles._id, valueTitre, image)
            setKey(key + 1)
        }
    }

    async function handleRemoveBanderoleImage(banderoleId: string) {
        try {
            const banderole = await removeBanderoleImage(banderoleId)
            setBanderoles(banderole)
            render(<Toaster success toastMsg="Image supprimée avec succès" />)
        } catch (error: any) {
            render(<Toaster error toastMsg={error?.response?.data} />)
        }
    }

    const updateBanderole = async (
        id: string,
        title?: string,
        imageFile?: File,
    ) => {
        try {
            const bodyFormData = new FormData()

            if (title) {
                bodyFormData.append('title', title)
            } else {
                bodyFormData.append('title', '')
            }

            if (imageFile) {
                bodyFormData.append('image', imageFile)
            }

            await axios({
                method: 'put',
                url: `${BanderoleEndpoint}/${id}`,
                data: bodyFormData,
                headers: {
                    Authorization: `Bearer ${auth.accessToken}` as string,
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(() => {
                    getBanderoles(page)
                    render(<Toaster success toastMsg="ENREGISTRÉ" />)
                })
                .catch(err =>
                    render(
                        <Toaster
                            error
                            toastMsg={err?.response?.data.message}
                        />,
                    ),
                )
        } catch (error: any) {
            render(<Toaster error toastMsg={error?.response?.data.message} />)
        }
    }

    const isVideo = banderoles?.imageUrl?.split('.').pop() === 'mp4'

    useEffect(() => {
        getBanderoles(page)
        Aos.init()
    }, [])

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            className="banderole"
            data-aos="fade-right"
        >
            <Grid item xs={12}>
                <Box mb={2}>
                    <Box sx={{ p: 1 }}>
                        <Typography variant="h6" gutterBottom>
                            Page :
                        </Typography>
                    </Box>
                    <FormControl variant="standard" fullWidth>
                        <Select
                            labelId="page-label"
                            id="page-fv"
                            value={page}
                            onChange={handleChange}
                        >
                            <MenuItem value={'HOME'}>Accueil</MenuItem>
                            <MenuItem value={'LAB'}>Lab</MenuItem>
                            <MenuItem value={'ESTIAM'}>Éstiam</MenuItem>
                            <MenuItem value={'JOB_OFFERS'}>
                                Offres d'emplois
                            </MenuItem>
                            <MenuItem value={'JOIN_US'}>
                                Nous rejoindre
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Grid>

            {banderoles && (
                <Grid item xs={12}>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        noValidate
                        autoComplete="off"
                    >
                        <Box mb={2}>
                            <Paper elevation={3}>
                                <Box sx={{ p: 2 }}>
                                    <Typography variant="h6" gutterBottom>
                                        Titre * :
                                    </Typography>
                                </Box>

                                <Box sx={{ p: 2 }}>
                                    <Editor
                                        currentValue={valueTitre}
                                        updateCurrentValue={setTitre}
                                    />
                                </Box>
                                <span className="error">
                                    {errors?.title?.message}
                                </span>
                            </Paper>
                        </Box>

                        <Grid container mt={5}>
                            <Paper elevation={3}>
                                <Grid container spacing={2} p={2}>
                                    <Grid item p={1} mb={1} md={6}>
                                        <Typography variant="h6" gutterBottom>
                                            Média * :
                                        </Typography>

                                        <Grid p={1} height="100%">
                                            <DropzoneArea
                                                dropzoneClass={classes.dropzone}
                                                key={debounceKey}
                                                acceptedFiles={[
                                                    'image/*,video/*',
                                                ]}
                                                dropzoneText="Glisser-déposer une image/vidéo ou rechercher dans les fichiers (max : 150Mo)"
                                                maxFileSize={150000000}
                                                filesLimit={1}
                                                onDrop={(files): void => {
                                                    setImage(files[0])
                                                    setBanderoles({
                                                        ...banderoles,
                                                        imageUrl:
                                                            URL.createObjectURL(
                                                                files[0],
                                                            ),
                                                    })
                                                }}
                                                showAlerts={false}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item p={1} mb={1} md={6}>
                                        <Typography variant="h6" gutterBottom>
                                            Aperçu :
                                        </Typography>

                                        <Grid p={1} height="100%">
                                            {banderoles?.imageUrl && (
                                                <>
                                                    {isVideo ? (
                                                        <video
                                                            key={
                                                                banderoles?.imageUrl
                                                            }
                                                            autoPlay
                                                            muted
                                                            loop
                                                            width="100%"
                                                        >
                                                            <source
                                                                src={
                                                                    banderoles?.imageUrl
                                                                }
                                                                type="video/mp4"
                                                            />
                                                        </video>
                                                    ) : (
                                                        <iframe
                                                            key={
                                                                banderoles?.imageUrl
                                                            }
                                                            id="Preview"
                                                            title="Preview"
                                                            width="100%"
                                                            height={'100%'}
                                                            src={
                                                                banderoles?.imageUrl
                                                            }
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Box m={1} p={2}>
                                    {banderoles?.imageUrl && (
                                        <Button
                                            onClick={() =>
                                                handleRemoveBanderoleImage(
                                                    banderoles?._id,
                                                )
                                            }
                                            variant="outlined"
                                            startIcon={<DeleteIcon />}
                                        >
                                            Supprimer
                                        </Button>
                                    )}
                                </Box>
                            </Paper>
                        </Grid>

                        <Box sx={{ pt: 1 }} style={{ textAlign: 'right' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                startIcon={<CheckIcon />}
                                type="submit"
                            >
                                ENREGISTRER
                            </Button>
                        </Box>
                    </form>
                </Grid>
            )}

            {!banderoles && (
                <Grid item xs={12}>
                    <Box sx={{ margin: '1px' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="medium"
                            startIcon={<AddIcon />}
                            onClick={togglePopup}
                        >
                            Ajouter Banderole
                        </Button>
                    </Box>
                </Grid>
            )}

            {isOpen && (
                <Grid item xs={12}>
                    <PopupAddBanderole
                        page={page}
                        isOpen={isOpen}
                        handleClose={togglePopup}
                        NewBanderoles={setBanderoles}
                        setTitre={setTitre}
                    />
                </Grid>
            )}
        </Grid>
    )
}
