import React, { useState } from 'react'
import {
    Box,
    Card,
    DialogTitle,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Slide,
    Grid,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { object, string } from 'yup'
import { useAddBanderole } from 'hooks/banderole/addBanderole/useBanderole'
import CheckIcon from '@mui/icons-material/Check'
import Typography from '@mui/material/Typography'
import Editor from '../../common/custom-text-editor/CustomTextEditor'
import { DropzoneArea } from 'react-mui-dropzone'
import { render } from '@testing-library/react'
import { useDebounce } from 'use-debounce/lib'
import Toaster from 'common/Toaster/Toaster'
import { TransitionProps } from '@mui/material/transitions'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />
})

interface BanderoleData {
    _id: string
    title: string
    imageUrl: string
    page: string
}

const schema = object({
    _id: string(),
    title: string(),
    imageUrl: string(),
    page: string(),
})

export function PopupAddBanderole({
    page,
    isOpen,
    handleClose,
    NewBanderoles,
    setTitre,
}: any) {
    const { handleSubmit } = useForm<BanderoleData>({
        resolver: yupResolver(schema),
    })

    const [valueTitre, setValueTitre] = useState<string>('')
    const { addBanderole } = useAddBanderole()

    async function onSubmit() {
        try {
            addBanderoles(valueTitre, page)
        } catch (err: any) {
            render(<Toaster error toastMsg={err.response} />)
        }
    }

    const addBanderoles = async (title?: string, page?: string) => {
        try {
            const bodyFormData = new FormData()

            if (title) {
                bodyFormData.append('title', title)
            }

            if (file) {
                bodyFormData.append('image', file)
                setKey(key + 1)
            }

            if (page) {
                bodyFormData.append('page', page)
            }

            addBanderole(bodyFormData)
                .then((value: BanderoleData) => {
                    NewBanderoles(value)

                    if (value.title) {
                        setTitre(value.title)
                    } else {
                        setTitre('')
                    }
                })
                .catch(() => {
                    render(<Toaster error toastMsg="image file is required" />)
                })

            handleClose()
        } catch (error: any) {
            render(<Toaster error toastMsg={error?.response?.data.message} />)
        }
    }

    const [key, setKey] = useState(0)
    const [file, setFile] = useState<any>([])
    const [debounceKey] = useDebounce(key, 1000)

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
            TransitionComponent={Transition}
            keepMounted
            maxWidth="md"
            aria-describedby="alert-dialog-slide-description"
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
            >
                <DialogTitle>{`Ajouter Banderole pour la page ${page}`}</DialogTitle>

                <DialogContent>
                    <Box style={{ padding: '10px' }}>
                        <Card>
                            <Box sx={{ margin: '0px 15px 1px 15px', p: 1 }}>
                                <Typography
                                    variant="h6"
                                    style={{ marginBottom: '10px' }}
                                >
                                    Titre:
                                </Typography>
                                <Editor
                                    currentValue={valueTitre}
                                    updateCurrentValue={setValueTitre}
                                />
                            </Box>

                            <Box sx={{ margin: '0px 15px 1px 15px', p: 1 }}>
                                <DropzoneArea
                                    key={debounceKey}
                                    acceptedFiles={['image/*,video/*']}
                                    dropzoneText="Drag and drop an image/video here or click (max file size : 150Mo)"
                                    maxFileSize={150000000}
                                    filesLimit={1}
                                    onDrop={(files): void => {
                                        setFile(files[0])
                                    }}
                                    showAlerts={false}
                                />
                            </Box>
                        </Card>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Grid container justifyContent="right" spacing={2}>
                        <Grid item>
                            <Button onClick={handleClose}>Fermer</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                startIcon={<CheckIcon />}
                                type="submit"
                            >
                                ENREGISTRER
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </form>
        </Dialog>
    )
}
