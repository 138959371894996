import React, { useEffect, useState, ReactElement, Ref } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Dialog,
    DialogContent,
    DialogTitle,
    Theme,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import { render } from '@testing-library/react'
import { UseFormRegister, useForm } from 'react-hook-form'
import { useDebounce } from 'use-debounce/lib'
import { object, string, array } from 'yup'
import ProgressBar from 'common/progress-bar/ProgressBar'
import Toaster from '../../../common/Toaster/Toaster'
import { useJobOffers } from 'hooks/job-offer/UseJobOffer'
import { JobOfferElement } from 'common/types/job-offer/JobOfferElement'
import { AddQuote } from '../add-quote-job-offer/QuotePopup'
import { FaqPopupForm } from '../add-faq-job-offer/FaqPopupForm'
import QuoteList from '../quote-list/QuoteList'
import { dropzoneStyle } from 'assets/GlobalStyles'
import { useActivitySectorElements } from 'hooks/activity-sector/all/useActivitySector'
import { useSolutionTechniqueElements } from 'hooks/solution-technique/all/useSolutionTechnique'
import SEO from '../../../common/seo/SEO'
import SaveButton from 'common/custom-button/SaveButton'
import DiscoverTeam from './sections/DiscoverTeamForm'
import JobOfferBanderoleForm from './sections/JobOfferBanderoleForm'
import JobOfferDetails from './sections/JobOfferDetails'
import JobOfferFilterForm from './sections/JobOfferFilterForm'
import JobOfferDescriptions from './sections/JobOfferDescriptions'
import JobOfferEntityForm from './sections/JobOfferEntityForm'
import JobOfferAboutSectionForm from './sections/JobOfferAboutSectionForm'
import FaqList from '../faq-list/FaqList'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>
    },
    ref: Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />
})

type Props = JobOfferElement

const schema = object({
    title: string(),
    category: string(),
    level: string(),
    metaDescription: string(),
    metaTitle: string(),
    description: string(),
    keywords: array(),
})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            borderRadius: 0,
            border: '1px solid #e5e5e5',
            width: theme.spacing(15),
            height: theme.spacing(15),
            '@media (max-width: 868px)': {
                width: theme.spacing(11),
                height: theme.spacing(11),
            },
        },
    }),
)

interface JobOfferFormProps {
    isOpen: boolean
    jobOffer: JobOfferElement
    handleClose: () => void
}

export function JobOfferForm({
    isOpen,
    handleClose,
    jobOffer,
}: JobOfferFormProps) {
    const classes = useStyles()
    const dropzoneClass = dropzoneStyle()
    const [titlePage, setTitlePage] = useState<string>('')
    const [isOpenQuote, setIsOpenQuote] = useState(false)
    const [isOpenQuesRes, setIsOpenQuesRes] = useState(false)
    const [openBanderoleImage, setOpenBanderoleImage] = useState(false)
    const [openLogo, setOpenLogo] = useState(false)
    const [key, setKey] = useState(0)
    const [file, setFile] = useState<any>([])
    const [banderoleImage, setBanderoleImage] = useState<any>([])
    const [logo, setLogo] = useState<any>([])
    const [loading, setLoading] = useState(false)
    const [debounceKey] = useDebounce(key, 1000)
    const [avatarView, setAvatarView] = useState<string>('')
    const [avatarBanderole, setAvatarBanderole] = useState<string>('')
    const [avatarLogo, setAvatarLogo] = useState<string>('')
    const [valueDescription, setValueDescription] = useState<
        string | undefined
    >('')
    const [valueMetaDescription, setValueMetaDescription] = useState<
        string | undefined
    >('')
    const [valueMetaTitle, setValueMetaTitle] = useState<string | undefined>('')
    const [valueLittleDescription, setValueLittleDescription] = useState<
        string | undefined
    >('')
    const [valueDescriptionEntity, setValueDescriptionEntity] = useState<
        string | undefined
    >('')
    const [valueAbout, setValueAbout] = useState<string | undefined>('')
    const [valueProfile, setValueProfile] = useState<string | undefined>('')

    const [pageInput, setPageInput] = useState<string[]>([])
    const [keywordInput, setKeywordInput] = useState<string>('')

    const { solutionTechniqueElements } = useSolutionTechniqueElements()
    const { activitySectorElements } = useActivitySectorElements()

    const [jobOfferForm, setJobOfferForm] = useState<{
        pages: string[]
        keywords?: string[]
    }>({
        pages: jobOffer?.pages ?? [],
        keywords: jobOffer?.keywords ?? [],
    })

    const { addJobOffer, updateJobOffer } = useJobOffers()

    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<Props>({ resolver: yupResolver(schema) })

    const handleCloseQuote = () => {
        setIsOpenQuote(false)
    }

    const handleCloseQuesRes = () => {
        setIsOpenQuesRes(false)
    }

    async function onSubmit() {
        try {
            addJobOffers(
                getValues('title'),
                getValues('category'),
                getValues('level'),
                getValues('contract'),
                getValues('startDate'),
                getValues('jobLocation'),
                getValues('videoUrl'),
                getValues('entity.title'),
            )
        } catch (err: any) {
            render(<Toaster error toastMsg={err.response} />)
        }
    }

    const addJobOffers = async (
        title?: string,
        category?: string,
        level?: string,
        contract?: string,
        startDate?: string,
        jobLocation?: string,
        videoUrl?: string,
        titleEntity?: string,
    ) => {
        try {
            const bodyFormData = new FormData()
            setLoading(true)

            if (jobOffer) {
                bodyFormData.append('_id', jobOffer._id)
            }

            bodyFormData.append('title', title ? title : '')
            bodyFormData.append('category', category ? category : '')
            bodyFormData.append('level', level ? level : '')
            bodyFormData.append('contract', contract ? contract : '')
            bodyFormData.append('startDate', startDate ? startDate : '')
            bodyFormData.append(
                'metaDescription',
                valueMetaDescription ? valueMetaDescription : '',
            )
            bodyFormData.append(
                'metaTitle',
                valueMetaTitle ? valueMetaTitle : '',
            )
            bodyFormData.append('about', valueAbout ? valueAbout : '')
            bodyFormData.append('profile', valueProfile ? valueProfile : '')
            bodyFormData.append('jobLocation', jobLocation ? jobLocation : '')
            bodyFormData.append('videoUrl', videoUrl ? videoUrl : '')
            bodyFormData.append(
                'description',
                valueDescription ? valueDescription : '',
            )
            bodyFormData.append(
                'littleDescription',
                valueLittleDescription ? valueLittleDescription : '',
            )
            bodyFormData.append('entity.title', titleEntity ? titleEntity : '')
            bodyFormData.append(
                'entity.description',
                valueDescriptionEntity ? valueDescriptionEntity : '',
            )

            if (jobOfferForm?.pages.length) {
                jobOfferForm.pages.forEach((page: string, index: number) => {
                    bodyFormData.append(`pages[${index}]`, page)
                })
            } else {
                bodyFormData.append(`pages[0]`, 'no page')
            }
            if (jobOfferForm?.keywords?.length)
                jobOfferForm.keywords.forEach(
                    (keyword: string, index: number) =>
                        bodyFormData.append(`keywords[${index}]`, keyword),
                )
            else bodyFormData.append(`keywords[0]`, '')

            if (file) {
                bodyFormData.append('image', file)
                setKey(key + 1)
            }
            if (logo) {
                bodyFormData.append('logo', logo)
                setKey(key + 1)
            }
            if (banderoleImage) {
                bodyFormData.append('banderoleImage', banderoleImage)
                setKey(key + 1)
            }

            if (jobOffer != null) {
                updateJobOffer(bodyFormData, jobOffer._id)
                    .then(res => {
                        if (res === 'OK') {
                            handleClose()
                        }
                    })
                    .finally(() => setLoading(false))
            } else {
                await addJobOffer(bodyFormData)
                    .then(res => {
                        if (res === 'Created') {
                            handleClose()
                        }
                    })
                    .finally(() => setLoading(false))
            }
        } catch (error: any) {
            setLoading(false)
            render(<Toaster error toastMsg={error?.response?.data.message} />)
        }
    }

    const toggleQuotePopup = () => {
        setIsOpenQuote(true)
    }

    const toggleFaqPopup = () => {
        setIsOpenQuesRes(true)
    }
    const handleChangeMetaTitle = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setValueMetaTitle(event.target.value)
    }
    const handleChangeMetaDescription = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setValueMetaDescription(event.target.value)
    }

    const savePages = () =>
        setJobOfferForm({
            ...jobOfferForm,
            pages: jobOfferForm?.pages
                ?.concat(pageInput)
                .filter(
                    (page: string, i: number, arr: string | any[]) =>
                        arr.indexOf(page) === i,
                ),
        })

    useEffect(() => {
        setValueDescription(jobOffer?.description)
        setValueDescriptionEntity(jobOffer?.entity?.description)
        setValueLittleDescription(jobOffer?.littleDescription)
        setValueAbout(jobOffer?.about)
        setValueProfile(jobOffer?.profile)
        setValueMetaDescription(jobOffer?.metaDescription)
        setValueMetaTitle(jobOffer?.metaTitle)
        setTitlePage(
            jobOffer != null
                ? "Modifier l'offre d'emploi"
                : "Ajouter une offre d'emploi",
        )
    }, [])

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
            TransitionComponent={Transition}
            keepMounted
            maxWidth="md"
            aria-describedby="alert-dialog-slide-description"
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
            >
                <DialogTitle>{titlePage}</DialogTitle>
                <DialogContent>
                    <Box p={3}>
                        <Card>
                            <SEO
                                {...{
                                    valueMetaTitle,
                                    valueMetaDescription,
                                    handleChangeMetaTitle,
                                    handleChangeMetaDescription,
                                }}
                                jobOffer
                                register={
                                    register as UseFormRegister<JobOfferElement>
                                }
                                defaultMetaDescription={
                                    jobOffer?.metaDescription
                                }
                                errorMetaTitleMessage={
                                    errors?.metaTitle?.message
                                }
                                errorMetaDescriptionMessage={
                                    errors?.metaDescription?.message
                                }
                            />
                            <CardHeader
                                avatar={
                                    <JobOfferBanderoleForm
                                        {...{
                                            jobOffer,
                                            setOpenBanderoleImage,
                                            openBanderoleImage,
                                            setBanderoleImage,
                                            setAvatarBanderole,
                                            avatarBanderole,
                                            debounceKey,
                                            dropzoneClass,
                                            classes,
                                            banderoleImage,
                                        }}
                                    />
                                }
                                title={
                                    <JobOfferDetails
                                        detail="title"
                                        {...{
                                            register,
                                            setValue,
                                            errors,
                                            jobOffer,
                                        }}
                                    />
                                }
                                subheader={
                                    <JobOfferDetails
                                        detail="category"
                                        {...{
                                            register,
                                            setValue,
                                            errors,
                                            jobOffer,
                                        }}
                                    />
                                }
                            />

                            <CardContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <JobOfferDetails
                                        detail="startDate"
                                        {...{
                                            register,
                                            setValue,
                                            errors,
                                            jobOffer,
                                        }}
                                    />
                                    <JobOfferDetails
                                        detail="city"
                                        {...{
                                            register,
                                            setValue,
                                            jobOffer,
                                        }}
                                        errorMessage={
                                            errors?.jobLocation?.message
                                        }
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <JobOfferDetails
                                        detail="contract"
                                        {...{
                                            register,
                                            setValue,
                                            jobOffer,
                                        }}
                                        errorMessage={errors?.contract?.message}
                                    />
                                    <JobOfferDetails
                                        detail="level"
                                        {...{
                                            register,
                                            setValue,
                                            jobOffer,
                                        }}
                                        errorMessage={errors?.level?.message}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    <JobOfferFilterForm
                                        filter="pages"
                                        {...{
                                            savePages,
                                            pageInput,
                                            setPageInput,
                                            solutionTechniqueElements,
                                            activitySectorElements,
                                            jobOfferForm,
                                            setJobOfferForm,
                                            keywordInput,
                                            setKeywordInput,
                                        }}
                                    />
                                    <JobOfferFilterForm
                                        filter="keywords"
                                        {...{
                                            savePages,
                                            pageInput,
                                            setPageInput,
                                            solutionTechniqueElements,
                                            activitySectorElements,
                                            jobOfferForm,
                                            setJobOfferForm,
                                            keywordInput,
                                            setKeywordInput,
                                        }}
                                    />
                                </Box>
                                <Grid>
                                    <JobOfferEntityForm
                                        errorEntityMessage={
                                            errors?.entity?.title?.message
                                        }
                                        errorDescriptionMessage={
                                            errors?.description?.message
                                        }
                                        {...{
                                            setValueDescriptionEntity,
                                            valueDescriptionEntity,
                                            jobOffer,
                                            register,
                                            setValue,
                                            setOpenLogo,
                                            openLogo,
                                            setLogo,
                                            setAvatarLogo,
                                            avatarLogo,
                                            logo,
                                            debounceKey,
                                            dropzoneClass,
                                            classes,
                                        }}
                                    />

                                    <JobOfferDescriptions
                                        section="littleDescription"
                                        descriptionValue={
                                            valueLittleDescription
                                        }
                                        setDescriptionValue={
                                            setValueLittleDescription
                                        }
                                        errorMessage={
                                            errors?.littleDescription?.message
                                        }
                                    />
                                </Grid>
                                <JobOfferAboutSectionForm
                                    errorAboutMessage={errors?.about?.message}
                                    {...{
                                        valueAbout,
                                        setValueAbout,
                                        file,
                                        setFile,
                                        debounceKey,
                                        dropzoneClass,
                                        classes,
                                        jobOffer,
                                        avatarView,
                                        setAvatarView,
                                    }}
                                />
                                <JobOfferDescriptions
                                    section="jobDescription"
                                    descriptionValue={valueDescription}
                                    setDescriptionValue={setValueDescription}
                                    errorMessage={errors?.description?.message}
                                />
                                <JobOfferDescriptions
                                    section="profileDescription"
                                    descriptionValue={valueProfile}
                                    setDescriptionValue={setValueProfile}
                                    errorMessage={errors?.profile?.message}
                                />
                                <Box>
                                    <DiscoverTeam
                                        {...{
                                            register,
                                            setValue,
                                            jobOffer,
                                        }}
                                        errorVideoUrlMessage={
                                            errors?.videoUrl?.message
                                        }
                                    />
                                    {jobOffer != null && (
                                        <>
                                            <QuoteList
                                                jobOffer={jobOffer}
                                                {...{ toggleQuotePopup }}
                                            />
                                            <FaqList
                                                jobOffer={jobOffer}
                                                {...{
                                                    toggleFaqPopup,
                                                }}
                                            />
                                        </>
                                    )}
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </DialogContent>
                <SaveButton {...{ handleClose }} />
            </form>

            {isOpenQuote && (
                <AddQuote
                    handleClose={handleCloseQuote}
                    isOpen={isOpenQuote}
                    jobOffer={jobOffer}
                    quoteId={null}
                    quoteElm={null}
                />
            )}
            {isOpenQuesRes && (
                <FaqPopupForm
                    handleClose={handleCloseQuesRes}
                    isOpen={isOpenQuesRes}
                    jobOffer={jobOffer}
                    questionResponseId={null}
                    questionResponseElm={null}
                />
            )}
            <ProgressBar loading={loading} />
        </Dialog>
    )
}
