import axios from 'axios'
import useToken from '../../../session/userSession'
import { useFormationsElements } from '../all/UseFormations'
import { SERVER_URL } from '../../../../config/environments'
import Toaster from 'common/Toaster/Toaster'
import { render } from '@testing-library/react'

const FETCH_ALL_FORMATIONS_URL = `${SERVER_URL.environment}/estiam/`

export function useAddFormationElement(estiamId: string) {
    const { mutateFormationsElements } = useFormationsElements()
    const { accessToken } = useToken()

    let data = ''

    async function add(bodyFormData: URLSearchParams) {
        await axios({
            method: 'POST',
            url: `${FETCH_ALL_FORMATIONS_URL}${estiamId}/estiam-formation`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type':
                    'application/x-www-form-urlencoded;charset=UTF-8',
            },
        })
            .then(response => {
                mutateFormationsElements()
                render(<Toaster success toastMsg="ENREGISTRÉ" />)
                data = response.statusText
            })
            .catch((err: any) => {
                render(
                    <Toaster
                        error
                        toastMsg={err.response.data.message.toString()}
                    />,
                )
            })
        return data
    }
    return { add }
}
