import axios from 'axios'
import useToken from '../../../session/userSession'
import { useComityMembersElements } from '../all/UseComityMembers'
import { SERVER_URL } from '../../../../config/environments'
import Toaster from 'common/Toaster/Toaster'
import { render } from '@testing-library/react'

const FETCH_ALL_COMITYMEMBERS_URL = `${SERVER_URL.environment}/estiam/`

export function useEditComityMemberElement(
    estiamId: string,
    comityMemberId: string,
) {
    const { mutateComityMembersElements } = useComityMembersElements()
    const { accessToken } = useToken()

    let data = ''

    async function editComityMember(bodyFormData: FormData) {
        await axios({
            method: 'PUT',
            url: `${FETCH_ALL_COMITYMEMBERS_URL}${estiamId}/comity-member/${comityMemberId}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                mutateComityMembersElements()
                render(<Toaster success toastMsg="ENREGISTRÉ" />)

                data = response.statusText
            })
            .catch((err: any) => {
                render(
                    <Toaster
                        error
                        toastMsg={err.response.data.message.toString()}
                    />,
                )
            })
        return data
    }
    return { editComityMember }
}
