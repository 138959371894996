import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import EditorText from 'common/custom-text-editor/CustomEditorText'
import React, { Dispatch, SetStateAction } from 'react'

interface JobOfferDescriptionsProps {
    section:
        | 'entity'
        | 'littleDescription'
        | 'about'
        | 'jobDescription'
        | 'profileDescription'
    descriptionValue?: string
    setDescriptionValue: Dispatch<SetStateAction<string | undefined>>
    errorMessage?: string
}

export default function JobOfferDescriptions({
    section,
    descriptionValue,
    setDescriptionValue,
    errorMessage,
}: JobOfferDescriptionsProps) {
    const getDescriptionTitle = (section: string) => {
        switch (section) {
            case 'entity':
                return "Description de l'entité :"
            case 'littleDescription':
                return 'Brève description :'
            case 'about':
                return 'À propos :'
            case 'jobDescription':
                return 'Descriptif du poste :'
            case 'profileDescription':
                return 'Profil recherché :'
            default:
                return ''
        }
    }
    return (
        <>
            <Grid xs={12}>
                <Box>
                    <Typography variant="h6">
                        {getDescriptionTitle(section)}
                    </Typography>
                </Box>
                <Box sx={{ pb: 1 }}>
                    <EditorText
                        currentValue={descriptionValue}
                        updateCurrentValue={setDescriptionValue}
                    />
                    <Typography variant="caption">
                        (Max : un paragraphe de 10 000 caractères max.)
                    </Typography>
                </Box>
                <span className="error">{errorMessage}</span>
            </Grid>
        </>
    )
}
