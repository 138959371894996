import React, { useState } from 'react'
import {
    Button,
    Grid,
    Paper,
    TablePagination,
    Typography,
    TableRow,
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableBody,
    IconButton,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Box } from '@mui/system'
import AddIcon from '@mui/icons-material/Add'
import { useAdvantage } from 'hooks/join-us/advantage/UseAdvantage'
import { AddAdvantage } from './add-advantage/AddAdvantage'
import ConfirmForm from 'common/confirm-form/ConfirmForm'
import { Advantage } from 'common/types/join-us/Advantage'
import AdvantagesView from '../../../common/view/view'

const Advantages = () => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [isOpen, setIsOpen] = useState(false)
    const [joinUs, setJoinUs] = useState<any>()
    const [idAdvantage, setIdAdvantage] = useState('')
    const [openConfirmForm, setOpenConfirmForm] = useState(false)

    const { advantages, deleteAdvantage } = useAdvantage()

    const handleClickOpen = (id: string) => {
        setIdAdvantage(id)
        setOpenConfirmForm(true)
    }

    const onDelete = () => {
        deleteAdvantage(idAdvantage)
        setOpenConfirmForm(false)
    }

    const togglePopup = () => {
        setJoinUs({ data: advantages })
        setIsOpen(!isOpen)
    }

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const onEdit = (adg: Advantage) => {
        setJoinUs({ data: adg })
        setIsOpen(true)
    }

    return (
        <Paper elevation={3} sx={{ padding: '10px' }}>
            <Grid item xs={12}>
                <Box sx={{ margin: '10px' }}>
                    <Typography variant="h5">Les avantages</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <AdvantagesView useTitle category="JOIN_US" />
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ margin: '10px' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        startIcon={<AddIcon />}
                        onClick={togglePopup}
                    >
                        Ajouter
                    </Button>
                </Box>
            </Grid>

            <TableContainer component={Paper}>
                <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>Titre</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {advantages &&
                            advantages
                                ?.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )
                                .map((row: any, index: number) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell>{row.title}</TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                onClick={() => onEdit(row)}
                                                size="large"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() =>
                                                    handleClickOpen(row._id)
                                                }
                                                size="large"
                                            >
                                                <DeleteOutlinedIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage="Lignes par page"
                component="div"
                count={advantages ? advantages?.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {isOpen && (
                <AddAdvantage
                    handleClose={togglePopup}
                    joinUs={joinUs}
                    isOpen={isOpen}
                />
            )}

            <ConfirmForm
                onConfirm={onDelete}
                open={openConfirmForm}
                setOpen={setOpenConfirmForm}
                action="delete"
            />
        </Paper>
    )
}

export default Advantages
