import axios from 'axios'
import useToken from '../../session/userSession'
import { SERVER_URL } from '../../../config/environments'
import { useJoinUs } from 'hooks/join-us/UseJoinUs'
import Toaster from 'common/Toaster/Toaster'
import { render } from '@testing-library/react'

const FETCH_ALL_JOBS_URL = `${SERVER_URL.environment}/join-us`

export function useOurJobs() {
    const { mutateJoinUsElements } = useJoinUs()
    const { accessToken } = useToken()

    async function createJob(joinUsId: string, bodyFormData: FormData) {
        return await axios({
            method: 'POST',
            url: `${FETCH_ALL_JOBS_URL}/${joinUsId}/job`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                mutateJoinUsElements()
                render(<Toaster success toastMsg="ENREGISTRÉ" />)
                return response
            })
            .catch(err => {
                render(
                    <Toaster
                        error
                        toastMsg={err.response.data.message.toString()}
                    />,
                )
            })
    }

    async function updateJob(
        joinUsId: string,
        jobId: string,
        bodyFormData: FormData,
    ) {
        return axios({
            method: 'PUT',
            url: `${FETCH_ALL_JOBS_URL}/${joinUsId}/job/${jobId}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                mutateJoinUsElements()
                render(<Toaster success toastMsg="MODIFIÉ" />)

                return response
            })
            .catch(err => {
                render(
                    <Toaster
                        error
                        toastMsg={err.response.data.message.toString()}
                    />,
                )
            })
    }

    async function deleteJob(joinUsId: string, jobId: string) {
        await fetch(`${FETCH_ALL_JOBS_URL}/${joinUsId}/job/${jobId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then(response => {
                mutateJoinUsElements()
                render(<Toaster success toastMsg="SUPPRIMÉ" />)
                return response.json()
            })
            .catch(error => {
                render(<Toaster error toastMsg={error.message} />)
            })
    }

    return { createJob, updateJob, deleteJob }
}
