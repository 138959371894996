import { NavbarElement } from '../../../common/types/navbar-element/NavbarElement'
import useToken from '../../session/userSession'
import { useNavbarElements } from '../all/useNavbarElements'
import { SERVER_URL } from '../../../config/environments'

const NAVBAR_ELEMENT_URL = `${SERVER_URL.environment}/navbar-element`

export function useNavbarElement(navbarElementId?: string) {
    const { mutateNavbarElements } = useNavbarElements()
    const { accessToken } = useToken()

    async function update(
        body: Partial<NavbarElement>,
    ): Promise<NavbarElement> {
        const response = await fetch(
            `${NAVBAR_ELEMENT_URL}/${navbarElementId}`,
            {
                body: JSON.stringify(body),
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        )

        if (!response.ok) {
            const error = await response.json()
            throw new Error(error.message)
        }

        await mutateNavbarElements()
        return await response.json()
    }

    async function create(body: NavbarElement): Promise<NavbarElement> {
        const response = await fetch(`${NAVBAR_ELEMENT_URL}`, {
            body: JSON.stringify(body),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        })

        if (!response.ok) {
            const error = await response.json()
            throw new Error(error.message)
        }

        await mutateNavbarElements()
        return await response.json()
    }

    async function deleteById() {
        const response = await fetch(
            `${NAVBAR_ELEMENT_URL}/${navbarElementId}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        )

        if (!response.ok) {
            const error = await response.json()
            throw new Error(error.message)
        }

        await mutateNavbarElements()
        return await response.json()
    }

    return { update, create, deleteById }
}
