import React from 'react'
import { render } from '@testing-library/react'
import useToken from '../../session/userSession'
import { SERVER_URL } from '../../../config/environments'
import Toaster from '../../../common/Toaster/Toaster'
import { useAllTalkingAboutUs } from '../get-all/useAllTalkingAboutUs'

const FETCH_TALKING_ABOUT_US_URL = `${SERVER_URL.environment}/talking-about-us`

export function useDeleteTalkingAboutUs(talkingAboutUsId: string) {
    const { mutateTalkingAboutUsElements } = useAllTalkingAboutUs()
    const { accessToken } = useToken()

    async function deleteTalkingAboutUs() {
        const response = await fetch(
            `${FETCH_TALKING_ABOUT_US_URL}/${talkingAboutUsId}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        )
        render(<Toaster success toastMsg="SUPPRIMÉ" />)
        await mutateTalkingAboutUsElements()
        return response.json()
    }
    return { deleteTalkingAboutUs }
}
