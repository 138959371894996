import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    Button,
    Box,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Card,
    CircularProgress,
    Select,
    MenuItem,
    SelectChangeEvent,
    IconButton,
    Avatar,
    Theme,
    FormControl,
    OutlinedInput,
    InputLabel,
    FormHelperText,
    Slide,
    Grid,
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { DropzoneDialog } from 'react-mui-dropzone'
import { render } from '@testing-library/react'
import { useForm } from 'react-hook-form'
import { useDebounce } from 'use-debounce/lib'
import { object, string } from 'yup'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { TransitionProps } from '@mui/material/transitions'

import { useExpert } from '../../../hooks/expert/useExpert'
import ProgressBar from '../../../common/progress-bar/ProgressBar'
import Toaster from '../../../common/Toaster/Toaster'
import { orderToString } from 'common/utils/utils'
import { ExpertElement } from 'common/types/expert/ExpertElement'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />
})

const schema = object({
    firstName: string(),
    lastName: string(),
    link: string(),
    sector: string(),
    poste: string(),
    linkedInUrl: string(),
    quote: string(),
})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            width: theme.spacing(15),
            height: theme.spacing(15),
            '@media (max-width: 868px)': {
                width: theme.spacing(11),
                height: theme.spacing(11),
            },
        },
        dropzone: {
            '& .MuiDropzoneArea-textContainer': {
                padding: '20px',
                '& .MuiTypography-root': {
                    fontSize: '17px',
                },
            },
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    }),
)

type Props = {
    isOpen: boolean
    handleClose: () => void
    expert: ExpertElement
}

export function AddExpert({ isOpen, handleClose, expert }: Props) {
    const classes = useStyles()

    const [open, setOpen] = useState(false)
    const [key, setKey] = useState(0)
    const [file, setFile] = useState<any>([])
    const [avatarView, setAvatarView] = useState<string>('')
    const [loading, setLoading] = useState(false)
    const [orderValue, setOrderValue] = useState('')

    const [debounceKey] = useDebounce(key, 1000)

    const handleChange = (e: SelectChangeEvent) => {
        setOrderValue(e.target.value.toString())
    }

    const { addExpert, update, experts } = useExpert(expert?._id)

    const totalExpertByCurrentSector = expert
        ? experts?.filter(({ sector }) => sector === expert.sector).length
        : 20

    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<ExpertElement>({ resolver: yupResolver(schema) })

    async function onSubmit() {
        try {
            addExperts({
                firstName: getValues('firstName'),
                lastName: getValues('lastName'),
                sector: getValues('sector'),
                poste: getValues('poste'),
                linkedInUrl: getValues('linkedInUrl'),
                quote: getValues('quote'),
            })
        } catch (err: any) {
            render(<Toaster error toastMsg={err.response} />)
        }
    }

    const addExperts = async (formValues: {
        firstName?: string
        lastName?: string
        sector?: string
        poste?: string
        linkedInUrl?: string
        quote?: string
    }) => {
        try {
            const bodyFormData = new FormData()
            setLoading(true)

            Object.entries(formValues).forEach(([key, value]) => {
                bodyFormData.append(key, value)
            })

            if (orderValue) {
                bodyFormData.append('order', orderValue.toString())
            }

            if (file) {
                bodyFormData.append('image', file)
                setKey(key + 1)
            }

            if (expert != null) {
                update(bodyFormData)
                    .then(res => {
                        if (res === 'OK') {
                            handleClose()
                        }
                    })
                    .finally(() => setLoading(false))
            } else {
                await addExpert(bodyFormData)
                    .then(res => {
                        if (res === 'Created') {
                            handleClose()
                        }
                    })
                    .finally(() => setLoading(false))
            }
        } catch (error: any) {
            setLoading(false)
            render(<Toaster error toastMsg={error?.response?.data.message} />)
        }
    }

    useEffect(() => {
        if (expert) {
            setOrderValue(expert?.order ? expert?.order.toString() : orderValue)
        }
    }, [])

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
            TransitionComponent={Transition}
            keepMounted
            maxWidth="md"
            aria-describedby="alert-dialog-slide-description"
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
            >
                <DialogTitle>
                    {expert != null ? "Modifier l'expert" : 'Ajouter un expert'}
                </DialogTitle>

                <DialogContent>
                    <Box style={{ padding: '10px' }}>
                        <Card>
                            <CardHeader
                                avatar={
                                    <>
                                        <IconButton
                                            component="span"
                                            onClick={() => setOpen(true)}
                                            size="large"
                                        >
                                            <Avatar
                                                className={classes.avatar}
                                                alt={expert?.firstName}
                                                src={
                                                    file.length == 0
                                                        ? expert?.imageUrl
                                                        : avatarView
                                                }
                                            />
                                        </IconButton>
                                        <DropzoneDialog
                                            key={debounceKey}
                                            acceptedFiles={['image/*']}
                                            maxFileSize={10000000}
                                            filesLimit={1}
                                            open={open}
                                            dropzoneClass={classes.dropzone}
                                            dropzoneText="Glisser-déposer une image ou rechercher dans les fichiers (max : 10Mo)"
                                            onClose={() => setOpen(false)}
                                            onDrop={async files => {
                                                const src =
                                                    window.URL.createObjectURL(
                                                        files[0],
                                                    )
                                                setAvatarView(src)
                                                setFile(files[0])
                                                setOpen(false)
                                            }}
                                            showAlerts={true}
                                            getDropRejectMessage={resultat =>
                                                `Ce fichier est trop grand, taille de fichier : ${resultat.size} bytes, la taille max est de 5000000 bytes`
                                            }
                                        />
                                    </>
                                }
                                title={
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <FormControl
                                            sx={{ m: 1, width: '50%' }}
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                required
                                                htmlFor="firstename-expert"
                                            >
                                                Prénom
                                            </InputLabel>
                                            <OutlinedInput
                                                required
                                                fullWidth
                                                label="Prénom"
                                                id="fisrtname-expert"
                                                {...register('firstName')}
                                                defaultValue={expert?.firstName}
                                                onChange={e =>
                                                    setValue(
                                                        'firstName',
                                                        e.target.value,
                                                    )
                                                }
                                                inputProps={{
                                                    'aria-label': 'weight',
                                                }}
                                            />
                                            <FormHelperText id="firstname-weight-helper-text">
                                                {errors?.firstName?.message}
                                            </FormHelperText>
                                        </FormControl>
                                        <FormControl
                                            sx={{ m: 1, width: '50%' }}
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                required
                                                htmlFor="lastname-expertt"
                                            >
                                                Nom
                                            </InputLabel>
                                            <OutlinedInput
                                                required
                                                fullWidth
                                                id="lastname-expert"
                                                label="Nom"
                                                {...register('lastName')}
                                                defaultValue={expert?.lastName}
                                                onChange={e =>
                                                    setValue(
                                                        'lastName',
                                                        e.target.value,
                                                    )
                                                }
                                                inputProps={{
                                                    'aria-label': 'weight',
                                                }}
                                            />
                                            <FormHelperText id="lastname-weight-helper-text">
                                                {errors?.lastName?.message}
                                            </FormHelperText>
                                        </FormControl>
                                    </Box>
                                }
                                subheader={
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <FormControl
                                            sx={{ m: 1, width: '50%' }}
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                required
                                                htmlFor="poste-expert"
                                            >
                                                Poste
                                            </InputLabel>
                                            <OutlinedInput
                                                id="poste-expert"
                                                label="Post"
                                                required
                                                {...register('poste')}
                                                defaultValue={expert?.poste}
                                                onChange={e =>
                                                    setValue(
                                                        'poste',
                                                        e.target.value,
                                                    )
                                                }
                                                inputProps={{
                                                    'aria-label': 'weight',
                                                }}
                                            />
                                            <FormHelperText id="post-weight-helper-text">
                                                {errors?.poste?.message}
                                            </FormHelperText>
                                        </FormControl>
                                        <FormControl
                                            sx={{ m: 1, width: '50%' }}
                                            variant="outlined"
                                        >
                                            <InputLabel
                                                required
                                                htmlFor="sector-expert"
                                            >
                                                Secteur
                                            </InputLabel>
                                            <OutlinedInput
                                                id="sector-expert"
                                                label="Secteur"
                                                required
                                                {...register('sector')}
                                                defaultValue={expert?.sector}
                                                onChange={e =>
                                                    setValue(
                                                        'sector',
                                                        e.target.value,
                                                    )
                                                }
                                                inputProps={{
                                                    'aria-label': 'weight',
                                                }}
                                            />
                                            <FormHelperText id="sector-expert-weight-helper-text">
                                                {errors?.sector?.message}
                                            </FormHelperText>
                                        </FormControl>
                                    </Box>
                                }
                            />

                            <CardContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <FormControl
                                        sx={{ m: 1, width: '40%' }}
                                        variant="outlined"
                                    >
                                        <InputLabel htmlFor="linkedin-expert">
                                            Linkedin url
                                        </InputLabel>
                                        <OutlinedInput
                                            id="linkedin-expert"
                                            label="Linkedin url"
                                            {...register('linkedInUrl')}
                                            defaultValue={expert?.linkedInUrl}
                                            onChange={e =>
                                                setValue(
                                                    'linkedInUrl',
                                                    e.target.value,
                                                )
                                            }
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                        <FormHelperText id="linkedinurl-weight-helper-text">
                                            {errors?.linkedInUrl?.message}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl
                                        sx={{ m: 1, width: '40%' }}
                                        variant="outlined"
                                    >
                                        <InputLabel htmlFor="quote-expert">
                                            Citation
                                        </InputLabel>
                                        <OutlinedInput
                                            id="quote-expert"
                                            label="Quotation"
                                            {...register('quote')}
                                            defaultValue={expert?.quote}
                                            onChange={e =>
                                                setValue(
                                                    'quote',
                                                    e.target.value,
                                                )
                                            }
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                        <FormHelperText id="quote-weight-helper-text">
                                            {errors?.quote?.message}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl
                                        sx={{ m: 1, width: '20%' }}
                                        variant="outlined"
                                    >
                                        <InputLabel id="order-select-label">
                                            Ordre
                                        </InputLabel>
                                        <Select
                                            label="Order"
                                            {...register('order')}
                                            defaultValue={orderToString(
                                                expert?.order,
                                            )}
                                            value={orderValue}
                                            required
                                            onChange={handleChange}
                                            id="order-select"
                                        >
                                            {Array.from(
                                                Array(
                                                    totalExpertByCurrentSector,
                                                ).keys(),
                                            ).map((res, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={res + 1}
                                                >
                                                    {orderToString(res + 1)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText id="order-weight-helper-text">
                                            {errors?.order?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </DialogContent>

                <Box
                    style={{
                        top: '40%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: '1',
                        position: 'absolute',
                    }}
                >
                    {loading && <CircularProgress size={80} />}
                </Box>

                <DialogActions>
                    <Grid container justifyContent="right" spacing={2}>
                        <Grid item>
                            <Button onClick={handleClose}>Fermer</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                startIcon={<CheckIcon />}
                                type="submit"
                            >
                                ENREGISTRER
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </form>

            <ProgressBar loading={loading} />
        </Dialog>
    )
}
