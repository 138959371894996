import React, { useState } from 'react'

import {
    Button,
    Grid,
    Paper,
    TablePagination,
    Typography,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableContainer,
    TableRow,
    IconButton,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Box } from '@mui/system'
import AddIcon from '@mui/icons-material/Add'
import { AddFormation } from './add-formation/AddFormation'
import { FormationsElement } from 'common/types/estiam/FormationsElement'
import ConfirmForm from 'common/confirm-form/ConfirmForm'
import { UseDeleteFormationsElements } from 'hooks/estiam/formations/deleteFormations/UseDeleteFormations'

const Formations = ({ formations, idEstiam, setLoading }: any) => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [isOpen, setIsOpen] = useState(false)
    const [estiam, setEstiam] = useState<any>()
    const [idFormation, setIdFormation] = useState('')
    const [openConfirmForm, setOpenConfirmForm] = useState(false)

    const { deleteFormations } = UseDeleteFormationsElements(
        idEstiam,
        idFormation,
    )

    const handleClickOpen = (Id: string) => {
        setIdFormation(Id)
        setOpenConfirmForm(true)
    }

    const onDelete = () => {
        deleteFormations()
        setOpenConfirmForm(false)
    }

    const togglePopup = () => {
        setEstiam({ data: formations, idEstiam: idEstiam })
        setIsOpen(!isOpen)
    }
    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const onEdit = (formation: FormationsElement) => {
        setEstiam({ data: formation, idEstiam: idEstiam })
        setIsOpen(true)
    }

    return (
        <Paper elevation={3} sx={{ padding: '10px' }}>
            <Typography variant="h5" gutterBottom component="div">
                Formations
            </Typography>

            <Grid item xs={12}>
                <Box sx={{ margin: '10px' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        startIcon={<AddIcon />}
                        onClick={togglePopup}
                    >
                        Ajouter une formation
                    </Button>
                </Box>
            </Grid>

            <TableContainer component={Paper}>
                <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>Titre</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Type de formation</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {formations.map((row: any, index: number) => (
                            <TableRow
                                key={index}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell>{row.title}</TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell>{row.formationType}</TableCell>
                                <TableCell align="right">
                                    <IconButton
                                        onClick={() => onEdit(row)}
                                        size="large"
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => handleClickOpen(row._id)}
                                        size="large"
                                    >
                                        <DeleteOutlinedIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage="Lignes par page"
                component="div"
                count={formations?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {isOpen && (
                <AddFormation
                    handleClose={togglePopup}
                    estiam={estiam}
                    isOpen={isOpen}
                    setLoading={setLoading}
                />
            )}

            <ConfirmForm
                onConfirm={onDelete}
                open={openConfirmForm}
                setOpen={setOpenConfirmForm}
                action="delete"
            />
        </Paper>
    )
}

export default Formations
