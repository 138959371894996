import {
    Grid,
    InputLabel,
    IconButton,
    Avatar,
    Typography,
    ClassNameMap,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { Dispatch, SetStateAction } from 'react'
import { DropzoneArea } from 'react-mui-dropzone'
import { JobOfferElement } from 'common/types/job-offer/JobOfferElement'
import JobOfferDescriptions from './JobOfferDescriptions'

interface JobOfferAboutSectionFormProps {
    valueAbout?: string
    setValueAbout: Dispatch<SetStateAction<string | undefined>>
    errorAboutMessage?: string
    file: any
    setFile: Dispatch<any>
    debounceKey: number
    dropzoneClass: ClassNameMap<'dropzone'>
    classes: ClassNameMap<'avatar'>
    jobOffer: JobOfferElement
    avatarView: string
    setAvatarView: Dispatch<SetStateAction<string>>
}

export default function JobOfferAboutSectionForm({
    valueAbout,
    setValueAbout,
    errorAboutMessage,
    file,
    setFile,
    debounceKey,
    dropzoneClass,
    classes,
    jobOffer,
    avatarView,
    setAvatarView,
}: JobOfferAboutSectionFormProps) {
    return (
        <Grid>
            <Box
                p={2}
                sx={{
                    display: 'grid',
                    flexWrap: 'wrap',
                }}
            >
                <InputLabel htmlFor="logo-jobOffer">
                    Image de la section à propos :
                </InputLabel>
                <IconButton component="span" size="large">
                    <Avatar
                        className={classes.avatar}
                        alt={jobOffer?.title}
                        src={
                            file.length === 0 ? jobOffer?.imageUrl : avatarView
                        }
                    />
                </IconButton>
                <DropzoneArea
                    key={debounceKey}
                    acceptedFiles={['image/*']}
                    dropzoneClass={dropzoneClass.dropzone}
                    dropzoneText="Glisser-déposer une image ou rechercher dans les fichiers (max : 150Mo)"
                    maxFileSize={50000000}
                    filesLimit={1}
                    onDrop={async files => {
                        const src = window.URL.createObjectURL(files[0])
                        setAvatarView(src)
                        setFile(files[0])
                    }}
                    showAlerts={true}
                    getDropRejectMessage={resultat =>
                        `Ce fichier est trop grand , taille de fichier : ${resultat.size} bytes, taille max est 5000000 bytes (5Mo)`
                    }
                />
                <Typography variant="caption">
                    (Max : un image de 5Mo max.)
                </Typography>
            </Box>
            <JobOfferDescriptions
                section="about"
                descriptionValue={valueAbout}
                setDescriptionValue={setValueAbout}
                errorMessage={errorAboutMessage}
            />
        </Grid>
    )
}
