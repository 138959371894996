import axios from 'axios'
import useToken from '../../session/userSession'
import { useSectoralBusinessSolutionElements } from '../all/UseSectoralBusinessSolution'
import { SERVER_URL } from '../../../config/environments'
import { render } from '@testing-library/react'
import Toaster from '../../../common/Toaster/Toaster'

const FETCH_ALL_SECTORAL_BUSINESS_SOLUTION_URL = `${SERVER_URL.environment}/sectoral-business-solution`

export function useSectoralBusinessSolutionElement(
    SectoralBusinessSolutionElementId: string,
) {
    const { mutateSectoralBusinessSolutionElements } =
        useSectoralBusinessSolutionElements()
    const { accessToken } = useToken()

    async function updateSectoralBusinessSolutionElement(body: FormData) {
        await axios({
            method: 'PUT',
            url: `${FETCH_ALL_SECTORAL_BUSINESS_SOLUTION_URL}/${SectoralBusinessSolutionElementId}`,
            data: body,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response: any) => {
                mutateSectoralBusinessSolutionElements()
                render(
                    <Toaster
                        success
                        toastMsg="Métier sectoriel modifié avec succès !"
                    />,
                )
                return response.data
            })
            .catch((error: any) => {
                if (error.response.data.message === 'Internal server error') {
                    render(
                        <Toaster
                            error
                            toastMsg="La catégorie doit être unique"
                        />,
                    )
                } else {
                    render(
                        <Toaster
                            error
                            toastMsg={error.response.data.message[0]}
                        />,
                    )
                }
            })
    }
    return { updateSectoralBusinessSolutionElement }
}
