import { Alert } from '@mui/material'

import ReactQuill from 'react-quill'

import 'react-quill/dist/quill.snow.css'
import './CustomEditorText.scss'

type Props = {
    currentValue?: string
    updateCurrentValue: (text: string) => void
    idEditor?: string
}

// Modules object for setting up the Quill editor
export const modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],
        ['link', 'image'],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ direction: 'rtl' }], // text direction

        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [
            {
                font: [],
            },
        ],
        [{ align: [] }],

        ['clean'], // remove formatting button
    ],
    history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
    },
}

// Formats objects for setting up the Quill editor
export const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'align',
    'strike',
    'script',
    'blockquote',
    'background',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
    'code-block',
]

export const EditorText = ({ currentValue, updateCurrentValue }: Props) => {
    const handleChange = (value: string) => {
        updateCurrentValue(value)
    }

    return (
        <div className="text-editor">
            <ReactQuill
                theme="snow"
                value={currentValue ?? ''}
                onChange={handleChange}
                modules={modules}
                formats={formats}
            />
            {(currentValue?.length ?? 0) > 1048612 && (
                <Alert sx={{ marginTop: 2 }} severity="error">
                    Le nombre de caractères maximum est 1048612 caractères !
                </Alert>
            )}
        </div>
    )
}

export default EditorText
