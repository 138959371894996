import React, { useEffect } from 'react'
import { Lab } from '../../common/types/lab/Lab'
import { LabElements } from './LabElement'
import { Grid, Box } from '@mui/material'

import LabMetaView from '../../common/view/view'
import { useLabElements } from '../../hooks/lab/all/useLab'
import { useLab } from 'hooks/lab/single/useLab'

export default function LabView() {
    const { labElements } = useLabElements()

    const { create } = useLab()

    useEffect(() => {
        async function createEmptyLab() {
            const labForm = new FormData()

            labForm.append('title', 'Titre 42C LAB')

            await create(labForm)
        }

        if (labElements?.length === 0) {
            createEmptyLab()
        }
    }, [labElements])

    return (
        <div>
            <Grid item xs={12}>
                <Box mb={2}>
                    <LabMetaView useMeta category="LAB" />
                </Box>
            </Grid>
            {labElements?.map((row: Lab) => (
                <LabElements row={row} key={row._id} />
            ))}
        </div>
    )
}
