import React from 'react'
import {
    CardContent,
    Typography,
    Card,
    CardHeader,
    CardMedia,
    CardActions,
    IconButton,
} from '@mui/material'
import FavoriteIcon from '@mui/icons-material/Favorite'

import { pink } from '@mui/material/colors'
import { TechnicalSolution } from 'common/types/technical-solution/TechnicalSolution'

const FavoriteTechnicalSolutionCard = (props: {
    technicalSolution: TechnicalSolution
}) => {
    const { technicalSolution } = props

    return (
        <div>
            <Card sx={{ maxWidth: 345 }}>
                <CardHeader
                    title={technicalSolution.title}
                    titleTypographyProps={{ variant: 'h6' }}
                    subheader={technicalSolution.subTitle}
                />
                <CardMedia
                    sx={{
                        borderTop: '1px solid #f9a6a6',
                        borderBottom: '1px solid #f9a6a6',
                    }}
                    image={technicalSolution.imageUrl}
                    alt={technicalSolution.title}
                    component="img"
                    height="194"
                />
                <CardContent>
                    {technicalSolution.category && (
                        <div>
                            <Typography>
                                <b>Catégorie :</b>
                            </Typography>
                            <Typography
                                dangerouslySetInnerHTML={{
                                    __html: technicalSolution.category,
                                }}
                            />
                        </div>
                    )}
                    {technicalSolution.description && (
                        <div>
                            <Typography>
                                <b>Description :</b>
                            </Typography>
                            <Typography
                                dangerouslySetInnerHTML={{
                                    __html: technicalSolution.description
                                        .slice(0, 50)
                                        .concat('...'),
                                }}
                            />
                        </div>
                    )}
                </CardContent>
                <CardActions disableSpacing>
                    <IconButton aria-label="Adit job offer" size="large">
                        <FavoriteIcon sx={{ color: pink[500] }} />
                    </IconButton>
                </CardActions>
            </Card>
        </div>
    )
}
export default FavoriteTechnicalSolutionCard
