import React from 'react'
import { Grid } from '@mui/material'
import MatchingCVView from '../../common/view/view'

export default function MatchingCV() {
    return (
        <div>
            <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
                data-aos="fade-right"
            >
                <Grid item xs={12}>
                    <MatchingCVView useMeta category="MATCHING_CV" />
                </Grid>
            </Grid>
        </div>
    )
}
