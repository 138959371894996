import React from 'react'
import CustomEditItem from '../../common/custom-edit-item/CustomEditItem'

export default function TechnicalSolutions() {
    return (
        <CustomEditItem
            useDescription
            category="technical-solution"
            id={window.location.pathname.split('/')[2]}
        />
    )
}
