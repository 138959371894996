import { useEffect, useState } from 'react'
import Aos from 'aos'
import Grid from '@mui/material/Grid'

import ContactView from '../../common/view/view'

import { DetailContact } from './detail-contact/DetailContact'
import { ListContact } from './list-contact/ListContact'
import { useContactsElements } from 'hooks/contact/all/UseContact'
import { ContactElement } from 'common/types/contact/ContactElement'

export default function Contact() {
    const { contactsElements } = useContactsElements()
    const [isOpen, setIsOpen] = useState(false)
    const [contact, setContact] = useState<ContactElement>()

    useEffect(() => {
        Aos.init()
    }, [])

    return (
        <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
            data-aos="fade-right"
        >
            <Grid item xs={12}>
                <ContactView
                    useDescription
                    useTitle
                    useMeta
                    category="CONTACT"
                />
            </Grid>

            <Grid item xs={12}>
                <ListContact
                    contacts={contactsElements}
                    setContact={setContact}
                    setIsOpen={setIsOpen}
                />
            </Grid>

            {isOpen && (
                <DetailContact
                    contact={contact}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                />
            )}
        </Grid>
    )
}
