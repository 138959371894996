import React from 'react'
import CustomEditItem from '../../common/custom-edit-item/CustomEditItem'

export default function SecteursActivites() {
    return (
        <CustomEditItem
            useDescription
            category="activity-sector"
            id={window.location.pathname.split('/')[2]}
        />
    )
}
