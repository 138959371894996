import React from 'react'
import { Container } from '@mui/material'
import HomeMetaView from '../../common/view/view'
export default function HomeView() {
    return (
        <Container component="section" maxWidth={false} disableGutters>
            <HomeMetaView useMeta category="HOME" />
        </Container>
    )
}
