import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Card,
    CardContent,
    Box,
} from '@mui/material'

import { render } from '@testing-library/react'
import { useForm } from 'react-hook-form'

import { object, string } from 'yup'

import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'

import Toaster from 'common/Toaster/Toaster'

import { useQuestionResponseElement } from 'hooks/job-offer/question-response/UseQuestionResponse'
import ProgressBar from 'common/progress-bar/ProgressBar'
import SaveButton from 'common/custom-button/SaveButton'
import { JobOfferElement } from 'common/types/job-offer/JobOfferElement'
import { QuestionResponseElement } from 'common/types/job-offer/question-response'

interface Props {
    _id?: string
    question: string
    response: string
}

const schema = object({
    question: string(),
    response: string(),
})

interface FaqPopupFormProps {
    handleClose: () => void
    isOpen: boolean
    jobOffer: JobOfferElement
    questionResponseId?: string | null
    questionResponseElm?: QuestionResponseElement | null
}

export function FaqPopupForm({
    handleClose,
    isOpen,
    jobOffer,
    questionResponseId,
    questionResponseElm,
}: FaqPopupFormProps) {
    const [loading, setLoading] = useState(false)

    const { addQuestionResponse, editQuestionResponse } =
        useQuestionResponseElement(jobOffer._id)

    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<Props>({ resolver: yupResolver(schema) })

    async function onSubmit() {
        try {
            addQuestionsResponse(getValues('question'), getValues('response'))
        } catch (err: any) {
            render(<Toaster error toastMsg={err.response} />)
        }
    }

    const addQuestionsResponse = async (question: string, response: string) => {
        try {
            const bodyFormData = new URLSearchParams()
            setLoading(true)

            bodyFormData.append('question', question ? question : '')
            bodyFormData.append('response', response ? response : '')

            if (questionResponseId != null) {
                editQuestionResponse(bodyFormData, questionResponseId)
                    .then(res => {
                        if (res === 'OK') {
                            handleClose()
                        }
                    })
                    .finally(() => setLoading(false))
            } else {
                addQuestionResponse(bodyFormData)
                    .then(res => {
                        if (res === 'Created') {
                            handleClose()
                        }
                    })
                    .finally(() => setLoading(false))
            }
        } catch (error: any) {
            setLoading(false)
            render(<Toaster error toastMsg={error?.response?.data.message} />)
        }
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
            >
                <DialogTitle>
                    {'Ajouter une question et une réponse'}
                </DialogTitle>
                <DialogContent>
                    <Card>
                        <CardContent>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControl
                                    sx={{ m: 1, width: '65ch' }}
                                    variant="outlined"
                                >
                                    <InputLabel htmlFor="question-question-response">
                                        Question
                                    </InputLabel>
                                    <OutlinedInput
                                        required
                                        fullWidth
                                        label="question"
                                        id="question-question-response"
                                        {...register('question')}
                                        defaultValue={
                                            questionResponseElm?.question
                                        }
                                        onChange={e =>
                                            setValue('question', e.target.value)
                                        }
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                    <FormHelperText id="sector-job-offer-weight-helper-text">
                                        {errors?.question?.message}
                                    </FormHelperText>
                                </FormControl>
                                <FormControl
                                    sx={{ m: 1, width: '65ch' }}
                                    variant="outlined"
                                >
                                    <InputLabel htmlFor="response-question-response">
                                        Réponse
                                    </InputLabel>
                                    <OutlinedInput
                                        required
                                        fullWidth
                                        id="response-question-response"
                                        label="Réponse"
                                        {...register('response')}
                                        defaultValue={
                                            questionResponseElm?.response
                                        }
                                        onChange={e =>
                                            setValue('response', e.target.value)
                                        }
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                    <FormHelperText id="sector-job-offer-weight-helper-text">
                                        {errors?.response?.message}
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                        </CardContent>
                    </Card>
                </DialogContent>

                <SaveButton {...{ handleClose }} />
            </form>

            <ProgressBar loading={loading} />
        </Dialog>
    )
}
