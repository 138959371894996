import useSWR from 'swr'
import { SectoralBusinessSolutionElements } from '../../../common/types/sectoral-business-solution/SectoralBusinessSolutionElements'
import { SERVER_URL } from '../../../config/environments'

const FETCH_ALL_SECTORAL_BUSINESS_SOLUTION_URL = `${SERVER_URL.environment}/sectoral-business-solution`

export function useSectoralBusinessSolutionElements() {
    const {
        data: sectoralBusinessSolutionElements,
        mutate: mutateSectoralBusinessSolutionElements,
    } = useSWR<SectoralBusinessSolutionElements[]>(
        'api/sectoral-business-solution/all',
        fetchAllSectoralBusinessSolution,
    )

    async function fetchAllSectoralBusinessSolution() {
        const response = await fetch(
            `${FETCH_ALL_SECTORAL_BUSINESS_SOLUTION_URL}/all`,
        )
        return response.json()
    }
    return {
        sectoralBusinessSolutionElements,
        mutateSectoralBusinessSolutionElements,
    }
}
