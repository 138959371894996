import React, { useState, useEffect } from 'react'
import Aos from 'aos'

import {
    Box,
    Button,
    Card,
    Container,
    Grid,
    TablePagination,
} from '@mui/material'

import AddIcon from '@mui/icons-material/Add'

import makeStyles from '@mui/styles/makeStyles'
import './TalkingAboutUs.scss'

import TalkingAboutUsCard from './talkingAboutUsCard/TalkingAboutUsCard'
import { useAllTalkingAboutUs } from 'hooks/talking-about-us/get-all/useAllTalkingAboutUs'
import { PopupAddTalkingAboutUs } from './talkingAboutUsDialog/PopupAddTalkingAboutUs'
import TalkingAboutUsView from '../../common/view/view'

const useStyle = makeStyles({
    saveButton: {
        background: 'linear-gradient(25deg, #FF0033, #FF5500)',
        color: '#fff',
        borderRadius: 'unset',
        width: '350px',
        height: '30px',
        float: 'right',
        marginBottom: '10px',
    },
})

const PageTalkingAboutUs = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [singleTalkingAboutUs, setSingleTalkingAboutUs] = useState<any>(null)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(4)

    const { talkingAboutUs } = useAllTalkingAboutUs()

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const togglePopup = () => {
        setSingleTalkingAboutUs(null)
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        Aos.init()
    }, [])

    const classes = useStyle()

    return (
        <>
            <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
                data-aos="fade-right"
            >
                <Grid item xs={12}>
                    <TalkingAboutUsView
                        useDescription
                        useTitle
                        useMeta
                        category="TALKING_ABOUT_US"
                    />
                </Grid>
            </Grid>
            <Container component="section" maxWidth={false} disableGutters>
                <main>
                    <Container>
                        <Grid container>
                            <Box mt="30px">
                                <Button
                                    className={classes.saveButton}
                                    variant="contained"
                                    startIcon={<AddIcon />}
                                    onClick={togglePopup}
                                >
                                    Ajouter un article
                                </Button>
                            </Box>
                        </Grid>
                    </Container>
                </main>
                {talkingAboutUs ? (
                    <>
                        <main>
                            <Container sx={{ py: 4, width: '100%' }}>
                                <Grid container spacing={4}>
                                    {talkingAboutUs
                                        ?.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage,
                                        )
                                        .map((talkingAboutUs: any) => (
                                            <Grid
                                                item
                                                xs={12}
                                                md={4}
                                                lg={3}
                                                key={talkingAboutUs._id}
                                            >
                                                <TalkingAboutUsCard
                                                    talkingAboutUs={
                                                        talkingAboutUs
                                                    }
                                                    setSingleTalkingAboutUs={
                                                        setSingleTalkingAboutUs
                                                    }
                                                    setIsOpen={setIsOpen}
                                                />
                                            </Grid>
                                        ))}
                                </Grid>
                            </Container>
                        </main>

                        <Card>
                            <TablePagination
                                rowsPerPageOptions={[4, 5, 10, 25, 100]}
                                labelRowsPerPage="Lignes par page"
                                component="div"
                                count={talkingAboutUs?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Card>
                    </>
                ) : (
                    <h2>Aucune donnée trouvée</h2>
                )}
            </Container>

            {isOpen && (
                <PopupAddTalkingAboutUs
                    handleClose={togglePopup}
                    isOpen={isOpen}
                    singleTalkingAboutUs={singleTalkingAboutUs}
                />
            )}
        </>
    )
}
export default PageTalkingAboutUs
