import React from 'react'
import {
    IconButton,
    TableCell,
    TableRow,
    Alert,
    Chip,
    Avatar,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Draggable } from 'react-beautiful-dnd'
import { ExpertElement } from 'common/types/expert/ExpertElement'
import { NavbarElement } from 'common/types/navbar-element/NavbarElement'

type Props = {
    navbar?: boolean
    experts?: boolean
    element: NavbarElement | ExpertElement
    onEditNavbar?: (element: NavbarElement) => void
    onEditExpert?: (element: ExpertElement) => void
    onDeleteClicked: (id: string) => void
    index: number
}

export default function DraggableListItem({
    element,
    onEditExpert,
    onEditNavbar,
    onDeleteClicked,
    index,
    navbar,
    experts,
}: Props) {
    return (
        <Draggable draggableId={element._id} index={index}>
            {(provided, snapshot) => (
                <TableRow
                    sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        userSelect: 'none',
                        background: snapshot.isDragging
                            ? 'rgba(255, 85, 0, 0.3)'
                            : '',
                        left: 'auto !important',
                        top: 'auto !important',
                    }}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    {navbar && 'title' in element && (
                        <>
                            <TableCell component="th" scope="row">
                                {element.title}
                            </TableCell>
                            <TableCell align="left">{element.link}</TableCell>
                        </>
                    )}
                    {experts && 'sector' in element && (
                        <>
                            <TableCell component="th" scope="row">
                                <Avatar
                                    alt={element.firstName}
                                    src={element.imageUrl}
                                />
                            </TableCell>
                            <TableCell align="left">
                                {element.firstName}
                            </TableCell>
                            <TableCell align="left">
                                {element.lastName}
                            </TableCell>
                            <TableCell align="left">{element.poste}</TableCell>
                            <TableCell align="left">{element.sector}</TableCell>
                        </>
                    )}

                    <TableCell align="left">
                        {!element.order ? (
                            <Alert severity="error">Non ordonné</Alert>
                        ) : (
                            <Chip
                                label={element.order}
                                color="primary"
                                variant="outlined"
                            />
                        )}
                    </TableCell>
                    <TableCell align="right">
                        <IconButton
                            onClick={() => {
                                navbar
                                    ? onEditNavbar &&
                                      onEditNavbar(element as NavbarElement)
                                    : experts &&
                                      onEditExpert &&
                                      onEditExpert(element as ExpertElement)
                            }}
                            size="large"
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => onDeleteClicked(element._id)}
                            size="large"
                        >
                            <DeleteOutlinedIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
            )}
        </Draggable>
    )
}
