import { Grid } from '@mui/material'
import React from 'react'
import ParticleImage, {
    ParticleOptions,
    Vector,
    forces,
    ParticleForce,
} from 'react-particle-image'
import useWindowSize from '@rooks/use-window-size'

const particleOptions: ParticleOptions = {
    filter: ({ x, y, image }) => {
        const pixel = image.get(x, y)
        return pixel.b > 50
    },
    color: () => '#FF5500',
    radius: () => Math.random() + 0.5,
    mass: () => 60,
    friction: () => 0.15,
    initialPosition: ({ canvasDimensions }) => {
        return new Vector(
            canvasDimensions.width / 2,
            canvasDimensions.height / 2,
        )
    },
}

const motionForce = (x: number, y: number): ParticleForce => {
    return forces.disturbance(x, y, 5)
}

export default function Welcome() {
    const { innerWidth, innerHeight } = useWindowSize()

    return (
        <Grid container justifyContent="center" alignItems="center">
            <ParticleImage
                src={'/42c_degrade_fond_transparent.png'}
                scale={0.75}
                entropy={30}
                maxParticles={6000}
                particleOptions={particleOptions}
                backgroundColor="transparent"
                width={Number(innerWidth) / 1.5}
                height={Number(innerHeight) / 1.5}
                mouseMoveForce={motionForce}
                touchMoveForce={motionForce}
            />
        </Grid>
    )
}
