/* eslint-disable react-hooks/rules-of-hooks */
import dotenv from 'dotenv'
import useCurrentEnv from '../hooks/session/useCurrentEnv'

const { isLocalEnv, isStagingEnv, isProdEnv } = useCurrentEnv()

const urls = {
    local: 'http://127.0.0.1:3050',
    develop: 'https://api.develop.42c.42cloud.io',
    staging: 'https://api.staging.42c.42cloud.io',
    prod: 'https://api.42c.fr',
}

dotenv.config()

const { REACT_APP_PrivateKey } = process.env

export default {
    REACT_APP_PrivateKey,
}

export const SERVER_URL = {
    environment: isProdEnv()
        ? urls.prod
        : isStagingEnv()
        ? urls.staging
        : isLocalEnv()
        ? urls.local
        : urls.develop,
}
