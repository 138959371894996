/* eslint-disable consistent-return */
import React, { useState, useContext } from 'react'
import {
    Theme,
    CssBaseline,
    Drawer,
    Avatar,
    List,
    ListItem,
    ListItemIcon,
    Collapse,
    ListItemText,
    AppBar,
    Box,
    IconButton,
    Typography,
    Button,
    Toolbar,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { useHistory, useLocation } from 'react-router-dom'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { FcDataConfiguration } from 'react-icons/fc'
import { FcAddDatabase } from 'react-icons/fc'
import { FcSupport } from 'react-icons/fc'
import { BsFillHeartFill } from 'react-icons/bs'
import { BiLogOut } from 'react-icons/bi'
import MenuIcon from '@mui/icons-material/Menu'
import { FaLaptop, FaTabletAlt, FaMobileAlt } from 'react-icons/fa'
import { AuthContext } from '../../context/auth-context'
import useWindowSize from '../../hooks/WindowSize/useWindowSize'
import { useActivitySectorElements } from '../../hooks/activity-sector/all/useActivitySector'
import { useSectoralBusinessSolutionElements } from '../../hooks/sectoral-business-solution/all/UseSectoralBusinessSolution'
import { useSolutionTechniqueElements } from '../../hooks/solution-technique/all/useSolutionTechnique'
import { ActivitySectorElement } from '../types/activity-sector/ActivitySectorElements'
import { SectoralBusinessSolutionElements } from '../types/sectoral-business-solution/SectoralBusinessSolutionElements'
import { TechnicalSolution } from '../types/technical-solution/TechnicalSolution'
import Logo42 from '../../image/42c_rond _degrade.png'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        date: {
            flexGrow: 2,
            fontSize: theme.spacing(2),
            textTransform: 'capitalize',
            fontWeight: 'bold',
        },
        avatar: {
            width: theme.spacing(17),
            height: theme.spacing(17),
            margin: 'auto',
            marginBottom: theme.spacing(6),
            marginTop: theme.spacing(6),
            '@media (max-width: 868px)': {
                width: theme.spacing(14),
                height: theme.spacing(14),
            },
        },
        active: {
            background: 'linear-gradient(25deg, #FF0033, #FF5500);',
            paddingLeft: theme.spacing(2),
        },
        button: {
            margin: theme.spacing(1),
            '@media (max-width: 868px)': {
                width: theme.spacing(11),
                height: theme.spacing(4),
                fontSize: theme.spacing(1.5),
            },
        },
    }),
)

interface Props {
    // eslint-disable-next-line react/require-default-props
    window?: () => Window
}

const menuItemsHomePage = [
    { text: 'SEO', path: '/home' },
    {
        text: 'Chiffres clés',
        path: '/second-banderole',
    },
    {
        text: 'Métiers Historiques',
        path: '/activity-sector-favorite',
    },
    {
        text: 'Multi-solutions',
        path: '/technical-solution-favorite',
    },
    {
        text: 'Success stories',
        path: '/success-stories',
    },
    {
        text: "Offres d'emploi",
        path: '/offres-emploi',
    },
]

const menuItemsPages = [
    {
        text: 'Barre de navigation',
        path: '/navbar',
        submenus: false,
    },
    {
        text: 'Banderoles',
        path: '/banderole',
    },
    {
        text: 'Multi-solutions',
        path: '/technical-solution',
        submenus: true,
    },
    {
        text: "Secteurs d'activités",
        path: '/activity-sector',
        submenus: true,
    },
    {
        text: 'Clients',
        path: '/nous-font-confiance',
        submenus: false,
    },
    {
        text: 'Les experts',
        path: '/les-experts',
        submenus: false,
    },
    {
        text: 'Le Lab',
        path: '/lab',
        submenus: false,
    },
    {
        text: 'Notre école',
        path: '/estiam',
        submenus: false,
    },
    { text: 'Success stories', path: '/list-success-stories', submenus: false },
    {
        text: 'On parle de nous',
        path: '/list-talking-about-us',
        submenus: false,
    },
    {
        text: 'Qui sommes-nous',
        path: '/join-us',
        submenus: false,
    },
    {
        text: "Offres d'emploi",
        path: '/list-offres-emploi',
        submenus: false,
    },
    {
        text: 'Candidatures',
        path: '/postuler',
        submenus: false,
    },
    {
        text: 'Matching de CV',
        path: '/matching-cv',
        submenus: false,
    },
    {
        text: 'Carte 42c',
        path: '/map-markers',
        submenus: false,
    },
    {
        text: 'Contact',
        path: '/contact',
        submenus: false,
    },
    {
        text: 'Mentions Légales',
        path: '/mentions-legales',
        submenus: false,
    },

    {
        text: 'CGU',
        path: '/cgu',
        submenus: false,
    },
]

export default function Settings(props: Props) {
    const { window } = props
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const { width } = useWindowSize()

    const container =
        window !== undefined ? () => window().document.body : undefined
    const auth: any = useContext(AuthContext)

    const [openSettingHome, setOpenSettingHome] = useState(false)
    const [mobileOpen, setMobileOpen] = useState(false)
    const [openCurrentItem, setOpenCurrentItem] = useState(false)
    const [currentItem, setCurrentItem] = useState<string>('')

    const handleLogout = () => {
        auth.logout()
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }
    const handleClickSettingHome = () => {
        setOpenSettingHome(!openSettingHome)
    }

    const handleClickCurrentItem = (path: string) => {
        setOpenCurrentItem(!openCurrentItem)
        return path === currentItem ? setCurrentItem('') : setCurrentItem(path)
    }

    const { activitySectorElements } = useActivitySectorElements()
    const { sectoralBusinessSolutionElements } =
        useSectoralBusinessSolutionElements()
    const { solutionTechniqueElements } = useSolutionTechniqueElements()

    const drawer = (
        <div>
            <Avatar
                className={classes.avatar}
                alt="Logo42"
                src={`${Logo42}`}
                onClick={() => history.push('/')}
            />
            {/* links/list section */}
            <List
                style={{
                    backgroundColor: '#5C626A',
                    color: '#FFFFFF',
                    height: '100%',
                }}
            >
                <ListItem button onClick={handleClickSettingHome}>
                    <ListItemText primary="Page d'accueil" />
                    {openSettingHome ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openSettingHome} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {menuItemsHomePage.map(item => (
                            <ListItem
                                button
                                key={item.text}
                                onClick={() => {
                                    history.push(item.path)
                                }}
                                className={
                                    location.pathname === item.path
                                        ? classes.active
                                        : 'null'
                                }
                            >
                                <ListItemIcon>
                                    <FcSupport />
                                </ListItemIcon>
                                <ListItemText primary={item.text} />
                            </ListItem>
                        ))}
                    </List>
                </Collapse>

                {menuItemsPages?.map((item: any) => (
                    <div key={item.text}>
                        {!item.submenus && (
                            <ListItem
                                button
                                className={
                                    location.pathname === item.path
                                        ? classes.active
                                        : 'null'
                                }
                                onClick={() => {
                                    history.push(item.path)
                                }}
                            >
                                <ListItemText primary={item.text} />
                            </ListItem>
                        )}

                        {item.submenus && (
                            <div>
                                <ListItem
                                    button
                                    key={item.text}
                                    onClick={() =>
                                        handleClickCurrentItem(item.path)
                                    }
                                >
                                    <ListItemText primary={item.text} />
                                    {item.path === currentItem ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItem>

                                {currentItem === '/activity-sector' && (
                                    <Collapse
                                        in={item.path === currentItem}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <List component="div" disablePadding>
                                            <ListItem
                                                button
                                                key={'activity-sector'}
                                                className={
                                                    location.pathname ===
                                                    `/activity-sector`
                                                        ? classes.active
                                                        : 'null'
                                                }
                                                onClick={() => {
                                                    history.push(
                                                        `/activity-sector`,
                                                    )
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <FcAddDatabase />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={'En-tête de page'}
                                                />
                                            </ListItem>
                                            <ListItem
                                                button
                                                key={'add-activity-sector'}
                                                className={
                                                    location.pathname ===
                                                    `/add/activity-sector`
                                                        ? classes.active
                                                        : 'null'
                                                }
                                                onClick={() => {
                                                    history.push(
                                                        `/add/activity-sector`,
                                                    )
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <FcAddDatabase />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        "Ajouter un secteur d'activité"
                                                    }
                                                />
                                            </ListItem>
                                            {activitySectorElements?.map(
                                                (i: ActivitySectorElement) => (
                                                    <ListItem
                                                        button
                                                        key={i._id}
                                                        className={
                                                            location.pathname ===
                                                            `${item.path}/${i._id}`
                                                                ? classes.active
                                                                : 'null'
                                                        }
                                                        onClick={() => {
                                                            history.push(
                                                                `${item.path}/${i._id}`,
                                                            )
                                                        }}
                                                    >
                                                        {i.isFavorite ? (
                                                            <ListItemIcon>
                                                                <BsFillHeartFill color="white" />
                                                            </ListItemIcon>
                                                        ) : (
                                                            <ListItemIcon>
                                                                <FcDataConfiguration />
                                                            </ListItemIcon>
                                                        )}
                                                        <ListItemText
                                                            primary={i.title}
                                                        />
                                                    </ListItem>
                                                ),
                                            )}
                                        </List>
                                    </Collapse>
                                )}

                                {currentItem === '/nos-metiers' && (
                                    <Collapse
                                        in={item.path === currentItem}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <ListItem
                                            button
                                            key={'add-nos-metiers'}
                                            className={
                                                location.pathname ===
                                                `/add/nos-metiers`
                                                    ? classes.active
                                                    : 'null'
                                            }
                                            onClick={() => {
                                                history.push(`/add/nos-metiers`)
                                            }}
                                        >
                                            <ListItemIcon>
                                                <FcAddDatabase />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    'Ajouter un métier sectoriel'
                                                }
                                            />
                                        </ListItem>
                                        <List component="div" disablePadding>
                                            {sectoralBusinessSolutionElements?.map(
                                                (
                                                    i: SectoralBusinessSolutionElements,
                                                ) => (
                                                    <ListItem
                                                        button
                                                        key={i._id}
                                                        className={
                                                            location.pathname ===
                                                            `${item.path}/${i._id}`
                                                                ? classes.active
                                                                : 'null'
                                                        }
                                                        onClick={() => {
                                                            history.push(
                                                                `${item.path}/${i._id}`,
                                                            )
                                                        }}
                                                    >
                                                        {i.isFavorite ? (
                                                            <ListItemIcon>
                                                                <BsFillHeartFill color="white" />
                                                            </ListItemIcon>
                                                        ) : (
                                                            <ListItemIcon>
                                                                <FcDataConfiguration />
                                                            </ListItemIcon>
                                                        )}
                                                        <ListItemText
                                                            primary={i.title}
                                                        />
                                                    </ListItem>
                                                ),
                                            )}
                                        </List>
                                    </Collapse>
                                )}

                                {currentItem === '/technical-solution' && (
                                    <Collapse
                                        in={item.path === currentItem}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <ListItem
                                            button
                                            key={'technical-solution'}
                                            className={
                                                location.pathname ===
                                                `/technical-solution-meta`
                                                    ? classes.active
                                                    : 'null'
                                            }
                                            onClick={() => {
                                                history.push(
                                                    `/technical-solution-meta`,
                                                )
                                            }}
                                        >
                                            <ListItemIcon>
                                                <FcAddDatabase />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={'En-tête de page'}
                                            />
                                        </ListItem>
                                        <ListItem
                                            button
                                            key={'add-technical-solution'}
                                            className={
                                                location.pathname ===
                                                `/add/technical-solution`
                                                    ? classes.active
                                                    : 'null'
                                            }
                                            onClick={() => {
                                                history.push(
                                                    `/add/technical-solution`,
                                                )
                                            }}
                                        >
                                            <ListItemIcon>
                                                <FcAddDatabase />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    'Ajouter une multi-solution'
                                                }
                                            />
                                        </ListItem>
                                        <List component="div" disablePadding>
                                            {solutionTechniqueElements?.map(
                                                (i: TechnicalSolution) => (
                                                    <ListItem
                                                        button
                                                        key={i._id}
                                                        className={
                                                            location.pathname ===
                                                            `${item.path}/${i._id}`
                                                                ? classes.active
                                                                : 'null'
                                                        }
                                                        onClick={() => {
                                                            history.push(
                                                                `${item.path}/${i._id}`,
                                                            )
                                                        }}
                                                    >
                                                        <ListItemIcon>
                                                            <FcDataConfiguration />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={i.title}
                                                        />
                                                    </ListItem>
                                                ),
                                            )}
                                        </List>
                                    </Collapse>
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </List>
        </div>
    )

    return (
        <div>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    background: 'linear-gradient(25deg, #FF0033, #FF5500);',
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                        size="large"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography className={classes.date}>
                        {new Date().toLocaleDateString('fr-FR', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            weekday: 'long',
                        })}
                    </Typography>
                    <Typography m={2}>
                        {width < 768 ? (
                            <FaMobileAlt />
                        ) : width < 1025 ? (
                            <FaTabletAlt />
                        ) : (
                            <FaLaptop />
                        )}
                    </Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        endIcon={<BiLogOut />}
                        onClick={handleLogout}
                    >
                        LOGOUT
                    </Button>

                    <Typography m={2} fontWeight="bold">
                        42c
                    </Typography>
                </Toolbar>
            </AppBar>

            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
        </div>
    )
}
