import React, { useEffect, useState } from 'react'
import {
    Avatar,
    Button,
    Card,
    CardHeader,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    OutlinedInput,
    Paper,
    TablePagination,
    Typography,
    IconButton,
    TableRow,
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableBody,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Box } from '@mui/system'
import AddIcon from '@mui/icons-material/Add'
import { AddJobs } from './add-jobs/AddJobs'
import { Job } from 'common/types/join-us/JoinUs'
import ConfirmForm from 'common/confirm-form/ConfirmForm'
import { useForm } from 'react-hook-form'
import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useOurJobs } from 'hooks/join-us/our-jobs/UseOurJobs'
import { useJoinUs } from 'hooks/join-us/UseJoinUs'
import EditorText from 'common/custom-text-editor/CustomEditorText'

interface Props {
    _id?: string
    title: string
    description: string
}

const schema = object({
    title: string(),
    description: string(),
})

const OurJob = ({ ourJob, idJoinUs, setLoading }: any) => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [isOpen, setIsOpen] = useState(false)
    const [joinUs, setJoinUs] = useState<any>()
    const [idOurJob, setIdOurJob] = useState('')
    const [openConfirmForm, setOpenConfirmForm] = useState(false)
    const [valueTitle, setValueTitle] = useState('')
    const [valueDescription, setValueDescription] = useState<string>('')

    const { createJoinUs, updateJoinUs } = useJoinUs()
    const { deleteJob } = useOurJobs()

    const handleClickOpen = (id: string) => {
        setIdOurJob(id)
        setOpenConfirmForm(true)
    }

    const onDelete = () => {
        deleteJob(idJoinUs, idOurJob)
        setOpenConfirmForm(false)
    }

    const togglePopup = () => {
        setJoinUs({ data: ourJob, idJoinUs: idJoinUs })
        setIsOpen(!isOpen)
    }

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const onEdit = (ourJob: Job) => {
        setJoinUs({ data: ourJob, idJoinUs: idJoinUs })
        setIsOpen(true)
    }

    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<Props>({ resolver: yupResolver(schema) })

    function onSubmit() {
        setLoading(true)
        addTestimonys(getValues('title')).finally(() => setLoading(false))
    }

    const addTestimonys = async (title?: string) => {
        if (!idJoinUs) {
            const data = new FormData()
            data.append('title', 'Nous rejoindre')
            idJoinUs = await createJoinUs(data).then(
                (response: any) => response._id,
            )
        }

        const bodyFormData = new FormData()

        if (title !== undefined) {
            bodyFormData.append('ourJobs.title', title)
        }

        if (valueDescription !== undefined) {
            bodyFormData.append('ourJobs.description', valueDescription)
        }

        await updateJoinUs(idJoinUs, bodyFormData)
    }

    useEffect(() => {
        setValueDescription(ourJob?.description)
        setTimeout(() => {
            setIsOpen(false)
        }, 5000)
    }, [])

    useEffect(() => {
        setValueTitle(ourJob.title)
        setValueDescription(ourJob.description || '')
    }, [ourJob])

    return (
        <Paper elevation={3} sx={{ padding: '10px' }}>
            <Grid item xs={12}>
                <Box sx={{ margin: '10px' }}>
                    <Typography variant="h5">Les métiers</Typography>
                </Box>
            </Grid>

            <Grid item xs={12} mb={2}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    autoComplete="off"
                >
                    <Card>
                        <CardHeader
                            title={
                                <div>
                                    <FormControl
                                        sx={{ m: 1, width: '42ch' }}
                                        variant="outlined"
                                    >
                                        <InputLabel
                                            required
                                            htmlFor="title-joinUs"
                                        >
                                            Titre
                                        </InputLabel>
                                        <OutlinedInput
                                            required
                                            fullWidth
                                            label="Titre"
                                            id="title-joinUs"
                                            {...register('title')}
                                            value={valueTitle}
                                            onChange={e => {
                                                setValueTitle(e.target.value)
                                                setValue(
                                                    'title',
                                                    e.target.value,
                                                )
                                            }}
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                        <FormHelperText id="title-weight-helper-text">
                                            {errors?.title?.message}
                                        </FormHelperText>
                                    </FormControl>

                                    <Grid>
                                        <Box>
                                            <Typography variant="h6">
                                                Description :
                                            </Typography>
                                        </Box>
                                        <Box sx={{ pb: 1 }}>
                                            <EditorText
                                                currentValue={valueDescription}
                                                updateCurrentValue={
                                                    setValueDescription
                                                }
                                            />

                                            <Typography variant="caption">
                                                (Max : un paragraphe de 100 000
                                                caractères max.)
                                            </Typography>
                                        </Box>
                                        <span className="error">
                                            {errors?.description?.message}
                                        </span>
                                    </Grid>
                                </div>
                            }
                            subheader={
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    type="submit"
                                >
                                    ENREGISTRER
                                </Button>
                            }
                        />
                    </Card>
                </form>
            </Grid>

            <Grid item xs={12}>
                <Box sx={{ margin: '10px' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        startIcon={<AddIcon />}
                        onClick={togglePopup}
                    >
                        Ajouter
                    </Button>
                </Box>
            </Grid>

            <TableContainer component={Paper}>
                <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>Icône</TableCell>
                            <TableCell>Titre</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ourJob &&
                            ourJob?.jobs.map((row: any, index: number) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell>
                                        <Avatar
                                            alt={row.title}
                                            src={row.imageUrl}
                                        />
                                    </TableCell>
                                    <TableCell>{row.title}</TableCell>
                                    <TableCell align="right">
                                        <IconButton
                                            onClick={() => onEdit(row)}
                                            size="large"
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() =>
                                                handleClickOpen(row._id)
                                            }
                                            size="large"
                                        >
                                            <DeleteOutlinedIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage="Lignes par page"
                component="div"
                count={ourJob?.jobs?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {isOpen && (
                <AddJobs
                    handleClose={togglePopup}
                    joinUs={joinUs}
                    isOpen={isOpen}
                    setLoading={setLoading}
                />
            )}

            <ConfirmForm
                onConfirm={onDelete}
                open={openConfirmForm}
                setOpen={setOpenConfirmForm}
                action="delete"
            />
        </Paper>
    )
}

export default OurJob
