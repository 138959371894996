import { render } from '@testing-library/react'
import useToken from '../../session/userSession'
import Toaster from '../../../common/Toaster/Toaster'
import axios from 'axios'
import { useSolutionTechniqueElements } from '../all/useSolutionTechnique'
import { SERVER_URL } from '../../../config/environments'
import { useHistory } from 'react-router-dom'

const FETCH_ADD_SOLUTION_TECHNIQUE_URL = `${SERVER_URL.environment}/technical-solution`

export function useAddTechnicalSolutionElement() {
    const { mutateSolutionTechniqueElements } = useSolutionTechniqueElements()
    const { accessToken } = useToken()
    const history = useHistory()
    let data = ''

    async function AddTechnicalSolutionElement(body: FormData) {
        await axios({
            method: 'POST',
            url: `${FETCH_ADD_SOLUTION_TECHNIQUE_URL}`,
            data: body,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response: any) => {
                mutateSolutionTechniqueElements()
                render(
                    <Toaster
                        success
                        toastMsg="Multi-solution ajoutée avec succès !"
                    />,
                )
                data = response.statusText
                history.push('/technical-solution/' + `${response.data._id}`)
            })
            .catch(error => {
                render(
                    <Toaster error toastMsg={error.response.data.message[0]} />,
                )
            })
        return data
    }
    return { AddTechnicalSolutionElement }
}
