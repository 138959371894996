import axios, { AxiosResponse } from 'axios'
import { render } from '@testing-library/react'
import Toaster from 'common/Toaster/Toaster'
import {
    ExpertElement,
    UpdateExpertElement,
} from 'common/types/expert/ExpertElement'
import { SERVER_URL } from 'config/environments'
import useToken from 'hooks/session/userSession'
import useSWR from 'swr'

const FETCH_EXPERTS_URL = `${SERVER_URL.environment}/expert`

export function useExpert(ExpertId?: string) {
    const { accessToken } = useToken()
    let data = ''

    const { data: experts, mutate: mutateExpertsElements } = useSWR<
        ExpertElement[]
    >('api/expert/all', fetchAllExpert)

    async function fetchAllExpert() {
        const response = await fetch(`${FETCH_EXPERTS_URL}/all`)
        return response.json()
    }

    async function update(bodyFormData: FormData) {
        await axios({
            method: 'PUT',
            url: `${FETCH_EXPERTS_URL}/${ExpertId}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                render(
                    <Toaster success toastMsg="Expert modifié avec succès !" />,
                )
                mutateExpertsElements()
                data = response.statusText
            })
            .catch(error => {
                render(
                    <Toaster error toastMsg={error.response.data.message[0]} />,
                )
            })
        return data
    }

    async function updateOrder(
        body: Partial<{ elements: UpdateExpertElement[] }>,
    ): Promise<any> {
        const response = await fetch(`${FETCH_EXPERTS_URL}/all`, {
            body: JSON.stringify(body),
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        })

        if (!response.ok) {
            const error = await response.json()
            throw new Error(error.message)
        }

        return await response.json()
    }

    async function addExpert(bodyFormData: FormData) {
        await axios({
            method: 'POST',
            url: `${FETCH_EXPERTS_URL}`,
            data: bodyFormData,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response: AxiosResponse<ExpertElement>) => {
                render(
                    <Toaster success toastMsg="Expert ajouté avec succès !" />,
                )
                mutateExpertsElements()
                data = response.statusText
            })
            .catch(error => {
                render(
                    <Toaster error toastMsg={error.response.data.message[0]} />,
                )
            })
        return data
    }

    async function deleteExpert() {
        const response = await fetch(`${FETCH_EXPERTS_URL}/${ExpertId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        })
        render(<Toaster success toastMsg="Expert supprimé avec succès !" />)
        await mutateExpertsElements()
        return response.json()
    }

    return {
        addExpert,
        update,
        deleteExpert,
        updateOrder,
        experts,
        mutateExpertsElements,
    }
}
