import React, { useEffect } from 'react'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { useHistory } from 'react-router-dom'
import './Error404.scss'
import Aos from 'aos'
import 'aos/dist/aos.css'
import LogoError from '../../image/logo42nav.png'

const Error404 = () => {
    const history = useHistory()

    useEffect(() => {
        Aos.init()
    }, [])

    return (
        <div className="global-div" data-aos="fade-right">
            <img className="LogoError" src={LogoError} alt="logo" />
            <h1>ERROR 404</h1>
            <div className="align-icon" onClick={() => history.push('/')}>
                <h3>Retouner au menu </h3>
                <AiOutlineArrowRight />
            </div>
        </div>
    )
}

export default Error404
