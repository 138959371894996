import useSWR from 'swr'
import { FormationsElement } from '../../../../common/types/estiam/FormationsElement'
import { SERVER_URL } from '../../../../config/environments'

const FETCH_ALL_FORMATIONS_URL = `${SERVER_URL.environment}/estiam`

export function useFormationsElements() {
    const { data: formationsElements, mutate: mutateFormationsElements } =
        useSWR<FormationsElement[]>('api/estiam/all', fetchAllFormations)

    async function fetchAllFormations() {
        const response = await fetch(`${FETCH_ALL_FORMATIONS_URL}/all`)
        return response.json()
    }
    return { formationsElements, mutateFormationsElements }
}
