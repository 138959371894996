import { ContactElement } from 'common/types/contact/ContactElement'
import useSWR from 'swr'
import { SERVER_URL } from '../../../config/environments'

const FETCH_ALL_CONTACTS_URL = `${SERVER_URL.environment}/question-contact`

export function useContactsElements() {
    const { data: contactsElements, mutate: mutateContactsElements } = useSWR<
        ContactElement[]
    >('api/question-contact/all', fetchAllContact)

    async function fetchAllContact() {
        const response = await fetch(`${FETCH_ALL_CONTACTS_URL}/all`)
        return response.json()
    }
    return { contactsElements, mutateContactsElements }
}
