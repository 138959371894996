import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    CardHeader,
    Dialog,
    DialogTitle,
    DialogContent,
    Card,
    FormControl,
    OutlinedInput,
    InputLabel,
    FormHelperText,
    Grid,
    Typography,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { object, string } from 'yup'

import './AddAdvantage.scss'
import { useAdvantage } from 'hooks/join-us/advantage/UseAdvantage'
import ProgressBar from 'common/progress-bar/ProgressBar'
import { Box } from '@mui/system'
import EditorText from 'common/custom-text-editor/CustomEditorText'
import SaveButton from 'common/custom-button/SaveButton'
interface Props {
    _id?: string
    title: string
    description: string
    imageUrl: string
}

const schema = object({
    title: string(),
    description: string(),
})

export function AddAdvantage({ handleClose, joinUs, isOpen }: any) {
    const [loading, setLoading] = useState(false)
    const [valueDescription, setValueDescription] = useState<string>('')

    const { createAdvantage, updateAdvantage } = useAdvantage()

    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<Props>({ resolver: yupResolver(schema) })

    async function onSubmit() {
        setLoading(true)
        addAdvantage({
            title: getValues('title'),
        }).finally(() => setLoading(false))
    }

    const addAdvantage = async (advantageFormValues: { title: string }) => {
        if (joinUs.data._id) {
            const response = await updateAdvantage(joinUs.data._id, {
                ...advantageFormValues,
                description: valueDescription,
            })
            if (response) {
                handleClose()
            }
        } else {
            const response = await createAdvantage({
                ...advantageFormValues,
                description: valueDescription,
            })

            if (response) {
                handleClose()
            }
        }
    }

    useEffect(() => {
        setValueDescription(joinUs?.data?.description)
    }, [])

    return (
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
            >
                {joinUs.data._id ? (
                    <DialogTitle>{'Modifier un avantage'}</DialogTitle>
                ) : (
                    <DialogTitle>{'Ajouter un nouvel avantage'}</DialogTitle>
                )}
                <DialogContent>
                    <Card>
                        <CardHeader
                            title={
                                <>
                                    <FormControl
                                        sx={{ m: 1, width: '50ch' }}
                                        variant="outlined"
                                    >
                                        <InputLabel
                                            required
                                            htmlFor="title-joinUs"
                                        >
                                            Titre
                                        </InputLabel>
                                        <OutlinedInput
                                            required
                                            fullWidth
                                            label="Titre"
                                            id="title"
                                            {...register('title')}
                                            defaultValue={joinUs?.data?.title}
                                            onChange={e =>
                                                setValue(
                                                    'title',
                                                    e.target.value,
                                                )
                                            }
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}
                                        />
                                        <FormHelperText id="sector-joinUs-weight-helper-text">
                                            {errors?.title?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </>
                            }
                            subheader={
                                <>
                                    <Grid>
                                        <Box>
                                            <Typography variant="h6">
                                                Description :
                                            </Typography>
                                        </Box>
                                        <Box sx={{ pb: 1 }}>
                                            <EditorText
                                                currentValue={valueDescription}
                                                updateCurrentValue={
                                                    setValueDescription
                                                }
                                            />

                                            <Typography variant="caption">
                                                (Max : un paragraphe de 100 000
                                                caractères max.)
                                            </Typography>
                                        </Box>
                                        <span className="error">
                                            {errors?.description?.message}
                                        </span>
                                    </Grid>
                                </>
                            }
                        />
                    </Card>
                </DialogContent>

                <SaveButton {...{ handleClose }} />
            </form>

            <ProgressBar loading={loading} />
        </Dialog>
    )
}
