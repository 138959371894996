import React from 'react'
import DraggableListItem from './DraggableListItem'
import {
    DragDropContext,
    Droppable,
    OnDragEndResponder,
} from 'react-beautiful-dnd'
import TableBody from '@mui/material/TableBody'
import { ExpertElement } from 'common/types/expert/ExpertElement'
import { NavbarElement } from 'common/types/navbar-element/NavbarElement'

type Props = {
    elements: NavbarElement[] | ExpertElement[]
    onEditNavbar?: (element: NavbarElement) => void
    onEditExpert?: (element: ExpertElement) => void
    onDeleteClicked: (id: string) => void
    onDragEnd: OnDragEndResponder
    page: number
    rowsPerPage: number
    navbar?: boolean
    experts?: boolean
}

export default function DraggableList({
    elements,
    onEditExpert,
    onEditNavbar,
    onDeleteClicked,
    onDragEnd,
    page,
    rowsPerPage,
    navbar,
    experts,
}: Props) {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-list">
                {provided => (
                    <TableBody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {elements
                            ?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage,
                            )
                            .map(
                                (
                                    row: NavbarElement | ExpertElement,
                                    index: number,
                                ) => (
                                    <DraggableListItem
                                        index={index}
                                        key={row._id}
                                        element={row}
                                        onEditExpert={onEditExpert}
                                        onEditNavbar={onEditNavbar}
                                        onDeleteClicked={onDeleteClicked}
                                        navbar={navbar ? true : false}
                                        experts={experts ? true : false}
                                    />
                                ),
                            )}
                        {provided.placeholder}
                    </TableBody>
                )}
            </Droppable>
        </DragDropContext>
    )
}
