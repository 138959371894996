import React from 'react'
import { render } from '@testing-library/react'
import useToken from '../../session/userSession'
import { useMapMarkersElements } from '../all/UseMapMarkers'
import { SERVER_URL } from '../../../config/environments'
import Toaster from '../../../common/Toaster/Toaster'

const FETCH_ALL_MAP_MARKERS_URL = `${SERVER_URL.environment}/map-markers`

export function UseDeleteMapMarkerElement(MapMarkersElementId: string) {
    const { mutateMapMarkersElements } = useMapMarkersElements()
    const { accessToken } = useToken()

    async function deleteMapMarker() {
        const response = await fetch(
            `${FETCH_ALL_MAP_MARKERS_URL}/${MapMarkersElementId}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        )
        render(<Toaster success toastMsg="Repère supprimé avec succès !" />)
        await mutateMapMarkersElements()
        return response.json()
    }
    return { deleteMapMarker }
}
