import { DialogActions, Button } from '@mui/material'
import React from 'react'
import CheckIcon from '@mui/icons-material/Check'

interface SaveButtonProps {
    handleClose: () => void
}

export default function SaveButton({ handleClose }: SaveButtonProps) {
    return (
        <DialogActions>
            <Button onClick={handleClose}>Fermer</Button>
            <Button
                variant="outlined"
                color="primary"
                size="medium"
                startIcon={<CheckIcon />}
                type="submit"
            >
                ENREGISTRER
            </Button>
        </DialogActions>
    )
}
