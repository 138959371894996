import * as React from 'react'

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    TablePagination,
} from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { ApplyElement } from '../../../common/types/apply/ApplyElement'

type Props = {
    apply?: ApplyElement[]
    setApply: (objApply: ApplyElement) => void
    setIsOpen: (value: boolean) => void
}

export function ListApply({ apply, setApply, setIsOpen }: Props) {
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)
    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const onView = (row: ApplyElement) => {
        setApply(row)
        setIsOpen(true)
    }

    return (
        <>
            <Paper sx={{ width: '100%' }}>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Prénom</TableCell>
                                <TableCell align="left">Nom</TableCell>
                                <TableCell align="left">E-mail</TableCell>
                                <TableCell align="left">Offre</TableCell>
                                <TableCell align="left">CV</TableCell>
                                <TableCell align="left">Message</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {apply
                                ?.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )
                                .map((row: ApplyElement) => (
                                    <TableRow
                                        key={row._id}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                { border: 0 },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.firstName}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.lastName}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.email}
                                        </TableCell>
                                        <TableCell>
                                            {row?.jobOffer?.title}
                                        </TableCell>
                                        <TableCell align="left">
                                            <a
                                                href={row.resumeUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <DownloadIcon />
                                            </a>
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.createdAt}
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                onClick={() => onView(row)}
                                                size="large"
                                            >
                                                <RemoveRedEyeIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    labelRowsPerPage="Lignes par page"
                    component="div"
                    count={apply?.length ?? 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    )
}
