import React, { useState } from 'react'
import {
    Paper,
    TablePagination,
    TableRow,
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableBody,
    IconButton,
    Button,
    Box,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import ConfirmForm from 'common/confirm-form/ConfirmForm'
import { useQuoteElement } from 'hooks/job-offer/quote/UseQuote'
import { QuoteElement } from 'common/types/job-offer/quote'
import { AddQuote } from '../add-quote-job-offer/QuotePopup'
import AddIcon from '@mui/icons-material/Add'
import { JobOfferElement } from 'common/types/job-offer/JobOfferElement'

interface QuoteListProps {
    jobOffer: JobOfferElement
    toggleQuotePopup: () => void
}

export default function QuoteList({
    jobOffer,
    toggleQuotePopup,
}: QuoteListProps) {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [isOpen, setIsOpen] = useState(false)
    const [quoteElm, setQuoteElm] = useState<QuoteElement>()
    const [idQuote, setIdQuote] = useState('')
    const [openConfirmForm, setOpenConfirmForm] = useState(false)

    const { deleteQuote, quoteElements } = useQuoteElement(jobOffer._id)

    const handleClickOpen = (id: string) => {
        setIdQuote(id)
        setOpenConfirmForm(true)
    }

    const onDelete = () => {
        deleteQuote(idQuote)
        setOpenConfirmForm(false)
    }

    const handleCloseQuote = () => {
        setIsOpen(false)
    }

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const onEdit = (quoteElm: QuoteElement) => {
        setIdQuote(quoteElm._id)
        setQuoteElm(quoteElm)
        setIsOpen(true)
    }

    return (
        <>
            <Box sx={{ margin: '10px' }}>
                <Button
                    variant="outlined"
                    color="primary"
                    size="medium"
                    startIcon={<AddIcon />}
                    onClick={toggleQuotePopup}
                >
                    Ajouter une citation
                </Button>
            </Box>
            <Paper elevation={3} sx={{ padding: '10px' }}>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Nom</TableCell>
                                <TableCell>Prénom</TableCell>
                                <TableCell>Citation</TableCell>
                                <TableCell>Rôle</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {quoteElements
                                ?.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )
                                .map((row: any, index: number) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell>{row.lastName}</TableCell>
                                        <TableCell>{row.firstName}</TableCell>
                                        <TableCell>{row.quote}</TableCell>
                                        <TableCell>{row.role}</TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                onClick={() => onEdit(row)}
                                                size="large"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() =>
                                                    handleClickOpen(row._id)
                                                }
                                                size="large"
                                            >
                                                <DeleteOutlinedIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    labelRowsPerPage="Lignes par page"
                    component="div"
                    count={quoteElements ? quoteElements?.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

                <ConfirmForm
                    onConfirm={onDelete}
                    open={openConfirmForm}
                    setOpen={setOpenConfirmForm}
                    action="delete"
                />

                {isOpen && (
                    <AddQuote
                        handleClose={handleCloseQuote}
                        isOpen={isOpen}
                        jobOffer={jobOffer}
                        quoteId={idQuote}
                        quoteElm={quoteElm}
                    />
                )}
            </Paper>
        </>
    )
}
